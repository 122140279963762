export const accountConstants = {
  GET_SHOPPING_HISTORY_REQUEST: 'GET_SHOPPING_HISTORY_REQUEST',
  GET_SHOPPING_HISTORY_SUCCESS: 'GET_SHOPPING_HISTORY_SUCCESS',
  GET_SHOPPING_HISTORY_FAILURE: 'GET_SHOPPING_HISTORY_FAILURE',

  GET_DOWNLOAD_REPORTS_REQUEST: 'GET_DOWNLOAD_REPORTS_REQUEST',
  GET_DOWNLOAD_REPORTS_SUCCESS: 'GET_DOWNLOAD_REPORTS_SUCCESS',
  GET_DOWNLOAD_REPORTS_FAILURE: 'GET_DOWNLOAD_REPORTS_FAILURE',
  GET_DOWNLOAD_REPORTS_RESET: 'GET_DOWNLOAD_REPORTS_RESET'
};

export const documentTypes = [
  {type:'CC', name: 'Cédula de  ciudadanía'},
  {type:'NIT', name: 'Num. Ident. Empre'},
  {type:'CE', name: 'Cédula de extranjería'},
  {type:'PD', name: 'Pase diplomático'},
  {type:'TE', name: 'Tarjeta de extranjería'},
  {type:'PA', name: 'Pasaporte'},
  {type:'CD', name: 'Carnet diplomático'}
];

export const documentTypesDefault = {
  CC: 'CC',
  PA: 'PA',
  CE: 'CE'
};

export const mayoriaEdad = {
  PENDIENTE: 'Pendiente',
  SI: 'Si'
};

export const tratamientos = {
  PENDIENTE: 'P',
  SI: 'S'
};

export const politicas = {
  PENDIENTE: 'P',
  SI: 'S'
};
