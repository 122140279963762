import { userConstants } from './../constants/UserConstants';

const initialState = {
  registering: false,
  registered: false,
  error: null
};

export function registration(state = initialState, action) {
  switch (action.type) {
    case userConstants.REGISTER_USER_REQUEST:
      return {
        registering: true
      };
    case userConstants.REGISTER_USER_SUCCESS:
      return {
        registered: true
      };
    case userConstants.REGISTER_USER_FAILURE:
      return {
        error: action.payload
      };
    case userConstants.REGISTER_USER_CLEAR:
      return {};
    default:
      return state;
  }
}
