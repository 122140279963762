import { driedConstants } from './../constants/DriedConstants';

const initialState = {
  loading: false,
  dried: null,
  error: null
};

export function dried(state = initialState, action) {
  switch (action.type) {
    case driedConstants.LIST_DRIED_REQUEST:
      return {
        loading: true
      };
    case driedConstants.LIST_DRIED_SUCCESS:
      return {
        dried: action.payload
      };
    case driedConstants.LIST_DRIED_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
