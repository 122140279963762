import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';
import { toggleModalConfirmAge } from './../../actions/ModalActions';

class ModalInfoComponent extends Component {

  render () {
    const { className, open, actionConfirm} = this.props;
    
    return (
      <Modal isOpen={open} toggle={toggleModalConfirmAge} className={`confirm-age-modal ${className}`}>
        <ModalHeader>¿Eres mayor de edad?</ModalHeader>
        <ModalBody>
          {'Debemos validar que eres mayor de edad para que puedas disfrutar de todos los productos que tenemos para ti.'}
        </ModalBody>
        <ModalFooter>
          <Button 
            color="primary" 
            className="btn-boostrap" 
            onClick={actionConfirm}>Aceptar</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    open : state.modal.confirmAge.open
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModalConfirmAge() {
      dispatch(toggleModalConfirmAge());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalInfoComponent);