import { lotteriesConstants } from './../constants/LotterieConstants';
import LotteriesService from './../services/LotteriesService';
import { toggleModalGeneral } from './ModalActions';
import { isArray } from 'util';
//====================================================================
// Loading Home Loterries
//====================================================================

export const loadLotteriesHome = (values) => (dispatch) => {

  const lotteriesHomeRequest = () => ({
    type: lotteriesConstants.HOME_LOTTERIES_REQUEST
  });

  const lotteriesHomeSuccess = payload => ({
    type: lotteriesConstants.HOME_LOTTERIES_SUCCESS,
    payload
  });

  const lotteriesHomeError = error => ({
    type: lotteriesConstants.HOME_LOTTERIES_FAILURE,
    error
  });

  dispatch(lotteriesHomeRequest());
  LotteriesService.lotteriesHome(values)
    .then(
      lotteries => {
        //Ordenando el listado de loterías por fecha, en orden descendente
        lotteries.data = Array.isArray(lotteries.data) ? lotteries.data.sort((a,b) => {
          return new Date(`${a.fecCierre} ${a.horaCierre}`) - new Date(`${b.fecCierre} ${b.horaCierre}`);
        }) : [];
        dispatch(lotteriesHomeSuccess(lotteries.data));
      },
      error => {
        dispatch(lotteriesHomeError(error.message));
      }
    );
};

//====================================================================
// Loading All List Loterries
//====================================================================

export const loadListLotteries = (values) => (dispatch) => {

  const listLotteriesRequest = () => ({
    type: lotteriesConstants.LIST_LOTTERIES_REQUEST
  });

  const listLotteriesSuccess = payload => ({
    type: lotteriesConstants.LIST_LOTTERIES_SUCCESS,
    payload
  });

  dispatch(listLotteriesRequest());
  LotteriesService.lotteriesList(values)
    .then(
      lotteries => {
        if ( lotteries.data ) {
          dispatch(listLotteriesSuccess(lotteries.data.horariosLoteria));
        } else {
          dispatch(listLotteriesSuccess([]));
        }
      },
      error => {
        dispatch(listLotteriesSuccess([]));
      }
    );
};


//====================================================================
// Request if the user is a winner
//====================================================================

export const isWinner = (values) => (dispatch) => {

  const isWinnerRequest = () => ({
    type: lotteriesConstants.WINNER_LOTTERIES_REQUEST
  });

  const isWinnerSuccess = payload => ({
    type: lotteriesConstants.WINNER_LOTTERIES_SUCCESS,
    payload
  });

  const isWinnerError = error => ({
    type: lotteriesConstants.WINNER_LOTTERIES_FAILURE,
    error
  });

  dispatch(isWinnerRequest());
  LotteriesService.isWinnerLotterie(values)
    .then(
      response => {
        dispatch((response.data.ganador)?isWinnerSuccess(response):isWinnerError(response));
        dispatch(toggleModalGeneral());
      },
      error => {
        dispatch(isWinnerError(error.message ? error.message : 'Ocurrió un error al momento de consultar si eres ganador'));
        dispatch(toggleModalGeneral());
      }
    );

};
