import { locationsConstants } from './../constants/LocationsConstants';
import LocationsService from './../services/LocationsService';
const countriesRequest = () => ({
  type: locationsConstants.GET_COUNTRIES
});

const countriesSuccess = payload => ({
  type: locationsConstants.SET_COUNTRIES,
  payload
});

const statesRequest = (type) => ({
  type: locationsConstants[`GET_STATES_${type}`]
});

const statesSuccess = (payload, type) => ({
  type: locationsConstants[`SET_STATES_${type}`],
  payload
});

const citiesRequest = (type) => ({
  type: locationsConstants[`GET_CITIES_${type}`]
});

const citiesSuccess = (payload, type) => ({
  type: locationsConstants[`SET_CITIES_${type}`],
  payload
});

//====================================================================
// Loading Countries
//====================================================================

export const loadCountries = () => (dispatch) => {

  dispatch(countriesRequest());

  LocationsService.loadCountries()
    .then(
      countries => {
        dispatch(countriesSuccess(countries));
      }
    );
};

export const loadStates = (id, type) => (dispatch) => {

  dispatch(statesRequest(type));
  //dispatch(citiesRequest(type));

  if (id !== 'null' && id !== null) {
    LocationsService.loadStates(id)
      .then(
        states => {
          dispatch(statesSuccess(states, type));
        }
      );
  }

};

export const loadCities = (id, type) => (dispatch) => {

  dispatch(citiesRequest(type));

  if (id !== 'null' && id !== null) {
    LocationsService.loadCities(id)
      .then(
        cities => {
          dispatch(citiesSuccess(cities, type));
        }
      );
  }
};
