// Dependencies
import React, { Component } from 'react';
import classnames from 'classnames';
import { Col, Row, Container, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';

//Components
import NuestraEmpresa from './../../components/About/NuestraEmpresa';
import NuestraRed from './../../components/About/NuestraRed';

//Actions
import { getPageInfo } from './../../reducers/generalReducer';
import { Tab, TabList, Tabs } from 'react-tabs';

class AboutContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1'
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillMount () {
    const {
      match: {
        params: {
          tab = '1'
        }
      }
    } = this.props;

    this.setState({
      activeTab: (tab !== '1' && tab !== '2') ? '1' : tab
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      this.props.history.push(`/nosotros/${tab}`);
    }
  }
  render() {
    const { infoEmpresa, infoRed } = this.props;
    return (
      <Col xs={12} className="homeContainer">
        <Row>
          <Container className="bg-primary return-seccion">
            <section className="mt-5 mb-4 p-4">
              <div className="tabs-page">
                <Tabs tabs fill defaultIndex={this.state.activeTab-1}>
                  <TabList className="react-tabs__tab-list tab-container">
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => this.toggle('1')}
                    >
                      Nuestra Empresa
                    </Tab>
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => this.toggle('2')}
                    >
                      Nuestra Red
                    </Tab>
                  </TabList>
                </Tabs>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1" className="p-5 text-center">
                    {this.state.activeTab === '1' && infoEmpresa && <NuestraEmpresa info={infoEmpresa} />}
                  </TabPane>
                  <TabPane tabId="2" className="p-5 text-center">
                    {this.state.activeTab === '2' && infoRed && <NuestraRed info={infoRed}/>}
                  </TabPane>
                </TabContent>
              </div>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error } = state.general.pages;
  return {
    loading,
    error,
    infoEmpresa: getPageInfo(state, 'nuestra-empresa'),
    infoRed: getPageInfo(state, 'nuestra-red')
  };
};

export default connect(mapStateToProps)(AboutContainer);
