import 'whatwg-fetch';
import {decryptUserData,encryptUserData} from './security/informationCodingSecurity';
import { getAuthApp } from './security/authApp';
import { decrypt } from './security/encryptorHandlerSecurity';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

class PagoPremioService {

  static loadListPagoPremio(values) {

    let obDecryptUserData = {
      ideUsuario: values.ideUsuario
    };
    obDecryptUserData = decryptUserData(obDecryptUserData);
    values.ideUsuario = obDecryptUserData.ideUsuario;
    const obEncryptValues = encryptUserData(values);
    const url = `${serverURL}/api/Users/etn/pago-premio`;
    const data = JSON.stringify({...obEncryptValues}, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

}

export default PagoPremioService;
