import { contactConstants } from './../constants/ContactConstants';
import ContactService from './../services/ContactService';
import { reset } from 'redux-form';
// import GA4React, {UseGA4REact} from 'ga-4-react';
/* ==========================================================================
PQRS Page
========================================================================== */

export const loadListPQRS = () => (dispatch) => {

  const listPQRSRequest = () => ({
    type: contactConstants.LIST_PQRS_REQUEST
  });

  const listPQRSSuccess = payload => ({
    type: contactConstants.LIST_PQRS_SUCCESS,
    payload
  });

  dispatch(listPQRSRequest());

  ContactService.loadPQRS()
    .then(
      list => {
        list.forEach(element => {
          element.value = element.id.toString();
          element.label = element.title;
        });
        dispatch(listPQRSSuccess(list));
      },
      error => {
        dispatch(listPQRSSuccess([]));
      }
    );
};

export const savePQRS = (values) => (dispatch) => {

  const pqrsRequest = () => ({
    type: contactConstants.SAVE_PQRS_REQUEST
  });

  const pqrsSuccess = payload => ({
    type: contactConstants.SAVE_PQRS_SUCCESS,
    payload
  });

  const pqrsError = error => ({
    type: contactConstants.SAVE_PQRS_FAILURE,
    error
  });

  dispatch(pqrsRequest());
  ContactService.savePQRS(values)
    .then(
      response => {
        dispatch(pqrsSuccess(response));
        dispatch(reset('PqrsForm'));
      },
      error => {
        dispatch(pqrsError(error.message ? error.message : 'Ocurrió un error al momento de guardar el Comentario'));
      }
    );
};

/* ==========================================================================
Qualify Service Page
========================================================================== */

export const loadListService = () => (dispatch) => {

  const listServiceRequest = () => ({
    type: contactConstants.LIST_SERVICE_REQUEST
  });

  const listServiceSuccess = payload => ({
    type: contactConstants.LIST_SERVICE_SUCCESS,
    payload
  });

  dispatch(listServiceRequest());

  Promise.all([ContactService.loadServiceHeader(), ContactService.loadServiceQuestions()]).then(values => {
    values[0].forEach((item, index) => {
      item.label = item.name;
      item.value = `service-${item.id}`;
      item.options = values[1].filter(question => question.serviceQualifyId === item.id);
    });
    dispatch(listServiceSuccess(values[0]));
  }, reason => {
    dispatch(listServiceSuccess([]));
  });
};

export const saveService = (values) => (dispatch) => {

  const serviceRequest = () => ({
    type: contactConstants.SAVE_SERVICE_REQUEST
  });

  const serviceSuccess = payload => ({
    type: contactConstants.SAVE_SERVICE_SUCCESS,
    payload
  });

  const serviceError = error => ({
    type: contactConstants.SAVE_SERVICE_FAILURE,
    error
  });

  dispatch(serviceRequest());
  ContactService.saveService(values)
    .then(
      response => {
        dispatch(serviceSuccess(response));
        dispatch(reset('ServiceForm'));
      },
      error => {
        dispatch(serviceError(error.message ? error.message : 'Ocurrió un error al momento de Calificar el Servicio'));
      }
    );
};

/* ==========================================================================
FQA Page
========================================================================== */

export const loadQuestions = () => (dispatch) => {

  const questionsRequest = () => ({
    type: contactConstants.SAVE_QUESTIONS_REQUEST
  });

  const questionsSuccess = payload => ({
    type: contactConstants.SAVE_QUESTIONS_SUCCESS,
    payload
  });

  dispatch(questionsRequest());

  ContactService.loadQuestions()
    .then(
      questions => {
        dispatch(questionsSuccess(questions));
      },
      error => {
        dispatch(questionsSuccess([]));
      }
    );
};
