import { payprizeConstants } from '../constants/PayPrizeConstants';
import PayPrizeService from '../services/PayPrizeService';
//====================================================================
// Loading Home News
//====================================================================

export const loadPayPrizeHome = (values) => (dispatch) => {

  const payprizeHomeRequest = () => ({
    type: payprizeConstants.HOME_PAYPRIZE_REQUEST
  });

  const payprizeHomeSuccess = payload => ({
    type: payprizeConstants.HOME_PAYPRIZE_SUCCESS,
    payload
  });

  const payprizeHomeError = error => ({
    type: payprizeConstants.HOME_PAYPRIZE_FAILURE,
    error
  });

  dispatch(payprizeHomeRequest());
  PayPrizeService.loadPayPrizeHome()
    .then(
      payprize => {
        dispatch(payprizeHomeSuccess(payprize));
      },
      error => {
        dispatch(payprizeHomeError(error.message ? error.message : 'Ocurrió un error al momento de traer formulario de pago'));
      }
    );

};

export const loadSinglePayPrize = (id) => (dispatch) => {

  const singlePayprizeRequest = () => ({
    type: payprizeConstants.SINGLE_PAYPRIZE_REQUEST
  });

  const singlePayprizeSuccess = payload => ({
    type: payprizeConstants.SINGLE_PAYPRIZE_SUCCESS,
    payload
  });

  const singlePayprizeError = error => ({
    type: payprizeConstants.SINGLE_PAYPRIZE_FAILURE,
    error
  });

  dispatch(singlePayprizeRequest());
  PayPrizeService.loadSinglePayPrize(id)
    .then(
      payprize => {
        dispatch(singlePayprizeSuccess(payprize));
      },
      error => {
        dispatch(singlePayprizeError(error.message ? error.message : 'Ocurrió un error al momento de traer formulario de pago'));
      }
    );

};
