import { cartConstants } from './../constants/CartConstants';
import { createSelector } from 'reselect';
import Response$ from '../events/emitter';
import React, { useState } from 'react';




const initialState = {
  items: {
    lotteries: [],
    recharges: []
  },
  checkout: {
    success: false,
    loading: false,
    error: null
  },
  succes: false,
  loading: false,
  error: null,
  discountByLottery: {},
  porcentajeCoupon: null
};

export const cart = (state = initialState, action) => {
  switch (action.type) {
    case cartConstants.GET_CART_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cartConstants.GET_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload
      };
    case cartConstants.GET_CART_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cartConstants.ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cartConstants.ADD_PRODUCT_SUCCESS:
      const { products, typeProduct } = action.payload;
      return {
        ...state,
        loading: false,
        succes: true,
        items: {
          ...state.items,
          [typeProduct]: state.items[typeProduct].concat(products)
        }
      };
    case cartConstants.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cartConstants.DELETE_PRODUCT_REQUEST: {
      // Desestructuración específica solo para esta acción
      const { idProductDelete, typeProductDelete } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [typeProductDelete]: state.items[typeProductDelete].map(product =>
            product.id === idProductDelete
              ? { ...product, deleting: true }
              : product
          )
        }
      };
    }
    case cartConstants.DELETE_PRODUCT_SUCCESS: {
      // Desestructuración específica solo para esta acción
      const { idProductDelete, typeProductDelete } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [typeProductDelete]: state.items[typeProductDelete].filter(product => product.id !== idProductDelete)
        }
      };
    }
    case cartConstants.DELETE_PRODUCT_FAILURE: {
      // Desestructuración específica solo para esta acción
      const { idProductDelete, typeProductDelete, errorDelete } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [typeProductDelete]: state.items[typeProductDelete].map(product => {
            if (product.id === idProductDelete) {
              return { ...product, deleting: false, deleteError: errorDelete };
            }
            return product;
          })
        }
      };
    }
    case cartConstants.CHECKOUT_CART_REQUEST:
      return {
        ...state,
        checkout: {
          loading: true
        }
      };
    case cartConstants.CHECKOUT_CART_SUCCESS:
      return {
        ...state,
        checkout: {
          success: action.payload
        }
      };
    case cartConstants.CHECKOUT_CART_FAILURE:
      return {
        ...state,
        checkout: {
          error: action.error
        }
      };
    case cartConstants.SUCCESS_PAYMENT_GATEWAY_CONFISYS:
      const data = {};
      action.payload.data.forEach((item) => {
        data[item.nombre] = item.valorStr;
      });
      return {
        ...state,
        paymentsEnabled: data
      };
    case cartConstants.SET_DISCOUNT_BY_LOTTERY:
      return {
        ...state,
        discountByLottery: action.payload.discountByLottery,
        porcentajeCoupon: action.payload.porcentajeCoupon
      };
    case cartConstants.CLEAR_CART:
      return initialState;
    default:
      return state;
  }
};

let porcentajeCoupon = null;
let loteries = null;
let numeroFraccionesMinimas = null;
let numeroFraccionesMaximas = null;

var discountByLottery = {};
var addDesc = {};

//let discountByLottery = null;


Response$.on('myEvent', (mensaje) => {
  porcentajeCoupon = mensaje.message?.porcentaje_descuento || null;
  loteries = mensaje.loteries || [];

  numeroFraccionesMinimas = mensaje.message?.numeroFraccionesMinimas || 0;
  numeroFraccionesMaximas = mensaje.message?.numeroFraccionesMaximas || Infinity;

  const discountMap = {};

  if (loteries.length > 0 && porcentajeCoupon) {
    loteries.forEach(obj => {
      const id = obj.idLoteria;
      discountMap[id] = porcentajeCoupon;
    });

    discountByLottery = discountMap;
  } else {
    discountByLottery = {};
  }
});





const lotteriesSelector = state => state.cart.items.lotteries;
const rechargesSelector = state => state.cart.items.recharges;
const discountByLotterySelector = state => state.cart.discountByLottery;

/* export const getTotalCart = createSelector(
  lotteriesSelector,
  rechargesSelector,
  (lotteries, recharges) => (
    (typeof lotteries.length !='undefined' ? lotteries.reduce((acc, item) => acc + parseInt(item.total), 0) : 0) +
    (typeof recharges.length !='undefined' ? recharges.reduce((acc, item) => acc + parseInt(item.total), 0) : 0)
  )
);
 */

export const getTotalCart = createSelector(
  lotteriesSelector,
  rechargesSelector,
  (lotteries, recharges) => {
    const totalLotteries = typeof lotteries.length != 'undefined' ? lotteries.reduce((acc, item) => acc + parseInt(item.total), 0) : 0;
    const totalRecharges = typeof recharges.length != 'undefined' ? recharges.reduce((acc, item) => acc + parseInt(item.total), 0) : 0;

    // Asegúrate de que las variables tengan valores antes de usarlas
    const minFracciones = numeroFraccionesMinimas || 0;
    const maxFracciones = numeroFraccionesMaximas || Infinity;

    const discountedTotal = typeof lotteries.length != 'undefined' ? lotteries.reduce((acc, item) => {
      if (item.fracciones >= minFracciones && item.fracciones <= maxFracciones) {
        const discountPercentage = discountByLottery[item.ideLoteria] || 0;
        const discountedItemTotal = item.total * (1 - discountPercentage / 100);
        return acc + discountedItemTotal;
      } else {
        return acc + item.total;
      }
    }, 0) : 0;

    return discountedTotal + totalRecharges;
  }
);

