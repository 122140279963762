import React, { Component, useEffect, useState } from 'react';

import PlanPremio from '../../assets/images/secos.svg';

import { loadPrizePlan } from './../../actions/PrizePlanActions';
import { loadDried } from './../../actions/DriedActions';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import CircularProgress from '../Global/CircularProgress/CircularProgress';

class ModalSecos extends Component {
  constructor(props) {
    super(props);

    this.handleSearchPrizePlan = this.handleSearchPrizePlan.bind(this);

    this.state = {
      loadDried: false
    };
  }

  handleSearchPrizePlan = values => {
    this.props.loadDried({
      ideLoteria: values.loteria,
      fecha: values.fecha || null
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show && this.props.show != nextProps.show) {
      this.setState({ loadDried: true });
      this.props.loadDried({
        ideLoteria: this.props.idLotterieURL,
        fecha: this.props.fechaSorteo
      });
    }
  }

  render() {
    const { loading, dried, show, onClose, title } = this.props;

    function formatNumberWithDecimals(number, decimals) {
      const roundedNumber = Number(number);
      return roundedNumber.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: decimals });
    }

    return (
      <div className="modal" style={{ display: show ? 'flex' : 'none' }}>
        <div className="single-modal-content-pru">
          <div className='single-close'>
            <p onClick={onClose} className='single-close'>X</p>
          </div>
          <div className='modal-secos'>
            <img src={PlanPremio} className='single-img' />
          </div>
          <h2 className='single-h2'>{title}</h2>
          <div className="table-container" id="myTableContainer">
            {loading ? (
              <Col xs={12}>
                <CircularProgress />
              </Col>
            ) : (
              <div>
                {dried && dried.length > 0 ? (
                  <table className="single-table">
                    <thead>
                      <tr>
                        <th className='single-th' style={{ textAlign: 'left' }}>Premio</th>
                        <th className='single-th' style={{ textAlign: 'right' }}>Número</th>
                        <th className='single-th' style={{ textAlign: 'right' }}>Serie</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dried.map((row, index) => (
                        <tr key={row.idLotterieURL}>
                          <td className={index % 2 === 0 ? 'single-cell' : 'single-cell-now'}>{row.desTipoResultado}</td>
                          <td className={index % 2 === 0 ? 'single-cell' : 'single-cell-now'} style={{ textAlign: 'right' }}>
                            {formatNumberWithDecimals(row.numero, 2)}
                          </td>
                          <td className={index % 2 === 0 ? 'single-cell' : 'single-cell-now'} style={{ textAlign: 'right' }}>
                            {formatNumberWithDecimals(row.serie, 2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className='textData'>Data no disponible</p>
                )}
              </div>
            )}
          </div>
          <h1 className='single-footer-secos'>Este premio puede variar dependiendo de la cantidad de  fracciones de billete que elijas. Podrás ver un detalle del posible
            premio que ganarás  más adelante cuando selecciones tu número de fracciones.</h1>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, dried } = state.dried;
  return {
    loading,
    error,
    dried
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadDried(values) {
      dispatch(loadDried(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSecos);
