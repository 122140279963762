import React, { Component, useEffect, useState } from 'react';

import datosPremios from '../../assets/images/Datos-Premios.png';

import { loadRecargaOki } from '../../actions/RecargaOkiActions';
import { loadReclamaPremio } from '../../actions/ReclamarPremioActions';
import { connect } from 'react-redux';
import { Button, Col } from 'reactstrap';
import {decryptUserData,encryptUserData} from '../../services/security/informationCodingSecurity';
import CircularProgress from '../Global/CircularProgress/CircularProgress';

import DetallesValor from '../../assets/images/DetallesValorFraccion.svg';
import DetallesFracciones from '../../assets/images/DetallesFracciones.svg';
import DetallesTotal from '../../assets/images/DetallesTotal.svg';
import DetallesJuega from '../../assets/images/DetallesJuega.svg';

import RecargaOkiService from '../../services/recargaOkiService';

import AWS from 'aws-sdk';

import ModalComponent from './ModalComponent';

const ModalPagoPremios = ({
  loading,
  error,
  pagoPremio,
  ideUsuario,
  colillaUsuario,
  ideEstadoUsuario,
  loadPagoPremio,
  celularUsuario,
  nombreUsuario,
  saldoUsuario,
  emailUsuario,
  apellidoUsuario,
  usuarioUser,
  usuarioUserConsulta,
  selectedPremioValor,
  selectedLoteria,
  show,
  handleOnSave,
  loadReclamaPremio,
  onClose }) => {

  const desLoteria = pagoPremio ? pagoPremio.desLoteria : null;
  const numero = pagoPremio && typeof pagoPremio.numero === 'string' ? pagoPremio.numero.replace(/\*/g, '') : null;
  const serieBillete = pagoPremio && typeof pagoPremio.serieBillete === 'string' ? pagoPremio.serieBillete.replace(/\*/g, '') : null;
  const sorteo = pagoPremio ? pagoPremio.sorteo : null;
  const vlrGanado = pagoPremio ? pagoPremio.vlrGanado : null;
  const desEstado = pagoPremio ? pagoPremio.desEstado : null;
  const fecSorteo = pagoPremio ? pagoPremio.fecSorteo : null;
  const colilla = pagoPremio ? pagoPremio.colilla : null;
  const LinkOkiEnv = process.env.REACT_APP_LINK_OKI;

  console.log('pagoPremio', pagoPremio);

  const [nuevoSaldo, setNuevoSaldo] = useState(null);

  const [mensajeActualizacion, setMensajeActualizacion] = useState('');
  const [vlrPremioActualizar, setVlrPremioActualizar] = useState(null);

  function generateUniqueCode() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const codeLength = 10;

    let uniqueCode = '';
    const timestamp = new Date().getTime().toString();

    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueCode += characters.charAt(randomIndex);
    }
    return timestamp + uniqueCode;
  }

  function enviarSMS() {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    });

    const sns = new AWS.SNS();

    const phoneNumber = '+57' + celularUsuario;

    const fecha = new Date(fecSorteo);
    const fechaFormateada = fecha.toLocaleDateString();

    const vlrPremioFormateado = vlrGanado.toLocaleString();

    const message = `${nombreUsuario}, se ha efectuado el pago del premio en tu cuenta OKI que ganaste el día ${fechaFormateada} por valor de $${vlrPremioFormateado} por la compra de ${desLoteria}.`;
    const utf8Message = Buffer.from(message).toString('utf8');

    const params = {
      Message: message,
      PhoneNumber: phoneNumber
    };

    sns.publish(params, (err, data) => {
      if (err) {
        console.error('Error al enviar el SMS:', err);
      } else {
        console.log('SMS enviado con éxito:', data.MessageId);
      }
    });
  }

  function enviarCorreo() {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    });

    const ses = new AWS.SES();

    const source = process.env.REACT_APP_REMITENTE;
    const data = process.env.REACT_APP_ASUNTO;

    const fecha = new Date(fecSorteo);
    const fechaFormateada = fecha.toLocaleDateString();

    const vlrPremioFormateado = vlrGanado.toLocaleString();

    const params = {
      Destination: {
        ToAddresses: [emailUsuario]
      },
      Message: {
        Body: {
          Html: {
            Data: `
            <html>
              <head>
                <meta name="viewport" content="width=device-width, initial-scale=1">
              </head>
              <body style="
                background-image: url('https://images.ctfassets.net/h8yqim07d20d/2HcT2SStwxBxIfXxgmouqk/beffb82fbdde3063cff5fe0775c19020/Correo.png');
                background-size: 550px 600px;
                background-repeat: no-repeat;
                background-position: center top;
                border-radius: 20px;">

                <!-- Contenedor de tabla para controlar el diseño -->
                <table width="100%" cellpadding="0" cellspacing="0">
                  <tr>
                    <td style="padding-top: 200px; width: 100%; text-align: center;">
                      <!-- Contenido con márgenes -->
                      <p style="text-align: center; font-family: 'Arial Black', Arial, sans-serif; font-weight: bold;">Fecha del premio: ${fechaFormateada}</p>
                      <p style="text-align: center; font-family: 'Arial Black', Arial, sans-serif; font-weight: bold;">Producto ganado: ${desLoteria}</p>
                      <p style="text-align: center; font-family: 'Arial Black', Arial, sans-serif; font-weight: bold;">Valor ganado: $${vlrPremioFormateado}</p>
                      <p style="text-align: center; font-family: 'Arial Black', Arial, sans-serif; font-weight: bold;">Verificar el pago de la cuenta aquí</p>
                      <p style="text-align: center; font-family: 'Arial Black', Arial, sans-serif; font-weight: bold;">Cuenta OKI: <a href="${LinkOkiEnv}">link billetera OKI</a></p>
                      <p style="text-align: center; font-family: 'Arial Black', Arial, sans-serif; font-weight: bold;"><a href="[Enlace para jugar]">Juega Ahora y reta tu suerte aquí</a></p>
                      <p style="text-align: center; font-family: 'Arial Black', Arial, sans-serif; font-weight: bold;">Loti.com.co</p>
                    </td>
                  </tr>
                </table>
              </body>
            </html>
            `
          }
        },
        Subject: {
          Data: data
        }
      },
      Source: source
    };

    ses.sendEmail(params, (err, data) => {
      if (err) {
        console.log('Error al enviar el correo electrónico:', err);
      } else {
        console.log('Correo electrónico enviado con éxito:', data);
      }
    });
  }

  function enviarCorreoReporte() {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    });

    const ses = new AWS.SES();
    const toAddresses = [process.env.REACT_APP_DESTINARARIO];
    const source = process.env.REACT_APP_REMITENTE;
    const data = process.env.REACT_APP_ASUNTO_REPORTE;

    const params = {
      Destination: {
        ToAddresses: toAddresses
      },
      Message: {
        Body: {
          Html: {
            Data: `
            <html>
              <head>
                <meta name="viewport" content="width=device-width, initial-scale=1">
              </head>
              <body style="
                background-image: url('https://images.ctfassets.net/h8yqim07d20d/2HcT2SStwxBxIfXxgmouqk/beffb82fbdde3063cff5fe0775c19020/Correo.png');
                background-size: 550px 600px;
                background-repeat: no-repeat;
                background-position: center top;
                border-radius: 20px;">

                <!-- Contenedor de tabla para controlar el diseño -->
                <table width="100%" cellpadding="0" cellspacing="0">
                  <tr>
                    <td style="padding-top: 200px; width: 100%; text-align: center;">
                      <!-- Contenido con márgenes -->
                      <p style="text-align: center; font-family: 'Arial Black', Arial, sans-serif; font-weight: bold;">Número de Cédula: ${usuarioUser}</p>
                      <p style="text-align: center; font-family: 'Arial Black', Arial, sans-serif; font-weight: bold;">Nombre y apellido: ${nombreUsuario} ${apellidoUsuario}</p>
                      <p style="text-align: center; font-family: 'Arial Black', Arial, sans-serif; font-weight: bold;">Número de Teléfono: ${celularUsuario}</p>
                    </td>
                  </tr>
                </table>
              </body>
            </html>
            `
          }
        },
        Subject: {
          Data: data
        }
      },
      Source: source
    };

    ses.sendEmail(params, (err, data) => {
      if (err) {
        console.log('Error al enviar el correo electrónico:', err);
      } else {
        console.log('Correo electrónico enviado con éxito:', data);
      }
    });
  }

  handleOnSave = () => {
    const values = {};
    // const ideSolicitud = values.ideSolicitud.trim().split(' ');
    const codigoAleatorio = generateUniqueCode();
    values.ideSolicitud = codigoAleatorio;
    let obDecryptUserData = {
      ideUsuario: ideUsuario
    };
    obDecryptUserData = decryptUserData(obDecryptUserData);
    values.ideUsuario = obDecryptUserData.ideUsuario;
    console.log('values.ideUsuario', values.ideUsuario);
    values.loteria = desLoteria;
    values.sorteo = sorteo;
    values.numero = numero;
    values.serie = serieBillete;
    values.valor = vlrGanado;
    values.medioPago = 'OKI';
    values.ideEstado = process.env.REACT_APP_DES_ESTADO;
    loadReclamaPremio(values);
  };

  const handleActualizarValores = () => {
    if (vlrPremioActualizar !== null) {
      setValues({
        ...values,
        valor: vlrPremioActualizar.toString()
      });
    }
  };

  const [values, setValues] = useState({
    usuarioUserConsulta: usuarioUserConsulta !== null && usuarioUserConsulta !== undefined ? usuarioUserConsulta.toString() : '',
    valor: saldoUsuario !== null && saldoUsuario !== undefined ? saldoUsuario.toString() : '',
    ideEstado: process.env.REACT_APP_ESTADO,
    colilla: ''
  });

  // useEffect(() => {
  //   if (ideEstadoUsuario !== null && ideEstadoUsuario !== undefined) {
  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       ideEstado: ideEstadoUsuario.toString()
  //     }));
  //   }
  // }, [ideEstadoUsuario]);

  useEffect(() => {
    if (colillaUsuario !== null && colillaUsuario !== undefined) {
      setValues((prevValues) => ({
        ...prevValues,
        colilla: colillaUsuario.toString()
      }));
    }
  }, [colillaUsuario]);

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      ideEstado: process.env.REACT_APP_ESTADO
    }));
  }, []);

  useEffect(() => {
    if (pagoPremio && pagoPremio.vlrGanado !== null) {
      setVlrPremioActualizar(pagoPremio.vlrGanado.toString());
      const valorNumerico = isNaN(parseFloat(values.valor)) ? 0 : parseFloat(values.valor);

      setNuevoSaldo(valorNumerico + parseFloat(pagoPremio.vlrGanado));
      console.log('ytr', values.valor);
      console.log('ytr', pagoPremio.vlrGanado);
    }
    console.log('pagoPremioR', pagoPremio);
  }, [pagoPremio]);

  function Mensaje({ message }) {
    return (
      <div className="mensaje">
        {message}
      </div>
    );
  }

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');

  const handleAceptarClick = () => {
    // const nuevoSaldo = parseFloat(values.valor) + parseFloat(pagoPremio.vlrPremio);
    // console.log('ultimo valor', nuevoSaldo);

    openModal();

    setMessage('Realizando recarga para su Cuenta Oki');
    setShowMessage(true);

    // setNuevoSaldo(nuevoSaldo);
    console.log('pagoPremioRetro', usuarioUserConsulta);
    console.log('pagoPremioRetro', nuevoSaldo);
    console.log('pagoPremioRetro', values.ideEstado);
    console.log('pagoPremioRetro', colilla);
    console.log('nuevoSaldo.toString()', nuevoSaldo.toString());

    RecargaOkiService.loadListRecargaOki({
      usuario: values.usuarioUserConsulta,
      valor: nuevoSaldo.toString(),
      ideEstado: values.ideEstado,
      colilla: values.colilla
    })
      .then((response) => {
        handleActualizarValores();
        console.log('Respuesta exitosa:', response);

        handleOnSave();

        enviarSMS();

        enviarCorreo();

        enviarCorreoReporte();

        // if (response && response.exitoso) {
        setMessage('Saldo cargado a su cuenta de OKI, por favor verificar');
        // } else {
        //   setMessage('Error al realizar la recarga');
        // }

        // // Simulación de retardo (esto puede variar según tu lógica real)
        // setTimeout(() => {
        //   // Muestra el mensaje "Saldo cargado a su cuenta de OKI, por favor verificar"
        //   setMessage('Saldo cargado a su cuenta de OKI, por favor verificar');
        //   setShowMessage(true);

        //   // Cierra el modal después de cierto tiempo (por ejemplo, 2 segundos)
        // }, 2000); // Simulación de tiempo de recarga
        setTimeout(() => {
          onClose(); // Esta función debería cerrar el modal
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.error('Error al realizar la recarga:', error);
        // Muestra un mensaje de error en caso de un error
        setMessage('Error al realizar la recarga');
        setShowMessage(true);
        onClose();
      });
  };

  // const handleAceptarClick = () => {
  //   RecargaOkiService.loadListRecargaOki({
  //     ideUsuario: values.ideUsuario,
  //     valor: values.valor,
  //     ideEstado: values.ideEstado,
  //     colilla: values.colilla
  //   })
  //     .then((response) => {
  //       console.log('Respuesta exitosa:', response);
  //       // Actualiza los valores en el estado después de una respuesta exitosa
  //       handleActualizarValores(); // Llama a la función para actualizar los valores
  //       // Muestra el mensaje de éxito
  //       setMessage('Realizando recarga');
  //       setShowMessage(true);
  //     })
  //     .catch((error) => {
  //       console.error('Error al realizar la recarga:', error);
  //       // Muestra un mensaje de error en caso de un error
  //       setMessage('Error al realizar la recarga');
  //       setShowMessage(true);
  //     });
  // };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="modal" style={{ display: show ? 'flex' : 'none' }}>
      {/* {mensajeActualizacion && (
        <div className={mensajeActualizacion.includes('correctamente') ? 'mensaje-exito' : 'mensaje-error'}>
          {mensajeActualizacion}
        </div>
      )} */}
      {showModal && (
        <div className="modal">
          <div className="modal-dialog-centered">
            <CircularProgress message={message} />
            {/* Agrega aquí el contenido de tu modal */}
          </div>
        </div>
      )}
      {/* {showMessage &&
        <ModalComponent className="modal-dialog-centered" dontClose>
          <CircularProgress message="Redireccionando a la Pasarela de Pago" />
        </ModalComponent>} */}
      {/* {showMessage && <h1>{message}</h1>} */}
      <div className="single-modal-content-pru">
        <div className='single-close'>
          <p onClick={onClose} className='single-close'>X</p>
        </div>
        <div className='modal-secos'>
          <img src={datosPremios} className='single-img-datos-pago mb20' />
        </div>
        <h2 className='h2-datos-premio'>Datos del premio</h2>
        {loading ? (
          <Col xs={12}>
            <CircularProgress />
          </Col>
        ) : (
          <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <div className='single-Detalle' style={{ margin: '10px 20px 20px' }}>
              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : (
                <div className='posiciones align-center p-1'>
                  <img src={DetallesValor} className='single-img-Detalle' />
                  <div className='text-left'>
                    <p className='single-span-Detalle no-mrn'>Lotería</p>
                    <p className='single-span-Detalle-valor no-mrn'>{desLoteria}</p>
                  </div>
                </div>
              )}
              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : (
                <div className='posiciones align-center p-1'>
                  <img src={DetallesFracciones} className='single-img-Detalle' />
                  <div className='text-left'>
                    <p className='single-span-Detalle no-mrn'>Número</p>
                    <p className='single-span-Detalle-valor no-mrn'>{numero}</p>
                  </div>
                </div>
              )}
              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : (
                <div className='posiciones align-center p-1'>
                  <img src={DetallesTotal} className='single-img-Detalle' />
                  <div className='text-left'>
                    <p className='single-span-Detalle no-mrn'>Valor</p>
                    <p className='single-span-Detalle-valor no-mrn'>{vlrGanado}</p>
                  </div>
                </div>
              )}
            </div>
            <div className='single-Detalle' style={{ margin: '10px 20px 20px' }}>
              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : (
                <div className='posiciones align-center p-1'>
                  <img src={DetallesFracciones} className='single-img-Detalle' />
                  <div className='text-left'>
                    <p className='single-span-Detalle no-mrn'>Serie</p>
                    <p className='single-span-Detalle-valor no-mrn'>{serieBillete}</p>
                  </div>
                </div>
              )}
              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : (
                <div className='posiciones align-center p-1'>
                  <img src={DetallesTotal} className='single-img-Detalle' />
                  <div className='text-left'>
                    <p className='single-span-Detalle no-mrn'>Sorteo {usuarioUser}</p>
                    <p className='single-span-Detalle-valor no-mrn'>{sorteo}</p>
                  </div>
                </div>
              )}
              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : (
                <div className='posiciones align-center p-1'>
                  <img src={DetallesJuega} className='single-img-Detalle' />
                  <div className='text-left'>
                    <p className='single-span-Detalle no-mrn'>Estado</p>
                    <p className='single-span-Detalle-valor no-mrn'>{desEstado}</p>
                  </div>
                </div>
              )}
            </div>
          </Col>
        )}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* <div className="button-text-reverse-ver-loterias text-center" onClick={onClose}> */}
          <a onClick={onClose} className='button-text-reverse-pago-premio mr-3 text-center'>Cancelar</a>
          <Button color="primary" style={{ fontSize: '20px', fontWeight: 'bold' }} onClick={handleAceptarClick}>Aceptar</Button>
          {/* {showMessage && (
            <div>
              <p>Actualización exitosa</p>
              <p>Valores actualizados:</p>
              <p>ideUsuarioWeb: {values.ideUsuario}</p>
              <p>valor: {values.valor}</p>
              <p>ideEstado: {values.ideEstado}</p>
              <p>colilla: {values.colilla}</p>
            </div>
          )} */}
          {/* <Button color="primary" onClick={onClose} style={{ fontSize: '20px', fontWeight: 'bold', marginRight: '20px' }}>Cancelar</Button> */}
        </div>
      </div>
      {/* <button onClick={openModal}>Abrir Modal</button> */}

      {/* {showModal && (
        <ModalComponent className="modal-dialog-centered" dontClose>
          <CircularProgress message="Redireccionando a la Pasarela de Pago" />
        </ModalComponent>
      )} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loading, error, recargaOki } = state.recarga;
  const { reclamo } = state.reclamo;
  return {
    loading,
    error,
    recargaOki,
    reclamo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRecargaOki(values) {
      dispatch(loadRecargaOki(values));
    },
    loadReclamaPremio(values) {
      dispatch(loadReclamaPremio(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPagoPremios);
