// Dependencies
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Col, Row, Container, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

//Containers
import EditAccountContainer from './../../containers/Account/EditAccountContainer';
import ChangePasswordContainer from './../../containers/Account/ChangePasswordContainer';
import ShoppingHistoryContainer from './../../containers/Account/ShoppingHistoryContainer';
import DeleteAccountContainer from './../../containers/Account/DeleteAccountContainer';
import { Tab, TabList, Tabs } from 'react-tabs';
import FormPaymentAward from '../../containers/Account/FormPaymentAwardContainer';
import { loadwebConfisysControlar } from '../../actions/WebConfisysControlarActions';
const enablePaymentTab = process.env.REACT_APP_ENABLE_PAYMENT_TAB;

const Account = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [nombre, setNombre] = useState('');
  const [valorStr, setValorStr] = useState('');

  useEffect(() => {
    const {
      match: {
        params: {
          tab = '1'
        }
      }
    } = props;

    setActiveTab((tab !== '1' && tab !== '2' && tab !== '3' && tab !== '4' && tab !== '5') ? '1' : tab);
  }, [props]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      props.history.push(`/mi-cuenta/${tab}`);
    }
  };

  useEffect(() => {
    console.log('Efecto activado');
    props.loadwebConfisysControlar({
      nombre: nombre
    });
  }, [nombre, props.loadwebConfisysControlar]);

  useEffect(() => {
    if (props.webConfisysControlar && props.webConfisysControlar.length > 0) {
      const valorStr = props.webConfisysControlar[0].valorStr;
      setValorStr(valorStr);
      console.log('Valor de valorStr:', valorStr);
    }
  }, [props.webConfisysControlar]);

  console.log('valorStr', valorStr);

  return (
    <Col xs={12} className="homeContainer">
      <Row>
        <Container className="bg-primary return-seccion">
          <section className="mt-5 mb-4 p-4">
            <div className="general-tabs tabs-page NavLinkNoPadding tab-container-account">
              <Tabs tabs fill defaultIndex={activeTab - 1}>
                <TabList className="react-tabs__tab-list tab-container">
                  <Tab
                    className="button-rounded button-rounded-puntos px-20 py5"
                    onClick={() => toggle('1')}
                  >
                    Datos e Información
                  </Tab>
                  <Tab
                    className="button-rounded button-rounded-puntos px-20 py5"
                    onClick={() => toggle('2')}
                  >
                    Cambiar Contraseña
                  </Tab>
                  <Tab
                    className="button-rounded button-rounded-puntos px-20 py5"
                    onClick={() => toggle('3')}
                  >
                    Historial de Compras
                  </Tab>
                  <Tab
                    className="button-rounded button-rounded-puntos px-20 py5"
                    onClick={() => toggle('4')}
                  >
                    Eliminar Cuenta
                  </Tab>
                  {valorStr === 'S' && (
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => toggle('5')}
                    >
                      Pago de premios
                    </Tab>
                  )}
                </TabList>
              </Tabs>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="p-5 text-center">
                  {activeTab === '1' && <EditAccountContainer />}
                </TabPane>
                
                <TabPane tabId="2" className="p-5 text-center">
                  {activeTab === '2' && <ChangePasswordContainer />}
                </TabPane>

                <TabPane tabId="3" className=" text-center">
                  {activeTab === '3' && <ShoppingHistoryContainer />}
                </TabPane>

                <TabPane tabId="4" className="p-5 text-center">
                  {activeTab === '4' && <DeleteAccountContainer />}
                </TabPane>

                {valorStr === 'S' && (
                  <TabPane tabId="5" className="p-2 text-center">
                    {activeTab === '5' && <FormPaymentAward />}
                  </TabPane>
                )}
              </TabContent>
            </div>
          </section>
        </Container>
      </Row>
    </Col>
  );
};

const mapStateToProps = (state) => {
  const { webConfisysControlar } = state.confisysControlar;
  return {
    webConfisysControlar
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadwebConfisysControlar(values) {
      dispatch(loadwebConfisysControlar(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
