/* eslint-disable prefer-template */
import {decryptUserData} from './../services/security/informationCodingSecurity';
import 'whatwg-fetch';
import { getAuthApp } from '../services/security/authApp';
import { encryptkey } from '../services/security/encryptorHandlerSecurity';

const serverURL = process.env.REACT_APP_SERVERURL;
const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

const handleResponseb = (response) => {
  if (!response.ok) {
    return Promise.reject(response.json());
  }

  return response.json();
};

class AccountService {

  static loadShoppingList(date) {

    let user = localStorage.getItem('userLoti');
    if (user) {
      user = JSON.parse(user);
      user = decryptUserData(user);
      const dateFilter = date ? `&filter[where][updatedAt][regexp]=${encryptkey(date)}` : '';
      const url = `${serverURL}/api/carts?filter[fields][id]=true`+
      '&filter[fields][order]=true&filter[fields][state]=true&filter[fields][updatedAt]=true&filter[fields][reference]=true'+
      `&filter[include]=${encryptkey('lotteries')}`+
      `&filter[include]=${encryptkey('recharges')}`+
      '&filter[where][user]='+encryptkey(user.userId.toString(), true)+
      `&filter[where][state][inq]=${encryptkey('PENDING')}`+
      `&filter[where][state][inq]=${encryptkey('REJECTED')}`+
      `&filter[where][state][inq]=${encryptkey('APPROVED')}`+
      `${dateFilter}&filter[order]=${encryptkey('id DESC')}`;
      
      return fetch(url, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': getAuthApp()
        })
      })
        .then(handleResponse)
        .then(cart => cart)
        .catch(error => {
          return Promise.reject(error);
        });
    } else {
      return Promise.reject('Ocurrio un error al momento de traer las ordenes de compra');
    }
  }


  static getReport(values) {
    const url = `${serverURL}/api/Users/etn/reporte-ventas`;
    const data = JSON.stringify({data: {...values}}, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'cache': false,
        'Authorization': getAuthApp()
      })
    }).then(handleResponseb)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default AccountService;
