import { homepayprizeConstants } from '../constants/HomePayPrizeConstants';
import PayPrizeHomeService from '../services/HomePayPrizeService';
//====================================================================
// Loading Home News
//====================================================================

export const loadHomePayPrize = (values) => (dispatch) => {

  const homepayPrizeRequest = () => ({
    type: homepayprizeConstants.HOME_HOMEPAYPRIZE_FAILURE
  });

  const homepayPrizeSuccess = payload => ({
    type: homepayprizeConstants.HOME_HOMEPAYPRIZE_SUCCESS,
    payload
  });

  const homepayPrizeError = error => ({
    type: homepayprizeConstants.HOME_HOMEPAYPRIZE_FAILURE,
    error
  });

  dispatch(homepayPrizeRequest());
  PayPrizeHomeService.loadHomePayPrize()
    .then(
      homepayprize => {
        dispatch(homepayPrizeSuccess(homepayprize));
      },
      error => {
        dispatch(homepayPrizeError(error.message ? error.message : 'Ocurrió un error al momento de traer formulario de pago pra Home'));
      }
    );

};

export const loadSinglePayPrizeHome = (id) => (dispatch) => {

  const singlePayprizehomeRequest = () => ({
    type: homepayprizeConstants.SINGLE_HOMEPAYPRIZE_REQUEST
  });

  const singlePayprizehomeSuccess = payload => ({
    type: homepayprizeConstants.SINGLE_HOMEPAYPRIZE_SUCCESS,
    payload
  });

  const singlePayprizehomeError = error => ({
    type: homepayprizeConstants.SINGLE_HOMEPAYPRIZE_FAILURE,
    error
  });

  dispatch(singlePayprizehomeRequest());
  PayPrizeHomeService.loadSinglePayPrizeHome(id)
    .then(
      homepayprize => {
        dispatch(singlePayprizehomeSuccess(homepayprize));
      },
      error => {
        dispatch(singlePayprizehomeError(error.message ? error.message : 'Ocurrió un error al momento de traer formulario de pago'));
      }
    );

};
