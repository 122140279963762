//Dependencies
import React from 'react';
import { reduxForm, Field } from 'redux-form';
import moment from 'moment';
import { Col, Row } from 'reactstrap';

//Components
import renderDatePicker from './../renderFields/renderDatePicker';

//Utils
import Validators from './../renderFields/validatorsFields';
import { normalizeDateRevert } from '../renderFields/normalizeFields';

const DateInlineForm = ({
  handleSubmit,
  onDelete,
  pristine,
  classForm,
  reset,
  submitting,
  loadingForm,
  readOnly,
  autoReset
}) => {
  return (
    <form onSubmit={handleSubmit} className={`${classForm} form-lotterie`}>
      <Row className="m-0 justify-content-stretch align-items-start">
        <Col xs={12} sm={12} md={8} ld={9} xl={9}>
          <Row className="align-items-baseline">
            <Col xs={12} sm={3}>
              <label htmlFor="lotterie-date" className="d-inline-block label-inline interna-resultados">Fecha:</label>
            </Col>
            <Col xs={12} sm={9}>
              <Field
                name="fecha"
                id="lotterie-date"
                label="yyyy-mm-dd"
                autocomplete="off"
                inputValueFormat="YYYY-MM-DD"
                dateFormat="YYYY-MM-DD"
                normalize={normalizeDateRevert}
                maxDate={moment()}
                format={value => Validators.FormatDate(value, 'YYYY-MM-DD')}
                component={renderDatePicker}
                readOnly={readOnly}
                autoReset={autoReset=true}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={4} lg={3} xl={3}>
          <div className="table-cart d-inline-block">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loadingForm}
            >
              Buscar
              {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
            </button>
          </div>
        </Col>
      </Row>

    </form>
  );
};

export default reduxForm({ form: 'DateInlineForm'})(DateInlineForm);
