import { gameConstants } from './../constants/GameConstants';
import { createSelector } from 'reselect';
import {decrypt} from '../services/security/encryptorHandlerSecurity';
const lottery = localStorage.getItem('gameLoti') ? JSON.parse(decrypt(localStorage.getItem('gameLoti'))):null;
const fractions = [];
if ( lottery ) {
  for (let index = 0; index < lottery.numFracciones; index++) {
    fractions.push({
      label: '1',
      value: 1,
      disabled: false
    });
  }
}
const initialState = {
  series: {
    loading: false,
    list: [],
    error: null
  },
  singleSerie: {
    loading: false,
    success: false,
    error: null
  },
  lottery: lottery || null,
  listFractions: lottery ? fractions : []
};

export function game(state = initialState, action) {
  switch (action.type) {
    case gameConstants.GAME_SERIES_REQUEST:
      return {
        ...state,
        series:{
          loading: true
        }
      };
    case gameConstants.GAME_SERIES_SUCCESS:
      return {
        ...state,
        series:{
          list: action.payload
        }
      };
    case gameConstants.GAME_SERIES_FAILURE:
      return {
        ...state,
        series:{
          error: action.error
        }
      };
    case gameConstants.GET_SERIE_REQUEST:
      return {
        ...state,
        singleSerie:{
          loading: true
        }
      };
    case gameConstants.GET_SERIE_SUCCESS:
      const serieValues = action.payload;
      return {
        ...state,
        singleSerie:{
          success: true
        },
        series:{
          list: state.series.list.map(serie =>
            serie.edited
              ? {...serie, ...serieValues}
              : serie
          )
        }
      };
    case gameConstants.GET_SERIE_FAILURE:
      return {
        ...state,
        singleSerie:{
          error: action.error
        }
      };
    case gameConstants.SET_LOTTERY:
      return {
        ...state,
        lottery: action.payload
      };
    case gameConstants.SET_GAME_FRACTIONS:
      return {
        ...state,
        listFractions: action.payload
      };
    case gameConstants.DISABLED_GAME_FRACTION:
      return {
        ...state,
        listFractions: state.listFractions.map((fraction, index) =>
          (index + 1) > action.payload
            ? { ...fraction, disabled: true }
            : fraction
        )
      };
    case gameConstants.ENABLED_GAME_FRACTION:
      return {
        ...state,
        listFractions: state.listFractions.map((fraction, index) => {
          return { ...fraction, disabled: false};
        }
        )
      };
    case gameConstants.RESET_GAME:
      return {
        ...state,
        series: {
          loading: false,
          list: [],
          error: null
        },
        singleSerie: {
          loading: false,
          success: false,
          error: null
        },
        listFractions: lottery ? fractions : []
      };
    default:
      return state;
  }
}

export const getLotteryInfo = createSelector(
  state => state.lotteries.lotteriesHome.lotteries,
  state => state.game.idLotterie,
  (lotteries, idLotterie) => (
    (idLotterie && lotteries && lotteries.length ? lotteries.filter(lotterie => lotterie.id === idLotterie)[0] : null)
  )
);
