import React, {Component} from 'react';
import { termsText } from './termsText';
import { Col, Row, Container } from 'reactstrap';
import TermsServices from './../../../services/TerminosCondiciones';

export default class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      politicas: ''
    };
  }

  componentDidMount() {
    TermsServices.TermsList()
      .then(res => {
        if (Array.isArray(res) && res[1] && res[1].description) {
          this.setState({
            politicas: res[1].description
          });
        } else {
          console.error('Unexpected response format:', res);
        }
      })
      .catch(error => {
        console.error('Error fetching terms:', error);
      });
  }

  render() {
    return (
      <Col xs={12} className="homeContainer return-seccion">
        <Row>
          <Container>
            <section className="mt-5 mb-4 p-4 border-container">
              <h3 className="first-title text-center my-5 interna-resultados">T&eacute;rminos y Condiciones</h3>
              <div className="text-polices text-justified p-4" dangerouslySetInnerHTML={{ __html: this.state.politicas }}/>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}
