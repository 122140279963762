import { homepayprizeConstants } from '../constants/HomePayPrizeConstants';

const initialState = {
  homepayPrize:{
    loading: false,
    homepayprize: null,
    error: null
  },
  single:{
    loading: false,
    homepayprize: null,
    error: null
  }
};

export function homepayprize(state = initialState, action) {
  switch (action.type) {
    case homepayprizeConstants.HOME_HOMEPAYPRIZE_REQUEST:
      return {
        ...state,
        homepayPrize: {
          loading: true
        }
      };
    case homepayprizeConstants.HOME_HOMEPAYPRIZE_SUCCESS:
      return {
        ...state,
        homepayPrize: {
          homepayprize: action.payload
        }
      };
    case homepayprizeConstants.HOME_HOMEPAYPRIZE_FAILURE:
      return {
        ...state,
        homepayPrize: {
          error: action.error
        }
      };
    case homepayprizeConstants.SINGLE_HOMEPAYPRIZE_REQUEST:
      return {
        ...state,
        single: {
          loading: true
        }
      };
    case homepayprizeConstants.SINGLE_HOMEPAYPRIZE_SUCCESS:
      return {
        ...state,
        single: {
          payprize: action.payload
        }
      };
    case homepayprizeConstants.SINGLE_HOMEPAYPRIZE_FAILURE:
      return {
        ...state,
        single: {
          error: action.error
        }
      };
    default:
      return state;
  }
}
