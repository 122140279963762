/*
@Author: Daniel Reyes Betancourt
@Description: Methods for encryption and decryption Gana services
@Date: 27/06/2019
@Version: 1.0
*/
import { decrypt, encrypt } from './encryptorHandlerSecurity';
const arrDecrypEncrypTap = [
  'ideUsuario',
  'usuario',
  'clave',
  'claveActual',
  'claveNueva',
  'contrasena',
  'user',
  'userId',
  'tipoDocumento',
  'remoteIPAddress',
  'ip',
  'claveNueva',
  'claveactual',
  'valor',
  'ideEstado',
  'colilla'
];
/*
@Description: Encrypt the information of a user from an object
@Params: {obUser} Object to be encrypted
*/
const encryptUserData = (obUser) => {
  const obEncryptUser = obUser;
  try {
    arrDecrypEncrypTap.forEach(key => {
      if (Object.prototype.hasOwnProperty.call(obEncryptUser, key)) {
        obEncryptUser[key] = encrypt(obEncryptUser[key]);
      }
    });
    return obEncryptUser;
  } catch (error) {
    return '';
  }
};

/*
@Description: Decrypt the information of a user from an object
@Params: {obUser} Object to be decrypted
*/
const decryptUserData = (obUser) => {
  const obDecryptUser = obUser;
  try {
    arrDecrypEncrypTap.forEach(key => {
      if (Object.prototype.hasOwnProperty.call(obDecryptUser, key)) {
        obDecryptUser[key] = decrypt(obDecryptUser[key]);
      }
    });
    return obDecryptUser;
  } catch (error) {
    return '';
  }
};

export { decryptUserData, encryptUserData };
