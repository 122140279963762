import { newsConstants } from './../constants/NewsConstants';

const initialState = {
  newsHome:{
    loading: false,
    news: null,
    error: null
  },
  single:{
    loading: false,
    news: null,
    error: null
  }
};

export function news(state = initialState, action) {
  switch (action.type) {
    case newsConstants.HOME_NEWS_REQUEST:
      return {
        ...state,
        newsHome: {
          loading: true
        }
      };
    case newsConstants.HOME_NEWS_SUCCESS:
      return {
        ...state,
        newsHome: {
          news: action.payload
        }
      };
    case newsConstants.HOME_NEWS_FAILURE:
      return {
        ...state,
        newsHome: {
          error: action.error
        }
      };
    case newsConstants.SINGLE_NEWS_REQUEST:
      return {
        ...state,
        single: {
          loading: true
        }
      };
    case newsConstants.SINGLE_NEWS_SUCCESS:
      return {
        ...state,
        single: {
          news: action.payload
        }
      };
    case newsConstants.SINGLE_NEWS_FAILURE:
      return {
        ...state,
        single: {
          error: action.error
        }
      };
    default:
      return state;
  }
}
