import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { toggleModalAccept } from './../../actions/ModalActions';
import alerta from '../../../src/assets/images/alerta.svg';

class ModalAccept extends Component {

  render() {
    const { children, className, open, toggleModalAccept, actionAccept } = this.props;
    return (
      <Modal isOpen={open} toggle={toggleModalAccept} className={`accept-modal ${className}`}>
        <ModalBody>
          {/* {children} */}
          <div className='text-center'>
            <img src={alerta} />
          </div>
          <h1 className='text-center first-title-consulta mb-4 color-black'>¿Estas seguro de cancelar la apuesta?</h1>
          <p className='text-center mb-4 text-size-medium text1 color-black'>Este movimiento no podrá deshacerse</p>
        </ModalBody>
        <div className='d-flex text-center justify-center content-center align-items-center'>
          <Button className="button-rounded button-rounded-gradient-resultados px-30 py5 mb-3 mr20" onClick={toggleModalAccept}>Cancelar</Button>
          <Button className="button-rounded button-rounded-gradient-resultados px-30 py5 mb-3" onClick={actionAccept}>Aceptar</Button>
        </div>


      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    open: state.modal.accept.open
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModalAccept() {
      dispatch(toggleModalAccept());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAccept);
