import moment from 'moment';
import { formatMoney } from './../../helpers/formatMoney';

const keyCaptcha = process.env.REACT_APP_GOOGLE_RECAPTCHA || '0';
const keywords = ['prueba','pruebas', 'root', 'admin', 'administrador','usuario'];

const maxLength = (max, value) =>
  value && value.length > max ? `El campo puede tener un máximo de ${max} caracteres` : undefined;

const minLength = (min, value) =>
  value && value.length < min ? `El campo debe tener un mínimo de ${min} caracteres` : undefined;

const documentType = (documentType, value) => {
  value = value.replace('.', '@');
  if (value && !/^(?!.*(.)\1{4})/i.test(value) || !/^(?!.*(0123456|123456|98765).*).*/i.test(value)) {
    return ('Este campo debe contener un documento válido');
  }
  if (documentType === 'CC') { //Cédula de  ciudadanía
    if (value && isNaN(Number(value)) && !/^[1-9]+([0-9])$/i.test(value)) {
      return ('El campo debe ser un número, sin caracteres especiales');
    } else if (Number(value.substr(0, 1)) < 1) {
      return ('El campo no debe iniciar por 0');
    } else if (value && value.length < 3) {
      return ('El campo debe tener un mínimo de 3 caracteres');
    } else if (value && value.length > 11) {
      return ('El campo debe tener un máximo de 11 caracteres');
    }
  } else if (documentType === 'NIT') { //Num. Ident. Empre
    if (value && isNaN(Number(value)) && !/^([0-9])$/i.test(value)) {
      return ('El campo debe ser un número');
    } else if (Number(value.substr(0, 1)) < 1) {
      return ('El campo no debe iniciar por 0');
    } else if (value && value.length < 3) {
      return ('El campo debe tener un mínimo de 3 caracteres');
    }
    if (value && value.length > 11) {
      return ('El campo debe tener un máximo de 11 caracteres');
    }
  } else if (documentType === 'PA') { //Pasaporte
    if (value && /^[A-Za-z2-7]+$/g.test(value)) {
      return ('El campo debe ser un número, sin caracteres especiales');
    } else if (value && value.length < 1) {
      return ('El campo debe tener un mínimo de 1 caracteres');
    } else if (value && value.length > 16) {
      return ('El campo debe tener un máximo de 16 caracteres');
    }
  } else if (documentType === 'CE') { //Cédula de extranjería
    if (value && isNaN(Number(value)) && !/^([0-9])$/i.test(value)) {
      return ('El campo debe ser un número, sin caracteres especiales');
    } else if (value && value.length < 1) {
      return ('El campo debe tener un mínimo de 1 caracteres');
    } else if (value && value.length > 6) {
      return ('El campo debe tener un máximo de 6 caracteres');
    }
  } else if (documentType === 'PD' || documentType === 'CD') { //Pase diplomático
    if (value && isNaN(Number(value)) && !/^[1-9]+([0-9])$/i.test(value)) {
      return ('El campo debe ser un número, sin caracteres especiales');
    } else if (Number(value.substr(0, 1)) < 1) {
      return ('El campo no debe iniciar por 0');
    } else if (value && value.length < 4) {
      return ('El campo debe tener un mínimo de 4 caracteres');
    } else if (value && value.length > 20) {
      return ('El campo debe tener un máximo de 20 caracteres');
    }
  } else if (documentType === 'TE') { //Tarjeta de extranjería
    if (value && isNaN(Number(value)) && !/^[1-9]+([0-9])$/i.test(value)) {
      return ('El campo debe ser un número, sin caracteres especiales');
    } else if (Number(value.substr(0, 1)) < 1) {
      return ('El campo no debe iniciar por 0');
    } else if (value && value.length < 5) {
      return ('El campo debe tener un mínimo de 5 caracteres');
    } else if (value && value.length > 10) {
      return ('El campo debe tener un máximo de 10 caracteres');
    }
  }
};
class Validators {
  static notrequired = value => (value ? undefined : undefined);

  static required = value => (value ? undefined : 'Este campo es requerido');

  static requiredfecha = value => (value ? undefined : 'Requerido');

  static requiredCap = value => (keyCaptcha == '0' || value ? undefined : 'Prueba que no eres un robot');

  static notNull = value => (value === 'null' || value === null ? 'Este campo es requerido' : undefined);

  static notDefault = value => (value === 'default' ? 'Este campo es requerido' : undefined);

  static notDefaultfecha = value => (value === 'default' ? 'Requerido' : undefined);

  static minMoneyValue = min => value => {
    //const valorMoney = value ?  : 0;
    return value && parseInt(value.replace(/[^\d]/g, '')) < min ?
      `El valor ingresado debe ser mayor o igual a $${formatMoney(min, 0, 3, '.', ',')}` :
      (!value ? 'Este campo es requerido' : undefined);
  }

  static maxMoneyValue = max => value => {
    //const valorMoney = value ?  : 0;
    return value && parseInt(value.replace(/[^\d]/g, '')) > max ?
      `El valor ingresado debe ser menor o igual a $${formatMoney(max, 0, 3, '.', ',')}` : undefined;
  }

  static minLength3 = value =>
    minLength(3, value);

  static minLength4 = value =>
    minLength(4, value);

  static minLength5 = value =>
    minLength(5, value);

  static minLength6 = value =>
    minLength(6, value);

  static minLength8 = value =>
    minLength(8, value)

  static minLength10 = value =>
    minLength(10, value)

  static maxLength3 = value =>
    maxLength(3, value);

  static maxLength4 = value =>
    maxLength(4, value);

  static maxLength15 = value =>
    maxLength(15, value);

  static maxLength10 = value =>
    maxLength(10, value);

  static maxLength20 = value =>
    maxLength(20, value);

  static maxLength25 = value =>
    maxLength(25, value);

  static maxLength30 = value =>
    maxLength(30, value);

  static maxLength50 = value =>
    maxLength(50, value);

  static maxLength60 = value =>
    maxLength(60, value);

  static maxLength80 = value =>
    maxLength(80, value);

  static maxLength100 = value =>
    maxLength(100, value);

  static maxLength150 = value =>
    maxLength(150, value);

  static isNumber = value =>
    value && isNaN(Number(value)) ? 'El campo debe ser un número' : undefined;

  static email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
      ? 'Debes ingresar un correo electrónico válido'
      : undefined;

  static named = value =>
    value && !/^([a-zA-ZÁÉÍÓÚñáéíóú _]{1,18}[\s]*)+$/i.test(value)
      ? 'Debes ingresar un nombre válido'
      : undefined;

  static validString = value =>
    value && !/^(?!.*(ABC|CDE|EFG|GHI|IJK|KLM|MNO|OPQ|QRS|STU|UVW|WXY|XYX).*).*/i.test(value)
      ? 'Ingrese un dato válido.'
      : undefined;

  static lastname = value =>
    value && !/^([a-zA-ZÁÉÍÓÚñáéíóú _]{1,18}[\s]*)+$/i.test(value)
      ? 'Debes ingresar un apellido válido'
      : undefined;

  static isdate = value =>
    value && new Date(value) instanceof Date ? undefined : 'Debe ser una fecha válida';

  static telephoneNumber = value =>
    value && !/^(0|[1-9][0-9]{6})$/i.test(value)
      ? 'El número de teléfono debe tener 7 dígitos'
      : undefined;

  static phoneNumber = value => {
    const newValue = value.replace(/[^\d]/g, '');
    return (
      newValue && !/^(0|[1-9][0-9]{9})$/i.test(newValue)
        ? 'El número de celular debe tener 10 dígitos'
        : undefined
    );
  }
  static documentType = value => {
    var documentType = document.getElementById('login-tipoDocumento').value;
    if (documentType === 'CC') { //Cédula de  ciudadanía
      value = value.replace('.','@');
      if ( value && isNaN(Number(value)) && !/^[1-9]+([0-9])$/i.test(value)) {
        return ('El campo debe ser un número, sin caracteres especiales');
      } else if ( Number(value.substr(0,1)) < 1) {
        return ('El campo no debe iniciar por 0');
      } else if ( value && value.length < 3 ) {
        return ( 'El campo debe tener un mínimo de 3 caracteres');
      } else if ( value && value.length > 11 ) {
        return ( 'El campo debe tener un máximo de 11 caracteres');
      }
    } else if (documentType === 'NIT') { //Num. Ident. Empre
      if ( value && isNaN(Number(value))) {
        return ('El campo debe ser un número');
      }
      if ( value && value.length < 3 ) {
        return ( 'El campo debe tener un mínimo de 3 caracteres');
      }
      if ( value && value.length > 11 ) {
        return ( 'El campo debe tener un máximo de 11 caracteres');
      }
    } else if (documentType === 'PA') { //Pasaporte
      if ( value && value.length < 1 ) {
        return ( 'El campo debe tener un mínimo de 1 caracteres');
      } else if ( value && value.length > 16 ) {
        return ( 'El campo debe tener un máximo de 16 caracteres');
      }
    } else if (documentType === 'CE') { //Cédula de extranjería
      value = value.replace('.','@');
      if ( value && isNaN(Number(value)) && !/^([0-9])$/i.test(value)) {
        return ('El campo debe ser un número, sin caracteres especiales');
      } else if ( value && value.length < 1 ) {
        return ( 'El campo debe tener un mínimo de 1 caracteres');
      } else if ( value && value.length > 6 ) {
        return ( 'El campo debe tener un máximo de 6 caracteres');
      }
    } else if (documentType === 'PD') { //Pase diplomático
      if ( value && isNaN(Number(value))) {
        return ('El campo debe ser un número');
      }
      if ( value && value.length < 3 ) {
        return ( 'El campo debe tener un mínimo de 3 caracteres');
      }
      if ( value && value.length > 11 ) {
        return ( 'El campo debe tener un máximo de 11 caracteres');
      }
    } else if (documentType === 'CD') { //Carnet diplomático
      if ( value && isNaN(Number(value))) {
        return ('El campo debe ser un número');
      }
      if ( value && value.length < 3 ) {
        return ( 'El campo debe tener un mínimo de 3 caracteres');
      }
      if ( value && value.length > 11 ) {
        return ( 'El campo debe tener un máximo de 11 caracteres');
      }
    } else if (documentType === 'TE') { //Tarjeta de extranjería
      if ( value && isNaN(Number(value))) {
        return ('El campo debe ser un número');
      }
      if ( value && value.length < 3 ) {
        return ( 'El campo debe tener un mínimo de 3 caracteres');
      }
      if ( value && value.length > 11 ) {
        return ( 'El campo debe tener un máximo de 11 caracteres');
      }
    }
  };


  static documentTypeLogin = value => {
    value.toUpperCase();
    var documentTypeVal = document.getElementById('login-tipoDocumento').value;
    return documentType(documentTypeVal, value);
  }

  static documentTypeForm = value => {
    var documentTypeVal = document.getElementById('account-tipoDocumento').value;
    return documentType(documentTypeVal, value);
  }

  static documentTypePQ = value => {
    var documentTypeVal = document.getElementById('pg-tipoDocumento').value;
    return documentType(documentTypeVal, value);
  }


  static validUserTesting = value =>{
    if ( keywords.some(word=>value.toLowerCase().includes(word))) {
      return 'Este dato es inválido.';
    }
  }

  static validPassword = value => {
    var patt = /^(?=.*\d)(?=.*[\u0021-\u002b\u002e\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{6,40}$/;
    if (!patt.test(value)) {
      return ('¡Uy! Necesitas al menos agregar una letra minúscula, mayúscula, un número y un carácter especial.');
    }
  }

  static documentTypeLogin = value => {
    value.toUpperCase();
    var documentTypeVal = document.getElementById('login-tipoDocumento').value;
    return documentType(documentTypeVal, value);
  }

  static documentTypeForm = value => {
    var documentTypeVal = document.getElementById('account-tipoDocumento').value;
    return documentType(documentTypeVal, value);
  }

  static documentTypePQ = value => {
    var documentTypeVal = document.getElementById('pg-tipoDocumento').value;
    return documentType(documentTypeVal, value);
  }


  static validUserTesting = value =>{
    if ( keywords.some(word=>value.toLowerCase().includes(word))) {
      return 'Este dato es inválido.';
    }
  }

  static FormatDate = (dt, formatText, simbol) => {
    // if it is a string that contains '/' then just return it
    simbol = simbol || '-';
    formatText = formatText || 'DD-MM-YYYY';
    if (dt) {
      if (typeof (dt) === 'string' && dt.indexOf(simbol) > -1) {
        return dt;
      }
      return moment(dt).format(formatText);
    } else if (dt === null) {
      return '';
    }
  };
}

export default Validators;
