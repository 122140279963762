import { lotteriesConstants } from './../constants/LotterieConstants';

const initialState = {
  lotteriesHome:{
    loading: false,
    lotteries: [],
    error: null
  },
  isWinner:{
    loading: false,
    success: null,
    error: null
  },
  listLotteries:{
    loading: false,
    list: []
  }
};

export function lotteries(state = initialState, action) {
  switch (action.type) {
    case lotteriesConstants.HOME_LOTTERIES_REQUEST:
      return {
        ...state,
        lotteriesHome: {
          loading: true
        }
      };
    case lotteriesConstants.HOME_LOTTERIES_SUCCESS:
      return {
        ...state,
        lotteriesHome: {
          lotteries: action.payload
        }
      };
    case lotteriesConstants.HOME_LOTTERIES_FAILURE:
      return {
        ...state,
        lotteriesHome: {
          error: action.error
        }
      };
    case lotteriesConstants.WINNER_LOTTERIES_REQUEST:
      return {
        ...state,
        isWinner: {
          loading: true
        }
      };
    case lotteriesConstants.WINNER_LOTTERIES_SUCCESS:
      return {
        ...state,
        isWinner: {
          success: action.payload
        }
      };
    case lotteriesConstants.WINNER_LOTTERIES_FAILURE:
      return {
        ...state,
        isWinner: {
          error: action.error
        }
      };
    case lotteriesConstants.LIST_LOTTERIES_REQUEST:
      return {
        ...state,
        listLotteries: {
          loading: true
        }
      };
    case lotteriesConstants.LIST_LOTTERIES_SUCCESS:
      return {
        ...state,
        listLotteries: {
          list: action.payload
        }
      };
    default:
      return state;
  }
}
