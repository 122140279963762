import 'whatwg-fetch';
import { getAuthApp } from '../services/security/authApp';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

export default class TermsServices {

  static TermsList() {
    const url = `${serverURL}/api/terminos_condiciones`;

    return fetch(url, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    }).then(handleResponse)
      .then(termns => termns)
      .catch(error => {
        return Promise.reject(error);
      });
  }

}
