import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import ServiceForm from './../../components/Contact/Service/ServiceForm';
import { saveService, loadListService } from './../../actions/ContactActions';
import { tratamientos } from './../../constants/ContactConstants';
import { terminos } from './../../constants/ContactConstants';

//Images
import iconPQRS from './../../assets/images/pqr-icon.svg';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';

class ServiceContainer extends Component {
  constructor(props) {
    super(props);

    this.handleOnSave = this.handleOnSave.bind(this);
  }

  handleOnSave = (questions, values) => {

    values.tratamientos = values.tratments ? tratamientos.SI : tratamientos.PENDIENTE;
    values.terminos = values.terms ? terminos.SI : terminos.PENDIENTE;
    delete values.tratments;
    delete values.terms;

    const radio = this.props.info.list.find(item => item.value === values.issue);
    values.issue = radio.id;

    //Agrupando las preguntas en un array de preguntas
    values.questions = [];
    for (const key in values) {
      if (key.search('pregunta-') >= 0) {
        const question = questions.find(item => parseInt(key.split('-')[1]) === item.id);
        values.questions.push({
          question: question.name,
          answer: values[key]
        });
      }
    }
    this.props.saveService(values);
  };

  componentDidMount () {
    this.props.loadListService();
  }

  render() {
    const { save, info } = this.props;
    const fields = {
      issue: 'service-1'
    };
    return (
      <section>

        <h4 className="mb-4 text-left interna-loterias">Selecciona una opción:</h4>
        {info.list && info.list.length &&
          <Row className="justify-content-center text-justify">
            <Col xs="12">
              <ServiceForm
                onSubmit={this.handleOnSave}
                loadingForm={save.loading}
                initialValues={fields}
                radioList={info.list}
              />
              {save.error && <Alert className="mt-3 mb-0" color="danger"> {save.error} </Alert>}
              {save.success && <Alert className="mt-3 mb-0" color="success"> Su Calificación ha sido registrada exitosamente </Alert>}
            </Col>
          </Row>
        }
        {info.loading && <Row><CircularProgress /></Row>}
        {!info.loading && (!info.list || !info.list.length) && <Alert className="mt-3 mb-0" color="danger">Ocurrio un error al traer la información</Alert>}
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { save, info } = state.contact.service;
  return {
    save,
    info
  };
}

const mapDispatchToProps = dispatch => {
  return {
    saveService(values) {
      dispatch(saveService(values));
    },
    loadListService() {
      dispatch(loadListService());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceContainer);
