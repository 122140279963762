import { contactConstants } from './../constants/ContactConstants';

const initialState = {
  pqrs: {
    save:{
      loading: false,
      success: false,
      error: null
    },
    info: {
      loading: false,
      list: []
    }
  },
  service: {
    save:{
      loading: false,
      success: false,
      error: null
    },
    info: {
      loading: false,
      list: []
    }
  },
  questions: {
    loading: false,
    list: []
  }
};
export function contact(state = initialState, action) {
  switch (action.type) {
    case contactConstants.SAVE_PQRS_REQUEST:
      return {
        ...state,
        pqrs: {
          ...state.pqrs,
          save: {
            loading: true
          }
        }
      };
    case contactConstants.SAVE_PQRS_SUCCESS:
      return {
        ...state,
        pqrs: {
          ...state.pqrs,
          save: {
            success: true
          }
        }
      };
    case contactConstants.SAVE_PQRS_FAILURE:
      return {
        ...state,
        pqrs: {
          ...state.pqrs,
          save: {
            error: action.error
          }
        }
      };
    case contactConstants.SAVE_PQRS_RESET:
      return {
        ...state,
        pqrs: {
          ...state.pqrs,
          save: initialState.pqrs.save
        }
      };
    case contactConstants.LIST_PQRS_REQUEST:
      return {
        ...state,
        pqrs: {
          ...state.pqrs,
          info: {
            loading: true
          }
        }
      };
    case contactConstants.LIST_PQRS_SUCCESS:
      return {
        ...state,
        pqrs: {
          ...state.pqrs,
          info: {
            list: action.payload
          }
        }
      };
    case contactConstants.SAVE_SERVICE_REQUEST:
      return {
        ...state,
        service: {
          ...state.service,
          save: {
            loading: true
          }
        }
      };
    case contactConstants.SAVE_SERVICE_SUCCESS:
      return {
        ...state,
        service: {
          ...state.service,
          save: {
            success: true
          }
        }
      };
    case contactConstants.SAVE_SERVICE_FAILURE:
      return {
        ...state,
        service: {
          ...state.service,
          save: {
            error: action.error
          }
        }
      };
    case contactConstants.SAVE_SERVICE_RESET:
      return {
        ...state,
        service: {
          ...state.service,
          save: initialState.service.save
        }
      };
    case contactConstants.LIST_SERVICE_REQUEST:
      return {
        ...state,
        service: {
          ...state.service,
          info: {
            loading: true
          }
        }
      };
    case contactConstants.LIST_SERVICE_SUCCESS:
      return {
        ...state,
        service: {
          ...state.service,
          info: {
            list: action.payload
          }
        }
      };
    case contactConstants.SAVE_QUESTIONS_REQUEST:
      return {
        ...state,
        questions: {
          loading: true
        }
      };
    case contactConstants.SAVE_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: {
          list: action.payload
        }
      };
    default:
      return state;
  }
}
