//Dependencis
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { getTotalCart } from './../../reducers/cartReducer';
import { toggleModalGeneral } from './../../actions/ModalActions';
import { formatMoney } from './../../helpers/formatMoney';

const ProductAdded = ({toggleModalGeneral, history, totalCart, typeReload, valueReload}) => {
  const hableGoCart = () => {
    toggleModalGeneral();
    history.push('/carrito');
  };
  return (
    <section className="p-5 bg-white modal-product-added border-rad-lg">
      <h4 className="text-center bolder textCa mb-4"><strong>Producto añadido <br />correctamente al  <br />carrito de compras</strong></h4>
      <Row className="justify-content-center mx-0 mb-3">
        <Col sm={12} md={10} lg={9} xl={8} className="p-0">
          <ul className="resume-cart">
            <li>
              <span className="resume-cart-type">Tipo:</span>
              <span className='textCa'>{typeReload}</span>
            </li>
            <li>
              <span className="resume-cart-type">Valor:</span>
              <span className='textCa'>{`$ ${formatMoney(valueReload, 0, 3, '.', ',')}`}</span>
            </li>
            <li>
              <span className="resume-cart-type">Total carrito:</span>
              <span className='textCa'>{`$ ${formatMoney(totalCart, 0, 3, '.', ',')}`}</span>
            </li>
          </ul>
        </Col>
      </Row>
      <div className="d-flex justify-content-around align-items-center">
        <button
          type="button"
          onClick={toggleModalGeneral}
          className="button-rounded button-rounded-gradient-resultados px-30 py5 w-auto content-center"
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="d-inline-block mr-1 mr-sm-5">Seguir Comprando</span>
          </div>
        </button>
        <button
          type="button"
          onClick={hableGoCart}
          className="button-rounded button-rounded-gradient-resultados px-30 py5 w-auto content-center"
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="d-inline-block  mr-1 mr-sm-5">Ir al carrito <span className="hidden-sm-down text-size-impnormal" >de compras</span></span>
            <i className="flaticon-commerce"/>
          </div>
        </button>
      </div>

    </section>
  );
};

const matStateToProps = state => ({
  totalCart: getTotalCart(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModalGeneral() {
      dispatch(toggleModalGeneral());
    }
  };
};

export default connect(matStateToProps, mapDispatchToProps)(withRouter(ProductAdded));
