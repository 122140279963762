export const lotteriesConstants = {
  HOME_LOTTERIES_REQUEST: 'HOME_LOTTERIES_REQUEST',
  HOME_LOTTERIES_SUCCESS: 'HOME_LOTTERIES_SUCCESS',
  HOME_LOTTERIES_FAILURE: 'HOME_LOTTERIES_FAILURE',

  WINNER_LOTTERIES_REQUEST: 'WINNER_LOTTERIES_REQUEST',
  WINNER_LOTTERIES_SUCCESS: 'WINNER_LOTTERIES_SUCCESS',
  WINNER_LOTTERIES_FAILURE: 'WINNER_LOTTERIES_FAILURE',

  LIST_LOTTERIES_REQUEST: 'LIST_LOTTERIES_REQUEST',
  LIST_LOTTERIES_SUCCESS: 'LIST_LOTTERIES_SUCCESS'
};
