export class trackingIdConstant {
  TRACKING_ID_PROD='G-P30ZFPRRNP';
  TRACKING_ID_QA='G-N969RJ1SSN';

  GOOGLE_TASK_MANAGER_ID_PROD = 'GTM-PHFF6MF';
  GOOGLE_TASK_MANAGER_ID_PROD_QA = '';

  TRACKING_ID=TRACKING_ID_PROD;
  GOOGLE_TASK_MANAGER_ID = GOOGLE_TASK_MANAGER_ID_PROD;
}
