/* eslint-disable max-len */
// Dependencies
import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import placeToPay from './../../../assets/images/Logo_PlacetoPay_blank.png';
import imaginamos from './../../../assets/images/imaginamos.png';
import PremioTD from './../../../assets/images/Logo_Premio_TD.png';
import LogoOKI from './../../../assets/images/logo-oki.svg';
import LogoGana from './../../../assets/images/logo-gana.svg';
import LogoReditos from './../../../assets/images/Reditos.svg';
import LogoPlacetopay from './../../../assets/images/Placetopay.svg';
import LogoLOTI from './../../../assets/images/loti2.svg';
import LogoFacebook from './../../../assets/images/icon-fb.svg';
import LogoInstagram from './../../../assets/images/icon-in.svg';
import LogoYouTube from './../../../assets/images/icon-yt.svg';

const Footer = ({ title }) => {

  const LinkGanaEnv = process.env.REACT_APP_LINK_GANA;
  const LinkOkiEnv = process.env.REACT_APP_LINK_OKI;
  const LinkGruposEnv = process.env.REACT_APP_LINK_GRUPOS_REDITOS;
  const LinkPlaycetopayEnv = process.env.REACT_APP_LINK_PLAYCETOPAY;
  const LinkCreditoRisEnv = process.env.REACT_APP_LINK_CREDITO_RIS;
  const LinkFacebookEnv = process.env.REACT_APP_FACEBOOK_LOTI;
  const LinkInstagramEnv = process.env.REACT_APP_INSTAGRAM_LOTI;
  const LinkYouTubeEnv = process.env.REACT_APP_YOUTUBE_LOTI;

  return (
    <footer className="custom-footer">
      <Container className='p-5'>
        <Row className="align-items-center">
          <Col xs={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} className="align-margin-center">
            <Row className="align-items-center">
              <Col xs={12} sm={12} md={3} lg={3} className="center-flex single-logo-gooter">
                <div href="/nosotros/1" className="text-center logo-footer-lotti">
                  <img class="img-responsive" src={LogoLOTI} /><br />
                  <div className="align-items-center posiciones justify-center social-footer-icons">
                    <a href={LinkFacebookEnv} target='_blank'><img src={LogoFacebook} /></a>
                    <a href={LinkInstagramEnv} target='_blank'><img src={LogoInstagram} /></a>
                    <a href={LinkYouTubeEnv} target='_blank'><img src={LogoYouTube} /></a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} className="center-flex single-seccion-footer">
                <a href="/nosotros/1" className="text-info-light">
                  <h1 className='navbar-footer'>Nosotros</h1><br />
                </a>
                <div className='single-hr-footer' />
                <a href="/nosotros/1" className="footer-navar-seccion">Nuestra empresa</a><br />
                <a href="/nosotros/2" className="footer-navar-seccion">Nuestra red</a><br />
                <a href="/productos" className="footer-navar-seccion">Productos</a><br />
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} className="center-flex single-seccion-footer">
                <a href="/puntos-gana" className="text-info-light">
                  <h1 className='navbar-footer'>Puntos Gana</h1><br />
                </a>
                <div className='single-hr-footer' />
                <a href="/puntos-gana" className="footer-navar-seccion">Política de privacidad</a><br />
                <a href="/puntos-gana" className="footer-navar-seccion">Aliados</a><br />
                <a href="/puntos-gana" className="footer-navar-seccion">Conoce el programa</a><br />
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} className="center-flex single-seccion-footer">
                <a href="/contacto/1" className="text-info-light">
                  <h1 className='navbar-footer'>Servicio al cliente</h1><br />
                </a>
                <div className='single-hr-footer' />
                <a href="/contacto/1" className="footer-navar-seccion">PQRS</a><br />
                <a href="/contacto/3" className="footer-navar-seccion">Preguntas frecuentes</a><br />
                <a href={LinkCreditoRisEnv} target='_blank' className="footer-navar-seccion">Solicita tu crédito</a><br />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className='custom-footer-seccion text-center'>
        <Row className='align-items-center'>
          <Col xs={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} className="align-margin-center p-4">
            <Row className="align-items-center">
              <Col xs={6} md="3" className="center-flex select-footer mb10">
                <a href={LinkGruposEnv} target="_blank" className="d-inline-block  footer-containers">
                  <img
                    className="img-fluid"
                    src={LogoReditos} title={'Grupo Réditos'} alt={'Grupo Réditos'}
                  />
                </a>
              </Col>
              <Col xs={6} md="3" className="center-flex select-footer mb10">
                <a href={LinkOkiEnv} target="_blank" className="d-inline-block  footer-containers">
                  <img
                    className="img-fluid footer-imaginamosLogo"
                    src={LogoOKI} title={'Imaginamos'} alt={'Imaginamos'}
                  />
                </a>
              </Col>
              <Col xs={6} md="3" className="center-flex select-footer">
                <a href={LinkPlaycetopayEnv} target="_blank" rel="noopener noreferrer">
                  <img src={LogoPlacetopay} alt={'placetopay'} />
                </a>
              </Col>
              <Col xs={6} md="3" className="center-flex select-footer">
                <a href={LinkGanaEnv} target="_blank" className="d-inline-block  footer-containers">
                  <img
                    className="img-fluid"
                    src={LogoGana} title={'Gana Web'} alt={'Gana Web'}
                  />
                </a>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={12} md="12" className="text-info-light font-montserrat-light text-size-13">
              <p className="text-right">
                Copyright © {title + (new Date().getFullYear())}.
                Prohibida su reproducción parcial, así como su traducción a cualquier idioma sin
                autorización de LotiColombia.
              </p>
            </Col> */}
          <Col xs={{ size: 12 }} className="text-info-light font-montserrat-light text-size-small">
            <br />
            <p style={{ textAlign: 'center', margin: 0 }}>
              {/* <a href="https://www.loticolombia.com/politicas-privacidad" className="link-decoration" target="_blank" rel="noopener noreferrer">Política de tratamiento de datos personales</a> para el uso del servicio de Compra de Lotería */}
              <a href={'/politicas-privacidad'} className="link-decoration" rel="noopener noreferrer">
                Política de tratamiento de datos personales
              </a>
              &nbsp;
              para el uso del servicio de Compra de Lotería &nbsp;
              <a href="/terminos-y-condiciones" className="link-decoration" rel="noopener noreferrer">Términos y condiciones</a>
            </p>
            <p className="text-center single-copy" style={{ margin: 0 }}>
              Copyright © {title + (new Date().getFullYear())}.
              Prohibida su reproducción parcial, así como su traducción a cualquier idioma sin
              autorización de LotiColombia.
            </p>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  title: PropTypes.string
};

export default Footer;
