import React from 'react';
import { Link } from 'react-router-dom';

const PaymentAward = ({ idsection, cover, descriptions }) => {
  return (
    <div className="m10 mb-3 pt-1 pb-1 single-pay">
      <div className='single-noticias single-loteri'>
        <div className='game-number-pay'>{idsection}</div>
        <div class="center-container">
          <img className="img-fluid mb-2 single-news custom-image-pay" src={cover} />
        </div>
        <div className="text-container">
          <p className="font-montserrat-light-news" style={{ textAlign: 'center', width: '300px', height: '200px', overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: descriptions }}/>
        </div>
      </div>
    </div>
  );
};

export default PaymentAward;
