import 'whatwg-fetch';
import { getAuthApp } from '../services/security/authApp';
const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

class GameService {

  static loadListSeries(values) {

    const url = `${serverURL}/api/Users/etn/consultar-disponiblidad-de-numero`;
    const data = JSON.stringify({ number: {...values} }, null, 2);

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }
  static loadListNumbers(numeroJugar) {

    const url = `${serverURL}/api/Users/etn/consultar-numeros-disponibles`;
    const data = JSON.stringify({ numeroJugar }, null, 2);
    console.log('entro consumir el servicio desde front');
    console.log('entro consumir el servicio desde front---::::',data);
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static loadListNumbersLargestSmallest(data) {

    const url = `${serverURL}/api/Users/etn/consultar-numeros-mayores-menores`;
    const dataBody = JSON.stringify({ data }, null, 2);
    console.log('entro consumir servicio del mayor y menor ');
    console.log('entro consumir servicio del mayor y menor ---::::',dataBody);
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: dataBody
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

}

export default GameService;
