// Dependencies
import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';
import iconoResultado from '../../../src/assets/images/icono-resultados.svg';

//Components
import ResultsPageContainer from './../../containers/Results/ResultsPageContainer';

//Actions
import { getPageInfo } from './../../reducers/generalReducer';


class Results extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { info } = this.props;
    const {
      match: {
        params: {
          date = null
        }
      }
    } = this.props;
    return (
      <Col xs={12} className="homeContainer">
        <Row>
          <Container className="bg-primary return-seccion">
            <section className="mt-5 mb-4 p-4  border-container">
              <h3 className="first-title text-center color-white my-4 interna-resultados"> Resultados</h3>
              <div className="font-montserrat-light text-center text-white mb-5 " dangerouslySetInnerHTML={{__html: info.description}} />
              <ResultsPageContainer dateparam={date}/>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error } = state.general.pages;
  return {
    loading,
    error,
    info: getPageInfo(state, 'resultados')
  };
};

export default connect(mapStateToProps)(Results);
