import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import lunaLoteria from '../../assets/images/elipse.svg';
import inconoNumero from '../../assets/images/icono_numero.svg';
import inconoSerie from '../../assets/images/icono-serie.svg';
import iconoFecha from '../../assets/images/icono_fecha.svg';
import iconoFavorito from '../../assets/images/icono_favorito.svg';
import Modal from '../Modal/ModalPlanPremios';
import ModalSecos from '../Modal/ModalSecos';

const SingleResult = ({ icon, data, complete, tradicional, id, onLotterieClick }) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalSecos, setShowModalSecos] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModalSecos = () => {
    setShowModalSecos(true);
  };

  const closeModalSecos = () => {
    setShowModalSecos(false);
  };


  const {
    ideLoteria,
    ideProducto,
    desLoteria,
    numero,
    numeroFavorito,
    serie,
    fecSorteo,
    sorteo
  } = data;

  const numberArray = numero ? numero.toString().split('') : [0, 0, 0, 0];
  const sorteoArray = sorteoArray ? sorteoArray.toString().split('') : [0];
  const numberSerie = serie ? serie.toString().split('') : [0, 0, 0];
  const numberFavortio = numeroFavorito ? numeroFavorito.split('-') : null;
  const dateFormat = fecSorteo ? moment(fecSorteo, 'DD-MM-YYYY').format('DD/MM/YYYY') : null;

  return (
    <Col xs={12} sm={complete ? 12 : 6} md={12}>
      <div>
        <Modal
          title="Plan de premios"
          show={showModal}
          onClose={closeModal}
          idLotterieURL={ideLoteria}
        />
      </div>
      <div>
        <ModalSecos
          title="Secos"
          show={showModalSecos}
          onClose={closeModalSecos}
          idLotterieURL={ideLoteria}
          fechaSorteo={fecSorteo}
        />
      </div>
      {sorteo &&
        tradicional &&
        <Row className="align-items-center">
          <Col xs={12} sm={12} md={12} lg={12} className="text-right px-0 result-single-property-gris px-0">
            Sorteo: {sorteoArray.map((number, i) => <span key={i} >{sorteo}</span>)}
          </Col>
        </Row>
      }
      {sorteo &&
        !tradicional &&
        <Row className="align-items-center">
          <Col xs={12} sm={12} md={12} lg={12} className="text-right px-0 result-single-property-gris px-0">
            Sorteo: {sorteoArray.map((number, i) => <span key={i} >{sorteo}</span>)}
          </Col>
        </Row>
      }
      <Row className="result-single align-items-center mb-2 pt-1 pb-1">
        <Col xs={12} sm={12} md={4} className="mb-3">
          <img className='background-logo' src={lunaLoteria} />
          <div className="mb-3 image-lotterie-resultados mx-auto">
            <img className="d-block" src={icon} alt={`Icono lotería ${name}`} />
          </div>
          {/* {complete && <h5 className="text-center mt-2">{dateFormat}</h5>} */}
        </Col>
        <Col xs={12} sm={12} md={8} className="result-single-params">

          <Row className="align-items-center border-bottom">
            <Col md={5} lg={5} className="text-left px-0 result-single-property">
              <img className='m-2' src={inconoNumero} />
              <span className=''>NÚMERO</span>
            </Col>
            <Col md={7} lg={7} className="color-white bolder pr-0">
              {numberArray.map((number, i) => <span className='mx-1 ' key={i} >{number}</span>)}
            </Col>
          </Row>
          {numberSerie &&
            !tradicional &&
            <Row className="align-items-center border-bottom">
              <Col md={5} lg={5} className="text-left my-0 px-0 result-single-property">
                <img className='m-2' src={inconoSerie} />
                <span>SERIE</span>
              </Col>
              <Col md={7} lg={7} className="color-white bolder">
                {numberSerie.map((number, i) => <span className='mx-1' key={i} >{number}</span>)}
              </Col>
            </Row>
          }

          {complete &&
            <Row className="align-items-center ">
              <Col md={5} lg={5} className="text-left my-0 px-0 result-single-property">
                <img className='m-2' src={iconoFecha} />
                <span className=''>FECHA</span>
              </Col>
              <Col md={7} lg={7} className="color-white bolder result-single-date">
                {dateFormat}
              </Col>
            </Row>}

          {complete ? (numberFavortio &&
            <Row className="align-items-center mb-3 ">
            </Row>) : (dateFormat &&
              <Row className="align-items-center ">
                <Col md={5} lg={5} className="text-left my-0 px-0 result-single-property">
                  <img className='m-2' src={iconoFecha} />
                  <span className=''>FECHA</span>
                </Col>
                <Col md={7} lg={7} className="color-white bolder result-single-date">
                  {dateFormat}
                </Col>
              </Row>)
          }
        </Col>
      </Row >
      {!tradicional &&
        <Row className={'justify-center pb-3 pt-4'}>
          <a className="button-rounded button-rounded-gradient-resultados px-30 py5 " onClick={openModal}>
            <span>Plan de premios</span>
          </a>
          <a className="button-rounded button-rounded-gradient-resultados px-30 py5" onClick={openModalSecos}>
            <span>Resultados</span>
          </a>
        </Row>
      }

      <Row className={'d-block'}>
        {numberFavortio &&
          !tradicional &&
          !complete &&
          <div className="align-items-center mb-5 numero-favorito justify-center">
            <div className="result-single-property-favorito ">
              <img src={iconoFavorito} />
              <span className='color-white-numero ml-3'>Número favorito</span>
            </div>
            <div className="text-center">
              <div>{numberFavortio.map((number, i) => <span key={i} className="rounded-number">{number}</span>)}</div>
            </div>
            <div className="text-center">
              <a className="button-text-reverse-jugar-resultado text-center" onClick={onLotterieClick}>
                Jugar
              </a>
            </div>
          </div>
        }
        {tradicional &&
          complete &&

          <div className="align-items-center mb-5 numero-favorito justify-center">
            <div className="result-single-property-favorito posiciones justify-center">
              <img src={iconoFavorito} />
              <span className='color-white-numero ml-3'>Número favorito</span>
            </div>
            <div className='posiciones justify-center'>
              <div>{numberFavortio.map((number, i) => <span key={i} className="rounded-number">{number}</span>)}</div>
            </div>
            <div className="text-center">
              <a className="button-text-reverse-jugar-resultado text-center " onClick={onLotterieClick}>
                Jugar
              </a>
            </div>
          </div>
        }
      </Row>
    </Col >
  );
};

SingleResult.propTypes = {
  complete: PropTypes.bool.isRequired,
  tradicional: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    ideLoteria: PropTypes.number.isRequired,
    ideProducto: PropTypes.number,
    desLoteria: PropTypes.string.isRequired,
    numero: PropTypes.number,
    numeroFavorito: PropTypes.string,
    serie: PropTypes.number,
    sorteo: PropTypes.number.isRequired,
    fecSorteo: PropTypes.string
  })
};

export default SingleResult;
