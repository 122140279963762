import React, { Component, useEffect, useState } from 'react';

import DetallesLoteria from '../../assets/images/DetallesLoteria.svg';
import PersonDetallesLoteria from '../../assets/images/modal-detalles.png';
import DetallesValor from '../../assets/images/DetallesValorFraccion.svg';
import DetallesFracciones from '../../assets/images/DetallesFracciones.svg';
import DetallesTotal from '../../assets/images/DetallesTotal.svg';
import DetallesJuega from '../../assets/images/DetallesJuega.svg';

import { loadPrizePlan } from '../../actions/PrizePlanActions';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import CircularProgress from '../Global/CircularProgress/CircularProgress';
import { formatMoney } from '../../helpers/formatMoney';

class ModalDetalle extends Component {
  constructor(props) {
    super(props);

    this.handleSearchPrizePlan = this.handleSearchPrizePlan.bind(this);

    this.state = {
      loadPrizePlan: false
    };
  }

  handleSearchPrizePlan = values => {
    this.props.loadPrizePlan({
      ideLoteria: values.loteria,
      fecha: values.fecha || null
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.loadPrizePlan && nextProps.show) {
      this.setState({ loadPrizePlan: true });
      this.props.loadPrizePlan({
        ideLoteria: this.props.idLotterieURL,
        fecha: null
      });
    }
  }

  render() {
    const { loading, prizePlan, show, onClose, title, date } = this.props;

    return (
      <div className="modal" style={{ display: show ? 'flex' : 'none' }}>
        <div className="single-modal-content">
          <div className='single-close'>
            <p onClick={onClose} className='single-close no-mrn'>X</p>
          </div>
          <div className='modalTitle'>
            <img src={DetallesLoteria} />
            <h2 className='single-h2'>{title}</h2>
          </div>
          <Row>
            <Col md={6} className='single-img-person'>
              <img src={PersonDetallesLoteria} />
            </Col>
            <Col md={6} className='mb20'>
              <div className='single-Detalle'>
                {loading ? (
                  <div>
                    <CircularProgress />
                  </div>
                ) : (
                  <div className='posiciones align-center p-1'>
                    <img src={DetallesValor} className='single-img-Detalle' />
                    <div className='text-left'>
                      <p className='single-span-Detalle no-mrn'>Valor Fracción</p>
                      <p className='single-span-Detalle-valor no-mrn'>{`$ ${formatMoney(prizePlan && prizePlan.valorFraccion, 0, 3, '.', ',')}`}</p>
                    </div>
                  </div>
                )}
                {loading ? (
                  <div>
                    <CircularProgress />
                  </div>
                ) : (
                  <div className='posiciones align-center p-1'>
                    <img src={DetallesFracciones} className='single-img-Detalle' />
                    <div className='text-left'>
                      <p className='single-span-Detalle no-mrn'>Fracciones por billete</p>
                      <p className='single-span-Detalle-valor no-mrn'>{prizePlan && prizePlan.numeroFracciones}</p>
                    </div>
                  </div>
                )}
                {loading ? (
                  <div>
                    <CircularProgress />
                  </div>
                ) : (
                  <div className='posiciones align-center p-1'>
                    <img src={DetallesTotal} className='single-img-Detalle' />
                    <div className='text-left'>
                      <p className='single-span-Detalle no-mrn'>Valor Total del Billete</p>
                      <p className='single-span-Detalle-valor no-mrn'>{`$ ${formatMoney(prizePlan && prizePlan.numeroFracciones * prizePlan.valorFraccion, 0, 3, '.', ',')}`}</p>
                    </div>
                  </div>
                )}
                {loading ? (
                  <div>
                    <CircularProgress />
                  </div>
                ) : (
                  <div className='posiciones align-center p-1'>
                    <img src={DetallesJuega} className='single-img-Detalle' />
                    <div className='text-left'>
                      <p className='single-span-Detalle no-mrn'>Juega dentro de</p>
                      <p className='single-span-Detalle-valor no-mrn'>{date}</p>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, prizePlan } = state.prizes;
  return {
    loading,
    error,
    prizePlan
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadPrizePlan(values) {
      dispatch(loadPrizePlan(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalle);
