


/*
@Author: Daniel Reyes Betancourt
@Description: Methods for encryption and decryption Gana services
@Date: 27/06/2019
@Version: 1.0
*/
import { encrypt } from './encryptorHandlerSecurity';

/*
@Description: Encrypt the information of a user from an object
@Params: {obUser} Object to be encrypted
*/
const getAuthApp = () => {
  
  try {

    return `Basic ${encrypt(process.env.REACT_APP_APPLICATION_USER)}:${encrypt(process.env.REACT_APP_APPLICATION_PASSWORD)}`;
  } catch (error) {
    return '';
  }
};

export { getAuthApp };
