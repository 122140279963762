//Dependencies
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import arrow from '../../assets/images/arrow.svg';
import calendar from '../../assets/images/calendar.svg';
import { normalizeDate } from '../renderFields/normalizeFields';

//Components
import renderField from './../renderFields/renderField';
import renderSelect from './../renderFields/renderSelect';
import renderDatePicker from './../renderFields/renderDatePicker';
import { loadListLotteries } from './../../actions/LotteriesActions';

//Utils
import Validators from './../renderFields/validatorsFields';

class LotterieForm extends Component {

  componentDidMount() {
    this.props.loadListLotteries({
      fecha: null,
      evento: null
    });
  }

  render() {
    const {
      handleSubmit,
      onDelete,
      pristine,
      classForm,
      reset,
      submitting,
      loadingForm,
      readOnly,
      loadingLotteries,
      listLotteries
    } = this.props;

    const autoReset = {
      autoReset:true
    };

    return (
      <form onSubmit={handleSubmit} className={`${classForm} form-lotterie`}>
        <Row className="m-0 justify-content-stretch align-items-start">
          <Col xs={12} sm={12} md={6} ld={5} xl={5}>
            <Row className="align-items-baseline">
              <Col xs={12} sm={3}>
                <label htmlFor="lotterie-date" className="d-inline-block color-white label-inline">Fecha:</label>
              </Col>
              <Col xs={12} sm={9}>
                <Field
                  name="fecha"
                  id="lotterie-date"
                  label="dd-mm-yyyy"
                  inputValueFormat="DD-MM-YYYY"
                  dateFormat="DD-MM-YYYY"
                  maxDate={moment()}
                  normalize={normalizeDate}
                  format={value => Validators.FormatDate(value)}
                  component={renderDatePicker}
                  className='form-file'
                  autoReset
                  readOnly={readOnly}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={5}>
            <Row className="align-items-baseline">
              <Col xs={12} sm={3}>
                <label htmlFor="lotterie-date" className="d-inline-block color-white label-inline">Lotería:</label>
              </Col>
              <Col xs={12} sm={9}>
                <Field
                  name="loteria"
                  id="lotterie-name"
                  type="select"
                  label="Lotería"
                  iconimg={arrow}
                  loadingOptions={loadingLotteries}
                  component={renderSelect}
                  validate={[Validators.notrequired]}
                  disabled={!listLotteries || !listLotteries.length}
                  readOnly={readOnly}
                >
                  <option value="null">Todos los resultados</option>
                  {listLotteries && listLotteries.length && listLotteries.map((option, index) => <option value={option.ideLoteria} key={index}>{option.desLoteria}</option>)}
                </Field>
              </Col>
            </Row>


          </Col>
          <Col xs={12} sm={12} md={12} lg={2} xl={2}>
            <button
              type="submit"
              className="button-text-reverse-buscar-resultado  mr-3 text-center"
              disabled={loadingForm}
            >
              Buscar
              {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
            </button>
          </Col>
        </Row>


      </form>
    );
  }

}

const mapStateToProps = (state) =>{
  const { list, loading } = state.lotteries.listLotteries;
  return {
    loadingLotteries: loading,
    listLotteries: list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadListLotteries(values) {
      dispatch(loadListLotteries(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'LotterieForm'})(LotterieForm));
