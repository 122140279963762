import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { history } from '../../helpers/history.js';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { setLotteryToPlay } from './../../actions/GameActions';
import { format, set } from 'date-fns';
import moment from 'moment';
import 'moment/locale/es';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Alert, CardText } from 'reactstrap';
import CountDown from '../../components/Lotteries/CountDown';
import { loadLotteriesHome } from './../../actions/LotteriesActions';
import Lotterie from './../../components/Lotteries/Lotterie';


const validate = (values, props) => {
  const errors = {};
  for (let i = 1; i <= 4; i++) {
    if (!values[`numero${i}`]) {
      errors.numero1 = '';
      break;
    }
  }

  return errors;
};

const MessageComponent = ({setLotteryToPlay, lotteries, props, loadLotteriesHome}) => {
  const searchParams = new URLSearchParams(history.location.search);

  const sorteo = String(searchParams.get('sorteo'));
  const idLoteria = String(searchParams.get('idLoteria'));
  const numero = String(searchParams.get('numero'));

  const [loadingLotteries, setLoadingLotteries] = useState(false);
  const [serviceExecutionCount, setServiceExecutionCount] = useState(0);


  if (!lotteries || lotteries.length === 0) {
    if (!loadingLotteries && serviceExecutionCount < 7) {
      setLoadingLotteries(true);

      loadLotteriesHome({
        fecha: '11-04-2018',
        evento: null
      });

      setServiceExecutionCount(prevCount => prevCount + 1);
    }
  }

  /*   if (!lotteries || lotteries.length === 0) {
    if (!loadingLotteries) {
      setLoadingLotteries(true);

      loadLotteriesHome({
        fecha: '11-04-2018',
        evento: null
      });
    }
  } */

  useEffect(() => {
    if (loadingLotteries && lotteries) {
      console.log('2222 Props lotteries :::: ', lotteries);
      setLoadingLotteries(false); 
    }
  }, [loadingLotteries, lotteries]);

  //const newLotteries = lotteries;

  (async() => {
    console.log('2222 Ingreso :::: ', lotteries);

    if (!lotteries || lotteries.length === 0) {
      if (!loadingLotteries && serviceExecutionCount < 10) {
        setLoadingLotteries(true);
  
        await loadLotteriesHome({
          fecha: '11-04-2018',
          evento: null
        });
  
        setServiceExecutionCount(prevCount => prevCount + 1);
      }
    }
  
    const selectedLoti = lotteries && await lotteries.filter(lotterie => lotterie.ideLoteria == idLoteria && lotterie.sorteo == sorteo)[0];
    

    if (selectedLoti) {
      console.log('2222 Entro '); 
      setLotteryToPlay(selectedLoti);

      history.replace(`/juego/${idLoteria}/${sorteo}`);
      history.push(`/juego/${idLoteria}/${sorteo}`);
      history.go();

      const caracteres = numero.split('');
      const cadenaConGuion = caracteres.join('-');
      localStorage.setItem('numero', cadenaConGuion);
      readFavorite$.emit('myEvent4', userData.initialRead);
    } 

  }) ();



  return (
    <div>
      {/* <h2>Mensaje Component</h2>
      <p>Sorteo: {sorteo}</p>
      <p>ID de Lotería: {idLoteria}</p>
      <p>Número: {numero}</p> */}
    </div>
  );
};


const mapStateToProps = state => {
  const { loading, error, lotteries } = state.lotteries.lotteriesHome;
  return {
    open: state.modal.update.open,
    user: state.authentication.user,
    loading,
    error,
    lotteries
  };
};


const mapDispatchToProps = dispatch => {
  return {
    changeValue(form, field, value) {
      dispatch(change(form, field, value));
    },
    setLotteryToPlay(lottery) {
      dispatch(setLotteryToPlay(lottery));
    },
    loadLotteriesHome(values) {
      dispatch(loadLotteriesHome(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)
(reduxForm({ form: 'GameForm', validate })(MessageComponent));