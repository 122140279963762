import { reclamaPremioConstants } from '../constants/ReclamaPremioConstas';

const initialState = {
  loading: false,
  reclamo: null,
  error: null
};

export function reclamo(state = initialState, action) {
  switch (action.type) {
    case reclamaPremioConstants.LIST_RECLAMO_REQUEST:
      return {
        loading: true
      };
    case reclamaPremioConstants.LIST_RECLAMO_SUCCESS:
      return {
        reclamo: action.payload
      };
    case reclamaPremioConstants.LIST_RECLAMO_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
