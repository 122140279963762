import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import shopping from './../../../src/assets/images/shopping_car.svg';
import notification from './../../../src/assets/images/notification.svg';

import { logoutUser } from './../../actions/UserActions';
import { toggleModalFavorite } from './../../actions/ModalActions';
import RespFavorite$ from '../../events/emitter';
import readFavorite$ from '../../events/emitter';


const CartHeader = ({ cart, toggleModal, user }) => {  
  const [initiaValue2, setInitiaValue2] = useState(false);

  let userData;
  let initiaValue = false;
  let loginIn = false;
  //let msgEvent = false;
  let out = false;

  const userDataJSON = localStorage.getItem('userData');
  
  out = localStorage.getItem('out');
  const [read, setRead] = useState(true);
  const [executedOnce, setExecutedOnce] = useState(false);

  function getUserData() {
    return new Promise(async(resolve, reject) => {
      try {
        await (ms => new Promise(resolve => setTimeout(resolve, ms)))(1000);

        const userDataJSON = localStorage.getItem('userData');
        const userData = JSON.parse(userDataJSON);
        if (userData) {
          resolve(userData);
        } else {
          reject('222267 No se encontraron datos en localStorage para "userData".');
        }
      } catch (error) {
        reject('222267 Error al obtener y parsear los datos de localStorage.');
      }
    });
  }

  if (userDataJSON) {
    userData = JSON.parse(userDataJSON);
    initiaValue = ((userData.initialValue != '0000') || (userData.initialValue == '0000' && userData.idLoteria) || (userData.initialValue == '0000' && userData.dataLoteria) ) ? true : false;
    loginIn = userData.logIn ? userData.logIn : false;
  }  


  const handleReadFavoriteRead = (mensaje) => {
    const userDataJSON2 = localStorage.getItem('userData');

    setTimeout(() => {
      userData = JSON.parse(userDataJSON2);
      const iRead = userData.initialRead;
      setRead(iRead);
    }, 5000);
  };

  let loginIn2;



  const handleRespFavoriteEvent = (mensaje) => {
    getUserData()
      .then(userData => {
        initiaValue = false;
        initiaValue = ((userData.initialValue != '0000') || (userData.initialValue == '0000' && userData.idLoteria) || (userData.initialValue == '0000' && userData.dataLoteria) ) ? true : false;
        loginIn = userData.logIn ? userData.logIn : false;
        loginIn2 = userData.logIn ? userData.logIn : false;
        setInitiaValue2(initiaValue);
        const iRead = userData.initialRead;
        if (initiaValue) {
          setRead(true);
        }
        console.log('2222557IN', initiaValue, loginIn, loginIn2);
      })
      .catch(error => {
        console.error(error);
      });

  };

  useEffect(() => {
    setInitiaValue2(initiaValue || false);
  }, [initiaValue]);


  React.useEffect(() => {
    RespFavorite$.on('myEvent2', handleRespFavoriteEvent);
    readFavorite$.on('myEvent4', handleReadFavoriteRead);
  }, []);

  
  return (
    <section className="navbar-right-section">
      <Link className="navbar-right-section-cart" title="Carrito de Compras" to="/carrito">
        <img className='log-cart' src={shopping} />
        {(typeof cart.lotteries != 'undefined' && typeof cart.recharges != 'undefined') &&
          (cart.lotteries.length > 0 || cart.recharges.length > 0) &&
          <p className="cart-quantity">{cart.lotteries.length + cart.recharges.length}</p>
        }
      </Link>
      { user ?
        <span className="navbar-right-section-cart" title="Notificaciones" onClick={() => initiaValue || initiaValue2 ? toggleModal('5') : null}>
          <div className="log-notification">
            <img className='icon' src={notification} alt="Notificaciones" /> 
            { read && (initiaValue || initiaValue2) ? <div className="cart-quantity">1</div> : null}
          </div>
        </span> : null
      }
    </section>
  );
};

const mapStateToProps = (state) => {
  const { loggingIn, error, user } = state.authentication;
  return {
    cart: state.cart.items,
    loggingIn,
    error,
    user
  };
};




const mapDispatchToProps = dispatch => {
  return {
    toggleModal(tab) {
      dispatch(toggleModalFavorite());
    },
    logoutUser() {
      dispatch(logoutUser());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartHeader);
