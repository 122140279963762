import { reloadsConstants } from './../constants/ReloadsConstants';
import ReloadsService from './../services/ReloadsService';
//Images
import iconClaro from './../assets/images/logo-claro.jpg';

//====================================================================
// Loading Operators Reloads with pacakages
//====================================================================

export const loadOperators = (typeOperator) => (dispatch) => {

  const operatorsRequest = () => ({
    type: reloadsConstants.RELOADS_OPERATORS_REQUEST
  });

  const operatorsSuccess = payload => ({
    type: reloadsConstants.RELOADS_OPERATORS_SUCCESS,
    payload
  });

  dispatch(operatorsRequest());

  ReloadsService.loadOperators(typeOperator)
    .then(
      operators => {
        dispatch(operatorsSuccess(operators.data[0].productos));
      },
      error => {
        dispatch(operatorsSuccess([]));
      }
    );
};

//====================================================================
// Loading All Operators for simple Reloads
//====================================================================

export const loadAllOperators = () => (dispatch) => {

  const allOperatorsRequest = () => ({
    type: reloadsConstants.RELOADS_OPERATORS_REQUEST
  });

  const allOperatorsSuccess = payload => ({
    type: reloadsConstants.RELOADS_OPERATORS_SUCCESS,
    payload
  });

  dispatch(allOperatorsRequest());

  ReloadsService.loadAllOperators()
    .then(
      operators => {
        dispatch(allOperatorsSuccess(operators.data[0].productos));
      },
      error => {
        dispatch(allOperatorsSuccess([]));
      }
    );
};

export const loadPackages = (idOperator, operator) => (dispatch) => {

  const packagesRequest = () => ({
    type: reloadsConstants.RELOADS_PACKAGES_REQUEST
  });

  const packagesSuccess = payload => ({
    type: reloadsConstants.RELOADS_PACKAGES_SUCCESS,
    payload
  });

  const packagesError = error => ({
    type: reloadsConstants.RELOADS_PACKAGES_FAILURE,
    error
  });

  dispatch(packagesRequest());
  ReloadsService.loadListPackages({ ideOperadorGANA : idOperator })
    .then(
      packages => {
        packages.data.paqueteMovil.forEach(element => {
          element.operator = operator;
          element.value = element.idePaqueteOperador.toString();
        });
        dispatch(packagesSuccess(packages.data.paqueteMovil));
      },
      error => {
        dispatch(packagesError(error.message ? error.message : 'Ocurrió un error al momento de traer el listado de paquetes disponibles'));
      }
    );
};
