import 'whatwg-fetch';
import { getAuthApp } from '../services/security/authApp';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

class ReloadsService {

  static loadOperators(typeOperator) {

    const url = `${serverURL}/api/Users/etn/operadores-con-paquetes?product=${encodeURIComponent(JSON.stringify({paquete:typeOperator}))}`;

    return fetch(url, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static loadAllOperators() {

    const url = `${serverURL}/api/Users/etn/consultar-operadores`;

    return fetch(url, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static loadListPackages(values) {

    const url = `${serverURL}/api/Users/etn/buscar-paquete-movil`;
    const data = JSON.stringify({ product: {...values} }, null, 2);

    return fetch(url, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default ReloadsService;
