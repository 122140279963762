import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import LoginForm from './../../components/Login/LoginForm';
import { loginUser } from './../../actions/UserActions';
import {encryptUserData} from '../../services/security/informationCodingSecurity';
import RespFavorite$ from './../../events/emitter';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.state = {
      emittedEvent: false // Agrega un estado para controlar si el evento se ha emitido
    };
  }

  handleOnSave = values => {
    this.props.loginUser( encryptUserData(JSON.parse(JSON.stringify(values))));
  };


  render() {   
    const { loggingIn, error, user, onChangePasswordClick } = this.props;
    const initialValues = {
      tipoDocumento: 'CC'
    };
    return (
      <section>
        <h4 className="mb-4 font-montserrat-bold">Ingresa tus datos</h4>
        <p className="mb-4">Recuerda que puedes iniciar sesión con tu cuenta Oki</p>
        <Row className="justify-content-center">
          <Col xs="12" sm="10" md="9" lg="8" xl="7">
            <LoginForm
              onSubmit={this.handleOnSave}
              loadingForm={loggingIn}
              initialValues={initialValues}
            />
            {error && <Alert className="mt-3 mb-0" color="danger"> {error} </Alert>}
            {user && user.token && (<Alert className="mt-3 mb-0" color="success"> Inicio de sesión Exitoso</Alert>)}           
            <span onClick={onChangePasswordClick} className="mt-3 button-text">¿Olvidaste tu contraseña?</span>
          </Col>
        </Row>
      </section>
    );
  }
}

const mapStateToProps = (state) =>{
  const { loggingIn, error, user } = state.authentication;
  return {
    loggingIn,
    error,
    user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser(values) {
      dispatch(loginUser(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
