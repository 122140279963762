import React, { useState, useEffect, useRef } from 'react';
import { format, set } from 'date-fns';
import moment from 'moment';
import 'moment/locale/es';
import { connect } from 'react-redux';
import { history } from '../../helpers/history';
import { logoutUser } from './../../actions/UserActions';
import { toggleModalFavorite } from './../../actions/ModalActions';
import notification from './../../../src/assets/images/notification.svg';
import notificationWhite from './../../../src/assets/images/notificationWhite.svg';
import userimg from './../../../src/assets/images/user.svg';
import DetallesValorFraccion from './../../../src/assets/images/DetallesValorFraccion.svg';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Alert, CardText } from 'reactstrap';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { normalizeNumbers } from './../../../src/components/renderFields/normalizeFields';
import renderInputGame from './../../../src/components/Game/renderInputGame';
import modalG from './../../../src/assets/images/modal-g.png';
import line5 from './../../../src/assets/images/Line5.svg';
import line6 from './../../../src/assets/images/Line6.svg';
import Ellipse13 from '../../assets/images/Ellipse13.svg';
import out from '../../assets/images/out.svg';
import Reloj from '../../assets/images/Reloj.svg';
import checkGreen from '../../assets/images/check-green.svg';
import renderRadioButtonGame from '../../components/Game/renderRadioButtonGame';
import Validators from './../../components/renderFields/validatorsFields';
import { setLotteryToPlay } from './../../actions/GameActions';
import Lotterie from './../../components/Lotteries/Lotterie';


import CountDown from '../../components/Lotteries/CountDown';
import MyFavoriteNumber$ from './../../events/emitter';
import readFavorite$ from '../../events/emitter';
import { loadLotteriesHome } from './../../actions/LotteriesActions';



const validate = (values, props) => {
  const errors = {};
  for (let i = 1; i <= 4; i++) {
    if (!values[`numero${i}`]) {
      errors.numero1 = '';
      break;
    }
  }

  return errors;
};

const ModalFavorite = ({ open, toggleModalFavorite, cancelAction, readOnly, lottery, setLotteryToPlay, loadLotteriesHome, lotteries }) => {


  let user = localStorage.getItem('userLoti2');
  user = JSON.parse(user);

  const userDataJSON = localStorage.getItem('userData');
  let userData;
  let readClick;
  const [loadingLotteries, setLoadingLotteries] = useState(false);
  const [serviceExecutionCount, setServiceExecutionCount] = useState(0);


  if (!lotteries || lotteries.length === 0) {
    if (!loadingLotteries && serviceExecutionCount < 7) {
      setLoadingLotteries(true);

      loadLotteriesHome({
        fecha: '11-04-2018',
        evento: null
      });

      setServiceExecutionCount(prevCount => prevCount + 1);
    }
  }


  useEffect(() => {
    if (loadingLotteries && lotteries) {
      setLoadingLotteries(false);
    }
  }, [loadingLotteries, lotteries]);

  const newLotteries = lotteries;

  if (userDataJSON) {
    userData = JSON.parse(userDataJSON);
  } else {
    userData = {
      serieValue: '',
      initialValue: '0000',
      initialNameLotery: '',
      numeroSorteo: '',
      horarios: '220000',
      fechaSorteo: '',
      idLoteria: '',
      abreviaturaLoteria: '',
      logIn: false,
      read: false,
      initialRead: true,
      dataLoteria: null,
      dL: null
    };
  }

  const initialSerieValue = user && user.numeroSerie ? user.numeroSerie : userData.serieValue;
  const initialValue = user && user.numeroFrecuente ? user.numeroFrecuente : userData.initialValue;
  const initialNameLotery = user && user.numeroFrecuente ? user.nombreLoteria : userData.initialNameLotery;
  const initialNumeroSorteo = user && user.numeroSorteo ? user.numeroSorteo : userData.numeroSorteo;
  const initialHorarios = user && user.horaSorteo ? user.horaSorteo : userData.horarios;
  const initialfechaSorteo = user && user.fechaSorteo ? user.fechaSorteo : userData.fechaSorteo;
  const initialidLoteria = user && user.idLoteria ? user.idLoteria : userData.idLoteria;
  const initialAbreviaturaLoteria = user && user.abreviaturaLoteria ? user.abreviaturaLoteria : userData.abreviaturaLoteria;
  const initialLogIn = user && user.nombres ? true : false;
  const initialDataLoteria = user && user.dataLoteria ? user.dataLoteria : userData.dataLoteria;
  const initialdL = user && user.dataLoteria ? user.dataLoteria : userData.dL;

  localStorage.setItem('initialValue', initialValue);

  userData = {
    serieValue: initialSerieValue,
    initialValue: initialValue,
    initialNameLotery: initialNameLotery,
    numeroSorteo: initialNumeroSorteo,
    horarios: initialHorarios,
    fechaSorteo: initialfechaSorteo,
    idLoteria: initialidLoteria,
    abreviaturaLoteria: initialAbreviaturaLoteria,
    logIn: initialLogIn,
    initialRead: readClick,
    dataLoteria: initialDataLoteria,
    dL: initialdL
  };

  const userDataJSONSave = JSON.stringify(userData);
  localStorage.setItem('userData', userDataJSONSave);


  const horaOriginal = initialHorarios; // Tu hora en formato hhmmss
  const horaFormateada = format(new Date(0, 0, 0, horaOriginal.substring(0, 2), horaOriginal.substring(2, 4)), 'HH:mm');


  const date = initialfechaSorteo ? initialfechaSorteo +' '+horaFormateada : '2023-12-12 22:00';

  const _date = date.replace(' ', 'T');
  const day = moment(_date).format('DD MMMM');
  const hour = moment(_date).format('h:mm a');

  const textTime = () => {
    const countDownDate = new Date(_date).getTime();
    // Get todays date and time
    const now = new Date().getTime();

    const diff = countDownDate - now;
    const days = (diff / (1000 * 60 * 60 * 24));
    if (days > 1) {
      return (
        `${day} / ${hour}`
      );
    } else {
      return (<CountDown date={_date} />);
    }
  };


  const textInputNumber1 = useRef(null);
  const textInputNumber2 = useRef(null);
  const textInputNumber3 = useRef(null);
  const textInputNumber4 = useRef(null);

  const handleFocusTextInput = (textNumber) => {
    if (textNumber && textNumber?.current) {
      console.log("textNumber Definido " + textNumber.current);
      textNumber.current.focus();
    }
    else {
      console.log("textNumber No definido correctamente");
    }
  }


  const [serieValue, setSerieValue] = useState(initialSerieValue);


  function inicioSeccion(string) {
    //return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return '';
  }

  const handleOnClick = () => {
    toggleModalFavorite();

    const selectedLoti = newLotteries.filter(lotterie => lotterie.ideLoteria == initialidLoteria && lotterie.sorteo == initialNumeroSorteo)[0];

    if (selectedLoti) {
      setLotteryToPlay(selectedLoti);

      history.replace(`/juego/${initialidLoteria}/${initialNumeroSorteo}`);
      history.push(`/juego/${initialidLoteria}/${initialNumeroSorteo}`);
      history.go();

      const caracteres = initialValue.split('');
      const cadenaConGuion = caracteres.join('-');

      readClick = false;
      userData.initialRead = false;
      const serie = serieValue ? serieValue : initialSerieValue;

      const userDataJSONSave = JSON.stringify(userData);
      localStorage.setItem('userData', userDataJSONSave);
      localStorage.setItem('numero', cadenaConGuion);
      localStorage.setItem('serieFav', serie);
      readFavorite$.emit('myEvent4', userData.initialRead);
    }
  };

  const handleOnClick2 = () => {
    toggleModalFavorite();
    history.push('/loterias');
    history.go();

    readClick = false;
    userData.initialRead = false;
    userData.dataLoteria = initialDataLoteria ? initialDataLoteria : initialdL;
    const userDataJSONSave = JSON.stringify(userData);
    localStorage.setItem('userData', userDataJSONSave);
    readFavorite$.emit('myEvent4', userData.initialRead);

  };

  const handleOnCancel = () => {
    toggleModalFavorite();
    readClick = false;
    userData.initialRead = false;
    const userDataJSONSave = JSON.stringify(userData);
    localStorage.setItem('userData', userDataJSONSave);
    readFavorite$.emit('myEvent4', userData.initialRead);
  };


  const numbers = Array.from({ length: 4 }, (_, index) => index + 1);
  const baseURL = process.env.REACT_APP_BASEURL;
  const playLot = initialAbreviaturaLoteria ? true : false;
  const series = typeof(initialSerieValue) === 'string' ? null : initialSerieValue;

  const handleSerieChange = (event) => {
    const selectedValue = event.target.value;
    setSerieValue(selectedValue);
  };

  return (
    <Modal isOpen={open} toggle={() => toggleModalFavorite()} className="modal-dialog-centered modalFavorite" backdrop={'static'}>
      <ModalHeader className="modalUser">
        <span onClick={() => handleOnCancel()} className='Modal-single-close-inicio'>X</span>
        <div className="user-info">
          <img src={modalG} className="modalG"/>
          <div className="user-logo">
            <img src={userimg} alt="User" />
          </div>
          <div className="user-greetings">
            <span className="modalUserSubTitle">¡Hola!</span><br />
            {user && user.nombres && user.primerApellido ? (
              <span className="modalUserTitle">
                {inicioSeccion(user.nombres)} {inicioSeccion(user.primerApellido) }
              </span>
            ) : null}
          </div>
          <div className="user-card">
            <img className='modalIconCamp2' src={DetallesValorFraccion} />
            Tu número favorito está disponible
          </div>

        </div>
      </ModalHeader>
      <ModalBody className="modalUserLotery">
        <form class="return-seccion2">
          <span className="modalUserSubTitle2">
            <img className='modalIconCamp' src={notificationWhite} />
            Notificaciones
          </span>
          <Row className="single-loteri3 mx-0 mb-4">
            { playLot ?
              <Col xs={12} sm={12} md={3} lg={4} className="mb-4">
                <section className="gameContainer-logo p-4 h-100 text-center single-loteri2">
                  <img className="content-center image-lotterieModal d-block mb-4 w-100" src={`${baseURL}/assets/images/loterias/${initialAbreviaturaLoteria}.png`} />
                  <p className="nameModal text-center mb-4">{initialNameLotery}</p>
                  <div className='single-ciere mx-auto' style={{ display: 'flex', alignItems: 'center', width: '90px' }}>
                    <img className='single-reloj' src={Reloj} style={{ margin: 5, width: '50px' }} /><h6 className='single-h6' style={{ margin: 0, width: '150px' }}>Las ventas cierran a las:</h6>
                  </div>
                  <CardText className='single-conteo2'>{textTime()}</CardText>

                </section>
              </Col>
              : null
            }
            <Col xs={12} sm={12} md={9} lg={8} className="mb-4">
              <section className="single-loteri2 p-4 back-white border-container h-100 section-game box-shadow-md">
                <div>
                  <p className="text-center mb-4">
                    Número favorito
                  </p>
                  <div className='greenCheck'><img src={checkGreen} /></div>
                </div>
                <div className="input-group justify-content-center text-center">
                  <Row className="mx-0 mb-4 alingSerie">
                    {numbers.map((number) => (
                      <Col xs={3} sm={3} md={3} lg={3} key={number}>
                        <Field
                          name={`numero${number}`}
                          id={`game-numero${number}`}
                          type="text"
                          inputRef={eval(`textInputNumber${number}`)}
                          normalize={normalizeNumbers}
                          label={initialValue.charAt(number - 1)} // Asigna el valor de la variable 'initialValue'
                          component={renderInputGame}
                          readOnly="true"
                          onChange={(event) => {
                            if (event.target.value && number < 4) {
                              handleFocusTextInput(eval(`textInputNumber${number + 1}`));
                            }
                          }}
                        />
                      </Col>
                    ))}

                    {series == null && playLot ?
                      <div className='modalUserSerie'>
                        <label>Serie</label>
                        <input type='radio' checked className='modalUserInput' />
                        <label>{initialSerieValue}</label>
                      </div> : null
                    }
                    {series && playLot ? (
                      <div className='modalUserSerie'>
                        <label className='alinSerieName'>Serie</label>
                        {series.slice(0, 4).map((serie, index) => (
                          <div key={index} className='modalUserSerieDiv'>
                            <input
                              type="radio"
                              id={`radio${index}`}
                              name="serieOption"
                              value={serie.id}
                              className='modalUserInput2'
                              onChange={handleSerieChange}
                            />
                            <label htmlFor={`radio${index}`} className='modalUserInputLabel'>{serie.id}</label>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </Row>
                </div>
              </section>
            </Col>
          </Row>
        </form>
        { playLot
          ?
          <div className='modalButon'>
            <Button className="button-rounded button-rounded-gradient-resultados align-items-center" onClick={handleOnClick}>JUGAR AHORA →</Button>
          </div>
          :
          <div className='modalButon'>
            <Button className="button-rounded button-rounded-gradient-resultados align-items-center" onClick={handleOnClick2}>Ver Loterías</Button>
          </div>
        }
      </ModalBody>
      <ModalFooter className="modalUserLotery2">
        <p className="cuponMsg" >
          <img class="input-positionImg line7" src={line5}></img>
          <img class="input-positionImg ellipse2" src={Ellipse13}></img>
          <img class="input-positionImg line7" src={line6}></img>
        </p>
        <div className='modalButon'>
          <span onClick={() => handleOnCancel()} className='Modal-single-close-cerrar'>Cerrar<img className='modalIconCampCerrar' src={out} /></span>
        </div>
      </ModalFooter>
    </Modal>
  );
};


const mapStateToProps = state => {
  const { loading, error, lotteries } = state.lotteries.lotteriesHome;

  return {
    open: state.modal.update.open,
    user: state.authentication.user,
    loading,
    error,
    lotteries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeValue(form, field, value) {
      dispatch(change(form, field, value));
    },
    toggleModalFavorite() {
      dispatch(toggleModalFavorite());
    },
    cancelAction() {
      dispatch(logoutUser());
    },
    setLotteryToPlay(lottery) {
      dispatch(setLotteryToPlay(lottery));
    },
    loadLotteriesHome(values) {
      dispatch(loadLotteriesHome(values));
    }
  };
};



export default connect(mapStateToProps, mapDispatchToProps)
(reduxForm({ form: 'GameForm', validate })(ModalFavorite));

