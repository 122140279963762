import React, { Component } from 'react';
import { FormText } from 'reactstrap';

class renderInputModal extends Component {
  focusInput() {
    this.input.focus();
  }

  render() {
    const {input, label, type, meta: {touched, error, warning}, ...rest} = this.props;
    return (
      <div className="position-relative single-input2">
        <input
          {...input}
          {...rest}
          ref={node => this.input = node}
          type={type}
          placeholder={label} className="form-file"
        />
        {touched &&
        ((error && <FormText className="text-left">{error}</FormText>) ||
        (warning && <FormText className="text-left">{warning}</FormText>))}
      </div>
    );
  }
}


export default renderInputModal;
