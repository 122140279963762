import { userConstants } from './../constants/UserConstants';

const initialState = {
  ubdate: {
    loading: false,
    success: false,
    error: null
  },
  info:{
    loading: false,
    success: null,
    error: null
  }
};

export function user (state = initialState, action) {
  switch (action.type) {
    case userConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        ubdate: {
          loading: true
        }
      };
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        ubdate: {
          success: true
        }
      };
    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        ubdate: {
          error: action.error
        }
      };
    case userConstants.UPDATE_USER_CLEAR:
      return {
        ...state,
        ubdate: {
          loading: false,
          success: false,
          error: null
        }
      };
    case userConstants.GET_USER_REQUEST:
      return {
        ...state,
        info: {
          loading: true
        }
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        ...state,
        info: {
          success: true
        }
      };
    case userConstants.GET_USER_FAILURE:
      return {
        ...state,
        info: {
          error: action.error
        }
      };
    default:
      return state;
  }
}
