import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { toggleModalGeneral } from './../../actions/ModalActions';

class ModalComponent extends Component {

  render () {
    const { children, className, open, toggleModalGeneral, customToggleModal, dontClose } = this.props;
    return (
      <Modal isOpen={open} toggle={!dontClose ? (customToggleModal ? customToggleModal : toggleModalGeneral) : null} className={`general-modal ${className}`}>
        {!dontClose &&
          <button type="button" className="modal-transparent-close" onClick={() => !dontClose ? (customToggleModal ? customToggleModal() : toggleModalGeneral()) : null} aria-label="Close">
            <p aria-hidden="true" className='single-close-fail no-mrn'>&times;</p>
          </button>
        }
        {children}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    open : state.modal.general.open
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModalGeneral() {
      dispatch(toggleModalGeneral());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
