import React, { Component } from 'react';
import { Col, Row, Container, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { decryptUserData } from '../../services/security/informationCodingSecurity';
import { OrderService } from './../../services/OrderService';

//Helpers
import { formatMoney } from './../../helpers/formatMoney';

//Actions
import { checkStateOrder } from './../../actions/OrderActions';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';

class OrderConfirmContainer extends Component {

  componentDidMount() {
    const {
      match: {
        params: {
          referencia = 0
        }
      }
    } = this.props;
    let order = null;
    if (window.location.pathname.includes('/confirmacion/payvalida')) {
      const urlParams = new URLSearchParams(window.location.search);
      order = urlParams.get('order');
    }
    if (referencia || referencia == 'payvalida') {
      this.props.checkStateOrder({ 'reference': order || referencia });
    } else {
      this.props.history.replace('/');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.check.success && !nextProps.check.success.carrito) {
      this.props.history.replace('/');
    }
  }

  handleClickPDF = () => {
    const { check } = this.props;
    console.log('check desde order ------------->>>>  ', check );

    const orderData = [
      {
        label: 'Resultado de la transacción',
        value: check.success.estado
      },
      {
        label: 'Nombre del comprador',
        value: check.success.nombre
      },
      {
        label: 'Email',
        value: check.success.email
      },
      {
        label: 'Documento',
        value: check.success.documento
      },
      {
        label: 'Teléfono',
        value: check.success.celular
      },
      {
        label: 'Fecha de la transacción',
        value: moment(check.success.fecha).format('LLL')
      },
      {
        label: 'Moneda',
        value: check.success.monto.currency
      },
      {
        label: 'Total Pagado',
        value: `$ ${formatMoney(check.success.monto.total, 0, 3, '.', ',')}`
      },
      {
        label: 'Banco',
        value: check.success.banco
      },
      {
        label: 'Referencia',
        value: check.success.referencia
      },
      {
        label: 'Descripción',
        value: check.success.descripcion
      },
      {
        label: 'Dirección IP',
        value: check.success.ip
      }
    ];

    if (check.success.descuento) {
      orderData.splice(7, 0, {
        label: 'Descuento',
        value: `$ ${formatMoney(check.success.descuento, 0, 3, '.', ',')}`
      });
      orderData.splice(7, 0, {
        label: 'Valor total',
        value: `$ ${formatMoney(check.success.totalSinDescuento, 0, 3, '.', ',')}`
      });
    } else if (check.success.monto.descuento) {
      orderData.splice(7, 0, {
        label: 'Descuento',
        value: `$ ${formatMoney(check.success.monto.descuento, 0, 3, '.', ',')}`
      });
      orderData.splice(7, 0, {
        label: 'Valor total',
        value: `$ ${formatMoney(check.success.monto.totalSinDescuento, 0, 3, '.', ',')}`
      });
    }

    OrderService.getPDFOrder(orderData);
  };

  render() {
    const { check, user } = this.props;
    return (
      <Col xs={12} className="homeContainer return-seccion">
        <Row>
          <Container>
            <section className="mt-5 mb-4 p-4">
              <h3 className="first-title text-center interna-resultados my-4"style={{ paddingTop: '100px' }}>Confirmación de Compras</h3>
              <div className="table-description">
                <h4>Datos de transacción</h4>
                <section className="p-5">
                  {check.loading && <CircularProgress />}
                  {check.success &&
                    <div>
                      <ul className="mb-3 list-confirm-cart">
                        <li>
                          <span className="list-confirm-cart-title">Resultado de la transacción</span>
                          <span>{check.success.estado}</span>
                        </li>
                        <li>
                          <span className="list-confirm-cart-title">Nombre del comprador</span>
                          <span>{check.success.nombre}
                          </span>
                        </li>
                        <li>
                          <span className="list-confirm-cart-title">Email</span>
                          <span>{check.success.email}</span>
                        </li>
                        <li>
                          <span className="list-confirm-cart-title">Documento</span>
                          <span>{check.success.documento}</span>
                        </li>
                        <li>
                          <span className="list-confirm-cart-title">Teléfono</span>
                          <span>{check.success.celular}</span>
                        </li>
                        <li>
                          <span className="list-confirm-cart-title">Fecha de la transacción</span>
                          <span>{moment(check.success.fecha).format('LLL')}</span>
                        </li>
                        <li>
                          <span className="list-confirm-cart-title">Moneda</span>
                          <span>{check.success.monto.currency}</span>
                        </li>
                        <li>
                          <span className="list-confirm-cart-title">Total pagado</span>
                          <span>{`$ ${formatMoney(check.success.monto.total, 0, 3, '.', ',')}`}</span>
                        </li>
                        {check.success.banco &&
                          <li>
                            <span className="list-confirm-cart-title">Banco</span>
                            <span>{check.success.banco}</span>
                          </li>
                        }
                        <li>
                          <span className="list-confirm-cart-title">Referencia</span>
                          <span>{check.success.referencia}</span>
                        </li>
                        <li>
                          <span className="list-confirm-cart-title">Descripción</span>
                          <span>{check.success.descripcion}</span>
                        </li>
                        <li>
                          <span className="list-confirm-cart-title">Dirección IP</span>
                          <span>{check.success.ip}</span>
                        </li>
                      </ul>
                      <Row className="m-0 justify-content-center">
                        <Col lg={5} className="single-comprobantes justify-content-center">
                          <button type="button" onClick={() => this.handleClickPDF()} className="button-rounded button-rounded-gradient-resultados mb10 px-30 py5">
                            <div className="center-right-button">
                              <span><strong>Comprobante</strong></span>
                              <i className="icon-float-right plus-icon flaticon-sheet" />
                            </div>
                          </button>
                          <div>
                            <Link type="button" className="button-rounded button-rounded-gradient-resultados px-30 py5" to="/">
                              <div className="center-right-button">
                                <span><strong>Continuar</strong></span>
                                <i className="icon-float-right plus-icon fa fa-arrow-circle-o-right" />
                              </div>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  }
                  {check.error && <Alert className="my-2 content-center" color="danger"> {check.error} </Alert>}
                </section>
              </div>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
};

const mapStateToProps = (state) => {
  const { check } = state.order;
  return {
    check,
    user: state.authentication.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkStateOrder(values) {
      dispatch(checkStateOrder(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmContainer);
