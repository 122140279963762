import React, { useFormContext, useState } from 'react';
import renderSelect from '../renderFields/renderSelect';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Col, FormGroup, FormText, Row } from 'reactstrap';
import { getDaysInMonth } from 'date-fns';
import Validators from '../renderFields/validatorsFields';

export const DatePickerFechaNacimientoEditUser = ({ yearName, monthName, dayName }) => {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedDay, setSelectedDay] = useState('');

  const years = () => {
    const currentYear = new Date().getFullYear() - 18;
    const years = [];

    for (let i = currentYear; i >= 1900; i--) {
      years.push(i);
    }
    return years;
  };

  const months = [
    { id: '01', name: 'Enero' },
    { id: '02', name: 'Febrero' },
    { id: '03', name: 'Marzo' },
    { id: '04', name: 'Abril' },
    { id: '05', name: 'Mayo' },
    { id: '06', name: 'Junio' },
    { id: '07', name: 'Julio' },
    { id: '08', name: 'Agosto' },
    { id: '09', name: 'Septiembre' },
    { id: '10', name: 'Octubre' },
    { id: '11', name: 'Noviembre' },
    { id: '12', name: 'Diciembre' }
  ];

  const handleChangeYear = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleChangeMonth = (event) => {
    setSelectedMonth(event.target.value);
    setSelectedDay('');
  };

  const handleChangeDay = (event) => {
    setSelectedDay(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const days = [];
  const daysInMonth = getDaysInMonth(new Date(selectedYear, selectedMonth - 1));
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }


  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md="4">
          <Field id={yearName} name={yearName} icon="flaticon-arrows" value={selectedYear} onChange={handleChangeYear} component={renderSelect} validate={[Validators.requiredfecha,
            Validators.notDefaultfecha]}>
            {years().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Field>
        </Col>
        <Col md="4">
          <Field id={monthName} name={monthName} icon="flaticon-arrows" value={selectedMonth} onChange={handleChangeMonth} component={renderSelect} validate={[Validators.requiredfecha,
            Validators.notDefaultfecha]}>
            {months.map((month) => (
              <option key={month.id} value={month.id}>
                {month.name}
              </option>
            ))}
          </Field>
        </Col>
        <Col md="4">
          <Field id={dayName} name={dayName} icon="flaticon-arrows" value={selectedDay} onChange={handleChangeDay} component={renderSelect} validate={[Validators.requiredfecha,
            Validators.notDefaultfecha]}>
            {days.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </Field>
        </Col>
      </Row>
    </form>
  );
};
