import { prizePlanConstants } from './../constants/PrizePlanConstants';

const initialState = {
  loading: false,
  prizePlan: null,
  error: null
};

export function prizes(state = initialState, action) {
  switch (action.type) {
    case prizePlanConstants.LIST_PRIZE_REQUEST:
      return {
        loading: true
      };
    case prizePlanConstants.LIST_PRIZE_SUCCESS:
      return {
        prizePlan: action.payload
      };
    case prizePlanConstants.LIST_PRIZE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
