import { documentTypes, documentTypesDefault } from '../constants/AccountConstants';

/**
 * Obtener listado de tipos de documento, ya sean por defecto (CC,CE,PA) o por 
 * definici�n en variable de entorno
 * @returns ArrayObject listDocumentTypes
 */
export const getDocumentTypes = (allDocs = false) => {
  let documentTypesFilter = documentTypes.filter((item) => Object.keys(documentTypesDefault).includes(item.type));
  const documentTypesAllowed = process.env.REACT_APP_DOCUMENT_TYPES_ALLOWED;
  if (documentTypesAllowed) {
    const listDocumentTypesAllowed = documentTypesAllowed.split(',').map(doc => doc.trim());
    if (listDocumentTypesAllowed && listDocumentTypesAllowed.length > 0) {
      documentTypesFilter = documentTypes.filter((item) => listDocumentTypesAllowed.includes(item.type));
    }   
  }
  return allDocs ? documentTypes : documentTypesFilter;
};

/**
 * 
 * @returns 
 */
export const getFullNameCurrentUser = () => {
  let fullName = '';
  const user = localStorage.getItem('userLoti');
  if (user) {
    const userObj = JSON.parse(user);
    fullName = `${userObj.nombres} ${userObj.primerApellido} ${userObj.segundoApellido}`;
  }
  return fullName;
};