import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CountDown extends Component {

  static propTypes = {
    date: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.updateValuesDate(this.props.date), 1000);
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }

  updateValuesDate (date) {
    const countDownDate = new Date(date).getTime();
    // Get todays date and time
    const now = new Date().getTime();

    // Find the distance between now an the count down date
    const distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    let hours = Math.floor(distance / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance < 0) {
      hours = 0;
      minutes = 0;
      seconds = 0;
      clearInterval(this.interval);
    }

    this.setState({hours, minutes, seconds});

  }

  render() {
    const { hours, minutes, seconds } = this.state;
    return (
      <span className="counter-time">
        <span className='single-time'><strong>{hours}</strong> h. </span>
        <span className='single-time'><strong>{minutes}</strong> m. </span>
        <span className='single-time'><strong>{seconds}</strong> s.</span>
      </span>
    );
  }
}

export default CountDown;
