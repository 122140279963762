import { generalConstants } from './../constants/GeneralConstants';
import { createSelector } from 'reselect';

const initialState = {
  pages:{
    loading: false,
    list: [],
    error: null
  }
};

export function general(state = initialState, action) {
  switch (action.type) {
    case generalConstants.LIST_PAGES_REQUEST:
      return {
        ...state,
        pages: {
          loading: true
        }
      };
    case generalConstants.LIST_PAGES_SUCCESS:
      return {
        ...state,
        pages: {
          list: action.payload
        }
      };
    case generalConstants.LIST_PAGES_FAILURE:
      return {
        ...state,
        pages: {
          error: action.error
        }
      };
    default:
      return state;
  }
}

export const getPageInfo = (state, slugPage) => createSelector(
  state => state.general.pages.list,
  (list) => (
    (list && list.length ? list.filter(page => page.page === slugPage)[0] : [])
  )
)(state);

