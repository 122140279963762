import { generalConstants } from './../constants/GeneralConstants';
import GeneralService from './../services/GeneralService';

export const loadListPages = () => (dispatch) => {

  const listPagesRequest = () => ({
    type: generalConstants.LIST_PAGES_REQUEST
  });

  const listPagesSuccess = payload => ({
    type: generalConstants.LIST_PAGES_SUCCESS,
    payload
  });

  const listPagesError = error => ({
    type: generalConstants.LIST_PAGES_FAILURE,
    error
  });

  dispatch(listPagesRequest());

  Promise.all([GeneralService.loadListPages(), GeneralService.loadListSubPages()]).then(values => {
    values[0].forEach((page, index) => {
      page.subpages = values[1].filter(subpage => subpage.pageId === page.id);
    });
    dispatch(listPagesSuccess(values[0]));
  }, reason => {
    dispatch(listPagesError(reason.message ? reason.message : 'Ocurrio un error al momento de cargar las información de la página'));
  });
};
