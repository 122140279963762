/* eslint-disable react/self-closing-comp */
import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';

//Components
//import SliderAbout from './../../components/About/SliderAbout';
import CatalogItem from './../../components/GanaPoints/CatalogItem';
import AllyItem from './../../components/GanaPoints/AllyItem';

//Actions
//import { getPageInfo } from './../../reducers/generalReducer';
import { loadCards } from './../../actions/GanaPointsActions';
import { loadUserPoints } from './../../actions/GanaPointsActions';

//Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class GanaPointsContainer extends Component {
  constructor(props) {
    super(props);
    this.puntosUsuario = 0;
  }
  /*handleSelect(eventKey) {
    let route;
    // Switch cases based on eventKeys
    switch (eventKey) {
      case 1:
        route = 'link1';
        break;
      case 2:
        route = 'link2';
        break;
      case 3:
        route = 'link3';
        break;
      case 4:
        route = 'link4';
        break;
      case 5:
        route = 'link5';
        break;
      default:
        route = '/';
    }
    this.props.history.push(route);
  }*/

  componentDidMount() {
    this.props.loadCards();
  }

  render() {
    const { loading, error, results, userPoints } = this.props;
    if (results) {
      this.descriptionPage = results[0].description;
      this.subpages = results[0].unity;
      this.cardsAlly = results[0].unity[4].cards;
      this.cardsCatalog = results[0].unity[3].cards;
    }

    if (userPoints) {
      this.puntosUsuario = userPoints.data.disponibles;
    }

    return (
      <Col xs={12} className="homeContainer">
        <Row>
          <Container className="bg-primary return-seccion">
            <section className="mt-5 mb-4 p-4 border-container">
              <h2 className="font-montserrat-bold text-center text-white my-5 interna-resultados">Puntos Gana</h2>
              <div className="font-montserrat-light text-white text-justified mb-5" dangerouslySetInnerHTML={{ __html: this.descriptionPage }} >
              </div>
              <h2 className="font-montserrat-bold text-center text-white my-5"><span>Puntos Disponibles:</span> <span className="color-white">{this.puntosUsuario}</span></h2>
              <section>
                <Tabs>
                  <TabList className="react-tabs__tab-list tab-container">
                    <Tab className="button-rounded button-rounded-puntos px-20 py5">Conoce nuestro progama</Tab>
                    <Tab className="button-rounded button-rounded-puntos px-20 py5">Acumulación de puntos</Tab>
                    <Tab className="button-rounded button-rounded-puntos px-20 py5">Catálogo</Tab>
                    <Tab className="button-rounded button-rounded-puntos px-20 py5">Políticas de privacidad</Tab>
                    <Tab className="button-rounded button-rounded-puntos px-20 py5">Aliados</Tab>
                  </TabList>

                  <TabPanel className="react-tabs__tab-panel tab-content color-white">
                    {this.subpages && this.subpages.length && <div className="font-montserrat-light text-justified" dangerouslySetInnerHTML={{ __html: this.subpages[0].description }}></div>}
                  </TabPanel>
                  <TabPanel className="react-tabs__tab-panel tab-content color-white">
                    {this.subpages && this.subpages.length && <div className="font-montserrat-light text-justified" dangerouslySetInnerHTML={{ __html: this.subpages[1].description }}></div>}
                  </TabPanel>
                  <TabPanel className="react-tabs__tab-panel tab-content color-white">
                    <Container>
                      <Row className="mt-5 mb-4">
                        {this.cardsCatalog && this.cardsCatalog.map((item, i) => {
                          return (
                            <Col key={i} xs={12} sm={6} md={6} lg={6} className="mb-4">
                              <CatalogItem
                                title={item.title}
                                description={item.description}
                                image={item.image}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </Container>
                  </TabPanel>
                  <TabPanel className="react-tabs__tab-panel tab-content color-white">
                    {this.subpages && this.subpages.length && <div className="font-montserrat-light text-justified" dangerouslySetInnerHTML={{ __html: this.subpages[2].description }}></div>}
                  </TabPanel>
                  <TabPanel className="react-tabs__tab-panel tab-content">
                    <Container>
                      <Row>
                        {this.cardsAlly && this.cardsAlly.map((item, i) => {
                          return (
                            <Col key={i} xs={12} sm={12} md={12} lg={12} className="mb-4">
                              <AllyItem
                                title={item.title}
                                description={item.description}
                                phone={item.phone}
                                address={item.address}
                                image={item.image}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </Container>
                  </TabPanel>
                </Tabs>
              </section>
            </section>
          </Container>
        </Row>
      </Col>
    );

  }

}

const mapStateToProps = (state) => {
  const { loading, error, results, userPoints } = state.ganaPoints.resultGanaPoints;
  return {
    loading,
    error,
    userPoints,
    results
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCards() {
      dispatch(loadCards());
      dispatch(loadUserPoints());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GanaPointsContainer);
