import { modalConstants } from './../constants/ModalConstants';

export const toggleModalGeneral = () => (dispatch) => {
  dispatch ({
    type: modalConstants.TOGGLE_MODAL_GENERAL
  });
};

export const toggleModalAccept = () => (dispatch) => {
  dispatch ({
    type: modalConstants.TOGGLE_MODAL_ACCEPT
  });
};

export const toggleModalAge = () => (dispatch) => {
  dispatch ({
    type: modalConstants.TOGGLE_MODAL_AGE
  });
};

export const toggleModalConfirmAge = () => (dispatch) => {
  dispatch ({
    type: modalConstants.TOGGLE_MODAL_CONFIRM_AGE
  });
};

export const toggleModalUpdate = () => (dispatch) => {
  dispatch ({
    type: modalConstants.TOGGLE_MODAL_UPDATE
  });
};

export const toggleModalFavorite = () => (dispatch) => {
  dispatch ({
    type: modalConstants.TOGGLE_MODAL_UPDATE
  });
};