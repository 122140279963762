import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormGroup, FormText } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default class renderDatePicker extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.string
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
      warning: PropTypes.string
    }).isRequired,
    inputValueFormat: PropTypes.string,
    autoReset: PropTypes.bool
  };

  static defaultProps = {
    inputValueFormat: 'DD-MM-YYYY',
    autoReset: false
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDate: props.input.value ? moment(props.input.value, props.inputValueFormat).toDate() : null,
    };
  }

  handleChange = (date) => {
    const formattedDate = date ? moment(date).format(this.props.inputValueFormat) : '';
    this.setState({ selectedDate: date });
    this.props.input.onChange(formattedDate);
  }


  handleClick = (e) => {
    e.preventDefault();
    this.setState({ selectedDate: null });
    this.props.input.onChange('');
  };

  render() {
    const {
      input, label, icon, dateFormat, topLabel, isClearable, autoReset, loadingOptions, iconimg,
      meta: { touched, error, warning },
      ...rest
    } = this.props;

    return (
      <FormGroup>
        <div className={`position-relative form-file-container ${topLabel && 'file-top-label'}`}>
          <DatePicker
            dateFormat={dateFormat}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            selected={this.state.selectedDate}
            showMonthDropdown
            showYearDropdown
            className="form-control form-file"
            autoComplete='off'
            placeholderText={label}
            value={this.state.selectedDate ? moment(this.state.selectedDate).format(dateFormat) : ''}
          />
          {loadingOptions ? <i className="form-file-icon fa fa-spinner fa-pulse" /> :
            (iconimg ? <img className='icon-render' src={iconimg} alt="icon" /> : <i className={`form-file-icon ${icon}`} />)}
        </div>
        {
          touched &&
          ((error && <FormText className="text-left">{error}</FormText>) ||
            (warning && <FormText className="text-left">{warning}</FormText>))
        }
        {
          autoReset &&
          <div className="table-cart reload-button position-absolute d-inline-block">
            <button
              type="button"
              className="icon-button btn-white"
              title="Reiniciar fecha"
              onClick={this.handleClick}
            >
              <i className="icon-absolute icon-clear icon-hover" />
            </button>
          </div>
        }
      </FormGroup>
    );
  }
}
