import React, { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import imageBanner from './../../assets/images/banner-1.jpg';
import imageNosotros from './../../assets/images/nosotros.jpg';
import SliderAbout from './SliderAbout';

import SubpageBlock from './../../containers/Products/SubpageBlock';

class NuestraRed extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { info } = this.props;
    const title = (info.title)?info.title.split('||'):['',''];
    return (
      <section className="nosotros-section">
        <Row className="align-center pb-5">
          <Col lg={7}>
            <h4 className="mb-4 text-left text-bold">{info.title}</h4>
            <article dangerouslySetInnerHTML={{ __html: info.description }} />
          </Col>
          <Col lg={5} className="pr-0">
            <figure class="ratio-2-1">
              <img src={info.image} className="d-block w-100" />
            </figure>
          </Col>
        </Row>

        <section className="font-montserrat-light mb-5 text-center"/>
        {info.subpages && info.subpages.length > 0 && info.subpages.map((subpage) => (
          <SubpageBlock key={subpage.id} subpage={subpage} />
        ))}
      </section>
    );
  }
}

export default NuestraRed;
