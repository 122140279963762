import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
//import BootstrapTable from 'react-bootstrap-table-next';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';
import { loadDried } from './../../actions/DriedActions';
import LotterieForm from './../../components/Lotteries/LotterieForm';
import { priceFormatter } from './../../helpers/formatMoney';

//CSS
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

class DriedListContainer extends Component {
  constructor(props) {
    super(props);

    this.handleSearchPrizePlan = this.handleSearchPrizePlan.bind(this);
  }

  handleSearchPrizePlan = values => {
    this.props.loadDried({
      ideLoteria: values.loteria,
      fecha: values.fecha || null
    });
  }

  componentDidMount() {
    this.props.loadDried({
      ideLoteria: this.props.idLotterieURL,
      fecha: null
    });
  }

  render() {
    const { loading, error, dried, idLotterieURL } = this.props;
    const sortHeaderFormatter = (column, colIndex, { sortElement, filterElement }) => {
      return (
        <div className="sortHeader">
          { column.text }
          { sortElement }
        </div>
      );
    };
    const columns = [
      {
        dataField: 'desTipoResultado',
        text: 'Premio',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerClasses: 'custom-table-data-header',
        classes: 'custom-table-data-row',
        headerFormatter: sortHeaderFormatter
      }, {
        dataField: 'numero',
        text: 'Número',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerClasses: 'custom-table-data-header',
        classes: 'custom-table-data-row',
        headerFormatter: sortHeaderFormatter
      }, {
        dataField: 'serie',
        text: 'Serie',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerClasses: 'custom-table-data-header',
        classes: 'custom-table-data-row',
        headerFormatter: sortHeaderFormatter
      }
    ];

    const initialValues = {
      loteria : idLotterieURL
    };

    return (
      <Row>
        <Col xs={12} className="mb-4 p-0">
          <LotterieForm
            classForm='form-lotterie-reverse'
            onSubmit={this.handleSearchPrizePlan}
            loadingForm={loading}
            initialValues={initialValues}
          />
        </Col>
        <Col xs={12}>
          {loading && <CircularProgress />}
          {dried &&
            <Row className="justify-content-center">
              <Col md={12} lg={11} xl={11} className="p-0">
                <section className="table-custom-data table-list-dried">
                  <>
                  </>
                  {/* <BootstrapTable
                    keyField='id'
                    data={dried}
                    columns={columns}
                    striped
                    hover
                    condensed
                    noDataIndication='No se encontraron resultados para la fecha.'
                  /> */}
                </section>
              </Col>
            </Row>
          }
          {error && <Alert className="my-2 content-center" color="danger"> {error} </Alert>}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) =>{
  const { loading, error, dried } = state.dried;
  return {
    loading,
    error,
    dried
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadDried(values) {
      dispatch(loadDried(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DriedListContainer);
