import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Alert } from 'reactstrap';
import { priceFormatter } from './../../helpers/formatMoney';
import { OrderService, getOrderInfo, getPaymentState } from './../../services/OrderService';
import moment from 'moment';

//Helpers
import { formatMoney } from './../../helpers/formatMoney';

const TableHistory = ({ shoppingList }) => {
  const [data, setData] = useState([]);

  const PriceCell = ({ value }) => {
    if (value === undefined || value === null || isNaN(value)) {
      return <span>$ 0</span>; // Valor por defecto si el valor es inválido
    }
    return <span>$ {formatMoney(value, 0, 3, '.', ',')}</span>;
  };

  useEffect(() => {
    if (shoppingList) {
      // Procesa los datos para asegurar que los estados están correctos
      const processedList = shoppingList.map(element => ({
        ...element,
        state: getPaymentState(element.state), // Asegúrate de que esta función retorna el estado correcto
        typeOfPurchase: element.lotteries.length > 0
          ? (element.recharges.length > 0 ? 'Loterías y Recargas' : 'Loterías')
          : 'Recargas',
        orderMontoTotal: element.order?.monto?.total || 0,
      }));
      setData(processedList); // Actualiza los datos procesados
    }
  }, [shoppingList]);

  const handleClickPDF = async (order) => {
    if (!order || !order.order) {
      console.error('Invalid order data:', order);
      return;
    }

    const infOrder = await order.order;
    console.log('infOrder ------------->>>>  ', infOrder);

    const orderData = [
      {
        label: 'Resultado de la transacción',
        value: infOrder.estado
      },
      {
        label: 'Nombre del comprador',
        value: `${infOrder.comprador?.name || ''} ${infOrder.comprador?.surname || ''}`
      },
      {
        label: 'Email',
        value: infOrder.comprador?.email || ''
      },
      {
        label: 'Documento',
        value: infOrder.comprador?.document || ''
      },
      {
        label: 'Teléfono',
        value: infOrder.comprador?.mobile || ''
      },
      {
        label: 'Fecha de la transacción',
        value: moment(infOrder.fecha).format('LLL')
      },
      {
        label: 'Moneda',
        value: infOrder.monto?.currency || ''
      },
      {
        label: 'Total pagado',
        value: `$ ${formatMoney(infOrder.monto?.total || 0, 0, 3, '.', ',')}`
      },
      {
        label: 'Banco',
        value: infOrder.banco || ''
      },
      {
        label: 'Referencia',
        value: infOrder.referencia
      },
      {
        label: 'Cus/Autorización',
        value: infOrder.cus || ''
      },
      {
        label: 'Descripción',
        value: infOrder.descripcion || ''
      },
      {
        label: 'Dirección IP',
        value: infOrder.ip || ''
      }
    ];

    if (infOrder.descuento) {
      orderData.splice(7, 0, {
        label: 'Valor total',
        value: `$ ${formatMoney(infOrder.totalSinDescuento, 0, 3, '.', ',')}`
      });
      orderData.splice(8, 0, {
        label: 'Descuento',
        value: `$ ${formatMoney(infOrder.descuento, 0, 3, '.', ',')}`
      });
    } else if (infOrder.monto?.descuento) {
      orderData.splice(7, 0, {
        label: 'Valor total',
        value: `$ ${formatMoney(infOrder.monto.totalSinDescuento, 0, 3, '.', ',')}`
      });
      orderData.splice(8, 0, {
        label: 'Descuento',
        value: `$ ${formatMoney(infOrder.monto.descuento, 0, 3, '.', ',')}`
      });
    }
    OrderService.getPDFOrder(orderData);
  };




  const columns = [
    {
      field: 'reference',
      headerName: 'Código',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      headerClassName: 'custom-header',
      flex: 1,
      width: 160,
    },
    {
      field: 'typeOfPurchase',
      headerName: 'Tipo de Compra',
      align: 'center',
      sortable: false,
      headerAlign: 'center',
      headerClassName: 'custom-header',
      flex: 1,
      width: 160,
    },
    {
      field: 'updatedAt',
      headerName: 'Fecha',
      align: 'center',
      sortable: false,
      headerAlign: 'center',
      headerClassName: 'custom-header',
      flex: 1,
      width: 160,
      renderCell: (params) => {
        const dateValue = params.value;
        return dateValue ? moment(dateValue).format('L') : 'Fecha no disponible';
      },
    },
    {
      field: 'orderMontoTotal',
      headerName: 'Valor',
      align: 'center',
      headerClassName: 'custom-header',
      renderCell: (params) => <div className="d-inline-block totalCart"><PriceCell value={params.value} /> </div> ,
      sortable: false,
      width: 160,
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'state',
      headerName: 'Estado',
      align: 'center',
      sortable: false,
      headerClassName: 'custom-header',
      headerAlign: 'center',
      width: 160,
      flex: 1,
    },
    {
      field: '',
      headerName: 'Comprobante',
      headerClassName: 'custom-header',
      sortable: false,
      width: 160,
      renderCell: (params) => {
        if (!params || !params.row) {
          console.error('params or params.row is undefined:', params);
          return null;
        }

        return (
          <button
            type="button"
            className="button-rounded button-rounded-blue d-inline-block w-100"
            onClick={() => handleClickPDF(params.row)}
          >
            <div className="d-flex justify-content-between align-items-center">
              <span>Descargar&nbsp;</span>
              <i className="flaticon-sheet" style={{ fontSize: '1.4em' }} />
            </div>
          </button>
        );
      },
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
  ];

  return (
    <section className="table-custom-data table-shopping-history">
      {data.length === 0 && (
        <Alert className="my-2 content-center" color="warning">
          No se han encontrado resultados
        </Alert>
      )}
      {data.length > 0 && (
        <div style={{ width: '100%' }}>
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            disableSelectionOnClick
            disableColumnSort
            disableColumnResize
            disableColumnMenu
            rowHeight={100}
          />
        </div>
      )}
    </section>
  );
};

export default TableHistory;
