import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';
import ResetPasswordForm from './../../components/Account/ResetPasswordForm';
import { changePassword, getUserWithToken, toggleModalLogin, recoveryPassword } from './../../actions/UserActions';
import { withRouter } from 'react-router-dom';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.handleOnSave = this.handleOnSave.bind(this);
  }
  componentDidMount () {
    const {
      match: {
        params: {
          token = null
        }
      }
    } = this.props;
    if (token) {
      this.props.getUserWithToken({token:token});
    } else {
      this.props.history.replace('/');
    }
  }

  componentWillReceiveProps (nextProps) {

    if (nextProps.error && (nextProps.error.mensaje !='Tu sesión ha expirado, por favor ingresa de nuevo.' && nextProps.error.mensaje !='Tu sesi¿n ha expirado, por favor ingresa de nuevo.') ) {
      this.props.history.replace('/');
    }
  }

  handleOnSave = formValues => {
    const values = JSON.parse(JSON.stringify(formValues));

    values.tipoDocumento=this.props.successUserToken.tipoDocumento;
    values.usuario=this.props.successUserToken.usuario;
    values.token = this.props.successUserToken.token;
    this.props.recoveryPassword(values);
  }

  render() {
    const { toggleModal, loading, error, success, user, loadingUserToken, errorUserToken, successUserToken } = this.props;
    //const loading = false, error = false, success = true;

    const messageSucces = () => {
      return (
        <div className="nosotros-section">
          <h4 className="first-title text-center mt-4 mb-4">Contraseña modificada</h4>
          <p className="mb-4 text-size-medium">
            Tu contraseña ha sido cambiada con éxito
          </p>
          <button
            type="button"
            className="btn btn-primary mb-3"
            onClick={() => toggleModal('1')}
          >
            Iniciar Sesión
          </button>
        </div>
      );
    };

    return (
      <Col sm="12" md={{ size: 6, offset: 3 }} className="return-seccion">
        <section className="border mt-5 mb-5 p-5 back-white border-container">
          <section className="nosotros-section text-center">
            {success &&
              messageSucces()
            }
            {!success && successUserToken && !errorUserToken &&
              <div id="cuenta-success">
                <h1 className="second-title mt-4 mb-4"><b>Recuperar contraseña</b></h1>
                <p className="mb-5 text-size-medium">Ahora puedes cambiar tu contraseña, ingresa tu nueva contraseña y sigue disfrutando de nuestros servicios</p>
                <ResetPasswordForm
                  onSubmit={this.handleOnSave}
                  loadingForm={loading}
                />
                {error && <Alert className="mt-3 mb-0" color="danger"> {error.mensaje} </Alert>}
              </div>
            }

            {(errorUserToken && (errorUserToken.mensaje == 'Tu sesión ha expirado, por favor ingresa de nuevo.' || errorUserToken.mensaje =='Tu sesi¿n ha expirado, por favor ingresa de nuevo.')) &&
              <div id="cuenta-unsuccess">
                <h1 className="second-title mt-4 mb-4"><b>El token ha expirado</b></h1>
                <p className="text-size-medium">Debes volver a solicitar un nuevo token</p>
                <div className="mt30 mb30">
                  <button
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={() => {
                      this.setState({
                        changePassword: true
                      }); toggleModal('1');
                    }}
                  >
                    Solicitar nuevo token
                  </button>
                </div>
              </div>
            }
            {loadingUserToken && !errorUserToken && !successUserToken && <CircularProgress />}
          </section>
        </section>
      </Col>

    );
  }
}

function mapStateToProps(state) {
  const {loading, error, success} = state.changePassword.recovery;
  const { loadingUserToken, errorUserToken, successUserToken } = state.changePassword.userToken ;
  const { user } = state.authentication;
  return {
    loading,
    error,
    success,
    loadingUserToken,
    errorUserToken,
    successUserToken,
    user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    toggleModal(tab) {
      dispatch(toggleModalLogin(tab));
    },
    getUserWithToken(values) {
      dispatch(getUserWithToken(values));
    },
    recoveryPassword(values) {
      dispatch(recoveryPassword(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordContainer));
