import 'whatwg-fetch';
import { getAuthApp } from '../services/security/authApp';
import { decrypt } from '../services/security/encryptorHandlerSecurity';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

class NewsService {

  static loadNewsHome() {

    const url = `${serverURL}/api/news`;

    return fetch(url, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error );
      });
  }

  static loadSingleNews(id) {

    const url = `${serverURL}/api/news/${id}`;

    return fetch(url, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => (response))
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default NewsService;
