import React, { Component } from 'react';
import './CountdownTimer.css'; 
import { connect } from 'react-redux';
import { loadPrimerActivo } from './../../../actions/ConteoRegresivoActions'; // Asegúrate de que la ruta es correcta

class CountdownTimer extends Component {
  constructor(props) {
    super(props);

    const targetDate = this.props.primerActivo && this.props.primerActivo.fecha_hora_final
      ? new Date(this.props.primerActivo.fecha_hora_final)
      : new Date();

    this.state = {
      loading: true, 
      noDatesAvailable: false, 
      timeRemaining: this.calculateTimeRemaining(targetDate),
      descripcionContador: this.props.primerActivo ? this.props.primerActivo.descripcion_Contador : '',
      textoBoton: this.props.primerActivo ? this.props.primerActivo.texto_boton : '',
      imagenFondo: this.props.primerActivo ? this.props.primerActivo.imagen_de_fondo : '',
      nuevaImagencontador: this.props.primerActivo ? this.props.primerActivo.nueva_imagencontador : '',
      urlForm: this.props.primerActivo ? this.props.primerActivo.url_form : '',
      nuevoFormatoContador: this.props.primerActivo ? this.props.primerActivo.nuevo_formato_contador : '',
      colorDescripcion: '' 

    };
  }

  componentDidMount() {
    this.loadData();
    this.startTimer();
  }

  componentDidUpdate(prevProps) {
    if (this.props.primerActivo !== prevProps.primerActivo) {
      const targetDate = this.props.primerActivo && this.props.primerActivo.fecha_hora_final
        ? new Date(this.props.primerActivo.fecha_hora_final)
        : new Date();

      this.setState({
        timeRemaining: this.calculateTimeRemaining(targetDate),
        descripcionContador: this.props.primerActivo ? this.props.primerActivo.descripcion_Contador : '',
        textoBoton: this.props.primerActivo ? this.props.primerActivo.texto_boton : '',
        imagenFondo: this.props.primerActivo ? this.props.primerActivo.imagen_de_fondo : '',
        nuevaImagencontador: this.props.primerActivo ? this.props.primerActivo.nueva_imagencontador : '',
        urlForm: this.props.primerActivo ? this.props.primerActivo.url_form : '',
        nuevoFormatoContador: this.props.primerActivo ? this.props.primerActivo.nuevo_formato_contador : '',
        colorDescripcion: this.extractColorFromHTML(this.props.primerActivo.descripcion_Contador) // Extrae color al actualizar

      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  loadData() {
    this.props.loadPrimerActivo()
      .then(response => {
        const data = response.body;
        const targetDate = data.fecha_hora_final
          ? new Date(data.fecha_hora_final)
          : new Date();

        const noDatesAvailable = isNaN(targetDate.getTime());

        this.setState({
          loading: false, 
          noDatesAvailable, 
          timeRemaining: noDatesAvailable ? { days: 0, hours: 0, minutes: 0, seconds: 0 } : this.calculateTimeRemaining(targetDate),
          descripcionContador: data.descripcion_Contador || '',
          textoBoton: data.texto_boton || '',
          imagenFondo: data.imagen_de_fondo || '',
          nuevaImagencontador: data.nueva_imagencontador || '',
          urlForm: data.url_form || '',
          nuevoFormatoContador: data.nuevo_formato_contador || '',
          colorDescripcion: this.extractStylesFromHTML(data.descripcion_Contador) // Extrae color al cargar

        });

        if (this.props.onNoDatesAvailable) {
          this.props.onNoDatesAvailable(noDatesAvailable);
        }
      })
      .catch(error => {
        console.error('Error loading primerActivo:', error);
        this.setState({
          loading: false, 
          noDatesAvailable: true 
        });

        if (this.props.onNoDatesAvailable) {
          this.props.onNoDatesAvailable(true);
        }
      });
  }

  startTimer() {
    this.timer = setInterval(() => {
      const targetDate = new Date(this.state.timeRemaining.targetDate || new Date());

      this.setState({
        timeRemaining: this.calculateTimeRemaining(targetDate)
      });
    }, 1000);
  }

  calculateTimeRemaining(targetDate) {
    if (!(targetDate instanceof Date) || isNaN(targetDate.getTime())) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const now = new Date();
    const timeDifference = targetDate - now;
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds, targetDate };
  }

  handleButtonClick = () => {
    if (this.state.urlForm) {
      window.location.href = this.state.urlForm;
    }
  }



  extractStylesFromHTML(html) {
    const div = document.createElement('div');
    div.innerHTML = html;

    const styles = {};

    const processStyles = (element) => {
      const style = element.getAttribute('style');
      if (style) {
        style.split(';').forEach(rule => {
          const [property, value] = rule.split(':').map(s => s.trim());
          if (property && value) {
            styles[property] = value;
          }
        });
      }
    };

    div.querySelectorAll('*').forEach(element => {
      processStyles(element);
    });


    return styles;
  }

  render() {
    const { days, hours, minutes, seconds } = this.state.timeRemaining;
    const { descripcionContador, textoBoton, imagenFondo, nuevaImagencontador, nuevoFormatoContador, loading, noDatesAvailable, colorDescripcion } = this.state;

    if (loading) {
      return (
        <div className="loading-indicator">
          <p>Loading...</p> 
        </div>
      );
    }

    if (noDatesAvailable) {
      return (
        <div className="no-dates-available">
          
        </div>
      );
    }

    const backgroundStyle = {
      backgroundImage: `url(${imagenFondo})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    };

    const countdownItemStyle = {
      display: 'flex',
      backgroundImage: `url(${nuevaImagencontador})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      padding: '5px', // Reducido para tamaño más pequeño
      margin: '3px',
      borderRadius: '3px', // Reducido para tamaño más pequeño
      textAlign: 'center',
      fontSize: '14px'
    };

    const daysStyle = nuevoFormatoContador ? this.extractStylesFromHTML(nuevoFormatoContador) : {};
    const splitNumber = (number) => number.toString().split('');
    const colorStyle = descripcionContador ? this.extractStylesFromHTML(descripcionContador) : {};
    const dayDigits = splitNumber(days);
    const hourDigits = splitNumber(hours);
    const minuteDigits = splitNumber(minutes);
    const secondDigits = splitNumber(seconds);

    return (
      <div className="countdown-timer-general" style={backgroundStyle} >

        <div className="countdown-item-text-left">
          <span dangerouslySetInnerHTML={{ __html: descripcionContador }} />
        </div>

        <div className="countdown-item-row">

          <div className="countdown-item" style={{ ...colorStyle }} >
            <div>
              <div className="countdown-item">
                {dayDigits.map((digit, index) => (
                  <div key={`day-${index}`} className="countdown-digit" style={{ ...countdownItemStyle, ...daysStyle }}>
                    {digit}
                  </div>
                ))}
              </div>
              <span className="countdown-label" >Días</span>
            </div>
          </div>

          <div className="countdown-item" style={{ ...colorStyle }}>
            <div>
              <div className="countdown-item">
                {hourDigits.map((digit, index) => (
                  <div key={`hour-${index}`} className="countdown-digit" style={{ ...countdownItemStyle, ...daysStyle }}>
                    {digit}
                  </div>
                ))}
              </div>
              <span className="countdown-label">Horas</span>
            </div>
          </div>

          <div className="countdown-item" style={{ ...colorStyle }}>
            <div>
              <div className="countdown-item">
                {minuteDigits.map((digit, index) => (
                  <div key={`minute-${index}`} className="countdown-digit" style={{ ...countdownItemStyle, ...daysStyle }}>
                    {digit}
                  </div>
                ))}
              </div>
              <span className="countdown-label" style={{ ...colorStyle }}>Minutos</span>
            </div>
          </div>

          <div className="countdown-item" style={{ ...colorStyle }}>
            <div>
              <div className="countdown-item">
                {secondDigits.map((digit, index) => (
                  <div key={`second-${index}`} className="countdown-digit" style={{ ...countdownItemStyle, ...daysStyle }}>
                    {digit}
                  </div>
                ))}
              </div>
              <span className="countdown-label">Segundos</span>
            </div>
          </div>

        </div>

        <div className="countdown-item-button" style={countdownItemStyle}>
          <button
            className="btn primary btn-block btn-primary-custom"
            onClick={this.handleButtonClick}
            dangerouslySetInnerHTML={{ __html: textoBoton }}
            style={{ ...daysStyle }}
          ></button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    primerActivo: state.lotteries.primerActivo 
  };
};

const mapDispatchToProps = {
  loadPrimerActivo
};

export default connect(mapStateToProps, mapDispatchToProps)(CountdownTimer);
