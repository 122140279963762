import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import RequestChangePasswordForm from './../../components/Login/RequestChangePasswordForm';
import { applicationChangePassword, toggleModalLogin, applicationChangePasswordClear } from './../../actions/UserActions';
import { withRouter } from 'react-router-dom';

class RequestChangePasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleBackHome = this.handleBackHome.bind(this);
  }

  handleOnSave = values => {
    this.props.applicationChangePassword(values);
  };

  handleBackHome = () => {
    this.props.applicationChangePasswordClear();
    this.props.toggleModalLogin('1');
    this.props.history.push('/');
  };

  render() {
    const { loading, error, success } = this.props;
    //const loading = false, error = false, success = true;

    const messageSucces = () => {
      return (
        <div>
          <h4 className="mb-4 font-montserrat-bold">Recuperar Contraseña</h4>
          <p className="mb-4">
            {/*Ingresa al link que se encuentra en el correo electrónico que te hemos enviado para confirmar tu cambio de contraseña
          */}
          Se ha enviado un correo electrónico para recuperar tu contraseña
          </p>
          <button
            type="button"
            className="btn btn-primary mb-3"
            onClick={() => this.handleBackHome()}
          >
            Regresar al Inicio
          </button>
        </div>
      );
    };

    return (
      <section>
        {success ? messageSucces() :
          <section>
            <h4 className="mb-3 font-montserrat-bold">Recuperar Contraseña</h4>
            <p className="mb-4">¿Deseas solicitar un cambio de contraseña?</p>
            <Row className="justify-content-center">
              <Col xs="12" sm="10" md="9" lg="8" xl="7">
                <RequestChangePasswordForm
                  onSubmit={this.handleOnSave}
                  loadingForm={loading}
                />
                {error && <Alert className="mt-3 mb-0" color="danger"> {error} </Alert>}
              </Col>
            </Row>
          </section>
        }
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { loading, error, success } = state.changePassword.application;
  return {
    loading,
    success,
    error
  };
}

const mapDispatchToProps = dispatch => {
  return {
    applicationChangePassword(values) {
      dispatch(applicationChangePassword(values));
    },
    toggleModalLogin(tab) {
      dispatch(toggleModalLogin(tab));
    },
    applicationChangePasswordClear() {
      dispatch(applicationChangePasswordClear());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RequestChangePasswordContainer));
