import 'whatwg-fetch';
import GeneralService from './GeneralService';
import jspdf from 'jspdf';
import {decryptUserData} from '../services/security/informationCodingSecurity';
import { getAuthApp } from '../services/security/authApp';
import { encryptkey } from '../services/security/encryptorHandlerSecurity';
import { getFullNameCurrentUser } from '../utils/accountUtils';
import { isObjectNotEmpty } from '../utils/objectUtils';

const serverURL = process.env.REACT_APP_SERVERURL;
const baseURL = process.env.REACT_APP_BASEURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

const handleResponseb = (response) => {
  if (!response.ok) {
    return Promise.reject(response.json());
  }

  return response.json();
};


const getPaymentState = state => {
  if (state === 'FAILED') {
    return 'HA FALLADO';
  } else if (state === 'APPROVED') {
    return 'APROBADO';
  } else if (state === 'APPROVED_PARTIAL') {
    return 'APROBADO PARCIALMENTE';
  } else if (state === 'REJECTED') {
    return 'RECHAZADO';
  } else if (state === 'PENDING') {
    return 'PENDIENTE';
  } else if (state === 'PENDING_VALIDATION') {
    return 'PENDIENTE POR VALIDACIÓN';
  } else if (state === 'REFUNDED') {
    return 'REEMBOLDASO';
  } else if (state === 'INCART') {
    return 'EN CARRITO';
  } else {
    return 'BUENO';
  }
};

/**
 * 
 * @param {*} order 
 * @param {*} ref 
 * @returns 
 */
const getAmountOrder = (order, ref) => {
  let amount = 0;
  if (order) {
    if (order.DATA && Object.keys(order.DATA).length > 0) {
      amount = order.DATA.AMOUNT ? order.DATA.AMOUNT : order.DATA.Amount;
    } 
    if (!amount || amount === 0) {
      if (order.lotteries) {
        for (const lot of order.lotteries) {
          amount += lot.total;
        }
      }
    }
  } 
  return amount;
};


/**
 * 
 * @param {*} order 
 * @returns 
 */
const getOrderInfo = order => {
  const mappedOrder = {
    estado: null,
    fecha: null,
    mensaje: null,
    banco: null,
    comprador:{ name: getFullNameCurrentUser(), surname: '', email: '' }, 
    monto:{ currency:'COP', total: getAmountOrder(order) },
    referencia: null,
    cus: null,
    descripcion: null,
    ip:'127.0.0.1'
  };

  if (order.hasOwnProperty('CODE')) {
    let stateToPay = '';
    if (order.DATA.STATE === 'APROBADA') { 
      stateToPay = 'APPROVED'; 
    }
    if (order.DATA.STATE === 'CANCELADA') { 
      stateToPay = 'REJECTED'; 
    }
    if (order.DATA.STATE === 'PENDIENTE') { 
      stateToPay = 'PENDING'; 
    }

    const data = order.DATA;
    if (isObjectNotEmpty(data)) {
      mappedOrder.estado = getPaymentState(stateToPay),
      mappedOrder.comprador.email = data.EMAIL;
      mappedOrder.fecha = data.TRANSACTION ? data.TRANSACTION.DATE : null;
      mappedOrder.referencia = data.ORDER ? data.ORDER.toString() : (data.OrderID ? data.OrderID.toString() : null);
      mappedOrder.banco = data.TRANSACTION ? data.TRANSACTION.ACCOUNT_TYPE : null;
      //mappedOrder.cus = data.TRANSACTION ? data.TRANSACTION.CUS : null,
      mappedOrder.cus = null;
      mappedOrder.descripcion = data.DESCRIPTION;
      mappedOrder.descuento = data.descuento ? data.descuento : null;
      mappedOrder.totalSinDescuento = data.totalSinDescuento ? data.totalSinDescuento : null;
    }
    
  } else {
    if (isObjectNotEmpty(order)) {
      mappedOrder.estado = getPaymentState(order.status.status);
      mappedOrder.comprador = order.request.buyer ? order.request.buyer : mappedOrder.comprador;
      mappedOrder.fecha = order.status.date;
      mappedOrder.mensaje = order.status.message;
      mappedOrder.monto = order.request.payment.amount;
      mappedOrder.banco = order.payment ? order.payment[0].issuerName : null;
      mappedOrder.referencia = order.request.payment.reference;
      mappedOrder.cus = order.payment ? order.payment[0].authorization : null;
      mappedOrder.descripcion = order.request.payment.description;
      mappedOrder.ip = order.request.ipAddress;
    }
  }

  return mappedOrder;
};

class OrderService {

  static getStateOrder (values) {
    const url = `${serverURL}/api/Users/place-to-pay-consultar`;
    const data = JSON.stringify({ pay: {...values} }, null, 2);

    return fetch(url, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(handleResponseb)
      .then(response => getOrderInfo(response.data))
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static getCartInfo(reference) {
    let user = localStorage.getItem('userLoti');

    if (user) {
      user = JSON.parse(user);
      user = decryptUserData(user);
      const url = `${serverURL}/api/carts?filter[fields][id]=true&filter[where][user]=${encryptkey(user.userId, true)}`+
      `&filter[where][reference]=${encryptkey(reference, true)}`;
      return fetch(url, {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': getAuthApp()
        }
      })
        .then(handleResponse)
        .then(cart => {
          console.log(cart);
          return cart[0];
        })
        .catch(error => {
          return Promise.reject(error);
        });
    } else {
      return Promise.reject('Ocurrio un error al momento de traer la información del carrito');
    }
  }

  static getPDFOrder(orderData) {
    let renglon = 20, mrgLeft = 20, mrgRight = 190;
    const baseURL = process.env.REACT_APP_BASEURL;
  
    GeneralService.toDataURL(`${baseURL}/assets/images/icono-loti.jpg`)
      .then(dataUrl => {
        const doc = new jspdf();
  
        // Configuración de fuente y estilos
        doc.setTextColor(39, 80, 155);
        doc.setFont('helvetica', 'bold'); // Configura la fuente y el estilo a 'bold'
        doc.addImage(dataUrl, 'JPEG', 147, 10, 45, 15);
  
        doc.setFontSize(20);
        doc.text(mrgLeft, (renglon + 3), 'Datos de Transacción');
  
        doc.setLineWidth(1);
        doc.setDrawColor(39, 80, 155);
        doc.line(mrgLeft, (renglon += 7), 190, renglon);
  
        doc.setTextColor(100);
        doc.setFontSize(15);
  
        renglon += 5;
        orderData.forEach((item, index) => {
          if (item.value) {
            doc.setFont('helvetica', 'bold'); // Aplica estilo 'bold' para las etiquetas
            doc.text(mrgLeft, (renglon += 10), item.label);
            doc.setFont('helvetica', 'normal'); // Cambia a 'normal' para los valores
            doc.text(mrgRight, renglon, `${item.value} `, { align: 'right' });
          }
        });
  
        // Guarda el PDF con el nombre especificado
        doc.save('comprobante-de-compra.pdf');
      });
  }

}

export {OrderService, getOrderInfo, getPaymentState};
