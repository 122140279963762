export const locationsConstants = {
  GET_COUNTRIES: 'GET_COUNTRIES',
  SET_COUNTRIES: 'SET_COUNTRIES',

  GET_STATES_DOCUMENT: 'GET_STATES_DOCUMENT',
  SET_STATES_DOCUMENT: 'SET_STATES_DOCUMENT',
  GET_CITIES_DOCUMENT: 'GET_CITIES_DOCUMENT',
  SET_CITIES_DOCUMENT: 'SET_CITIES_DOCUMENT',

  GET_STATES_USER: 'GET_STATES_USER',
  SET_STATES_USER: 'SET_STATES_USER',
  GET_CITIES_USER: 'GET_CITIES_USER',
  SET_CITIES_USER: 'SET_CITIES_USER'
};
