// context/CartContext.js
import React, { createContext, useState, useContext } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [porcentajeCoupon, setPorcentajeCoupon] = useState(null);  
  const [loteries, setLoteries] = useState([]);
  const [numeroFraccionesMinimas, setNumeroFraccionesMinimas] = useState(null);
  const [numeroFraccionesMaximas, setNumeroFraccionesMaximas] = useState(null);


  return (
    <CartContext.Provider value={{ porcentajeCoupon, setPorcentajeCoupon, loteries, setLoteries, numeroFraccionesMinimas, setNumeroFraccionesMinimas, numeroFraccionesMaximas, setNumeroFraccionesMaximas }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
