import { userConstants } from '../constants/UserConstants';
import { accountConstants } from './../constants/AccountConstants';

const initialState = {
  modalLogin: false,
  shopping: {
    list: [],
    loading: false,
    error: null
  },
  reports: {
    loading: false,
    success: null,
    error: null
  }
};

export const account = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL_LOGIN':
      return Object.assign({}, state, {
        modalLogin: !state.modalLogin,
        modalTab: action.tab
      });
    case accountConstants.GET_SHOPPING_HISTORY_REQUEST:
      return {
        ...state,
        shopping: {
          loading: true
        }
      };
    case accountConstants.GET_SHOPPING_HISTORY_SUCCESS:
      return {
        ...state,
        shopping: {
          list: action.payload
        }
      };
    case accountConstants.GET_SHOPPING_HISTORY_FAILURE:
      return {
        ...state,
        shopping: {
          error: action.error
        }
      };
    case accountConstants.GET_DOWNLOAD_REPORTS_REQUEST:

      return {
        ...state,
        reports: {
          loading: true
        }

      };

    case accountConstants.GET_DOWNLOAD_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          success: true
        }
      };
    case accountConstants.GET_DOWNLOAD_REPORTS_FAILURE:
      return {
        ...state,
        reports: {
          error: action.error
        }
      };
    case accountConstants.GET_DOWNLOAD_REPORTS_RESET:
      return {
        ...state,
        reports: {
          loading: false,
          error:null,
          success:false
        }
      };
    default: return state;
  }
};
