import { webConfisyControlarConstants } from '../constants/WebConfisysControlarConstants';

const initialState = {
  loading: false,
  webConfisysControlar: null,
  error: null
};

export function confisysControlar(state = initialState, action) {
  switch (action.type) {
    case webConfisyControlarConstants.LIST_CONFISYS_CONTROLAR_REQUEST:
      return {
        loading: true
      };
    case webConfisyControlarConstants.LIST_CONFISYS_CONTROLAR_SUCCESS:
      return {
        webConfisysControlar: action.payload
      };
    case webConfisyControlarConstants.LIST_CONFISYS_CONTROLAR_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
