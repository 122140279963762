import { getOrderInfo } from '../services/OrderService';
import { accountConstants } from './../constants/AccountConstants';
import AccountService from './../services/AccountService';
//====================================================================
// Loading Home Results
//====================================================================

export const loadShoppingHistory = (values) => (dispatch) => {

  const shoppingHistoryRequest = () => ({
    type: accountConstants.GET_SHOPPING_HISTORY_REQUEST
  });

  const shoppingHistorySuccess = payload => ({
    type: accountConstants.GET_SHOPPING_HISTORY_SUCCESS,
    payload
  });

  const shoppingHistoryError = error => ({
    type: accountConstants.GET_SHOPPING_HISTORY_FAILURE,
    error
  });

  dispatch(shoppingHistoryRequest());
  AccountService.loadShoppingList(values)
    .then(
      shoppingList => {
        shoppingList.map((item) => {
          if (Array.isArray(item.order)) {
            item.order = (getOrderInfo({...item.order[0], lotteries: item.lotteries}));
          } else {
            item.order = (getOrderInfo({...item.order, lotteries: item.lotteries}));
          } 
          return item;
        });
        dispatch(shoppingHistorySuccess(shoppingList));
      },
      error => {
        dispatch(shoppingHistoryError(error.message ? error.message : 'Ocurrió un error al momento de traer el historial de compras'));
      }
    );
};

export const getReport = (values) => (dispatch) => {
  const ReportRequest = () => ({
    type: accountConstants.GET_DOWNLOAD_REPORTS_REQUEST
  });

  const ReportSuccess = payload => ({
    type: accountConstants.GET_DOWNLOAD_REPORTS_SUCCESS,
    payload
  });

  const ReportError = error => ({
    type: accountConstants.GET_DOWNLOAD_REPORTS_FAILURE,
    error
  });

  const ReportClear = () => ({
    type: accountConstants.GET_DOWNLOAD_REPORTS_RESET
  });

  dispatch(ReportRequest());
  AccountService.getReport(values)
    .then(
      response => {
        dispatch(ReportSuccess(response));
        const binaryImg = atob(response.data.$data);
        const length = binaryImg.length;
        const arrayBuffer = new ArrayBuffer(length);
        const uintArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < length; i++) {
          uintArray[i] = binaryImg.charCodeAt(i);
        }
        let file;
        let name;
        const hoy = new Date();
        let dd = hoy.getDate();
        let mm = hoy.getMonth()+1;
        const yyyy = hoy.getFullYear();
        if (dd<10) {
          dd=`0${dd}`;
        }

        if (mm<10) {
          mm=`0${mm}`;
        }
        // eslint-disable-next-line prefer-template
        const today = yyyy+'-'+mm+'-'+dd;
        if (values.formato == 'PDF') {
          file = new Blob([uintArray], {type: 'application/pdf'});
          name = `reporte-${today}.pdf`;
        } else {
          file = new Blob([uintArray], {type: 'application/vnd.ms-excel'});
          name = `reporte-${today}.xls`;
        }
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        //window.open(fileURL);
        const anchor = document.createElement('a');
        anchor.download = name;
        anchor.href = fileURL;
        anchor.click();
        dispatch(ReportClear());
      },
      error => {
        error.then(errorResponse => {
          const errorMessage = errorResponse.error;
          dispatch(ReportError(errorMessage.error.mensaje ? errorMessage.error.mensaje :
            'Ocurrió un error al momento de descargar el reporte.'));
        });

      }
    );
};
