import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import AccountForm from './../../components/Account/AccountForm';
import { updateUser, getUserInfo, updateUserClear } from './../../actions/UserActions';
import moment from 'moment';

import ModalComponent from './../../components/Modal/ModalComponent';
import { mayoriaEdad } from '../../constants/AccountConstants';
import { tratamientos } from '../../constants/AccountConstants';
import { politicas } from '../../constants/AccountConstants';

class EditAccountContainer extends Component {
  constructor(props) {
    super(props);

    this.handleOnSave = this.handleOnSave.bind(this);
    this.clearMessage();
    this.getObligacion = this.getObligacion.bind(this);
  }

  handleOnSave = formValues => {
    const values = JSON.parse(JSON.stringify(formValues));
    const primerApellido = values.primerApellido.trim().split(' ');
    const apellido1 = primerApellido[0];
    const apellido2 = primerApellido.slice(1).join(' ');
    delete values.intranet;
    delete values.remoteIPAddress;
    delete values.userId;
    delete values.fechaUltimoLogin;
    values.celular = values.celular.toString().replace(/[^\d]/g, '');
    values.fechaNacimiento = values.dayFn + '-' + values.monthFn + '-' + values.yearFn;
    values.terminosYCondiciones = values.politicas ? politicas.SI : politicas.PENDIENTE;
    values.tratamientoDeDatos = values.tratamientos ? tratamientos.SI : tratamientos.PENDIENTE;
    values.primerApellido = apellido1;
    values.segundoApellido = apellido2;
    values.idePais = null;
    values.ideEstado = null;
    values.ideDepartamento = null;
    values.obligacionFiscal = this.state.valorObligacion === null ? 'R-99-PN' : this.state.valorObligacion.toString();
    values.mayoriaEdad = (values.mayoriaEdad ? mayoriaEdad.SI : mayoriaEdad.PENDIENTE);
    this.props.updateUser(values);
  };

  clearMessage = () => {
    this.props.updateUserClear();
  }
  getObligacion = (x) => {
    this.setState({ valorObligacion: x });
  };

  getBornDate() {
    if (localStorage.getItem('userLoti') != null && JSON.parse(localStorage.getItem('userLoti')).fechaNacimiento === undefined) {
      return true;
    } else {
      return false;
    };
  }


  render() {
    const { loading, error, success, user } = this.props;
    const [year, month, day] = moment(user ? user.fechaNacimiento : null).format('YYYY-MM-DD').split('-');
    const fields = {
      ...user,
      mayoriaEdad: (user && user.mayoriaEdad === mayoriaEdad.SI),
      yearFn: (year),
      monthFn: (month),
      dayFn: (Number(day)),
      new_experience_loti: true
    };

    console.log(user);

    return (
      <section className="nosotros-section">
        {this.getBornDate() && <Alert className="mt-3 mb-0" color="warning" >
          Debes completar todos tus datos personales para poder continuar (Fecha de nacimiento)
        </Alert>}
        <h3 className="first-title text-center mt-4 mb-5 interna-resultados">Editar datos e información del usuario</h3>
        <Row className="justify-content-center">
          <Col xs="12">
            <AccountForm
              formEditing
              onSubmit={this.handleOnSave}
              loadingForm={loading}
              initialValues={fields}
              getObligacion={this.getObligacion}
            />
            {error && <Alert className="mt-3 mb-0" color="danger"> {error} </Alert>}
            {success && <Alert className="mt-3 mb-0" color="success">
              Tu información ha sido actualizada exitosamente
            </Alert>
            }
          </Col>
        </Row>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { loading, error, success } = state.user.ubdate;
  const { user } = state.authentication;
  return {
    loading,
    error,
    success,
    user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateUser(values) {
      dispatch(updateUser(values));
    },
    updateUserClear() {
      dispatch(updateUserClear());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountContainer);
