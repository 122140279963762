import { webConfisyConstants } from '../constants/WebConfisysConstants';

const initialState = {
  loading: false,
  webConfisys: null,
  error: null
};

export function confisys(state = initialState, action) {
  switch (action.type) {
    case webConfisyConstants.LIST_CONFISYS_REQUEST:
      return {
        loading: true
      };
    case webConfisyConstants.LIST_CONFISYS_SUCCESS:
      return {
        webConfisys: action.payload
      };
    case webConfisyConstants.LIST_CONFISYS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
