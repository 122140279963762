/* eslint-disable prefer-template */
import {decryptUserData} from './../services/security/informationCodingSecurity';
import 'whatwg-fetch';
import {getAuthApp} from '../services/security/authApp';
import {encryptkey,encrypt,decrypt} from '../services/security/encryptorHandlerSecurity';
import { cartConstants } from '../constants/CartConstants';
import { checkoutCoupon } from '../actions/CartActions';



const serverURL = process.env.REACT_APP_SERVERURL;



const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }
  const data = response.json();
  return data;
};

const handleResponseb = (response) => {
  if (!response.ok) {
    return Promise.reject(response.json());
  }

  return response.json();
};

class CartService {
  static async saveCart(cartJson, deleteCart) {

    let user = localStorage.getItem('userLoti');
    if (user) {
      user = JSON.parse(user);
      user = decryptUserData(user);
      const where = `/update?where=${encryptkey(JSON.stringify({id:cartJson.id}), true)}`;
      const url2 = `${serverURL}/api/carts/new-related`;
      const url = `${serverURL}/api/carts${cartJson.id ? where : ''}`;
      const data = JSON.stringify(
        {
          user: encryptkey(user.userId,false),
          userId: encryptkey(user.usuario,false),
          state: encryptkey('INCART',false),
          order: [{}]
        }, null, 2);
      return fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': getAuthApp()
        }),
        body: data
      })
        .then(handleResponse)
        .then(cart => {
          const loterias = [];
          if (cartJson.lotteries.length > 0 || cartJson.recharges.length > 0) {
            cartJson.lotteries.map( async (item,index) => {
              if (item.lottery) {
                const info = item.lottery;
                const loteria = {
                  ideproducto:encryptkey(info.ideProducto,false),
                  ideLoteria:encryptkey(info.ideLoteria,false),
                  abreviatura:encryptkey(info.abreviatura,false),
                  sigla:encryptkey(info.sigla,false),
                  numeroSorteo:encryptkey(info.sorteo,false),
                  numero:encryptkey(item.numero,false),
                  fracciones:encryptkey(item.fracciones,false),
                  serie:encryptkey(item.serie,false),
                  total:encryptkey(item.total,false)
                };
                loterias.push(loteria);
              } else {
                const loteria = {
                  ideproducto:encryptkey(item.ideproducto,false),
                  ideLoteria:encryptkey(item.ideLoteria,false),
                  abreviatura:encryptkey(item.abreviatura,false),
                  sigla:encryptkey(item.sigla,false),
                  numeroSorteo:encryptkey(item.numeroSorteo,false),
                  numero:encryptkey(item.numero,false),
                  fracciones:encryptkey(item.fracciones,false),
                  serie:encryptkey(item.serie,false),
                  total:encryptkey(item.total,false)
                };
                loterias.push(loteria);
              }
            });

            const info = JSON.stringify(
              {
                data:{
                  id: encryptkey(cartJson.id ? cartJson.id : cart.id,false),
                  loterias: loterias,
                  recargas: cartJson.recharges,
                  createRow: deleteCart
                }
              }, null, 2);

            fetch(url2, {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': getAuthApp()
              }),
              body: info
            })
              .then(handleResponse)
              .catch(error => {

                return Promise.reject(error);

              });
          }

          if (cartJson.id) {
            if (cartJson.lotteries.length === 1 && deleteCart === true) {
              const info = JSON.stringify(
                {
                  data:{
                    id: encryptkey(cartJson.id,false),
                    loterias: loterias,
                    recargas: cartJson.recharges,
                    createRow: deleteCart
                  }
                }, null, 2);

              fetch(url2, {
                method: 'POST',
                headers: new Headers({
                  'Content-Type': 'application/json',
                  'Authorization': getAuthApp()
                }),
                body: info
              })
                .then(handleResponse)
                .catch(error => {

                  return Promise.reject(error);

                });
              cartJson = {};
            }
            return {data: cartJson};
          } else {
            cart.lotteries= [];
            cart.recharges= [];
            return {data: cart};
          }
        })
        .catch(error => {
          return Promise.reject(error);
        });
    } else {
      return new Promise((resolve, reject) => {
        try {
          localStorage.setItem('cartLoti', encrypt(JSON.stringify(cartJson)));
          setTimeout(function() {
            resolve({ data: cartJson});
          }, 1000);
        } catch (err) {
          reject({message: 'Ocurrió un error al momento de guardar el carrito de compras local'});
        }
      });
    } 
  }


  static async getCart(combine) {
    const scopeCart=(cart, combine)=> {
      const cartScope = typeof cart === 'string' ? JSON.parse(decrypt(cart)):[];
      const finalCart = ((cartScope.length > 0) ? cartScope[0] || {lotteries: [],recharges: []} : {lotteries: [],recharges: []});
      if (combine) {
        finalCart.lotteries = finalCart.lotteries.concat(cartJson.lotteries);
        finalCart.recharges = finalCart.recharges.concat(cartJson.recharges);
        localStorage.removeItem('cartLoti');
      }
      return {
        pending:   cart.length > 0 ? true:false,
        finalCart: finalCart
      }; 
    };
    let user = localStorage.getItem('userLoti');
    const cartLocal = localStorage.getItem('cartLoti') ? await decrypt(localStorage.getItem('cartLoti')):null;
    const cartJson = (cartLocal ? JSON.parse(cartLocal) : {
      lotteries: [],
      recharges: []
    });
    if (user) {
      user = JSON.parse(user);
      user = decryptUserData(user);
      const loteriAux=await encryptkey('lotteries');  
      const recharAux=await encryptkey('recharges');   
      const url = `${serverURL}/api/carts?filter[fields][id]=true&filter[include]=${ loteriAux }`+
      `&filter[include]=${ recharAux }`+
      '&filter[where][user]='+encryptkey(user.userId, true)+
      `&filter[where][state]=${encryptkey('INCART')}`;
      return fetch(url, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': getAuthApp()
        })
      })
        .then(handleResponse)
        .then(cart => {
          cart = encrypt(JSON.stringify(cart));
          const scopeCartResult = scopeCart(cart,combine);
          if ( scopeCartResult.pending > 0) {
            const urlPending = `${serverURL}/api/carts?filter[fields][id]=true&filter[where][user]=`+
            encryptkey(user.userId, true)+
            `&filter[where][state]=${encryptkey('PENDING')}`+
            `&filter[order]=${encryptkey('id DESC')}`;
            return fetch(urlPending, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthApp()
              }
            })
              .then(handleResponse)
              .then(cartPending => {
                if (cartPending.length >= 1) {
                  scopeCartResult.finalCart.pendiente=true;
                }
                return ({ data : scopeCartResult.finalCart });
              });
          } else {
            return ({ data : scopeCart(cart,combine).finalCart });
          }

        })
        .catch(error => {
          return Promise.reject(error);
        });
    } else {
      return new Promise((resolve, reject) => {
        try {
          setTimeout(function() {
            resolve({ data: cartJson });
          }, 250);
        } catch (err) {
          console.error(err);
          reject({message: 'Ocurrió un error al momento de traer el carrito de compras local'});
        }
      });
    }
  }

  static checkoutCart (values) {
    const user = localStorage.getItem('userLoti');
    values.user= user;
    values.idcart= encrypt(JSON.stringify(values.idcart));
    values.payment= encrypt(JSON.stringify(values.payment));
    const url = `${serverURL}/api/Users/place-to-pay`;
    const data = JSON.stringify({ pay: {...values} }, null, 2);
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(handleResponseb)
      .then(response => {
        return new Promise((resolve, reject) => {
          try {
            setTimeout(function() {
              resolve(response);
            }, 500);
          } catch (err) {
            console.log(err);
            reject(err);
          }
        });
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static obtenerConfisysPagos = async () => {
    const url = `${serverURL}/api/carts/get-confisys-payments`;
    const body = {
      listaNombresConfisys: [
        cartConstants.CFS_PAYVALIDA_ENABLED_PAYMENTS,
        cartConstants.CFS_PLACETOPAY_ENABLED_PAYMENTS
      ]
    };
    console.warn('BODY', JSON.stringify(body));
    return fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: getAuthApp()
      }),
      body: JSON.stringify({confisys: body}, null, 2)
    })
      .then(handleResponse)
      .catch(error => Promise.reject(error));
  };

  

  static checkoutCoupon = async (values) => {
    const url = `${serverURL}/api/cuponesredimir/get-consult-coupon`;
    const body = {
      data: values    
    };
    console.warn('BODY', JSON.stringify(body));
    return fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: getAuthApp()
      }),
      body: JSON.stringify({confisys: body}, null, 2)
    })
      .then(handleResponse)
      .then(response => {
        console.log('Respuesta del servidor:', response); // Log para verificar la respuesta
        return response;
      })
      .catch(error => {
        console.error('Error en checkoutCoupon:', error); // Log de error
        return Promise.reject(error);
      }); 
  };
}




export default CartService;
