import 'whatwg-fetch';
import { getAuthApp } from '../services/security/authApp';
import { decrypt } from '../services/security/encryptorHandlerSecurity';
const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

class ContactService {

  static savePQRS(values) {
    const url = `${serverURL}/api/apqrs`;
    const data = JSON.stringify(values, null, 2);

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(handleResponse)
      .then(response => (response))

      .catch(error => {
        return Promise.reject(error);
      });
  }

  static saveService(values) {

    const url = `${serverURL}/api/a-services-qualifies`;
    const data = JSON.stringify(values, null, 2);

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(handleResponse)
      .then(response => (response))
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static loadServiceHeader() {

    const url = `${serverURL}/api/services-qualifies`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => (response))
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static loadServiceQuestions() {

    const url = `${serverURL}/api/questions`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => (response))
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static loadQuestions() {

    const url = `${serverURL}/api/faqs`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => (response))
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static loadPQRS() {

    const url = `${serverURL}/api/pqrs`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => (response))
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static loadQuestionService() {

    const url = `${serverURL}/api/questions`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => (response))
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default ContactService;
