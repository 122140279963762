import { reclamaPremioConstants } from '../constants/ReclamaPremioConstas';
import reclamaPremioService from '../services/reclamaPremioService';

//====================================================================
// Loading web confisys
//====================================================================

export const loadReclamaPremio = (body) => (dispatch, getState) => {

  const reclamaPremioRequest = () => ({
    type: reclamaPremioConstants.LIST_RECLAMO_REQUEST
  });

  const reclamaPremioSuccess = payload => ({
    type: reclamaPremioConstants.LIST_RECLAMO_SUCCESS,
    payload
  });

  const reclamaPremioError = error => ({
    type: reclamaPremioConstants.LIST_RECLAMO_FAILURE,
    error
  });

  dispatch(reclamaPremioRequest());
  // const nombreConfisys = process.env.REACT_APP_NOMBRE_CONFISYS;
  reclamaPremioService.loadListReclamoPremio(body)
    .then(
      response => {
        EventsGaTracker('recarga', 'Registro de nueva recarga');
        dispatch(reclamaPremioSuccess(response));
      }
    )
    .catch(error => {
      if (error.message) {
        try {
          const errorMessage = JSON.parse(error.message.split('-')[1]);
          dispatch(reclamaPremioError(errorMessage.mensaje ? errorMessage.mensaje : 'Ocurrió un error al momento de Crear la cuenta. Inténtalo nuevamente o contáctanos para reportar tu problema.'));
        } catch (e) {
          dispatch(reclamaPremioError('Error desconocido al procesar la solicitud.'));
        }
      } else {
        dispatch(reclamaPremioError('Error desconocido al procesar la solicitud.'));
      }
    });
};
