import React from 'react';
import { Alert } from 'reactstrap';

const CircularProgress = ({ message }) => {
  return (
    <Alert color="light" className="fa-2x text-center p-2 mb-2 circular-progress-container w-100">
      <i className="fa fa-spinner fa-spin d-inline-block color-main" />
      {message && <p className="mt-4 mb-2 text-size-medium textCa">{message}</p>}
    </Alert>
  );
};

export default CircularProgress;
