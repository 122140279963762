import React from 'react';
import { connect } from 'react-redux';
import { toggleModalLogin } from './../../actions/UserActions';
import { Modal } from 'reactstrap';
import ModalLoginContainer from './../../components/Login/ModalLoginContainer';

const ModalLogin = ({ modalLoginOpen, toggleModal, modalTab}) => {
  return (
    <Modal isOpen={modalLoginOpen} size="lg" toggle={() =>toggleModal()} className="modal-transparent modal-dialog-centered modalPurple">
      {/* <button type="button" className="close modal-transparent-close" onClick={() =>toggleModal()} aria-label="Close">
        <span aria-hidden="true">X</span>
      </button> */}
      <span onClick={() =>toggleModal()} className='single-close-inicio'>X</span>
      <ModalLoginContainer tab={modalTab} />;
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    modalLoginOpen: state.account.modalLogin,
    modalTab: state.account.modalTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal(tab) {
      dispatch(toggleModalLogin(tab));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalLogin);
