import moment from 'moment';
import { formatMoney } from './../../helpers/formatMoney';

const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `${onlyNums} -`;
    }
    if (onlyNums.length === 6) {
      return `${onlyNums.slice(0, 3)} - ${onlyNums.slice(3)} -`;
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)} - ${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)} - ${onlyNums.slice(3, 6)} - ${onlyNums.slice(6, 10)}`;
};

const normalizenoLed = (value) => {
  if (!value.replace(/^[0-9,$]*$/, '')) {
    return value;
  }
};

const normalizeTelePhone = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `${onlyNums} -`;
    }
    if (onlyNums.length === 7) {
      return `${onlyNums.slice(0, 3)} - ${onlyNums.slice(4)}`;
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 3)} - ${onlyNums.slice(4)}`;
};

const normalizeDate = (value) => {
  if (!value || moment.isMoment(value)) {
    return value;
  }

  if (value instanceof Date) {
    return moment(value).format('DD-MM-YYYY');
  }

  const normalizedValue = value.replace(/[^0-9/-]/g, '');
  return normalizedValue;
};

const normalizeDateRevert = (value) => {
  if (!value || moment.isMoment(value)) {
    return value;
  }
  const normalizedValue = value.replace(/[^0-9/-]/g, '');
  return normalizedValue;
};


const normalizeCurrency = (value, previousValue) => {
  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums < 1) {
    return '';
  }

  return `$ ${formatMoney(onlyNums, 0, 3, '.', ',')}`;
};

const normalizeNumbers = (value, previousValue) => {
  const onlyNums = value.replace(/[^\d]$/g, '');

  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums < 0 || onlyNums > 9) {
      return previousValue.replace(/[^\d]$/g, '');
    }
  }

  return onlyNums;
};

const normalizeOnlyNumbers = (value, previousValue) => {
  const onlyNums = value.replace(/[^\d]/g, '');

  return onlyNums;
};

export { normalizePhone, normalizenoLed, normalizeCurrency, normalizeNumbers, normalizeTelePhone, normalizeOnlyNumbers, normalizeDate, normalizeDateRevert };
