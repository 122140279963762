import { modalConstants } from './../constants/ModalConstants';

const initialState = {
  general:{
    open: false
  },
  accept:{
    open: false
  },
  age:{
    open: false
  },
  confirmAge:{
    open: false
  },
  update:{
    open:false
  },
  favorite:{
    open:false
  }
};

export function modal(state = initialState, action) {
  switch (action.type) {
    case modalConstants.TOGGLE_MODAL_GENERAL:
      return {
        ...state,
        general:{
          open: !state.general.open
        }
      };
    case modalConstants.TOGGLE_MODAL_ACCEPT:
      return {
        ...state,
        accept:{
          open: !state.accept.open
        }
      };
    case modalConstants.TOGGLE_MODAL_AGE:
      return {
        ...state,
        age:{
          open: !state.age.open
        }
      };
    case modalConstants.TOGGLE_MODAL_CONFIRM_AGE:
      return {
        ...state,
        confirmAge:{
          open: !state.confirmAge.open
        }
      };
    case modalConstants.TOGGLE_MODAL_UPDATE:
      return {
        ...state, 
        update: {
          open: !state.update.open
        }
      };
    case modalConstants.TOGGLE_MODAL_FAVORITE:
      return {
        ...state, 
        update: {
          open: !state.favorite.open
        }
      };      
    default:
      return state;
  }
}
