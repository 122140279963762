import React from 'react';
import { reduxForm, Field } from 'redux-form';

//Components
import renderField from './../renderFields/renderField';

//Utils
import Validators from './../renderFields/validatorsFields';

const validate = values => {
  const errors = {};

  if (values.claveNueva && values.repetirclaveNueva
    && (values.claveNueva !== values.repetirclaveNueva)) {

    errors.claveNueva = 'Las contraseñas deben coincidir';
    errors.repetirclaveNueva = 'Las contraseñas deben coincidir';
  }

  return errors;
};

const ResetPasswordForm = ({
  handleSubmit,
  onDelete,
  pristine,
  reset,
  submitting,
  loadingForm,
  readOnly
}) => {
  return (
    <form onSubmit={handleSubmit}>

      <Field
        name="claveNueva"
        id="changePass-claveNueva"
        type="password"
        icon="flaticon-locked"
        label="Nueva contraseña"
        component={renderField}
        validate={[Validators.required, Validators.minLength8, Validators.maxLength15, Validators.validPassword]}
        readOnly={readOnly}
      />

      <Field
        name="repetirclaveNueva"
        id="changePass-claveNuevaConfirm"
        type="password"
        icon="flaticon-locked"
        label="Repetir nueva contraseña"
        component={renderField}
        validate={[Validators.required, Validators.minLength8, Validators.maxLength15, Validators.validPassword]}
        readOnly={readOnly}
      />

      <button
        type="submit"
        className="btn btn-primary"
        disabled={loadingForm}
      >
        Cambiar contraseña
        {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
      </button>
    </form>
  );
};

export default reduxForm({ form: 'ResetPasswordForm', validate})(ResetPasswordForm);
