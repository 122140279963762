import { userConstants } from './../constants/UserConstants';

const initialState = {
  loading: false,
  success: false,
  error: null,
  delete: {
    loading: false,
    success: false,
    error: null
  }
};

export function confirmAccount(state = initialState, action) {
  switch (action.type) {
    case userConstants.REQUEST_ACTIVE_ACCOUNT:
      return {
        ...state,
        loading: true
      };
    case userConstants.REQUEST_ACTIVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        success: action.payload
      };
    case userConstants.REQUEST_ACTIVE_ACCOUNT_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case userConstants.DELETE_USER_REQUEST:
      return {
        ...state,
        delete: {
          loading: true
        }
      };
    case userConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        delete: {
          success: action.payload
        }
      };
    case userConstants.DELETE_USER_FAILURE:
      return {
        ...state,
        delete: {
          error: action.payload
        }
      };
    case userConstants.DELETE_USER_CLEAR:
      return {
        ...state,
        delete: {
          loading: false,
          success: false,
          error: null
        }
      };
    default:
      return state;
  }
}
