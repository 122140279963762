import React from 'react';
import { FormGroup, Input, FormText } from 'reactstrap';

const renderSelect = ({input, label, children, icon, loadingOptions, topLabel, iconimg, meta: {touched, error, warning}, ...rest}) => {

  return (
    <FormGroup>
      <div className={`position-relative form-file-container ${topLabel && 'file-top-label'}`}>
        <Input
          {...input}
          {...rest}
          type="select"
          value={input.value ? input.value : 'default'}
          placeholder={label} className="form-control form-file"
        >
          { children }
        </Input>
        {topLabel && <label>{label}</label>}
        {loadingOptions ? <i className="form-file-icon fa fa-spinner fa-pulse" /> :
          (iconimg ? <img className='icon-render' src={iconimg} /> : <i className={`form-file-icon ${icon}`} />)}
      </div>
      {touched &&
      ((error && <FormText className="text-left">{error}</FormText>) ||
      (warning && <FormText className="text-left">{warning}</FormText>))}
    </FormGroup>
  );
};


export default renderSelect;
