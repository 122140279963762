import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import DateInlineForm from './../../components/Account/DateInlineForm';
import TableHistory from './../../components/Account/TableHistory';
import { loadShoppingHistory } from './../../actions/AccountActions';
import moment from 'moment';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';

class ShoppingHistoryContainer extends Component {
  constructor(props) {
    super(props);

    this.handleOnSave = this.handleOnSave.bind(this);
  }

  componentDidMount () {
    this.props.loadShoppingHistory();
  }

  handleOnSave = values => {
    console.log("entre a handleOnSave");
    console.log("Values", values);
    if (moment.isMoment(values.fecha)) {
      console.log("Fechas1", values.fecha);
      values.fecha = values.fecha.format('YYYY-MM-DD');
      console.log("Fechas", values.fecha);
    }
    this.props.loadShoppingHistory(values.fecha);
  };

  render() {
    const { list, error, loading } = this.props;
    return (
      <section className="nosotros-section">
        <Row className="justify-content-center">
          <Col xs={12} xl={8} className="mb-4 p-5">
            <DateInlineForm
              onSubmit={this.handleOnSave}
              loadingForm={loading}
            />
          </Col>
          <Col xs={12}>
            {loading && <CircularProgress />}
            {list && <TableHistory shoppingList={list}/>}
            {error && <Alert className="my-2 content-center" color="danger"> {error} </Alert>}
          </Col>
        </Row>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { list, error, loading } = state.account.shopping;
  return {
    list,
    error,
    loading
  };
}

const mapDispatchToProps = dispatch => {
  return {
    loadShoppingHistory(date) {
      dispatch(loadShoppingHistory(date));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingHistoryContainer);
