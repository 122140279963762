import 'whatwg-fetch';
import {getAuthApp} from '../services/security/authApp';
const serverURL = process.env.REACT_APP_SERVERURL;

const serverGana = process.env.REACT_APP_SERVERGANA;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }
  return response.json();
};

class LocationsService {

  static loadCountries() {

    const url = `${serverGana}/authenticate/getCountry`;

    return fetch(url, {
      method: 'GET',
      headers:new Headers({
        'Content-Type': 'application/json'
        //'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => [])
      .catch(error => {
        return Promise.reject([]);
      });
  }

  static loadStates(idCountry) {

    const url = `${serverGana}/authenticate/getState/${idCountry}`;

    return fetch(url, {
      method: 'GET',
      headers:new Headers({
        'Content-Type': 'application/json'
        //'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => [])
      .catch(error => {
        return Promise.reject([]);
      });
  }

  static loadCities(idState) {

    const url = `${serverGana}/authenticate/getCity/${idState}`;

    return fetch(url, {
      method: 'GET',
      headers:new Headers({
        'Content-Type': 'application/json'
        //'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default LocationsService;
