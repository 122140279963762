import React, { useRef, useEffect } from 'react';
import { FormText } from 'reactstrap';

const renderInputGame = React.forwardRef(({ input, label, inputRef, type, meta: { touched, error, warning }, ...rest }) => (
  <div className="position-relative single-input">
    <input
      {...input}
      {...rest}
      ref={inputRef}  // Asegúrate de pasar ref aquí
      type={type}
      placeholder={label}
      className="form-file"
    />
    {touched &&
      ((error && <FormText className="text-left">{error}</FormText>) ||
      (warning && <FormText className="text-left">{warning}</FormText>))}
  </div>
));

export default renderInputGame;
