import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert, Button, Modal } from 'reactstrap';
import CircularProgress from '../../components/Global/CircularProgress/CircularProgress';
import { loadPagoPremio } from '../../actions/PagoPremioActions';
import { loadwebConfisys } from '../../actions/WebConfisysActions';
import { priceFormatter, quitarAsteriscos } from '../../helpers/formatMoney';
import ModalPagoPremios from '../../components/Modal/ModalPagoPremios';
import { Box } from '@mui/material';
import { decryptUserData } from '../../services/security/informationCodingSecurity';
import { DataGrid } from '@mui/x-data-grid';

const PagoPremioListContainer = ({ loading, error, pagoPremio, webConfisys, nombre, fecSorteo, loadPagoPremio, loadwebConfisys, user }) => {
  const [showModalPagoPremio, setShowModalPagoPremio] = useState(false);
  const [selectedPremio, setSelectedPremio] = useState(null);
  const [valorUVT, setValorUVT] = useState(null);
  const [celularUsuario, setCelularUsuario] = useState('');
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [saldoUsuario, setSaldoUsuario] = useState('');
  const [ideUsuario, setIdeUsuario] = useState('');
  const [email, setEmail] = useState('');
  const [primerApellido, setPrimerApellido] = useState('');
  const [usuarioUser, setUsuarioUser] = useState('');
  const [colillaUsuario, setColillaUsuario] = useState('');
  const [usuarioUserConsulta, setCelularUsuarioConsulta] = useState('');
  const [numTransaccionUsuario, setNumTransaccionUsuario] = useState('');
  const [ideEstadoUsuario, setideEstadoUsuario] = useState('');
  const [selectedPremioValor, setSelectedPremioValor] = useState(null);
  const [selectedLoteria, setSelectedLoteria] = useState(null);
  const [selectedFecSorteo, setSelectedFecSorteo] = useState(null);

  const openModalPagoPremio = (row) => {
    setSelectedPremio(row);
    setSelectedPremioValor(row.vlrGanado);
    setSelectedLoteria(row.desLoteria);
    setSelectedFecSorteo(row.fecSorteo);
    setShowModalPagoPremio(true);
    console.log('Datos de la fila seleccionada:', row);
  };

  const closeModalPagoPremio = () => {
    setSelectedPremio(null);
    setShowModalPagoPremio(false);
  };

  useEffect(() => {
    loadwebConfisys({
      ideUsuario: ideUsuario
    });
  }, [ideUsuario, loadwebConfisys]);

  useEffect(() => {
    loadPagoPremio({
      nombre: nombre
    });
  }, [nombre, loadPagoPremio]);

  useEffect(() => {
    if (webConfisys && Array.isArray(webConfisys) && webConfisys.length > 0) {
      try {
        const uvtData = webConfisys[0];
        const valorUVT = uvtData.valor;
        if (typeof valorUVT === 'number') {
          setValorUVT(valorUVT);
          console.log('Valor de UVT obtenido:', valorUVT);
        } else {
          console.error('El valor de UVT no es un número válido:', valorUVT);
        }
      } catch (error) {
        console.error('Error al procesar los datos de webConfisys:', error);
      }
    } else {
      console.error('webConfisys no contiene datos válidos:', webConfisys);
    }
  }, [webConfisys]);

  useEffect(() => {
    if (user && user.celular && user.nombres && user.saldo && user.userId && user.email && user.primerApellido && user.usuario) {
      let obDecryptUserData = {
        usuario: user.usuario
      };
      obDecryptUserData = decryptUserData(obDecryptUserData);
      setCelularUsuario(user.celular);
      setNombreUsuario(user.nombres);
      setSaldoUsuario(user.saldo);
      setIdeUsuario(user.userId);
      setEmail(user.email);
      setPrimerApellido(user.primerApellido);
      setUsuarioUser(obDecryptUserData);
      console.log('obDecryptUserData:', user);
      console.log('obDecryptUserData:', obDecryptUserData);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.usuario) {
      setCelularUsuarioConsulta(user.usuario);
      console.log('usuarioConsulta', user.usuario);
    }
  }, [user]);

  console.log('usuarioConsulta', user);

  useEffect(() => {
    if (selectedPremio && selectedPremio.colilla && selectedPremio.ideEstado && selectedPremio.numTransaccion) {
      setColillaUsuario(selectedPremio.colilla);
      setNumTransaccionUsuario(selectedPremio.numTransaccion);
      console.log('numTransaccion1:', selectedPremio.numTransaccion);
      setideEstadoUsuario(selectedPremio.ideEstado);
    }
    console.log('PagoPremio:', selectedPremio);
  }, [selectedPremio]);

  const isReclamarEnabled = (row) => {
    const vlrPremioUVT = row.vlrGanado / valorUVT;
    return row.ideEstado === '10' && vlrPremioUVT < 2;
  };

  const isReclamarEnabledEst = (row) => {
    const vlrPremioUVT = row.vlrGanado / valorUVT;
    return vlrPremioUVT < 2;
  };


  const isValidarFechaPremio = (row) => {
    const fechaDeseada = new Date(process.env.REACT_APP_FECHADESEADA);
    const fechaSorteo = new Date(row.fecSorteo);
    return fechaSorteo >= fechaDeseada;
  };

  const [columnTextValor, setColumnTextValor] = useState('Valor Premio');
  const [columnTextMedioPago, setColumnTextMedioPago] = useState('Medio de Pago');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setColumnTextValor('Valor');
        setColumnTextMedioPago('Medio');
      } else {
        setColumnTextValor('Valor Premio');
        setColumnTextMedioPago('Medio de Pago');
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const columns = [
    { field: 'desLoteria', headerName: 'Lotería', width: 150, headerClassName: 'custom-header' },
    { field: 'sorteo', headerName: 'Sorteo', width: 120, headerClassName: 'custom-header' },
    {
      field: 'numero',
      headerName: 'Número',
      width: 120,
      headerClassName: 'custom-header',
      classes: 'custom-table-data-row',
      renderCell: (params) => {
        const numeroSinAsteriscos = quitarAsteriscos(params.value);
        return numeroSinAsteriscos || 'N/A';
      }
    },
    {
      field: 'serieBillete',
      headerName: 'Serie',
      width: 120,
      headerClassName: 'custom-header',
      classes: 'custom-table-data-row',
      renderCell: (params) => {
        const numeroSinAsteriscos = quitarAsteriscos(params.value);
        return numeroSinAsteriscos || 'N/A';
      }
    },
    {
      field: 'desEstado',
      headerName: 'Estado',
      width: 150,
      headerClassName: 'custom-header',
      classes: 'custom-table-data-row',
      text: columnTextMedioPago,
      renderCell: (params) => {
        // Aseguramos que params.row y desEstado estén definidos
        const desEstado = params.row?.desEstado?.toLowerCase() || '';
        const esPagoWeb = params.row?.esPagoWeb;

        if (desEstado === 'pagado' && esPagoWeb === 'S') {
          return 'Reclamado';
        } else if (desEstado === 'pagado' && esPagoWeb === null) {
          return 'Pagado en punto físico';
        } else if (desEstado === 'aprobado') {
          return 'Pendiente';
        }
        return 'Pendiente';
      }
    },
    {
      field: 'vlrGanado',
      headerName: 'Valor Premio',
      text: columnTextValor,
      width: 150,
      headerClassName: 'custom-header',
      classes: 'custom-table-data-row',
      renderCell: (params) => {
        const valorPremio = params.row?.vlrGanado;
        return valorPremio ? priceFormatter(valorPremio) : 'N/A';
      }
    },
    {
      field: 'reclamar',
      headerName: 'Reclamar',
      width: 150,
      headerClassName: 'custom-header',
      classes: 'custom-table-data-row',
      renderCell: (params) => {
        const isEnabled = isReclamarEnabled(params.row);
        const isEnableFechaPremio = isValidarFechaPremio(params.row);
        const isEnabledEst = isReclamarEnabledEst(params.row);
        //const vlrPremioUVT = row.vlrGanado / valorUVT;

        return (
          <div>
            <div className="tooltip-container">
              <Button color="primary"
                onClick={() => openModalPagoPremio(params.row)}
                style={{ fontSize: '10px', fontWeight: 'bold' }}
                disabled={!isEnabled || !isEnableFechaPremio}
              >
                Reclamar
              </Button>
              {!isEnabledEst && params.row && params.row.desEstado.toLowerCase() !== 'pagado' &&
                <div className="tooltip" style={{ textAlign: 'center', lineHeight: '1' }}>
                  {'Para reclamar este premio debes '}
                  <br />
                  {'dirigirte a un punto físico'}
                </div>
              }
              {!isEnableFechaPremio && isEnabledEst && params.row.desEstado.toLowerCase() !== 'pagado' &&
                <div className="tooltip" style={{ textAlign: 'center', lineHeight: '1' }}>
                  {'Para reclamar este premio debes '}
                  <br />
                  {'dirigirte a un punto físico'}
                </div>
              }
            </div>
          </div>


        );
      },
    },
  ];

  return (
    <Row>
      <Col xs={12}>
        {loading && <CircularProgress />}
        {pagoPremio && pagoPremio.length > 0 && (
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12} className="p-0">
              <section className="table-custom-data-premio">               
                  <DataGrid
                    rows={pagoPremio}
                    columns={columns}
                    // pageSize={10}
                    // rowsPerPageOptions={[]}
                    // pagination={false}
                    // disableSelectionOnClick                    
                    getRowId={(row) => row.numTransaccion}
                  />               
              </section>
            </Col>
          </Row>
        )}

        {showModalPagoPremio && (
          <ModalPagoPremios
            title="Detalle lotería"
            show={showModalPagoPremio}
            pagoPremio={selectedPremio}
            onClose={closeModalPagoPremio}
            onCloseModal={setShowModalPagoPremio}
            celularUsuario={celularUsuario}
            nombreUsuario={nombreUsuario}
            selectedPremioValor={selectedPremioValor}
            selectedLoteria={selectedLoteria}
            fecSorteo={selectedFecSorteo}
            saldoUsuario={saldoUsuario}
            ideUsuario={ideUsuario}
            colillaUsuario={colillaUsuario}
            numTransaccionUsuario={numTransaccionUsuario}
            ideEstadoUsuario={ideEstadoUsuario}
            emailUsuario={email}
            apellidoUsuario={primerApellido}
            usuarioUser={usuarioUser.usuario}
            usuarioUserConsulta={usuarioUserConsulta}
          />
        )}
        {error && <Alert className="my-2 content-center" color="danger">{error}</Alert>}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const { loading, error, pagoPremio } = state.premio;
  const { webConfisys } = state.confisys;
  const { user } = state.authentication;
  return {
    loading,
    error,
    pagoPremio,
    webConfisys,
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPagoPremio(values) {
      dispatch(loadPagoPremio(values));
    },
    loadwebConfisys(values) {
      dispatch(loadwebConfisys(values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PagoPremioListContainer);
