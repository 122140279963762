import { recargaOkiConstants } from '../constants/RecargaOkiConstants';
import RecargaOkiService from '../services/recargaOkiService';

//====================================================================
// Loading Recarga Oki
//====================================================================

export const loadRecargaOki = (values) => (dispatch) => {
  const recargaOkiRequest = () => ({
    type: recargaOkiConstants.LIST_OKI_REQUEST
  });

  const recargaOkiSuccess = (payload) => ({
    type: recargaOkiConstants.LIST_OKI_SUCCESS,
    payload
  });

  const recargaOkiError = (error) => ({
    type: recargaOkiConstants.LIST_OKI_FAILURE,
    error
  });

  // setTimeout(() => {
  RecargaOkiService.loadListRecargaOki(values)
    .then(
      listRecargaOki => {
        console.log('values:', values);
        EventsGaTracker('listRecargaOki', 'Recarga Usuario usuario Oki');
        dispatch(recargaOkiSuccess(listRecargaOki));
        console.log('Éxito en la carga de datos desde listRecargaOki:', listRecargaOki);
        dispatch(recargaOkiRequest());
      },
      error => {
        error.then(errorResponse => {
          const errorMessage = JSON.parse(errorResponse.message.split('-')[1]);
          dispatch(recargaOkiError(errorMessage.mensaje ? errorMessage.mensaje :
            'Ocurrió un error al momento de Recargar la cuenta. Inténtalo nuevamente o contáctanos para reportar tu problema.'));
        });
      }
    );
  // }, 500);
  // dispatch(recargaOkiRequest());
  // RecargaOkiService.loadListRecargaOki(values)
  //   .then(
  //     (listRecargaOki) => {
  //       console.log('Éxito en la carga de datos desde listRecargaOki:', listRecargaOki);
  //       if (listRecargaOki.data && listRecargaOki.data.length) {
  //         listRecargaOki.data.forEach((recarga) => {
  //           recarga.ideUsuario = body.ideUsuario;
  //         });
  //       }
  //       dispatch(recargaOkiSuccess(listRecargaOki.data));
  //     },
  //     (error) => {
  //       dispatch(
  //         recargaOkiError(
  //           error.message
  //             ? error.message
  //             : 'Ocurrió un error al momento de traer el listado de recarga oki'
  //         )
  //       );
  //     }
  //   );
};
