import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../helpers/history';
import { logoutUser } from './../../actions/UserActions';
import { toggleModalUpdate } from './../../actions/ModalActions';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ModalConfirmUpdate = ({ open, toggleModalUpdate, cancelAction}) => {

  const handleOnClick = () => {
    toggleModalUpdate();
    history.push('/mi-cuenta/1');
    history.go();
  };

  const handleOnCancel = () => {
    cancelAction();
    toggleModalUpdate();
  };
  
  return (
    <Modal isOpen={open} toggle={() => toggleModalUpdate()} className="modal-dialog-centered" backdrop={'static'}>
      <ModalHeader className={'bg-primary text-white'}>Información</ModalHeader>
      <ModalBody>
        {'Por favor actualiza los datos.'}
      </ModalBody>
      <ModalFooter>
        <Button className="btn-main bg-main" onClick={handleOnClick}>Aceptar</Button>  
        <Button className="btn-main bg-secondary" onClick={handleOnCancel}>Cancelar</Button>  
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    open: state.modal.update.open
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModalUpdate() {
      dispatch(toggleModalUpdate());
    },
    cancelAction() {
      dispatch(logoutUser());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmUpdate);
