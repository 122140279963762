//Dependencis
import React from 'react';

const LotteryNotSelected = ({toggleModal}) => {
  return (
    <section className="p-5 bg-white border-rad-lg">
      <h3 className="text-center first-title mb-5">Lo Sentimos</h3>
      <p className="text-center mb-5 text-size-medium">Primero debes seleccionar una lotería <br/> para poder jugar un sorteo.</p>
      <button
        type="button"
        onClick={toggleModal}
        className="button-rounded button-rounded-blue w-auto content-center"
      >
        <div className="d-flex justify-content-between align-items-center">
          <span className="d-inline-block mr-5">Seleccionar lotería</span>
          <i className="flaticon-dice"/>
        </div>
      </button>
    </section>
  );
};

export default LotteryNotSelected;
