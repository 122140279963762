import React, { Component, useState } from 'react';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Col, FormGroup, FormText, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
//import { Checkbox } from '@material-ui/core';

//Components
import renderField from './../renderFields/renderField';
import renderSelect from './../renderFields/renderSelect';
import renderDatePicker from './../renderFields/renderDatePicker';
import renderCaptcha from './../renderFields/renderCaptcha';
import renderCheckBox from './../renderFields/renderCheckBox';
import { normalizePhone, normalizenoLed, normalizeOnlyNumbers } from './../renderFields/normalizeFields';
import { DatePickerFechaNacimiento } from '../DatePicker/DatePickerFechaNacimiento';
import { DatePickerFechaNacimientoEditUser } from '../DatePicker/DatePickerFechaNacimientoEditUser';
import { DatePickerDocumento } from '../DatePicker/DatePickerDocumento';

import {loadCountries, loadStates, loadCities} from './../../actions/LocationsActions';

import Validators from './../renderFields/validatorsFields';
import {getDocumentTypes} from '../../utils/accountUtils';
import { Link } from 'react-router-dom';
import ModalInfoComponent from '../Modal/ModalInfoComponent';
import { toggleModalConfirmAge } from './../../actions/ModalActions';

const validate = values => {
  const errors = {};

  if (values.contrasena && values.contrasenaConfirm
    && (values.contrasena !== values.contrasenaConfirm)) {

    errors.contrasena = 'La contraseña y la confirmación de la contraseña deben coincidir';
    errors.contrasenaConfirm = 'La contraseña y la confirmación de la contraseña deben coincidir';
  }

  if (values.email && values.emailConfirm
    && (values.email !== values.emailConfirm)) {

    errors.email = 'Los correos deben coincidir';
    errors.emailConfirm = 'Los correos deben coincidir';
  }
  return errors;
};

const labelPoliticas = `<span>
  Acepto los <a href="/terminos-y-condiciones" target="_blank">Términos y condiciones</a>
</span>`;

const labelTratamientos = `<span>
Acepto las <a href="/politicas-privacidad" target="_blank">Políticas de tratamiento de datos</a>
</span>`;

const labelNoResp = 'No responsable';

const labelGranContri = 'Gran contribuyente';

const labelAutoretenedor = 'Autoretenedor';

const labelRetencionIva = 'Agente de retención IVA';

const labelRegimenSimple = 'Régimen simple de tributación';

const UpperCase = value => value.toUpperCase();

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      obligacion: [],
      estadoNoResponsable: false,
      estadoGranContri: false,
      estadoAutoretenedor: false,
      estadoRetencionIva: false,
      estadoRegimenSimple: false,
      obligacionFiscal: 'R-99-PN',
      mayoriaEdad: false
    };
  }

  componentDidMount() {
    this.props.loadCountries();
    if (this.props.initialValues.idePaisExpedicionDocumento) {
      this.props.loadStates(this.props.initialValues.idePaisExpedicionDocumento, 'DOCUMENT');
      if (this.props.initialValues.ideMunicipioExpedicionDocumento) {
        this.props.loadCities(this.props.initialValues.ideMunicipioExpedicionDocumento, 'DOCUMENT');
      }
    }
    if (this.props.initialValues.idePais) {
      this.props.loadStates(this.props.initialValues.idePais, 'USER');
      if (this.props.initialValues.ideDepartamento) {
        this.props.loadCities(this.props.initialValues.ideDepartamento, 'USER');
      }
    }

    if (this.props.initialValues.obligacionFiscal) {
      this.checkedObligacionesInit(this.props.initialValues.obligacionFiscal);
      this.props.getObligacion(this.props.initialValues.obligacionFiscal);

    } else {
      this.checkNoResp(true);
      this.setState({estadoNoResponsable: true});
      this.state.obligacion.push('R-99-PN');
      this.props.getObligacion(this.state.obligacion);
    }
    this.setState({test:true});
  }

  prueba = (e) =>{
    this.setState({valueCheck:e.target.checked});
  }


checkNoResp = (valor) => {
  this.setState({
    estadoNoResponsable: valor
  });

};
checkGranContri = (valor) => {
  this.setState({
    estadoGranContri: valor
  });
};
checkAutoretendor = (valor) => {
  this.setState({
    estadoAutoretenedor: valor
  });
};
checkRetencionIva = (valor) => {
  this.setState({
    estadoRetencionIva: valor
  });
};
checkRegimenSimple = (valor) => {
  this.setState({
    estadoRegimenSimple: valor
  });
};

  concatenar = (e) => {
    if (this.state.obligacion.indexOf('R-99-PN')> -1) {
      this.state.obligacion.splice(this.state.obligacion.indexOf('R-99-PN'),1);
    }
    if (e.target.id==='R-99-PN') {
      if (e.target.checked) {
        var cantidad = this.state.obligacion.length;
        this.checkGranContri(false);
        this.checkAutoretendor(false);
        this.checkRetencionIva(false);
        this.checkRegimenSimple(false);
        for (var i = 0; i <cantidad; i++ ) {
          this.state.obligacion.pop(i);
        }
        this.checkNoResp(true);
        this.state.obligacion.push(e.target.value);
      } else {
        this.checkNoResp(false);
        this.state.obligacion.splice(this.state.obligacion.indexOf(e.target.value),1);
        this.checkNoResp(true);
        this.state.obligacion.push(e.target.value);
      }
    } else if (e.target.id==='O-13') {
      if (e.target.checked) {
        this.checkNoResp(false);
        this.checkGranContri(true);
        this.state.obligacion.push(e.target.value);
      } else {
        this.checkGranContri(false);
        if (this.state.obligacion.length===1) {
          this.checkNoResp(true);
          this.state.obligacion.push('R-99-PN');
        }
        this.state.obligacion.splice(this.state.obligacion.indexOf(e.target.value),1);
      }
    } else if (e.target.id==='O-15') {
      if (e.target.checked) {
        this.checkNoResp(false);
        this.checkAutoretendor(true);
        this.state.obligacion.push(e.target.value);
      } else {
        this.checkAutoretendor(false);
        if (this.state.obligacion.length===1) {
          this.checkNoResp(true);
          this.state.obligacion.push('R-99-PN');
        }
        this.state.obligacion.splice(this.state.obligacion.indexOf(e.target.value),1);
      }
    } else if (e.target.id==='O-23') {
      if (e.target.checked) {
        this.checkNoResp(false);
        this.checkRetencionIva(true);
        this.state.obligacion.push(e.target.value);
      } else {
        this.checkRetencionIva(false);
        if (this.state.obligacion.length===1) {
          this.checkNoResp(true);
          this.state.obligacion.push('R-99-PN');
        }
        this.state.obligacion.splice(this.state.obligacion.indexOf(e.target.value),1);
      }
    } else if (e.target.id==='O-47') {
      if (e.target.checked) {
        this.checkNoResp(false);
        this.checkRegimenSimple(true);
        this.state.obligacion.push(e.target.value);
      } else {
        this.checkRegimenSimple(false);
        if (this.state.obligacion.length===1) {
          this.checkNoResp(true);
          this.state.obligacion.push('R-99-PN');
        }
        this.state.obligacion.splice(this.state.obligacion.indexOf(e.target.value),1);
      }
    }
    this.props.getObligacion(this.state.obligacion);
    return this.state.obligacion;

  }

  checkedObligacionesInit = (data) => {
    if (data !== null && data !== undefined) {
      var vector = data.split(';');
      for (var i in vector) {
        if ( vector[i] === 'R-99-PN') {
          this.checkNoResp(true);
        } else if (vector[i] === 'O-13') {
          this.checkGranContri(true);
        } else if (vector[i] === 'O-15') {
          this.checkAutoretendor(true);
        } else if (vector[i] === 'O-23') {
          this.checkRetencionIva(true);
        } else if (vector[i] === 'O-47') {
          this.checkRegimenSimple(true);
        }
      }
    } else {
      this.checkNoResp(true);
      this.setState({estadoNoResponsable: true});
    }
  };

  checkNoResp = (valor) => {
    this.setState({
      estadoNoResponsable: valor
    });
  };
  checkGranContri = (valor) => {
    this.setState({
      estadoGranContri: valor
    });
  };
  checkAutoretendor = (valor) => {
    this.setState({
      estadoAutoretenedor: valor
    });
  };
  checkRetencionIva = (valor) => {
    this.setState({
      estadoRetencionIva: valor
    });
  };
  checkRegimenSimple = (valor) => {
    this.setState({
      estadoRegimenSimple: valor
    });
  };
  concatenar = (e) => {
    if (this.state.obligacion.indexOf('R-99-PN')> -1) {
      this.state.obligacion.splice(this.state.obligacion.indexOf('R-99-PN'),1);
    }
    if (e.target.id==='R-99-PN') {
      if (e.target.checked) {
        var cantidad = this.state.obligacion.length;
        this.checkGranContri(false);
        this.checkAutoretendor(false);
        this.checkRetencionIva(false);
        this.checkRegimenSimple(false);
        for (var i = 0; i <cantidad; i++ ) {
          this.state.obligacion.pop(i);
        }
        this.checkNoResp(true);
        this.state.obligacion.push(e.target.value);
      } else {
        this.checkNoResp(false);
        this.state.obligacion.splice(this.state.obligacion.indexOf(e.target.value),1);
        this.checkNoResp(true);
        this.state.obligacion.push(e.target.value);
      }
    } else if (e.target.id==='O-13') {
      if (e.target.checked) {
        this.checkNoResp(false);
        this.checkGranContri(true);
        this.state.obligacion.push(e.target.value);
      } else {
        this.checkGranContri(false);
        if (this.state.obligacion.length===1) {
          this.checkNoResp(true);
          this.state.obligacion.push('R-99-PN');
        }
        this.state.obligacion.splice(this.state.obligacion.indexOf(e.target.value),1);
      }
    } else if (e.target.id==='O-15') {
      if (e.target.checked) {
        this.checkNoResp(false);
        this.checkAutoretendor(true);
        this.state.obligacion.push(e.target.value);
      } else {
        this.checkAutoretendor(false);
        if (this.state.obligacion.length===1) {
          this.checkNoResp(true);
          this.state.obligacion.push('R-99-PN');
        }
        this.state.obligacion.splice(this.state.obligacion.indexOf(e.target.value),1);
      }
    } else if (e.target.id==='O-23') {
      if (e.target.checked) {
        this.checkNoResp(false);
        this.checkRetencionIva(true);
        this.state.obligacion.push(e.target.value);
      } else {
        this.checkRetencionIva(false);
        if (this.state.obligacion.length===1) {
          this.checkNoResp(true);
          this.state.obligacion.push('R-99-PN');
        }
        this.state.obligacion.splice(this.state.obligacion.indexOf(e.target.value),1);
      }
    } else if (e.target.id==='O-47') {
      if (e.target.checked) {
        this.checkNoResp(false);
        this.checkRegimenSimple(true);
        this.state.obligacion.push(e.target.value);
      } else {
        this.checkRegimenSimple(false);
        if (this.state.obligacion.length===1) {
          this.checkNoResp(true);
          this.state.obligacion.push('R-99-PN');
        }
        this.state.obligacion.splice(this.state.obligacion.indexOf(e.target.value),1);
      }
    }
    this.props.getObligacion(this.state.obligacion);
    return this.state.obligacion;

  }

  checkedObligacionesInit = (data) => {
    if (data !== null && data !== undefined) {
      var vector = data.split(';');
      for (var i in vector) {
        if ( vector[i] === 'R-99-PN') {
          this.checkNoResp(true);
          this.state.obligacion.push('R-99-PN');
        } else if (vector[i] === 'O-13') {
          this.checkGranContri(true);
          this.state.obligacion.push('O-13');
        } else if (vector[i] === 'O-15') {
          this.checkAutoretendor(true);
          this.state.obligacion.push('O-15');
        } else if (vector[i] === 'O-23') {
          this.checkRetencionIva(true);
          this.state.obligacion.push('O-23');
        } else if (vector[i] === 'O-47') {
          this.checkRegimenSimple(true);
          this.state.obligacion.push('O-47');
        }
      }
    } else {
      this.checkNoResp(true);
      this.setState({estadoNoResponsable: true});
    }
  };

  checkNoResp = (valor) => {
    this.setState({
      estadoNoResponsable: valor
    });
  };
  checkGranContri = (valor) => {
    this.setState({
      estadoGranContri: valor
    });
  };
  checkAutoretendor = (valor) => {
    this.setState({
      estadoAutoretenedor: valor
    });
  };
  checkRetencionIva = (valor) => {
    this.setState({
      estadoRetencionIva: valor
    });
  };
  checkRegimenSimple = (valor) => {
    this.setState({
      estadoRegimenSimple: valor
    });
  };

  render() {
    const {
      handleSubmit,
      loadingForm,
      readOnly,
      countries,
      documents,
      user,
      changeValue,
      formEditing,
      politicasValue,
      tratamientosValue,
      mayoriaEdadValue
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row className="mb-4">
          {!formEditing &&
          <Col xs="12" sm="12" md="6">
            <h6>Tipo de documento</h6>
            <Field
              name="tipoDocumento"
              id="account-tipoDocumento"
              type="select"
              label="Tipo de Documento"
              icon="flaticon-arrows"
              topLabel={formEditing}
              component={renderSelect}
              validate={[Validators.required, Validators.notDefault]}
              readOnly={readOnly}
              onChange={event => {
                console.log(event);
              }}
            >
              <option value="default">Seleccionar</option>
              {
                getDocumentTypes().map(item => (
                  <option value={item.type}>{item.name}</option>
                ))
              }
            </Field>
          </Col>
          }

          {!formEditing &&
            <Col>
              <h6>Expedición documento</h6>
              <DatePickerDocumento
                yearName='yearDc'
                monthName='monthDc'
                dayName='dayDc'
                readOnly={readOnly}
                topLabel={formEditing}
                validate={[Validators.isdate]}
              />
            </Col>
          }

          {!formEditing &&
          <Col xs="12">
            <Field
              name="usuario"
              id="account-document"
              type="text"
              icon="flaticon-profile"
              label="Identificación"
              normalize={normalizenoLed}
              topLabel={formEditing}
              component={renderField}
              validate={[Validators.required, Validators.documentTypeForm]}
              readOnly={readOnly}
            />
          </Col>
          }
          <Col xs="12" sm="12" md="6">
            <Field
              name="nombres"
              id="account-name"
              type="text"
              icon="flaticon-profile"
              label="Nombres"
              normalize={UpperCase}
              topLabel={formEditing}
              component={renderField}
              validate={[Validators.required, Validators.minLength3, Validators.maxLength150,Validators.named, Validators.validUserTesting, Validators.validString]}
              readOnly={readOnly}
            />
          </Col>
          <Col xs="12" sm="12" md="6">
            <Field
              name="primerApellido"
              id="account-lastname1"
              type="text"
              icon="flaticon-profile"
              label="Apellidos"
              normalize={UpperCase}
              topLabel={formEditing}
              component={renderField}
              validate={[Validators.required, Validators.minLength3, Validators.maxLength50,Validators.lastname, Validators.validString,Validators.validUserTesting]}
              readOnly={readOnly}
            />
          </Col>

          <Col xs="12" sm="12" md="6">
            <Field
              name="email"
              id="account-email"
              type="email"
              icon="flaticon-email"
              label="Correo Electrónico"
              topLabel={formEditing}
              component={renderField}
              validate={[Validators.required, Validators.email, Validators.minLength5, Validators.maxLength80, Validators.validUserTesting]}
              readOnly={readOnly}
            />
          </Col>

          {!formEditing &&
            <Col xs="12" sm="12" md="6">
              <Field
                name="emailConfirm"
                id="account-email-confirm"
                type="email"
                icon="flaticon-email"
                label="Confirmar Correo Electrónico"
                component={renderField}
                validate={[Validators.required, Validators.email, Validators.minLength5, Validators.maxLength80]}
                readOnly={readOnly}
              />
            </Col>
          }

          <Col xs="12" sm="12" md="6">
            <Field
              name="celular"
              id="account-cellphone"
              type="text"
              icon="flaticon-phone-call"
              label="Celular"
              normalize={normalizenoLed}
              topLabel={formEditing}
              component={renderField}
              validate={[Validators.required, Validators.minLength10, Validators.maxLength10]}
              readOnly={readOnly}
            />
          </Col>
          <Col xs="12" sm="12" md={formEditing ? '6' : '6'}>
            <Field
              name="direccion"
              id="account-address"
              type="text"
              icon="flaticon-house"
              label="Dirección"
              topLabel={formEditing}
              component={renderField}
              validate={[Validators.required, Validators.minLength3, Validators.maxLength100]}
              readOnly={readOnly}
            />
          </Col>
          {formEditing &&
          <Col xs="12" sm="12" md="6">
            <h6>Fecha de nacimiento</h6>
            <DatePickerFechaNacimientoEditUser
              yearName='yearFn'
              monthName='monthFn'
              dayName='dayFn'
              readOnly={readOnly}
            />
          </Col>
          }

          {!formEditing &&
            <Col xs="12" sm="12" md="6">
              <Field
                name="contrasena"
                id="account-password"
                type="password"
                icon="flaticon-locked"
                label="Contraseña"
                component={renderField}
                validate={[Validators.required, Validators.minLength8, Validators.maxLength15, Validators.validPassword]}
                readOnly={readOnly}
              />
            </Col>
          }
          {!formEditing &&
            <Col xs="12" sm="12" md="6">
              <Field
                name="contrasenaConfirm"
                id="account-passwordConfirm"
                type="password"
                icon="flaticon-locked"
                label="Confirmar Contraseña"
                component={renderField}
                validate={[Validators.required, Validators.minLength8, Validators.maxLength15]}
                readOnly={readOnly}
              />
            </Col>
          }
          {!formEditing &&
            <Col>
              <h6>Fecha de nacimiento</h6>
              <DatePickerFechaNacimiento
                yearName='yearFn'
                monthName='monthFn'
                dayName='dayFn'
                readOnly={readOnly}
                topLabel={formEditing}
              />
            </Col>
          }
          {!formEditing &&
          <Col xs="12" sm="12" md="6">
            <h6>Género</h6>
            <Field
              name="genero"
              id="account-sex"
              type="select"
              label="Sexo"
              icon="flaticon-arrows"
              topLabel={formEditing}
              component={renderSelect}
              validate={[Validators.required, Validators.notDefault]}
              readOnly={readOnly}
            >
              <option value="default">Seleccionar</option>
              <option value="Masculino">Masculino</option>
              <option value="Femenino">Femenino</option>
              <option value="Otro">Otro</option>
            </Field>
          </Col>
          }

          <Col xs="12" sm="12" md="6">
            <Col className="text-left">
              <Field
                name="politicas"
                id="politicas"
                label={labelPoliticas}
                component={renderCheckBox}
                validate={[Validators.required]}
              />
            </Col>
            <Col className="text-left">
              <Field
                name="tratamientos"
                id="tratamientos"
                label={labelTratamientos}
                component={renderCheckBox}
                validate={[Validators.required]}
              />
            </Col>
            <Col className="text-left">
              <Field
                name="mayoriaEdad"
                id="mayoriaEdad"
                label={'Soy'}
                component={renderCheckBox}
                validate={[Validators.required]}
                labelContent={
                  <Link to="/" onClick={(e) => {
                    e.preventDefault();
                    this.props.toggleModalConfirmAge();
                  }}>
                    &nbsp;Mayor De Edad
                  </Link>
                }
              />
            </Col>
          </Col>

          <Col xs="12" sm="12" md="6">
            <p className="text-left">
              <span className="label label-default"> RESPONSABILIDADES FISCALES <br/></span>
              <div className='mt20'>
                <label><input type="checkbox" id="R-99-PN" value="R-99-PN" onChange={(e) => this.concatenar(e)} checked={this.state.estadoNoResponsable} font- /> {labelNoResp} </label><br />
                <label><input type="checkbox" id="O-13" value="O-13" onChange={(e) => this.concatenar(e)} checked={this.state.estadoGranContri} /> {labelGranContri} </label><br />
                <label><input type="checkbox" id="O-15" value="O-15" onChange={(e) => this.concatenar(e)} checked={this.state.estadoAutoretenedor} /> {labelAutoretenedor} </label><br />
                <label><input type="checkbox" id="O-23" value="O-23" onChange={(e) => this.concatenar(e)} checked={this.state.estadoRetencionIva} /> {labelRetencionIva} </label><br />
                <label><input type="checkbox" id="O-47" value="O-47" onChange={(e) => this.concatenar(e)} checked={this.state.estadoRegimenSimple} /> {labelRegimenSimple} </label>
                <span class="label label-default"> <br />En caso de no tener responsabilidad fiscal seleccione la opción No responsable </span>
              </div>
            </p>
          </Col>

          {!formEditing &&
            <Col xs="12">
              <Field
                name="captcha"
                component={renderCaptcha}
                validate={[Validators.requiredCap]}
              />
            </Col>
          }

        </Row>
        <ModalInfoComponent
          className={'modal-confirm-age'}
          actionConfirm={() => {
            this.props.change('mayoriaEdad', true);
            this.props.toggleModalConfirmAge();
          }}
        />
        <button
          type="submit"
          className="btn btn-primary"
          disabled={loadingForm || (!politicasValue || !tratamientosValue || !mayoriaEdadValue )}
        >
          {formEditing ? 'Actualizar Datos' : 'Crear Cuenta'}
          {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
        </button>
      </form>
    );
  }
}

const selector = formValueSelector('AccountForm');

const mapStateToProps = (state) =>{
  const { countries, documents, user } = state.locations;
  return {
    countries,
    documents,
    user,
    politicasValue: selector(state, 'politicas'),
    tratamientosValue: selector(state, 'tratamientos'),
    mayoriaEdadValue: selector(state, 'mayoriaEdad')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCountries() {
      dispatch(loadCountries());
    },
    loadStates(id, type) {
      dispatch(loadStates(id, type));
    },
    loadCities(id, type) {
      dispatch(loadCities(id, type));
    },
    changeValue(form, field, value) {
      dispatch(change(form, field, value));
    },
    toggleModalConfirmAge() {
      dispatch(toggleModalConfirmAge());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'AccountForm', validate})(LoginForm));
