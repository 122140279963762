// Dependencies
import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';

//Containers
import LotteriesPageContainer from './../../containers/Lotteries/LotteriesPageContainer';

//Actions
import { getPageInfo } from './../../reducers/generalReducer';

//images
import Loteria from '../../assets/images/icon-loterie.svg';


class Lotteries extends Component {
  render() {
    const { info } = this.props;
    return (
      <Col xs={12} className="homeContainer">
        <Row>
          <Container className="bg-primary return-seccion">
            <section className="mt-5 mb-4 p-4 border-container">
              <h3 className="first-title text-center my-4 interna-resultados">Loterías</h3>
              <div className="font-montserrat-light text-center" dangerouslySetInnerHTML={{__html: info.description}} />
            </section>
            <section>
              <LotteriesPageContainer />
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error } = state.general.pages;
  return {
    loading,
    error,
    info: getPageInfo(state, 'loterias')
  };
};

export default connect(mapStateToProps)(Lotteries);
