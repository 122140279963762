export const contactConstants = {
  SAVE_PQRS_REQUEST: 'SAVE_PQRS_REQUEST',
  SAVE_PQRS_SUCCESS: 'SAVE_PQRS_SUCCESS',
  SAVE_PQRS_FAILURE: 'SAVE_PQRS_FAILURE',
  SAVE_PQRS_RESET: 'SAVE_PQRS_RESET',

  LIST_PQRS_REQUEST: 'LIST_PQRS_REQUEST',
  LIST_PQRS_SUCCESS: 'LIST_PQRS_SUCCESS',

  SAVE_SERVICE_REQUEST: 'SAVE_SERVICE_REQUEST',
  SAVE_SERVICE_SUCCESS: 'SAVE_SERVICE_SUCCESS',
  SAVE_SERVICE_FAILURE: 'SAVE_SERVICE_FAILURE',
  SAVE_SERVICE_RESET: 'SAVE_SERVICE_RESET',

  LIST_SERVICE_REQUEST: 'LIST_SERVICE_REQUEST',
  LIST_SERVICE_SUCCESS: 'LIST_SERVICE_SUCCESS',

  SAVE_QUESTIONS_REQUEST: 'SAVE_QUESTIONS_REQUEST',
  SAVE_QUESTIONS_SUCCESS: 'SAVE_QUESTIONS_SUCCESS'
};
export const tratamientos = {
  PENDIENTE: 'P',
  SI: 'S'
};

export const terminos = {
  PENDIENTE: 'P',
  SI: 'S'
};
