import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';

//Components
import SubpageBlock from './../../containers/Products/SubpageBlock';

//Actions
import { getPageInfo } from './../../reducers/generalReducer';

const ProductsPageContainer = ({ info }) => {
  return (
    <Col xs={12} className="homeContainer">
      <Row>
        <Container className="bg-primary return-seccion">
          <section className="mt-5 mb-4 p-4 border-container">
            <h3 className="first-title text-center my-4 interna-resultados">Nuestros Productos</h3>
            <Row className="align-center pb-5">
              <Col lg={7}>
                <h4 className="mb-4 text-left text-bold">{info.title}</h4>
                <article dangerouslySetInnerHTML={{ __html: info.description }} />
              </Col>
              <Col lg={5} className="pr-0">
                <figure class="ratio-2-1">
                  <img src={info.image} className="d-block w-100" />
                </figure>
              </Col>
            </Row>
            <div>
              {info.subpages && info.subpages.length > 0 && info.subpages.map((subpage) => (
                <SubpageBlock key={subpage.id} subpage={subpage} />
              ))}
            </div>
          </section>
        </Container>
      </Row>
    </Col>
  );
};

const mapStateToProps = (state) => {
  const { loading, error } = state.general.pages;
  return {
    loading,
    error,
    info: getPageInfo(state, 'nuestros-productos')
  };
};

export default connect(mapStateToProps)(ProductsPageContainer);
