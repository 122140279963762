import { driedConstants } from './../constants/DriedConstants';
import DriedService from './../services/DriedService';
//====================================================================
// Loading Home Results
//====================================================================

export const loadDried = (values) => (dispatch) => {

  const driedRequest = () => ({
    type: driedConstants.LIST_DRIED_REQUEST
  });

  const driedSuccess = payload => ({
    type: driedConstants.LIST_DRIED_SUCCESS,
    payload
  });

  const driedError = error => ({
    type: driedConstants.LIST_DRIED_FAILURE,
    error
  });

  dispatch(driedRequest());
  DriedService.loadListDried(values)
    .then(
      listDried => {
        //verificar que venga el listado de secos correctamente y validar si viene como objeto o como array
        listDried = listDried.data.resultadosLoteria ? Array.isArray(listDried.data.resultadosLoteria) ? listDried.data.resultadosLoteria : [listDried.data.resultadosLoteria] : [];
        //Añadimos un ID a cada item por el key de la tabla
        if (listDried.length) {
          listDried.forEach((dried, i) => {
            dried.id = i;
          });
        }
        dispatch(driedSuccess(listDried));
      },
      error => {
        dispatch(driedError(error.message ? error.message : 'Ocurrió un error al momento de traer el listado de secos'));
      }
    );
};
