import React from 'react';

const AllyItem = ({ title, description, phone, address, image }) => {
  return (
    <div className="text-center card-body card-aliados">
      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 card-al-title">
        <img className="d-block card-al-img" src={image} alt="Aliado loticolombia" />
      </div>
      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 card-al-body">
        <p>{title}</p>
        <p className="text-aliado font-montserrat-light" dangerouslySetInnerHTML={{__html: description}} />
        <p className="text-aliado ">Télefono: <span className="font-montserrat-light">{phone}</span></p>
        <p className="text-aliado">Dirección: <span className="font-montserrat-light">{address}</span></p>
      </div>
    </div>
  );
};

export default AllyItem;
