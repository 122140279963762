//Dependencies
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Row } from 'reactstrap';

//Components
import renderField from './../renderFields/renderField';
import renderSelect from './../renderFields/renderSelect';
import renderDatePicker from './../renderFields/renderDatePicker';
import { loadListLotteries } from './../../actions/LotteriesActions';

//Utils
import Validators from './../renderFields/validatorsFields';

class DownloadReportsForm extends Component {

  componentDidMount() {
    this.props.loadListLotteries({
      fecha: null,
      evento: null
    });
  }

  render() {
    const {
      handleSubmit,
      onDelete,
      pristine,
      reset,
      submitting,
      loadingForm,
      readOnly,
      formEditing,
      loadingLotteries,
      listLotteries
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Row className="mb-4">
          <Col xs="12" sm="12" md="6">
            <Field
              name="tipoReporte"
              id="reports-tipoReporte"
              type="select"
              label="Tipo de reporte"
              icon="flaticon-arrows"
              topLabel={formEditing}
              component={renderSelect}
              validate={[Validators.required, Validators.notDefault]}
              readOnly={readOnly}
            >
              <option value="default">Tipo de reporte</option>
              <option value="1">Reporte de ventas </option>
              <option value="2">Reporte premios pagados</option>
            </Field>
          </Col>

          <Col xs="12" sm="12" md="6">
            <Field
              name="ideLoteria"
              id="reports-tipoLoteria"
              type="select"
              label="Lotería"
              icon="flaticon-arrows"
              loadingOptions={loadingLotteries}
              component={renderSelect}
              validate={[Validators.required, Validators.notNull]}
              disabled={!listLotteries || !listLotteries.length}
              readOnly={readOnly}
            >
              <option value="null">Seleccione la Lotería</option>
              {listLotteries && listLotteries.length && listLotteries.map((option, index) => <option value={option.ideLoteria} key={index}>{option.desLoteria}</option>)}
            </Field>
          </Col>

          <Col xs="12" sm="12" md="6">
            <Field
              name="fechaInicio"
              id="reports-fechaInicio"
              icon="flaticon-calendar"
              label="Fecha de inicio"
              inputValueFormat="DD-MM-YYYY"
              dateFormat="DD-MM-YYYY"
              maxDate={moment()}
              topLabel={formEditing}
              format={value => Validators.FormatDate(value, 'DD-MM-YYYY', '-')}
              component={renderDatePicker}
              validate={[Validators.required, Validators.isdate]}
              readOnly={readOnly}
            />
          </Col>

          <Col xs="12" sm="12" md="6">
            <Field
              name="fechaFin"
              id="reports-fechaFin"
              icon="flaticon-calendar"
              label="Fecha de finalización"
              inputValueFormat="DD-MM-YYYY"
              dateFormat="DD-MM-YYYY"
              maxDate={moment()}
              topLabel={formEditing}
              format={value => Validators.FormatDate(value, 'DD-MM-YYYY', '-')}
              component={renderDatePicker}
              validate={[Validators.required, Validators.isdate]}
              readOnly={readOnly}
            />
          </Col>

          <Col xs="12" sm="12" md="6">
            <Field
              name="formato"
              id="reports-formatoReporte"
              type="select"
              label="Formato del reporte"
              icon="flaticon-arrows"
              topLabel={formEditing}
              component={renderSelect}
              validate={[Validators.required, Validators.notDefault]}
              readOnly={readOnly}
            >
              <option value="default">Formato del reporte</option>
              <option value="PDF">PDF</option>
              <option value="XLS">XLS</option>
            </Field>
          </Col>

          <Col xs="12" sm="12" md="6">
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loadingForm}
            >
              Descargar reporte
              {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
            </button>
          </Col>

        </Row>
      </form>
    );
  }

}

const mapStateToProps = (state) =>{
  const { list, loading } = state.lotteries.listLotteries;
  return {
    loadingLotteries: loading,
    listLotteries: list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadListLotteries(values) {
      dispatch(loadListLotteries(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'DownloadReportsForm'})(DownloadReportsForm));
