import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
import Slider from 'react-slick';

// Components
import CircularProgress from '../../components/Global/CircularProgress/CircularProgress';
import { loadPayPrizeHome } from '../../actions/PayPrizeActions';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PaymentAward from '../../components/Payment/PaymentAward';

import { Tab, TabList, Tabs } from 'react-tabs';
import PagoPremioListContainer from '../PagoPremio/PagoPremioContainer';

const CustomPrevArrow = ({ onClick }) => (
  <button className="custom-carousel-arrow custom-carousel-prev-arrow" onClick={onClick} />
);

const CustomNextArrow = ({ onClick }) => (
  <button className="custom-carousel-arrow custom-carousel-next-arrow" onClick={onClick} />
);

const FormPaymentAward = ({ loading, error, payprize, loadPayPrizeHome }) => {
  useEffect(() => {
    loadPayPrizeHome();
  }, [loadPayPrizeHome]);

  const sortedpay = payprize && [...payprize].sort((a, b) => b.ideSeccion - a.ideSeccion);

  const sliderRef = useRef(null);
  const [currentCarousel, setCurrentCarousel] = useState(0);

  const prevCarousel = () => {
    sliderRef.current.slickPrev();
  };

  const nextCarousel = () => {
    sliderRef.current.slickNext();
  };

  const settings = {
    infinite: true,
    arrows: false,
    prevArrow: <CustomPrevArrow onClick={prevCarousel} />,
    nextArrow: <CustomNextArrow onClick={nextCarousel} />,
    initialSlide: currentCarousel,
    beforeChange: (_, nextSlide) => setCurrentCarousel(nextSlide),
    // className: 'custom-carousel' + slidesToShows,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // const renderCarousel = (newsGroup, index) => {
  //   const shouldRenderSlider = newsGroup.length > 3;

  //   if (shouldRenderSlider) {
  //     return (
  //       <Slider ref={sliderRef} key={index} {...settings}>
  //         {newsGroup.map((newsItem, i) => (
  //           <div className="custom-carousel-item" key={i}>
  //             <div className="news-item-wrapper-pay">
  //               <PaymentAward {...newsItem} />
  //             </div>
  //           </div>
  //         ))}
  //       </Slider>
  //     );
  //   } else {
  //     return (
  //       <div className="custom-carousel-item" style={{ display: 'flex', alignItems: 'center' }}>
  //         {newsGroup.map((newsItem, i) => (
  //           <div key={i} className="news-item-wrapper-pay" style={{ marginRight: '20px' }}>
  //             <PaymentAward {...newsItem} />
  //           </div>
  //         ))}
  //       </div>
  //     );
  //   }
  // };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderCarousel = (newsGroup, index) => {
    const shouldRenderSlider = newsGroup.length > 3;
    const shouldRenderButtons = !shouldRenderSlider;

    const shouldRenderSliderPago = windowWidth < 768;
    console.log('Ancho de la pantalla:', shouldRenderSliderPago);

    if (shouldRenderSlider) {
      return (
        <Container>
          <Slider ref={sliderRef} key={index} {...settings}>
            {newsGroup.map((newsItem, i) => (
              <div className="custom-carousel-item" key={i}>
                <div className="news-item-wrapper-pay">
                  <PaymentAward {...newsItem} />
                </div>
              </div>
            ))}
          </Slider>
          {!shouldRenderButtons && (
            <Container>
              <button className="custom-carousel-arrow-pago custom-carousel-prev-arrow-pagos-prev" onClick={prevCarousel}></button>
              <button className="custom-carousel-arrow-pago custom-carousel-next-arrow-pago" onClick={nextCarousel}></button>
            </Container>
          )}
        </Container>
      );
    }

    if (shouldRenderSliderPago) {
      return (
        <Container>
          <Slider ref={sliderRef} key={index} {...settings}>
            {newsGroup.map((newsItem, i) => (
              <div className="custom-carousel-item" key={i}>
                <div className="news-item-wrapper-pay">
                  <PaymentAward {...newsItem} />
                </div>
              </div>
            ))}
          </Slider>
          {shouldRenderButtons && (
            <Container>
              <button className="custom-carousel-arrow-pago custom-carousel-prev-arrow-pagos-premios-prev" onClick={prevCarousel}></button>
              <button className="custom-carousel-arrow-pago custom-carousel-next-arrow-premios-pago" onClick={nextCarousel}></button>
            </Container>
          )}
        </Container>
      );
    } else {
      return (
        <div className="custom-carousel-item" style={{ display: 'flex', alignItems: 'center' }}>
          {newsGroup.map((newsItem, i) => (
            <div key={i} className="news-item-wrapper-pay" style={{ marginRight: '20px' }}>
              <PaymentAward {...newsItem} />
            </div>
          ))}
        </div>
      );
    }
  };

  const ideUsuario = 15;

  const renderCarousels = () => {
    if (!sortedpay) {
      return null;
    }

    const groups = [sortedpay];

    return groups.map((group, index) => (
      <Col className="p-4" xs={12} sm={12} key={index}>
        {renderCarousel(group, index)}
      </Col>
    ));
  };

  return (
    <div className="mt-3 last-child-nmrg-b" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
      {loading ? (
        <Col xs={12}><CircularProgress /></Col>
      ) : (
        <Container>
          <h3 className="first-title text-center my-4 interna-resultados">¿Cómo reclamar tu premio?</h3>
          {renderCarousels()}
          {/* Botón siguiente */}
          <h3 className="first-title text-center interna-resultados pb20">Métodos de pago</h3>
          <Tabs tabs fill>
            <TabList className="react-tabs__tab-list tab-container tab-list">
              <Tab
                className="button-rounded button-rounded-puntos px-20 py5"
              // onClick={() => this.toggle('1')}
              >
                OKI
              </Tab>
              {/* <Tab
                className="button-rounded button-rounded-puntos px-20 py5"
              // onClick={() => this.toggle('2')}
              >
                TRANSFERENCIA
              </Tab> */}
              {/* <Tab
                className="button-rounded button-rounded-puntos px-20 py5"
              // onClick={() => this.toggle('2')}
              >
                POWWI
              </Tab> */}
              <div className="tooltip-container">
                <Tab className="button-rounded button-rounded-puntos-disabled px-20" disabled>
                  POWWI
                  <div className="tooltip">Próximamente</div>
                </Tab>
              </div>
            </TabList>
          </Tabs>
          <PagoPremioListContainer idLotterieURL={ideUsuario} style={{ height: 'auto', maxHeight: 'none' }} />
        </Container>
      )}
      {error && <Alert className="my-2 content-center" color="danger"> {error} </Alert>}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loading, error, payprize } = state.payprize.payprizeHome;
  return {
    loading,
    error,
    payprize
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPayPrizeHome: () => {
      dispatch(loadPayPrizeHome());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPaymentAward);
