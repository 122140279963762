// Dependencies
import React, { Component } from 'react';
import { Col, Row, Container, Alert } from 'reactstrap';
import { connect } from 'react-redux';

//Components
import LotterieWinnerForm from './../../components/Lotteries/LotterieWinnerForm';
import ModalComponent from './../../components/Modal/ModalComponent';
import IsWinnerFail from './../../components/Lotteries/IsWinner/IsWinnerFail';
import IsWinnerSucces from './../../components/Lotteries/IsWinner/IsWinnerSucces';
import { isWinner } from './../../actions/LotteriesActions';

//Actions
import { getPageInfo } from './../../reducers/generalReducer';

class LotterieWinnerContainer extends Component {
  constructor(props) {
    super(props);

    this.handleIsWinner = this.handleIsWinner.bind(this);
  }

  handleIsWinner = values => {
    this.props.isWinner(values);
  }

  render() {
    const { loading, error, success, info } = this.props;
    return (
      <Col xs={12} className="homeContainer return-seccion">
        <Row>
          <Container className="bg-primary">
            <section className="border mt-5 mb-4 p-4 back-white border-container">
              <h3 className="first-title text-center interna-resultados my-5">¿Eres Ganador?</h3>
              <div className="font-montserrat-light text-center text-size-medium mb-5" dangerouslySetInnerHTML={{__html: info.description}} />
              <LotterieWinnerForm
                onSubmit={this.handleIsWinner}
                loadingForm={loading}
              />
              {error &&
                <ModalComponent className="modal-dialog-centered modal-md">
                  <IsWinnerFail />
                </ModalComponent>
              }
              {success &&
                <ModalComponent className="modal-dialog-centered modal-md">
                  <IsWinnerSucces />
                </ModalComponent>
              }
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) =>{
  const { loading, error, success } = state.lotteries.isWinner;
  return {
    loading,
    error,
    success,
    info: getPageInfo(state, 'eres-ganador')
  };
};
const mapDispatchToProps = dispatch => {
  return {
    isWinner(values) {
      dispatch(isWinner(values));
    }
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(LotterieWinnerContainer);
