/*
@Author: Daniel Reyes Betancourt
@Description: Methods to encrypt and decrypt information
@Date: 26/06/2019
@Version: 1.0
*/
import crypto from 'crypto-js';
const sbKey = process.env.REACT_APP_ENCRYPTKEY;
if (!sbKey) {
  throw new Error('Encryption key (sbKey) is not defined in the environment variables.');
}
const iv = crypto.enc.Utf8.parse(sbKey);
const key = crypto.enc.Utf8.parse(sbKey.toString());

/*
@Description: Encrypt a text in AES format
@Params: {sbText} Text to be Encrypted
*/
const encrypt = sbText => {
  try {
    const sbEncryptText = crypto.AES.encrypt(sbText.toString(), key, { iv: iv }).toString();
    return sbEncryptText;
  } catch (error) {
    console.error('ERROR ENCRYPT FRONT', error);
    throw new Error('Error encrypting data');
  }
};

/**
 * @Description: Decrypt a text in AES format
 * @Params: {sbCiphertext} Encrypted text 
 * @returns 
 */
const decrypt = (sbCiphertext) => {
  if (!sbCiphertext) {
    throw new Error('Ciphertext is not provided.');
  }
  try {
    const decryptMessage = crypto.AES.decrypt(sbCiphertext, key, { iv: iv });
    return hex2a(decryptMessage.toString());
  } catch (error) {
    console.error('ERROR DECRYPT FRONT', error);
    throw new Error('Error decrypting data');
  }
};


// Convert hex string to ASCII.
function hex2a(hex) {
  var str = '';
  for (var i = 0; i < hex.length; i += 2) {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }
  return str;
}

const encryptkey = (texto, url = false) => {
  if (!sbKey) {
    throw new Error('Encryption key (sbKey) is not defined.');
  }

  texto = texto.toString();
  let result = '';
  for (let i = 0; i < texto.length; i++) {
    let caracter = texto.substr(i, 1);
    // Verificar que `sbKey` sea una cadena y tiene suficiente longitud
    if (sbKey.length === 0) {
      throw new Error('Encryption key (sbKey) is an empty string.');
    }
    const keychar = sbKey.substr((i % sbKey.length) - 1, 1);
    caracter = String.fromCharCode(caracter.charCodeAt() + keychar.charCodeAt());
    result += caracter;
  }
  result = Buffer.from(result).toString('base64');
  return url ? encodeURIComponent(result) : result;
};




export { encrypt, decrypt, encryptkey };
