import { webConfisyControlarConstants } from '../constants/WebConfisysControlarConstants';
import WebConfisysControlarService from '../services/webConfisysControlarService';

//====================================================================
// Loading web confisys
//====================================================================

export const loadwebConfisysControlar = () => (dispatch, getState) => {

  const webConfisysRequestControlar = () => ({
    type: webConfisyControlarConstants.LIST_CONFISYS_CONTROLAR_REQUEST
  });

  const webConfisysSuccessControlar = payload => ({
    type: webConfisyControlarConstants.LIST_CONFISYS_CONTROLAR_SUCCESS,
    payload
  });

  const webConfisysErrorControlar = error => ({
    type: webConfisyControlarConstants.LIST_CONFISYS_CONTROLAR_FAILURE,
    error
  });

  dispatch(webConfisysRequestControlar());
  const nombreConfisys = process.env.REACT_APP_NOMBRE_CONFISYS_CONTROLAR;
  WebConfisysControlarService.loadListWebConfisysControlar(nombreConfisys)
    .then(
      listWebConfisysControlar => {
        console.log('Eestoy por aca listWebConfisysControlar');
        if (listWebConfisysControlar.data && listWebConfisysControlar.data.length) {
          listWebConfisysControlar.data.forEach(webConfisysControlar => {
            webConfisysControlar.nombre = nombreConfisys;
          });
        }
        dispatch(webConfisysSuccessControlar(listWebConfisysControlar.data));
      },
      error => {
        console.error('Error en la carga de datos desde WebConfisys:', error);
        dispatch(webConfisysErrorControlar(error.message ? error.message : 'Ocurrió un error al momento de traer el listado de premios'));
      }
    );
};
