// Dependencies
import React, { Component } from 'react';
import classnames from 'classnames';
import { Col, Row, Container, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import PqrsContainer from './../../containers/Contact/PqrsContainer';
import ServiceContainer from './../../containers/Contact/ServiceContainer';
import FrequentQuestionsContainer from './../../containers/Contact/FrequentQuestionsContainer';
import { Tab, TabList, Tabs } from 'react-tabs';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1'
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount () {
    const {
      match: {
        params: {
          tab = '1'
        }
      }
    } = this.props;

    this.setState({
      activeTab: (tab !== '1' && tab !== '2' && tab !== '3') ? '1' : tab
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      this.props.history.push(`/contacto/${tab}`);
    }
  }

  render() {
    return (
      <Col xs={12} className="homeContainer">
        <Row>
          <Container className="bg-primary return-seccion">
            <section className="mt-5 mb-4 p-4">
              <h3 className="first-title text-center my-4 interna-loterias">Contáctenos</h3>
              <div className="tabs-page">
                <Tabs tabs fill defaultIndex={this.state.activeTab-1}>
                  <TabList className="react-tabs__tab-list tab-container">
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => this.toggle('1')}
                    >
                      PQRS
                    </Tab>
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => this.toggle('2')}
                    >
                      Calificar servicio
                    </Tab>
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => this.toggle('3')}
                    >
                      Preguntas frecuentes
                    </Tab>
                  </TabList>
                </Tabs>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1" className="p-5 text-center">
                    <PqrsContainer />
                  </TabPane>
                  <TabPane tabId="2" className="p-5 text-center">
                    <ServiceContainer />
                  </TabPane>
                  <TabPane tabId="3" className="p-5 text-center">
                    <FrequentQuestionsContainer />
                  </TabPane>
                </TabContent>
              </div>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}

export default Contact;
