import 'whatwg-fetch';
import { getAuthApp } from '../services/security/authApp';
const serverURL = process.env.REACT_APP_SERVERURL;
const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }
  return response.json();
};

class DriedService {

  static loadListDried(values) {
    const url = `${serverURL}/api/Users/etn/consultar-resultados-completos`;
    const data = JSON.stringify({ lottery: {...values} }, null, 2);

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default DriedService;
