//Dependencies
import React, { Component } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

//Components
import renderField from './../../renderFields/renderField';
import renderSelect from './../../renderFields/renderSelect';
import renderCheckBox from './../../renderFields/renderCheckBox';
import { loadOperators } from './../../../actions/ReloadsActions';

//Utils
import Validators from './../../renderFields/validatorsFields';
import { normalizePhone, normalizeCurrency } from './../../renderFields/normalizeFields';

const labelPoliticas = `<span>
  Acepto los <a href="/terminos-y-condiciones" target="_blank">Términos y condiciones</a> y
  las <a href="/politicas-privacidad" target="_blank">Políticas de tratamiento de datos</a>
</span>
  `;

const validate = values => {
  const errors = {};

  // const valorMoney = values.valor ? values.valor.replace(/[^\d]/g, '') : null;

  // if (!valorMoney) {
  //   errors.valor = 'Este campo es requerido';
  // }

  return errors;
};

class ReloadsPhoneForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      minValueReload: 1000,
      maxValueReload: 100000,
      valueReload:false
    };
  }

  componentDidMount() {
    this.props.loadOperators('N');
  }

  render() {
    const {
      handleSubmit,
      onDelete,
      pristine,
      classForm,
      reset,
      submitting,
      loadingForm,
      readOnly,
      loadingOperators,
      listOperators,
      politicasValue
    } = this.props;

    let myState = {
      minValueReload: 1000,
      maxValueReload: 100000,
      valueReload:false
    };

    return (
      <form onSubmit={handleSubmit} className={`${classForm} form-reloads-phone`}>
        <Row className="mx-0 mb-5 align-items-top">
          <Col xs={12} sm={6} md={4}>
            <Field
              name="celular"
              id="reload-celular"
              type="text"
              icon="flaticon-phone-call"
              label="Número de Celular"
              component={renderField}
              normalize={normalizePhone}
              validate={[Validators.required, Validators.phoneNumber]}
              readOnly={readOnly}
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Field
              name="operador"
              id="reload-operador"
              type="select"
              label="Operador"
              icon="flaticon-arrows"
              component={renderSelect}
              loadingOptions={loadingOperators}
              validate={[Validators.required, Validators.notNull]}
              disabled={!listOperators || !listOperators.length}
              readOnly={readOnly}
              onChange={event => {
                if (event.target.value !== 'null') {
                  const operator = listOperators.filter(item => item.ideProducto === parseInt(event.target.value));
                  this.setState({minValueReload : operator[0].vlrMinimo, maxValueReload: operator[0].vlrMaximo});
                  myState = {
                    minValueReload: operator[0].vlrMinimo,
                    maxValueReload: operator[0].vlrMaximo
                  };
                }
              }}
            >
              <option value="null">Seleccione el Operador</option>
              {listOperators && listOperators.length && listOperators.map(option => <option value={option.ideProducto} key={option.ideProducto}>{option.desProducto}</option>)}
            </Field>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Field
              name="valor"
              id="reload-valor"
              type="text"
              icon="fa fa-usd"
              label="Valor a Recargar"
              normalize={normalizeCurrency}
              component={renderField}
              autoComplete="no"
              validate={[Validators.required,Validators.minMoneyValue(myState.minValueReload),Validators.maxMoneyValue(myState.maxValueReload)]}
              readOnly={readOnly}
              onChange={event=>{
                if (event.target.value !== 'null') {
                  let valorRecarga = event.target.value.split('$ ');
                  valorRecarga=valorRecarga[1];
                  if (parseInt(valorRecarga) >= myState.minValueReload && parseInt(valorRecarga) <= myState.maxValueReload) {
                    myState.valueReload=true;
                    this.setState({valueReload :true});
                  } else {
                    myState.valueReload=false;
                    this.setState({valueReload :false});
                  }
                }
              }}
            />
          </Col>
        </Row>


        <Row className="m-0 justify-content-center align-items-center">

          <Col xs={12} sm={12} md={6} lg={5} xl={8} className="text-center">
            <Field
              name="politicas"
              id="reload-politicas"
              label={labelPoliticas}
              formGroupClass="mb-0"
              component={renderCheckBox}
              validate={[Validators.required]}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={12} className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loadingForm || !politicasValue || !this.state.valueReload}
            >
              Añadir al Carrito
              {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
            </button>
          </Col>
        </Row>


      </form>
    );
  }
}

const selector = formValueSelector('ReloadsPhoneForm');

const mapStateToProps = (state) =>{
  const { loadingOperators, listOperators } = state.reloads.operators;
  return {
    loadingOperators,
    listOperators,
    politicasValue: selector(state, 'politicas')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadOperators(typeReload) {
      dispatch(loadOperators(typeReload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'ReloadsPhoneForm'})(ReloadsPhoneForm));
