import React from 'react';
import { FormGroup, Input, FormFeedback, FormText } from 'reactstrap';

const renderTextArea = ({input, label, icon, meta: {touched, error, warning}, ...rest}) => {

  const getValidationState = () => {
    return error ? false : warning ? false : true;
  };

  return (
    <FormGroup>
      <div className="position-relative form-file-container">
        <textarea
          {...input}
          {...rest}
          placeholder={label} className="form-file"
        />
        <i className={`form-file-icon ${icon}`} />
      </div>
      {touched &&
      ((error && <FormText className="text-left">{error}</FormText>) ||
      (warning && <FormText className="text-left">{warning}</FormText>))}
    </FormGroup>
  );
};


export default renderTextArea;
