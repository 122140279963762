import React from 'react';
import { reduxForm, Field } from 'redux-form';

//Components
import renderField from './../renderFields/renderField';
import renderCaptcha from './../renderFields/renderCaptcha';
import renderSelect from './../renderFields/renderSelect';

//Utils
import Validators from './../renderFields/validatorsFields';

const UpperCase = value => value.toUpperCase();
const RequestChangePasswordForm = ({
  handleSubmit,
  onDelete,
  pristine,
  reset,
  submitting,
  loadingForm,
  readOnly
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="tipoDocumento"
        id="login-tipoDocumento"
        type="select"
        label="Tipo de Documento"
        icon="flaticon-arrows"
        component={renderSelect}
        validate={[Validators.required, Validators.notNull]}
        readOnly={readOnly}
      >
        <option value="null">Tipo de Documento</option>
        <option value="CC">Cédula de  ciudadanía</option>
        <option value="NIT">Num. Ident. Empre</option>
        <option value="PA">Pasaporte</option>
        <option value="CE">Cédula de extranjería</option>
        {/* <option value="OT">Otro</option> */}
        <option value="PD">Pase diplomático</option>
        <option value="CD">Carnet diplomático</option>
        <option value="TE">Tarjeta de extranjería</option>
      </Field>

      <Field
        name="usuario"
        id="login-cedula"
        type="text"
        icon="flaticon-profile"
        label="Cédula de Ciudadania"
        component={renderField}
        normalize={UpperCase}
        validate={[Validators.required, Validators.documentTypeLogin]}
        readOnly={readOnly}
      />
      <Field
        name="captcha"
        component={renderCaptcha}
        validate={[Validators.notrequired]}
      />
      <button
        type="submit"
        className="btn btn-primary"
        disabled={loadingForm}
      >
        Solicitar Cambio
        {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
      </button>
    </form>
  );
};

export default reduxForm({ form: 'RequestChangePasswordForm'})(RequestChangePasswordForm);
