import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import PqrsForm from './../../components/Contact/PQRS/PqrsForm';
import { savePQRS, loadListPQRS } from './../../actions/ContactActions';
import { tratamientos } from './../../constants/ContactConstants';

//Images
import iconPQRS from './../../assets/images/Icon-pqrs.svg';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';

//Actions
import { getPageInfo } from './../../reducers/generalReducer';

class PqrsContainer extends Component {
  constructor(props) {
    super(props);

    this.handleOnSave = this.handleOnSave.bind(this);
  }

  handleOnSave = values => {
    // const radio = this.props.info.list.find(item => item.value === values.issue);
    // values.issue = radio.title;
    values.tratamientos = values.tratments2 ? tratamientos.SI : tratamientos.PENDIENTE;
    delete values.tratments2;
    this.props.savePQRS(values);
  };

  componentDidMount () {
    this.props.loadListPQRS();
  }

  render() {
    const { save, info, textInfo } = this.props;
    const fields = {
      issue: '2'
    };
    return (
      <section>

        <div className="media align-items-center mb-4">
          <img className="mr-4" src={iconPQRS} />
          <div className="media-body">
            <h4 className="mt-0 text-left mb-3 interna-loterias">{textInfo.title}</h4>
            <div className="text-justify font-montserrat-light text-size-small" dangerouslySetInnerHTML={{__html: textInfo.description}} />
          </div>
        </div>
        <h4 className="mb-4 text-left interna-loterias">Selecciona una opción:</h4>
        {info.loading && <Row><CircularProgress className="w-100"/></Row>}
        {info.list && info.list.length ?
          <Row className="justify-content-center  text-justify">
            <Col xs="12">
              <PqrsForm
                onSubmit={this.handleOnSave}
                loadingForm={save.loading}
                initialValues={fields}
                radioList={info.list}
              />
              {save.error && <Alert className="mt-3 mb-0" color="danger"> {save.error} </Alert>}
              {save.success && <Alert className="mt-3 mb-0" color="success"> Su mensaje ha sido registrado exitosamente </Alert>}
            </Col>
          </Row> : !info.loading &&
          <Alert className="mt-3 mb-0" color="light">Ocurrio un error al traer la información</Alert>
        }
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { save, info } = state.contact.pqrs;
  return {
    save,
    info,
    textInfo: getPageInfo(state, 'pqrs')
  };
}

const mapDispatchToProps = dispatch => {
  return {
    savePQRS(values) {
      dispatch(savePQRS(values));
    },
    loadListPQRS() {
      dispatch(loadListPQRS());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PqrsContainer);
