//Dependencies
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

//Components
import renderField from './../renderFields/renderField';
import renderSelect from './../renderFields/renderSelect';
import renderDatePicker from './../renderFields/renderDatePicker';
import { loadListLotteries } from './../../actions/LotteriesActions';
import arrow from '../../assets/images/arrow.svg';
import calendar from '../../assets/images/calendar.svg';

//Utils
import Validators from './../renderFields/validatorsFields';
import { normalizeDate } from '../renderFields/normalizeFields';

class LotterieWinnerForm extends Component {

  componentDidMount() {
    this.props.loadListLotteries({
      fecha: null,
      evento: null
    });
  }

  render() {
    const {
      handleSubmit,
      onDelete,
      pristine,
      classForm,
      reset,
      submitting,
      loadingForm,
      readOnly,
      loadingLotteries,
      listLotteries
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className={`${classForm} form-reloads-phone`}>
        <Row className="mx-0 mb-5 justify-content-left">
          <Col sm={16} md={16} lg={16}>
            <Row className="align-items-top mb-12">
              <Col xs={12} sm={6}>
                <h6 className="text-left font-size-1rem">Seleccione la lotería</h6>
                <Field
                  name="ideLoteria"
                  id="winner-ideLoteria"
                  type="select"
                  label="Lotería"
                  iconimg={arrow}
                  loadingOptions={loadingLotteries}
                  component={renderSelect}
                  validate={[Validators.required, Validators.notNull]}
                  disabled={!listLotteries || !listLotteries.length}
                  readOnly={readOnly}
                >
                  <br /><br />
                  <option value="null">Lotería</option>
                  {listLotteries && listLotteries.length && listLotteries.map((option, index) => <option value={option.ideLoteria} key={index}>{option.desLoteria}</option>)}
                </Field>
              </Col>
              <Col xs={12} sm={6}>
                <h6 className="text-left">Fecha de sorteo</h6>
                <Field
                  name="fecha"
                  id="winner-fecha"
                  iconimg={calendar}
                  label="Fecha"
                  inputValueFormat="DD-MM-YYYY"
                  dateFormat="DD-MM-YYYY"
                  format={value => Validators.FormatDate(value)}
                  normalize={normalizeDate}
                  maxDate={moment()}
                  component={renderDatePicker}
                  validate={[Validators.required, Validators.isdate]}
                  readOnly={readOnly}
                />
              </Col>
              <Col xs={12} sm={6}>
                <h6 className="text-left">Número Jugado</h6>
                <Field
                  name="numero"
                  id="winner-numero"
                  type="number"
                  label="ej.1234"
                  component={renderField}
                  validate={[Validators.required, Validators.minLength3, Validators.maxLength4]}
                  readOnly={readOnly}
                />
              </Col>
              <Col xs={12} sm={6} >
                <h6 className="text-left">Serie</h6>
                <Field
                  name="serie"
                  id="winner-serie"
                  type="number"
                  label="ej.123"
                  component={renderField}
                  validate={[Validators.required, Validators.minLength3, Validators.maxLength3]}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row className="align-items-top">

              <Col xs={12} sm={12} className={'justify-center posiciones'}>
                <button
                  type="submit"
                  className="button-rounded button-rounded-consultar"
                  disabled={loadingForm}
                >
                  <div className="align-items-center">
                    <span >Consultar Resultados</span>
                    {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block color-black" />}
                  </div>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { list, loading } = state.lotteries.listLotteries;
  return {
    loadingLotteries: loading,
    listLotteries: list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadListLotteries(values) {
      dispatch(loadListLotteries(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'LotterieWinnerForm' })(LotterieWinnerForm));
