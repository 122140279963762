import EventEmitter from 'eventemitter3';


var Response$ = new EventEmitter();
var RespFavorite$ = new EventEmitter();
var MyFavoriteNumber$ = new EventEmitter();
var readFavorite$ = new EventEmitter();
var numberFavoriteSeries$ = new EventEmitter();
var newNumberMajor$ = new EventEmitter();






export default ( Response$, RespFavorite$, MyFavoriteNumber$, readFavorite$, numberFavoriteSeries$, newNumberMajor$ );