import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import DeleteAccountForm from './../../components/Account/DeleteAccountForm';
import { deleteUser, logoutUser, deleteUserClear } from './../../actions/UserActions';
import moment from 'moment';

class DeleteAccountContainer extends Component {
  constructor(props) {
    super(props);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.clearMessage();
  }

  handleOnSave = formValues => {
    const values = JSON.parse(JSON.stringify(formValues));
    this.props.deleteUser(values);

  };
  logout = () => {
    const thes=this;
    setTimeout(()=> {
      thes.props.logoutUser();
    },2000);
  }
  clearMessage = () => {
    this.props.deleteUserClear();
  }

  render() {
    const { loading, error, success, user } = this.props;
    const fields = {
      ...user
    };
    return (
      <section className="nosotros-section">
        <h3 className="first-title text-center mt-4 mb-5 interna-resultados">Eliminar cuenta</h3>
        <p className="text-size-small">Si crees que no volverás a usar LotiColombia y quieres eliminar tu cuenta, podemos hacerlo por ti. Ten en cuenta que no podrás
         volver a activarla ni recuperar ningún dato o contenido que hayas subido a tu cuenta.</p>
        <p className="text-size-small">Si aún quieres eliminar tu cuenta, confírmanos tu motivo y haz clic en "Eliminar mi cuenta"</p>

        <Row className="justify-content-center mt30 mb30">
          <Col xs="12">
            {(!success || (success && success.mensaje =='El saldo del usuario debe ser  0 par poder cancelar la cuenta')) &&
              <DeleteAccountForm
                onSubmit={this.handleOnSave}
                loadingForm={loading}
                initialValues={fields}
              />
            }
            {error && <Alert className="mt-3 mb-0" color="danger"> {error} </Alert>}
            {success && success.mensaje !='El saldo del usuario debe ser  0 par poder cancelar la cuenta' &&
              <Alert className="mt-3 mb-0" color="success">{success.mensaje}{this.logout()}</Alert>
            }
            {success && success.mensaje=='El saldo del usuario debe ser  0 par poder cancelar la cuenta' &&
              <Alert className="mt-3 mb-0" color="danger"> {success.mensaje}</Alert>
            }
          </Col>
        </Row>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { loading, error, success } = state.confirmAccount.delete;
  const { user } = state.authentication;
  return {
    loading,
    error,
    success,
    user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    deleteUser(values) {
      dispatch(deleteUser(values));
    },
    logoutUser() {
      dispatch(logoutUser());
    },
    deleteUserClear() {
      dispatch(deleteUserClear());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountContainer);
