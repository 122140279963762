import { ganaPointsConstants } from './../constants/GanaPointsConstants';
import GanaPointsService from './../services/GanaPointsService';
import UserService from './../services/UserService';
//====================================================================
// Loading Cards
//====================================================================

export const loadCards = () => (dispatch) => {

  const ganaPointsRequest = () => ({
    type: ganaPointsConstants.GANA_POINTS_REQUEST
  });

  const ganaPointsSuccess = payload => ({
    type: ganaPointsConstants.GANA_POINTS_SUCCESS,
    payload
  });

  const ganaPointsError = error => ({
    type: ganaPointsConstants.GANA_POINTS_FAILURE,
    error
  });

  dispatch(ganaPointsRequest());
  GanaPointsService.loadCards()
    .then(
      results => {
        dispatch(ganaPointsSuccess(results));
      },
      error => {
        dispatch(ganaPointsError(error.message ? error.message : 'Ocurrió un error al momento de cargar las tarjetas'));
      }
    );

};

//====================================================================
// Loading User Points
//====================================================================

export const loadUserPoints = () => (dispatch, getState) => {

  const user = getState().authentication.user;
  if (user) {
    const body = {
      'token': user.token,
      'tipoDocumento': user.tipoDocumento,
      'usuario': user.usuario
    };

    const userPointsRequest = () => ({
      type: ganaPointsConstants.USER_POINTS_REQUEST
    });

    const userPointsSuccess = payload => ({
      type: ganaPointsConstants.USER_POINTS_SUCCESS,
      payload
    });

    const userPointsError = error => ({
      type: ganaPointsConstants.USER_POINTS_FAILURE,
      error
    });

    dispatch(userPointsRequest());
    UserService.getPuntos(body)
      .then(
        results => {
          dispatch(userPointsSuccess(results));
        },
        error => {
          if (error === 'Unauthorized') {
            dispatch(userPointsError('Debes de volver a iniciar sesión'));
          } else {
            dispatch(userPointsError(error.message ? error.message : 'Ocurrió un error al momento de cargar los puntos del usuario. Inténtalo más tarde'));
          }
        }
      );
  }
};
