import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { change } from 'redux-form';
import moment from 'moment';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';
import { loadResultsHome } from './../../actions/ResultsActions';
import SingleResult from './../../components/Results/SingleResult';
import LotterieForm from './../../components/Lotteries/LotterieForm';

//Constants

class ResultsHomeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateResults: null
    };

    this.handleSearchResults = this.handleSearchResults.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dateparam !== this.props.dateparam && nextProps.dateparam !== this.state.dateResults) {
      this.props.loadResultsHome({
        fecha: nextProps.dateparam
      });
      this.props.changeValue('LotterieForm', 'fecha', (nextProps.dateparam === 'null' ? null : nextProps.dateparam));
    }
  }

  handleSearchResults = values => {
    if (moment.isMoment(values.fecha)) {
      values.fecha = values.fecha.format('DD-MM-YYYY');
    }
    this.props.loadResultsHome({
      fecha: values.fecha || null,
      ideLoteria: (values.loteria && values.loteria != 'null') ? values.loteria : undefined
    }, null);
    this.props.history.push(`/resultados/${values.fecha ? values.fecha : 'null'}`);
    this.setState({
      dateResults: values.fecha
    });
  }

  componentDidMount() {

    this.props.loadResultsHome({
      fecha: this.props.dateparam
    });
    this.setState({
      dateResults: this.props.dateparam
    });
  }

  render() {
    const { loading, error, results, dateparam } = this.props;
    const initialValues = {
      fecha: dateparam === 'null' ? null : dateparam
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    return (
      <Row>
        <Col xs={12} className="mb-4">
          <LotterieForm
            onSubmit={this.handleSearchResults}
            loadingForm={loading}
            initialValues={initialValues}
          />
        </Col>
        <Col xs={12}>
          {loading && <CircularProgress />}
          <Row className="align-items-center">
            {results && !loading && (results.length > 0 ? results.filter(result => result.numero).map((result, i) => {
              //result.serie = parseInt(result.serie);
              const planes = result.ideClaseLoteria == 1 ? true : false;
              return (
                <Col key={i} xs={12} sm={6} className="result-single-page mb-4 py-30">
                  <SingleResult key={i} data={result} icon={`${baseURL}/assets/images/loterias/${result.sigaLoteria || result.siglaLoteria}.png`} complete={planes}
                    tradicional={!planes}
                  />
                </Col>
              );
            }) : <Alert className="my-2 content-center" color="light"> No se encontraron resultados para la fecha. </Alert>)}
          </Row>
          {error && <Alert className="my-2 content-center" color="danger"> {error} </Alert>}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, results } = state.results.resultsHome;
  return {
    loading,
    error,
    results
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadResultsHome(values, completos) {
      dispatch(loadResultsHome(values, completos));
    },
    changeValue(form, field, value) {
      dispatch(change(form, field, value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResultsHomeContainer));
