// Dependencies
import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';

//Components
import PrizePlanListContainer from './../../containers/PrizePlan/PrizePlanListContainer';

class PrizePlan extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      match: {
        params: {
          idLotterie = 0
        }
      }
    } = this.props;
    return (
      <Col xs={12} className="homeContainer return-seccion">
        <Row>
          <Container>
            <section className="mt-5 mb-4 p-4">
              <h3 className="first-title text-center my-4 interna-resultados"><span className="color-white">Plan de Premios</span></h3>
              <PrizePlanListContainer idLotterieURL={idLotterie}/>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}

export default PrizePlan;
