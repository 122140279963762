import { combineReducers } from 'redux';

import { account } from './accountReducer';
import { authentication } from './authenticationReducer';
import { user } from './userReducer';
import { registration } from './registrationReducer';
import { alert } from './alertReducer';
import { results } from './resultsReducer';
import { lotteries } from './lotteriesReducer';
import { modal } from './modalReducer';
import { prizes } from './prizePlanReducer';
import { premio } from './pagoPremioReducer';
import { confisys } from './webConfisysReducer';
import { confisysControlar } from './webConfisysControlarReducer';
import { recarga } from './recargaOkiReducer';
import { reclamo } from './reclamoPremioReducer';
import { dried } from './driedReducer';
import { cart } from './cartReducer';
import { contact } from './contactReducer';
import { confirmAccount } from './confirmAccountReducer';
import { reloads } from './reloadsReducer';
import { changePassword } from './changePasswordReducer';
import { locations } from './locationsReducer';
import { news } from './newsReducer';
import { slider } from './sliderReducer';
import { general } from './generalReducer';
import { game } from './gameReducer';
import { order } from './orderReducer';
import { ganaPoints } from './ganaPointsReducer';
import { payprize } from './payprizeReducer';
import { homepayprize } from './homePayprizeReducer';

import { reducer as reduxForm } from 'redux-form';

export default combineReducers({
  account,
  authentication,
  user,
  alert,
  results,
  contact,
  confirmAccount,
  modal,
  game,
  reloads,
  order,
  dried,
  prizes,
  premio,
  confisys,
  recarga,
  reclamo,
  lotteries,
  registration,
  changePassword,
  news,
  slider,
  locations,
  cart,
  general,
  ganaPoints,
  payprize,
  homepayprize,
  confisysControlar,
  form: reduxForm
});
