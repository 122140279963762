// Dependencies
import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Alert, CardText } from 'reactstrap';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { history } from '../../helpers/history';

//Components
import renderInputGame from './renderInputGame';
import renderInputModal from './renderInputModal';

import renderFieldLabel from './../renderFields/renderFieldLabel';
import renderRadioButtonGame from './renderRadioButtonGame';
import renderCheckBoxGame from './renderCheckBoxGame';
import CircularProgress from './../Global/CircularProgress/CircularProgress';
import { setLotteryToPlay } from './../../actions/GameActions';


//Actions
import { loadListSeries, loadSingleSerie, setDisabledFractions, setEnabledFractions, clearListSeries, loadListNumbers, loadListNumbersLargestSmallest } from './../../actions/GameActions';

//Utils
import Validators from './../renderFields/validatorsFields';
import { formatMoney } from './../../helpers/formatMoney';
import { normalizeNumbers } from './../renderFields/normalizeFields';

import MyFavoriteNumber$ from './../../events/emitter';
import numberFavoriteSeries$ from './../../events/emitter';
import newNumberMajor$ from './../../events/emitter';
import { loadLotteriesHome } from './../../actions/LotteriesActions';





const baseURL = process.env.REACT_APP_BASEURL;
let alternativeNumber;
//let initialValueNumber;

let altenativeLotery;
let selectSerieValue;
let selectSerieLotery;
let numberMajor = [];
let validateFlag;
let valueContent = '';
let flagLotery;


const validate = (values, props) => {
  const errors = {};

  const error = props.listFractions.some((fraction, index) => {
    return (!fraction.disabled ? (values[`fraccion-${index}`] ? true : false) : false);
  });
  if (!error) {
    errors['fraccion-0'] = 'Debes seleccionar por lo menos una fracción';
  }

  for (let i = 1; i <= 4; i++) {
    if (!values[`numero${i}`]) {
      errors.numero1 = 'Debes completar los 4 números';
      break;
    }
  }

  return errors;
};

const GameForm = (props) => {
  const cargar = () => {
    const numeroFavorito = localStorage.getItem('numero');
    const serieFavorita = localStorage.getItem('serieFav');

    setTimeout(() => {
      const radioButton = document.querySelectorAll(`input[value="${serieFavorita}"]`);
      if (radioButton.length > 0) {
        const firstRadioButton = radioButton[0]; firstRadioButton.click();
      } else {
        const textInputs = document.querySelectorAll('.custom-radio-button input[type="text"]');

        if (textInputs.length > 0) {
          const nextInput = textInputs[0];
          nextInput.click();

          const inputEvent = new Event('input', { bubbles: true });
          nextInput.value = serieFavorita;
          nextInput.dispatchEvent(inputEvent);


          setTimeout(() => {

            const [num1, num2, num3, num4] = numeroFavorito.split('-');
            props.changeValue('GameForm', 'numero1', num1);
            props.changeValue('GameForm', 'numero2', num2);
            props.changeValue('GameForm', 'numero3', num3);
            props.changeValue('GameForm', 'numero4', num4);

            const values = {
              'ideAplicacion': '7',
              'numero': [num1, num2, num3, num4].join(''),
              'ideLoteria': props.idLotterie,
              'sorteo': props.sorteoLotterie,
              'serie': serieFavorita
            };

            props.changeValue('GameForm', 'serie', null);
            props.loadListSeries(values);

            setTimeout(() => {
              const radioButton = document.querySelectorAll(`input[value="${serieFavorita}"]`);
              if (radioButton.length > 0) {
                const firstRadioButton = radioButton[0]; firstRadioButton.click();
              } else {
                console.log('2222555NS');
              }
            }, 2200);

          }, 1000);


        } else {
          console.log('2222555NT');
        }
      }
    }, 2200);

    if (numeroFavorito) {
      const [num1, num2, num3, num4] = numeroFavorito.split('-');
      props.changeValue('GameForm', 'numero1', num1);
      props.changeValue('GameForm', 'numero2', num2);
      props.changeValue('GameForm', 'numero3', num3);
      props.changeValue('GameForm', 'numero4', num4);

      const values = {
        'ideAplicacion': '7',
        'numero': [num1, num2, num3, num4].join(''),
        'ideLoteria': props.idLotterie,
        'sorteo': props.sorteoLotterie,
        'serie': (typeof event === 'string' ? event : null)
      };
      if (typeof event === 'string') {
        props.loadSingleSerie(values);
      } else {
        props.changeValue('GameForm', 'serie', null);
        props.loadListSeries(values);

      }
      localStorage.removeItem('numero');
      localStorage.removeItem('serieFav');

    };
  };

  const [loadingLotteries, setLoadingLotteries] = useState(false);


  useEffect(() => {
    props.clearListSeries();
    cargar();
  }, []);

  const handleChangeInputField = (event) => {
    const keys = Object.keys(props.valuesInputNumber);
    const isvalid = keys.every((value) => {
      return props.valuesInputNumber[value];
    });
    if (keys.length === 4 && isvalid) {
      const values = {
        'ideAplicacion': '7',
        'numero': Object.values(props.valuesInputNumber).join(''),
        'ideLoteria': props.idLotterie,
        'sorteo': props.sorteoLotterie,
        'serie': (typeof event === 'string' ? event : null)
      };
      if (typeof event === 'string') {
        const valuesNumberLargestSmallest = {
          'numero': Object.values(props.valuesInputNumber).join(''),
          'ideLoteria': props.idLotterie,
          'sorteo': props.sorteoLotterie,
          'serie': event
        };
        props.loadListNumbersLargestSmallest(valuesNumberLargestSmallest);
        props.loadSingleSerie(values);
      } else {
        props.loadListNumbers(values);
        props.changeValue('GameForm', 'serie', null);
        props.loadListSeries(values);
      }

      props.changeValue('GameForm', 'cartVal', 0);

    }

    if (!props.lotteries || props.lotteries.length === 0) {
      if (!loadingLotteries) {
        setLoadingLotteries(true);

        props.loadLotteriesHome({
          fecha: '11-04-2018',
          evento: null
        });
      }
    }

  };

  useEffect(() => {
    if (loadingLotteries && props.lotteries) {
      setLoadingLotteries(false); // Restablecer el estado de carga una vez que se completa la carga
    }
  }, [loadingLotteries, props.lotteries]);

  const handleSelectAllFractions = (event) => {
    let valueCart = 0;
    props.listFractions.forEach((fraction, index) => {
      if (!fraction.disabled) {
        valueCart += props.lottery.vlrFraccion;
        props.changeValue('GameForm', `fraccion-${index}`, true);
      }
    });

    props.changeValue('GameForm', 'cartVal', valueCart);
  };

  const handleChangeSerie = (event) => {

    if (event.target.value) {
      const serie = series.list.filter(item => item.serie === event.target.value)[0];
      if (serie.cantFracciones < listFractions.length) {
        props.setDisabledFractions(serie.cantFracciones);
      } else {
        props.setEnabledFractions(serie.cantFracciones);
      }
      listFractions.forEach((item, index) => {
        props.changeValue('GameForm', `fraccion-${index}`, false);
      });
      props.changeValue('GameForm', 'cartVal', 0);
    }
  };


  const handleChangeSerieInput = (event, serie) => {
    selectSerieLotery = serie;
    selectSerieValue = event.target.value;
  };

  const textInputNumber1 = useRef(null);
  const textInputNumber2 = useRef(null);
  const textInputNumber3 = useRef(null);
  const textInputNumber4 = useRef(null);


  const handleFocusTextInput = (textNumber) => {
    if (textNumber && textNumber?.current) {
      console.log("textNumber Definido " + textNumber.current);
      textNumber.current.focus();
    }
    else {
      console.log("textNumber No definido correctamente");
    }

    const keys = Object.keys(props.valuesInputNumber);
    if (keys.length === 4) {
      setAlternativeNumber(false);
    }
  };

  const {
    handleSubmit,
    onSubmit,
    onDelete,
    pristine,
    fields,
    classForm,
    reset,
    submitting,
    loadingForm,
    readOnly,
    changeValue,
    series,
    singleSerie,
    lottery,
    valueSerie,
    cartValue,
    listFractions,
    setLotteryToPlay,
    lotteries,
    loadLotteriesHome
  } = props;

  const [altenativeLotery, setAltenativeLotery] = useState([]);
  const [alternativeNumber, setAlternativeNumber] = useState();
  const [idLoteriaSet, setIdLoteriaSet] = useState(new Set());


  numberFavoriteSeries$.on('myEventSerie', (data) => {

    setAltenativeLotery(data.data);

  });

  useEffect(() => {
    setTimeout(() => {
      if (series.error || singleSerie.error) {
        setAlternativeNumber(true);
      } else {
        setAlternativeNumber(false);
      }

      idLoteriaSet.clear();
    }, 500);
  }, [altenativeLotery, series.error, singleSerie.error]);


  const numbers = Array.from({ length: 4 }, (_, i) => i + 1);

  newNumberMajor$.on('myEventMajor', (data) => {
    const numberMajorData = data.data;
    const allValues = [];

    for (const key in numberMajorData) {
      if (numberMajorData.hasOwnProperty(key)) {
        const value = numberMajorData[key];
        allValues.push({ value });
      }
    }

    if (validateFlag !== true) {
      setTimeout(() => {
        numberMajor = allValues;
        validateFlag = true;
        if (numberMajor.length > 0) {
          valueContent = numberMajor[0].value.numeroSerie;
          toggleModal();
          setAlternativeNumber(true);
          idLoteriaSet.clear();
        }
      }, 1000);
      setTimeout(() => {
        validateFlag = false;

      }, 5000);
    }
  });





  const handleOnClickNewSerie = async () => {

    if (!props.lotteries || props.lotteries.length === 0) {
      if (!loadingLotteries) {
        setLoadingLotteries(true);

        await props.loadLotteriesHome({
          fecha: '11-04-2018',
          evento: null
        });
      }
    }

    const newLotteries = await props.lotteries;

    if (selectSerieLotery) {
      const selectedLoti = newLotteries.filter(lotterie => lotterie.ideLoteria == selectSerieLotery.idLoteria && lotterie.sorteo == selectSerieLotery.numeroSorte)[0];

      if (selectedLoti) {
        setLotteryToPlay(selectedLoti);

        history.replace(`/juego/${selectSerieLotery.idLoteria}/${selectSerieLotery.numeroSorte}`);
        history.push(`/juego/${selectSerieLotery.idLoteria}/${selectSerieLotery.numeroSorte}`);
        history.go();

        const caracteres = selectSerieLotery.numeroFrecuente.split('');
        const cadenaConGuion = caracteres.join('-');
        const serie = selectSerieLotery.numeroSerie;
        localStorage.setItem('numero', cadenaConGuion);
        localStorage.setItem('serieFav', serie);
      }
    }

  };




  const handleOnClickModalMajor = async (selectedValue) => {

    if (!props.lotteries || props.lotteries.length === 0) {
      if (!loadingLotteries) {
        setLoadingLotteries(true);

        await props.loadLotteriesHome({
          fecha: '11-04-2018',
          evento: null
        });
      }
    }

    const newLotteries = await props.lotteries;
    const selectedLoti = newLotteries.filter(lotterie => lotterie.ideLoteria == String(selectedValue.idLoteria) && lotterie.sorteo == String(selectedValue.proximoSorteo))[0];

    if (selectedLoti) {
      setLotteryToPlay(selectedLoti);

      history.replace(`/juego/${String(selectedValue.idLoteria)}/${String(selectedValue.proximoSorteo)}`);
      history.push(`/juego/${String(selectedValue.idLoteria)}/${String(selectedValue.proximoSorteo)}`);
      history.go();

      const caracteres = String(selectedValue.numeroFrecuente).split('');
      const cadenaConGuion = caracteres.join('-');
      const serie = selectedValue.numeroSerie;
      localStorage.setItem('numero', cadenaConGuion);
      localStorage.setItem('serieFav', serie);
    }

  };


  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSerie, setSelectedSerie] = useState(null);




  // Tu función para controlar la apertura/cierre de la modal
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  /*   
  // Cuando la variable externa cambie, actualiza el estado de la modal
  useEffect(() => {
    if (numberMajor.length > 0) {
      toggleModal();
    }
  }, [numberMajor]); */

  const handleOnCancel = () => {
    toggleModal();
  };


  return (
    <form className='return-seccion'>
      <Row className="mt-5">
        <Col xs={12} sm={12} md={3} lg={4} className="mb-4">
          <section className="gameContainer-logo p-4 h-100 text-center single-loteri">
            <img className="content-center image-lotterie d-block mb-4 w-100" src={`${baseURL}/assets/images/loterias/${lottery.abreviatura}.png`} />
            <Link className="button-rounded button-rounded-gradient-jugar" to="/loterias">
              <span>Cambiar</span>
            </Link>
          </section>
        </Col>
        <Col xs={12} sm={12} md={9} lg={8} className="mb-4">
          <section className="single-loteri p-4 back-white border-container h-100 section-game box-shadow-md">
            <div className="game-number">1</div>
            <h3 className="text-center first-title mb-4 interna-loterias">Número</h3>
            <p className="text-center mb-4">Escribe el número por el cual deseas apostar</p>
            <div className="input-group justify-content-center text-center">
              <Row className="mx-0 mb-4">
                <Col xs={3} sm={3} md={3} lg={3}>
                  <Field
                    name="numero1"
                    id="game-numero1"
                    type="text"
                    inputRef={textInputNumber1}
                    normalize={normalizeNumbers}
                    label="0"
                    disabled={series.loading}
                    component={renderInputGame}
                    readOnly={readOnly}
                    onChange={(event) => {
                      if (event.target.value) {
                        handleFocusTextInput(textInputNumber2);
                      }
                    }}
                    onBlur={handleChangeInputField}
                  />
                </Col>
                <Col xs={3} sm={3} md={3} lg={3}>
                  <Field
                    name="numero2"
                    id="game-numero2"
                    type="text"
                    inputRef={textInputNumber2}
                    normalize={normalizeNumbers}
                    label="0"
                    disabled={series.loading}
                    component={renderInputGame}
                    readOnly={readOnly}
                    onChange={(event) => {
                      if (event.target.value) {
                        handleFocusTextInput(textInputNumber3);
                      } else {
                        handleFocusTextInput(textInputNumber1);
                      }
                    }}
                    onBlur={handleChangeInputField}
                  />
                </Col>
                <Col xs={3} sm={3} md={3} lg={3}>
                  <Field
                    name="numero3"
                    id="game-numero3"
                    type="text"
                    inputRef={textInputNumber3}
                    normalize={normalizeNumbers}
                    label="0"
                    disabled={series.loading}
                    component={renderInputGame}
                    readOnly={readOnly}
                    onChange={(event) => {
                      if (event.target.value) {
                        handleFocusTextInput(textInputNumber4);
                      } else {
                        handleFocusTextInput(textInputNumber2);
                      }
                    }}
                    onBlur={handleChangeInputField}
                  />
                </Col>
                <Col xs={3} sm={3} md={3} lg={3}>
                  <Field
                    name="numero4"
                    id="game-numero4"
                    type="text"
                    inputRef={textInputNumber4}
                    normalize={normalizeNumbers}
                    label="0"
                    disabled={series.loading}
                    component={renderInputGame}
                    readOnly={readOnly}
                    onInput={(event) => {
                      if (event.target.value) {
                        props.changeValue('GameForm', 'numero4', event.target.value);
                        props.valuesInputNumber.numero4 = event.target.value;
                        handleChangeInputField();
                      } else {
                        props.changeValue('GameForm', 'numero4', null);
                        props.valuesInputNumber.numero4 = null;
                        handleFocusTextInput(textInputNumber3);
                      }
                    }}
                  />
                </Col>
              </Row>
            </div>
          </section>
        </Col>


        <Col xs={12} sm={12} md={12} lg={4} className="serieComponent">
          <section className="single-loteri mb-4 p-4 back-white border-container section-game box-shadow-md">
            <div className="game-number">2</div>
            <h3 className="text-center first-title mb-4 interna-loterias">Serie</h3>
            <p className="text-center mb-4">Selecciona alguna de las series disponibles para tu número</p>
            {(series.loading || singleSerie.loading) && <CircularProgress />}
            {series.list && series.list.length > 0 ?
              <Field
                name='serie'
                id='game-serie'
                formGroupClass="font-montserrat-light radio-list-serie text-center"
                component={renderRadioButtonGame}
                radioList={series.list}
                changeCustomSerie={handleChangeInputField}
                validate={[Validators.required]}
                onChange={handleChangeSerie}
              /> : !series.loading &&
              <Alert className="my-2 content-center text-size-extraSmall" color="light">Antes de seleccionar una serie debes escribir el número a jugar </Alert>
            }
            {(series.error || singleSerie.error) && <Alert className="my-2 content-center" color="danger"> {series.error || singleSerie.error} </Alert>}


          </section>
        </Col>

        <Modal isOpen={modalOpen} toggle={toggleModal} className="single-loteri borderPadding modalBodyPurple">
          <ModalBody className="modalBodyPurple">
            <div className="game-number"><span onClick={() => handleOnCancel()} className='Modal-single-close-inicio'>X</span></div>
            <p className="text-center mb-4">
              Tenemos la Serie: <span className="button-rounded borderRoseFavorite align-items-center">{valueContent}</span>
              en los siguientes números, por esta misma lotería
            </p>

            {numberMajor.map((item, i) => (
              <div key={i} className="input-group justify-content-center text-center mb-4 modalBodyFlex">
                <Row className="modalBodyFlex">
                  {numbers.map((number) => (
                    <Col xs={3} sm={3} md={3} lg={3} key={number} >
                      <Field
                        name={`numeros${number}`}
                        id={'game-numero1'}
                        type="text"
                        withRef
                        normalize={normalizeNumbers}
                        label={String(item.value.numeroFrecuente).charAt(number - 1)}
                        component={renderInputModal}
                        readOnly="true"
                      />
                    </Col>
                  ))}
                  <Col className="ButonNumber2">
                    <Button className="button-rounded button-rounded-gradient-resultados align-items-center" onClick={() => handleOnClickModalMajor(item.value)}>
                      JUGAR
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}

          </ModalBody>
        </Modal>

        <Col xs={12} sm={12} md={12} lg={8}>
          <section className="single-loteri mb-4 p-4 back-white border-container section-game box-shadow-md">
            <div className="game-number">3</div>
            <h3 className="text-center first-title mb-4 interna-loterias">Fracciones</h3>
            <p className="text-center mb-4">Toma la cantidad de fracciones que quieras de esta lotería </p>
            {valueSerie ?
              <div>
                <Row className="list-fractions mb-5">
                  {listFractions.map((fracion, index) => {
                    return (
                      <Col lg={listFractions.length === 1 ? 12 : listFractions.length === 2 ? 6 : listFractions.length === 3 ? 4 : listFractions.length === 4 ? 3 : 3} className="p-0 mt20" key={index}>
                        <Field
                          name={`fraccion-${index}`}
                          id={`game-fracciones-${index}`}
                          disabled={fracion.disabled}
                          label={index + 1}
                          component={renderCheckBoxGame}
                          onChange={event => {
                            if (event.target.value === 'false' || event.target.value === '') {
                              props.changeValue('GameForm', 'cartVal', parseInt(cartValue) + lottery.vlrFraccion);
                            } else if (event.target.value === 'true') {
                              props.changeValue('GameForm', 'cartVal', parseInt(cartValue) - lottery.vlrFraccion);
                            }
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <div className="text-center">
                  <button
                    type="button"
                    className="button-rounded button-rounded-gradient-jugar"
                    onClick={handleSelectAllFractions}
                  >
                    <div className="center-right-button">
                      <span><strong>Tomar billete completo</strong></span>
                      <i className="icon-float-right plus-icon flaticon-hand-finger-pressing-a-circular-ring-button" />
                    </div>
                  </button>
                </div>
              </div>
              :
              <Alert className="my-2 content-center text-size-extraSmall" color="light">Antes de seleccionar una fracción debes seleccionar una serie </Alert>
            }
          </section>
        </Col>

        {alternativeNumber ? <Col xs={12} sm={12} md={12} lg={4} className="serieComponent"></Col> : null}
        {alternativeNumber && altenativeLotery.length > 0 ?
          <Col xs={12} sm={12} md={12} lg={8}>
            <section className="single-loteri mb-4 p-4 back-white border-container section-game box-shadow-md">
              <h3 className="text-center first-title mb-4 interna-loterias2">También tenemos tu número favorito en estas Loterías</h3>
              {alternativeNumber ?
                <div>
                  <Row className="list-fractions mb-5">
                    {altenativeLotery.map((lotery, index) => {
                      if (lottery.ideLoteria !== lotery.idLoteria) {
                        if (!idLoteriaSet.has(lotery.idLoteria)) {
                          idLoteriaSet.add(lotery.idLoteria);
                          const seriesToShow = altenativeLotery
                            .filter(item => item.idLoteria === lotery.idLoteria)
                            .slice(0, 2)
                            .map((serie, index) => (
                              <div key={index} className="form-check form-check-inline">
                                <input
                                  type="radio"
                                  id={`serie${index}`}
                                  name="serie"
                                  value={serie.numeroSerie}
                                  className="modalUserInput3"
                                  onChange={(event) => handleChangeSerieInput(event, serie)}
                                />
                                <label htmlFor={`serie${index}`} className="custom-radio-label">{serie.numeroSerie}</label>
                              </div>
                            ));

                          return (
                            <Col lg={6} className="p-0 mt20" key={index}>
                              <div className="position-relative check-fraction animated pulse">
                                <img className="image-lotterieModal2 d-block mb-4 w-100" src={`${baseURL}/public/assets/images/loterias/${lotery.abreviaturaLoteria}.png`} />
                                <div className='modalUserInputPosition'>
                                  {seriesToShow.length > 0 ? (
                                    <div className="font-montserrat-light radio-list-serie text-center">
                                      {seriesToShow}
                                    </div>
                                  ) : (
                                    !series.loading && (
                                      <Alert className="my-2 content-center text-size-extraSmall" color="light">
                                        Error
                                      </Alert>
                                    )
                                  )}
                                </div>

                                <div className="ButonNumber3">
                                  <Button className="button-rounded button-rounded-gradient-resultados ButonNumber3" onClick={handleOnClickNewSerie}>
                                    JUGAR
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          );
                        }
                      }
                      return null;
                    })}
                  </Row>

                </div>
                :
                <Alert className="my-2 content-center text-size-extraSmall" color="light">No disponible</Alert>
              }
            </section>
          </Col>
          : null}

        <Col xs={12} className="mt-4 mb-5">
          <Row className="justify-content-center">
            <Col lg={8} className="single-loteri billete-dorado">
              <Row className="justify-content-center">
                <Col lg={9} className="text-center">
                  <h5 className='interna-loterias'>PREMIO MAYOR</h5>
                  <h4 className='interna-loterias'>{`$ ${formatMoney((lottery.premioMayor ? lottery.premioMayor.vlrBrutoBillete : 0), 0, 3, '.', ',')}`}</h4>
                  <div className="d-flex align-items-center">
                    <div className="w-50">
                      <h6 className='interna-loterias'>Fecha</h6>
                      <p>{moment(lottery.fecSorteo).format('LL')}</p>
                    </div>
                    <div className="w-50">
                      <h6 className='interna-loterias'>Hora</h6>
                      <p>{moment(`${lottery.fecSorteo} ${lottery.horaSorteo}:00`).format('HH:mm A')}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="mb-5">
          <Row className="justify-content-center">
            {/* <Col xs={12} md={6} lg={5} xl={5} className="mb-3">
              <Field
                name="cartVal"
                type="hidden"
                disabled
                component={renderFieldLabel}
              />
            </Col> */}
            <div className="mb-3">
              <button
                type="submit"
                className="button-rounded button-rounded-gradient-resultados px-30 py5 d-inline-block align-middle"
                disabled={loadingForm || !cartValue}
                onClick={handleSubmit(onSubmit.bind(this, 0))}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span>Añadir al carrito</span>
                  <span>
                    {`$ ${formatMoney(cartValue, 0, 3, '.', ',')}`}
                    {!loadingForm && <i className="flaticon-commerce vertical-middle ml-2" />}
                    {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block vertical-middle" />}
                  </span>
                </div>
              </button>
            </div>

            <div className="mb-3">
              <button
                type="submit"
                className="button-rounded button-rounded-gradient-resultados px-30 py5"
                disabled={loadingForm || !cartValue}
                onClick={handleSubmit(onSubmit.bind(this, 1))}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span>Seguir Jugando</span>
                  <span>
                    {!loadingForm && <i className="vertical-middle ml-2" />}
                    {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block vertical-middle" />}
                  </span>
                </div>
              </button>
            </div>
          </Row>
        </Col>
      </Row>
    </form>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    changeValue(form, field, value) {
      dispatch(change(form, field, value));
    },
    loadListSeries(values) {
      dispatch(loadListSeries(values));
    },
    loadListNumbers(values) {
      dispatch(loadListNumbers(values));
    },
    loadListNumbersLargestSmallest(values) {
      dispatch(loadListNumbersLargestSmallest(values));
    },
    loadSingleSerie(values) {
      dispatch(loadSingleSerie(values));
    },
    setDisabledFractions(validFractions) {
      dispatch(setDisabledFractions(validFractions));
    },
    setEnabledFractions(validFractions) {
      dispatch(setEnabledFractions(validFractions));
    },
    setLotteryToPlay(lottery) {
      dispatch(setLotteryToPlay(lottery));
    },
    clearListSeries() {
      dispatch(clearListSeries());
    },
    loadLotteriesHome(values) {
      dispatch(loadLotteriesHome(values));
    }
  };
};

const selector = formValueSelector('GameForm');



const mapStateToProps = (state) => {
  const { loading, error, lotteries } = state.lotteries.lotteriesHome;
  const { series, listFractions, singleSerie } = state.game;

  return {
    loading,
    error,
    lotteries,
    valuesInputNumber: selector(state, 'numero1', 'numero2', 'numero3', 'numero4'),
    valueSerie: selector(state, 'serie'),
    cartValue: selector(state, 'cartVal'),
    series,
    listFractions,
    singleSerie
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'GameForm', validate })(GameForm));
