import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Row, Col, Alert, Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { loadQuestions } from './../../actions/ContactActions';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';

//Actions
import { getPageInfo } from './../../reducers/generalReducer';

class FrequentQuestionsContainer extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.state = {
      collapse: 0
    };
  }

  componentDidMount() {
    this.props.loadQuestions();
  }

  handleToggle(e) {
    const event = e.target.dataset.event;
    this.setState({ collapse: this.state.collapse === Number(event) ? 0 : Number(event) });
  }

  render() {
    const { loading, list, textInfo } = this.props;
    const { collapse } = this.state;
    return (
      <section>

        <h4 className="mb-4 interna-loterias text-left">{textInfo.title}</h4>
        <div className="text-justify text-size-small mb-4" dangerouslySetInnerHTML={{ __html: textInfo.description }} />
        <Row className="justify-content-center">
          <Col xs="12">

            {list && list.length && list.map((card, index) => {
              return (
                <Card className="mb-3 acordeon-custom-item cast" key={index}>
                  <CardHeader
                    onClick={this.handleToggle}
                    data-event={index}
                    className={`text-left ${collapse === index ? 'active' : ''}`}
                  >
                    <span className="d-inline-block mr-3">{index + 1}</span>{card.title}
                  </CardHeader>
                  <Collapse isOpen={collapse === index}>
                    <CardBody>
                      <div className="richtext-content" dangerouslySetInnerHTML={{ __html: card.content }} />
                    </CardBody>
                  </Collapse>
                </Card>
              );
            })}

            {loading && <Row><CircularProgress /></Row>}
            {!loading && (!list || !list.length) && <Alert className="mt-3 mb-0" color="danger">Ocurrio un error al traer la información</Alert>}
          </Col>
        </Row>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { loading, list } = state.contact.questions;
  return {
    loading,
    list,
    textInfo: getPageInfo(state, 'preguntas-frecuentes')
  };
}

const mapDispatchToProps = dispatch => {
  return {
    loadQuestions() {
      dispatch(loadQuestions());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FrequentQuestionsContainer);
