// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

//Containers
import InfoAccountHeader from './../../../containers/Header/InfoAccountHeader';
import CartHeader from './../../../containers/Header/CartHeader';
import CountdownTimer from './CountdownTimer';

//Assets
import logo from './../../../assets/images/LotiColombia.svg';
import Gana from './../../../assets/images/logo-gana.svg';
import OKI from './../../../assets/images/logo-oki.svg';

import { Collapse, Navbar, NavbarToggler, Nav, Container } from 'reactstrap';

const LinkGanaEnv = process.env.REACT_APP_LINK_GANA;
const LinkOkiEnv = process.env.REACT_APP_LINK_OKI;

class Header extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
    this.handleToggleDropDown = this.handleToggleDropDown.bind(this);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      noDatesAvailable: false
    };
  }

  handleToggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleToggleDropDown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  handleNoDatesAvailable = (noDatesAvailable) => {
    this.setState({ noDatesAvailable });
  }

  render() {
    const { title, items } = this.props;
    const { isOpen, noDatesAvailable } = this.state;

    return (
      <header className="main-header-bar container-Loti ">

        <Navbar light expand="md" className="navar-in p-0 fixed-top">
          <Container className="borde">
            <div className="borde2">
              <Link className="navbar-brand" to={localStorage.getItem('userLoti') !== null ? JSON.parse(localStorage.getItem('userLoti')).actualizarusuario === 'S' ? '/mi-cuenta/1' : '/' : '/'}>
                <img className="navbar-brand-image" src={logo} title={`Icono ${title}`} alt={`Icono ${title}`} />
              </Link>
              <div className="d-flex align-items-center justify-content-between">

                <Collapse isOpen={isOpen} navbar>
                  <Nav className="font-montserrat-light" navbar>
                    {items && items.map((item, key) => (
                      <li className="nav-item" key={key}>
                        <Link
                          className={`button-text p-2 ${this.props.history.location.pathname.split('/')[1] === item.url.split('/')[1] ? 'active' : ''}`}
                          onClick={this.handleToggle}
                          to={localStorage.getItem('userLoti') !== null ? JSON.parse(localStorage.getItem('userLoti')).actualizarusuario === 'S' ? '/mi-cuenta/1' : item.url : item.url}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </Nav>
                  <div className='button-text'>
                    <a className="logo2" href={LinkOkiEnv} target='_blank' rel="noopener noreferrer">
                      <img className="logo-oki" src={OKI} alt="OKI Logo" />
                    </a>
                    <a className="logo1" href={LinkGanaEnv} target='_blank' rel="noopener noreferrer">
                      <img className="logo-gana" src={Gana} alt="Gana Logo" />
                    </a>
                  </div>
                </Collapse>
                <InfoAccountHeader />
                <CartHeader />
                <NavbarToggler onClick={this.handleToggle} />
              </div>

            </div>
            <div className="borde3 ">
              <CountdownTimer onNoDatesAvailable={this.handleNoDatesAvailable} />
              {noDatesAvailable && (
                <div className="no-dates-message">

                </div>
              )}
            </div>
          </Container>
        </Navbar>

      </header>
    );
  }
}

export default withRouter(Header);
