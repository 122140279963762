import { resultsConstants } from './../constants/ResultsConstants';

const initialState = {
  resultsHome:{
    loading: false,
    results: null,
    error: null
  }
};

export function results(state = initialState, action) {
  switch (action.type) {
    case resultsConstants.HOME_RESULTS_REQUEST:
      return {
        ...state,
        resultsHome: {
          loading: true
        }
      };
    case resultsConstants.HOME_RESULTS_SUCCESS:
      return {
        ...state,
        resultsHome: {
          results: action.payload
        }
      };
    case resultsConstants.HOME_RESULTS_FAILURE:
      return {
        ...state,
        resultsHome: {
          error: action.error
        }
      };
    default:
      return state;
  }
}
