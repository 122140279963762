/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */

const formatMoney = (number, n, x, s, c) => {
  // eslint-disable-next-line prefer-template
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
  const num = parseInt(number).toFixed(Math.max(0, ~~n));
  // eslint-disable-next-line prefer-template
  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

const priceFormatter = (cell, row) => {
  return (
    `$ ${formatMoney(cell, 0, 3, '.', ',')}`
  );
};

const quitarAsteriscos = (cadena) => {
  return cadena.replace(/\*/g, '');
};

export { formatMoney, priceFormatter, quitarAsteriscos};
