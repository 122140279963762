import { userConstants } from './../constants/UserConstants';
import { cartConstants } from './../constants/CartConstants';
import UserService from './../services/UserService';
import { getCart } from './CartActions';
import { history } from './../helpers/history';
import { ModalsGaTracker, EventsGaTracker } from '../useGATracker';
import { mayoriaEdad } from '../constants/AccountConstants';
import { modalConstants } from '../constants/ModalConstants';
import { useLocation } from 'react-router-dom';
import RespFavorite$ from './../events/emitter';
const favoriteEnv = process.env.REACT_APP_FAVORITE_NUMBER;

//====================================================================
// Toggle Modal for login or create account
//====================================================================

const registerUserClear = () => ({
  type: userConstants.REGISTER_USER_CLEAR
});

const loginUserClear = () => ({
  type: userConstants.LOGIN_USER_CLEAR
});

export const applicationChangePasswordClear = () => ({
  type: userConstants.REQUEST_CHANGE_PASSWORD_CLEAR
});

export const ChangePasswordClear = () => ({
  type: userConstants.CHANGE_PASSWORD_CLEAR
});

export const updateUserClear = () => ({
  type: userConstants.UPDATE_USER_CLEAR
});

export const deleteUserClear = () => ({
  type: userConstants.DELETE_USER_CLEAR
});

export const toggleModalLogin = (tab) => (dispatch) => {
  dispatch({
    type: userConstants.TOGGLE_MODAL_LOGIN,
    tab: tab
  });

  if (!tab) {
    dispatch(registerUserClear());
    dispatch(loginUserClear());
    dispatch(applicationChangePasswordClear());
    dispatch(updateUserClear());
    dispatch(deleteUserClear());
  }
};

export const toggleModalUpdate = () => (dispatch) => {
  dispatch({
    type: modalConstants.TOGGLE_MODAL_UPDATE
  });
};

export const toggleModalFavorite = () => (dispatch) => {
  dispatch({
    type: modalConstants.TOGGLE_MODAL_FAVORITE
  });
};

//====================================================================
// Register a new user to the db
//====================================================================

export const registerUser = (values) => (dispatch) => {

  const registerUserRequest = () => ({
    type: userConstants.REGISTER_USER_REQUEST
  });

  const registerUserSuccess = (payload) => ({
    type: userConstants.REGISTER_USER_SUCCESS,
    payload
  });

  const registerUserError = payload => ({
    type: userConstants.REGISTER_USER_FAILURE,
    payload
  });

  dispatch(registerUserRequest());
  setTimeout(() => {
    UserService.register(values)
      .then(
        response => {
          EventsGaTracker('user', 'Registro de nuevo usuario');
          dispatch(registerUserSuccess(response));
        },
        error => {
          error.then(errorResponse => {
            const errorMessage = JSON.parse(errorResponse.message.split('-')[1]);
            dispatch(registerUserError(errorMessage.mensaje ? errorMessage.mensaje :
              'Ocurrió un error al momento de Crear la cuenta. Inténtalo nuevamente o contáctanos para reportar tu problema.'));
          });
        }
      );
  }, 500);
};

//====================================================================
// Update user
//====================================================================

export const updateUser = (values) => (dispatch) => {

  const updateUserRequest = () => ({
    type: userConstants.UPDATE_USER_REQUEST
  });

  const updateUserSuccess = (payload) => ({
    type: userConstants.UPDATE_USER_SUCCESS,
    payload
  });

  const updateUserError = error => ({
    type: userConstants.UPDATE_USER_FAILURE,
    error
  });

  dispatch(updateUserRequest());
  setTimeout(() => {
    UserService.update(values)
      .then(
        user => {
          EventsGaTracker('user', 'Modificar usuario');
          dispatch(updateUserSuccess(user));
          dispatch(getUserInfo());
        },
        error => {
          error.then(errorResponse => {
            const errorMessage = JSON.parse(errorResponse.message.split('-')[1]);
            dispatch(updateUserError(errorMessage.mensaje ? errorMessage.mensaje :
              'Ocurrió un error al momento de Crear la cuenta. Inténtalo nuevamente o contáctanos para reportar tu problema.'));
          });
        }
      );
  }, 500);
};

//====================================================================
// Update user
//====================================================================

export const deleteUser = (values) => (dispatch) => {
  const deleteUserRequest = () => ({
    type: userConstants.DELETE_USER_REQUEST
  });

  const deleteUserSuccess = (payload) => ({
    type: userConstants.DELETE_USER_SUCCESS,
    payload
  });

  const deleteUserError = (payload) => ({
    type: userConstants.DELETE_USER_FAILURE,
    payload
  });

  dispatch(deleteUserRequest());
  setTimeout(() => {
    UserService.deleteUser(values)
      .then(
        user => {
          dispatch(deleteUserSuccess(user.data));
          if (user.toEliminar) {
            EventsGaTracker('user', 'Eliminar usuario');
            history.push('/eliminar');
            history.go();
          }
        },
        error => {
          error.then(errorResponse => {
            const errorMessage = errorResponse.error;
            dispatch(deleteUserError(errorMessage.error.mensaje ? errorMessage.error.mensaje :
              'Ocurrió un error al momento de Eliminar el usuario. Inténtalo más tarde'));
          });
        }
      );
  }, 500);
};
//====================================================================
// Get user Info
//====================================================================

export const getUserInfo = () => (dispatch, getState) => {

  const user = getState().authentication.user;
  EventsGaTracker('user', 'Consultar informacion de usuario', 'UserConsulta');
  if (user) {
    const body = {
      'token': user.token,
      'tipoDocumento': user.tipoDocumento,
      'usuario': user.usuario
    };

    const getUserRequest = () => ({
      type: userConstants.GET_USER_REQUEST
    });

    const getUserSuccess = (payload) => ({
      type: userConstants.GET_USER_SUCCESS,
      payload
    });

    const getUserError = error => ({
      type: userConstants.GET_USER_FAILURE,
      error
    });

    dispatch(getUserRequest());
    UserService.getInfo(body)
      .then(
        user => {
          //const pathname = window.location.pathname;
          const local = JSON.parse(localStorage.getItem('userLoti'));
          if (local.comercial) {
            user.data.comercial = true;
          }
          /* if (local.mayoriaEdad === mayoriaEdad.PENDIENTE && !pathname.includes('/mi-cuenta')) {
            dispatch(toggleModalUpdate());
          } */
          localStorage.setItem('userLoti', JSON.stringify(user.data));
          dispatch(getUserSuccess(user.data));
        },
        error => {
          if (error === 'Unauthorized') {
            dispatch(getUserError('Debes de volver a iniciar sesión'));
          } else {
            dispatch(getUserError(error.message ? error.message : 'Ocurrió un error al momento de Actualizar su información. Inténtalo más tarde'));
          }
          dispatch(logoutUser());
        }
      );
  }
};

//====================================================================
// Login user
//====================================================================

export const loginUser = (values) => (dispatch) => {

  ModalsGaTracker('loginUser');

  const loginUserRequest = () => ({
    type: userConstants.LOGIN_USER_REQUEST
  });

  const loginUserSuccess = payload => ({
    type: userConstants.LOGIN_USER_SUCCESS,
    payload
  });

  const loginUserError = payload => ({
    type: userConstants.LOGIN_USER_FAILURE,
    payload
  });

  dispatch(loginUserRequest());
  setTimeout(() => {
    UserService.login(values)
      .then(
        user => {
          if (user && user.token) {
            localStorage.setItem('userLoti', JSON.stringify(user));
            user.clave = values.clave;
            console.log('favoriteenv',favoriteEnv);
            if (favoriteEnv == 's') {
              user.clave = values.clave;
              dispatch(favoriteNumber(user));
            }
          }
          if (user.statusCode != null && user.statusCode == 401) {
            dispatch(loginUserError(user.message));
          } else {
            if (user.mayoriaEdad === mayoriaEdad.PENDIENTE) {
              dispatch(toggleModalUpdate());
            } else {
              dispatch(loginUserSuccess(user));
              dispatch(getCart(true));
              setTimeout(() => {
                EventsGaTracker('user', 'Iniciar sesion');
                dispatch(toggleModalLogin('1'));
              }, 2000);


              if (user !== null) {
                if (user.actualizarusuario === 'S') {
                  alert('Debes actualizar tus datos para continuar'); // eslint-disable-line no-alert
                  history.push('/mi-cuenta/1');
                  history.go();
                }
              };
            }

          }
        },
        error => {
          error.then(errorResponse => {
            const errorMessage = errorResponse.error;
            dispatch(loginUserError('Usuario o clave inválida'));
          });
        }
      );
  }, 500);
};
//====================================================================
// Favorite
//====================================================================

export const favoriteNumber = (values) => (dispatch) => {
  setTimeout(() => {
    //const currentUser = JSON.parse(localStorage.getItem('userLoti')) || {};

    UserService.getUserArray(values)
      .then(
        user => {
          if (user && user.token) {
            localStorage.setItem('userLoti2', JSON.stringify(user));
            setTimeout(() => {
              const data = '2222 envio el mensaje';
              RespFavorite$.emit('myEvent2', data);

              dispatch(toggleModalFavorite());
            }, 3000);


          }
        }
      );
  }, 500);
};


//====================================================================
// Logout user
//====================================================================

export const logoutUser = () => (dispatch) => {
  UserService.logout();
  dispatch({ type: userConstants.LOGOUT_USER });
  dispatch({ type: cartConstants.CLEAR_CART });
  localStorage.removeItem('userData');
  localStorage.removeItem('out');
  localStorage.removeItem('userLoti2');
};
//====================================================================
// Activate Account
//====================================================================

export const activeAccount = (values) => (dispatch) => {
  const applicationActiveAccountRequest = () => ({
    type: userConstants.REQUEST_ACTIVE_ACCOUNT
  });
  const applicationActiveAccountRequestSuccess = (payload) => ({
    type: userConstants.REQUEST_ACTIVE_ACCOUNT_SUCCESS,
    payload
  });
  const applicationActiveAccountRequestError = (payload) => ({
    type: userConstants.REQUEST_ACTIVE_ACCOUNT_FAILURE,
    payload
  });

  dispatch(applicationActiveAccountRequest());
  setTimeout(() => {
    UserService.activeAccount(values)
      .then(
        user => {
          dispatch(applicationActiveAccountRequestSuccess(user.data));
        },
        error => {
          error.then(errorResponse => {
            const errorMessage = JSON.parse(errorResponse.message.split('-')[1]);
            dispatch(applicationActiveAccountRequestError(errorMessage.mensaje ? errorMessage.mensaje :
              'Ocurrió un error al momento de Crear la cuenta. Inténtalo nuevamente o contáctanos para reportar tu problema.'));
          });
        }
      );
  }, 500);
};

//====================================================================
// getUserWithToken
//====================================================================

export const getUserWithToken = (values) => (dispatch) => {
  const getUserWithToken = () => ({
    type: userConstants.GET_USER_WITH_TOKEN_REQUEST
  });
  const getUserWithTokenSuccess = (payload) => ({
    type: userConstants.GET_USER_WITH_TOKEN_SUCCESS,
    payload
  });
  const getUserWithTokenError = (payload) => ({
    type: userConstants.GET_USER_WITH_TOKEN_FAILURE,
    payload
  });
  dispatch(getUserWithToken());
  setTimeout(() => {
    UserService.getUserWithToken(values)
      .then(
        user => {
          dispatch(getUserWithTokenSuccess(user.data));
        },
        error => {
          error.then(errorResponse => {
            const errorMessage = errorResponse.error;
            dispatch(getUserWithTokenError(errorMessage.error ? errorMessage.error : errorMessage));
          });
        }
      );
  }, 500);
};

//====================================================================
// recoveryPassword web
//====================================================================

export const recoveryPassword = (values) => (dispatch) => {
  const recoveryPassword = () => ({
    type: userConstants.RECOVERY_PASSWORD_REQUEST
  });
  const recoveryPasswordSuccess = (payload) => ({
    type: userConstants.RECOVERY_PASSWORD_SUCCESS,
    payload
  });
  const recoveryPasswordError = (payload) => ({
    type: userConstants.RECOVERY_PASSWORD_FAILURE,
    payload
  });
  dispatch(recoveryPassword());
  setTimeout(() => {
    UserService.changePasswordWeb(values)
      .then(
        user => {
          dispatch(recoveryPasswordSuccess(user.data));
        },
        error => {
          error.then(errorResponse => {
            const errorMessage = errorResponse.error;
            dispatch(recoveryPasswordError(errorMessage.error ? errorMessage.error : errorMessage));
          });
        }
      );
  }, 500);
};


//====================================================================
// Request change password
//====================================================================

export const applicationChangePassword = (values) => (dispatch) => {

  const applicationChangePasswordRequest = () => ({
    type: userConstants.REQUEST_CHANGE_PASSWORD_REQUEST
  });

  const applicationChangePasswordSuccess = () => ({
    type: userConstants.REQUEST_CHANGE_PASSWORD_SUCCESS
  });

  const applicationChangePasswordError = payload => ({
    type: userConstants.REQUEST_CHANGE_PASSWORD_FAILURE,
    payload
  });

  dispatch(applicationChangePasswordRequest());
  setTimeout(() => {
    UserService.changePasswordRequest(values)
      .then(
        user => {
          dispatch(applicationChangePasswordSuccess());
        },
        error => {
          error.then(errorResponse => {
            const errorMessage = errorResponse.error;
            dispatch(applicationChangePasswordError(errorMessage.error.mensaje ? errorMessage.error.mensaje : 'Ocurrió un error al momento de solicitar el cambio de contraseña. Inténtalo más tarde'));
          });
        }
      );
  }, 500);
};

//====================================================================
// Change password
//====================================================================

export const changePassword = (values) => (dispatch) => {

  const changePasswordRequest = () => ({
    type: userConstants.CHANGE_PASSWORD_REQUEST
  });

  const changePasswordSuccess = () => ({
    type: userConstants.CHANGE_PASSWORD_SUCCESS
  });

  const changePasswordError = payload => ({
    type: userConstants.CHANGE_PASSWORD_FAILURE,
    payload
  });

  dispatch(changePasswordRequest());
  setTimeout(() => {
    UserService.changePassword(values)
      .then(
        user => {
          dispatch((typeof user.data.mensaje != 'undefined' && user.data.mensaje != ' Clave inválida') ? changePasswordSuccess() : changePasswordError(user.data.mensaje));
        },
        error => {
          error.then(errorResponse => {
            const errorMessage = errorResponse.error;
            dispatch(changePasswordError(errorMessage.error.mensaje ? errorMessage.error.mensaje : 'Ocurrió un error al momento de cambiar la contraseña. Inténtalo más tarde'));
          });
        }
      );
  }, 500);
};
