import React from 'react';
import { reduxForm, Field } from 'redux-form';

//Components
import renderField from './../renderFields/renderField';
import renderSelect from './../renderFields/renderSelect';
import renderCheckBox from './../renderFields/renderCheckBox';

//Utils
import Validators from './../renderFields/validatorsFields';
import { getDocumentTypes } from '../../utils/accountUtils';
import renderCaptcha from '../../components/renderFields/renderCaptcha';

const UpperCase = value => value.toUpperCase();
const LoginForm = ({
  handleSubmit,
  onDelete,
  pristine,
  reset,
  submitting,
  loadingForm,
  readOnly
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="tipoDocumento"
        id="login-tipoDocumento"
        type="select"
        label="Tipo de Documento"
        icon="flaticon-arrows"
        component={renderSelect}
        validate={[Validators.required, Validators.notNull]}
        readOnly={readOnly}
      >
        <option value="default">Tipo de Documento</option>
        {
          getDocumentTypes().map(item => (
            <option value={item.type}>{item.name}</option>
          ))
        }
      </Field>
      <Field
        name="usuario"
        id="login-cedula"
        type="text"
        icon="flaticon-profile"
        label="Cédula"
        component={renderField}
        normalize={UpperCase}
        validate={[Validators.required, Validators.documentTypeLogin]}
        readOnly={readOnly}
      />

      <Field
        name="clave"
        id="login-password"
        type="password"
        icon="flaticon-locked"
        label="Contraseña"
        component={renderField}
        validate={[Validators.required, Validators.maxLength60]}
        readOnly={readOnly}
      />

      <Field
        name="captcha"
        component={renderCaptcha}
        validate={[Validators.requiredCap]}
      />

      <Field
        name="comercial"
        id="comercial"
        label="Comercializador"
        component={renderCheckBox}
      />


      <button
        type="submit"
        className="btn btn-primary"
        disabled={loadingForm}
      >
        Iniciar sesión
        {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
      </button>
    </form>
  );
};

export default reduxForm({ form: 'LoginForm' })(LoginForm);
