import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes';
import configureStore from './store/configureStore';
import { Buffer } from 'buffer'; // Importa Buffer
import { CartProvider } from './components/Cart/CartContext'; // Importa el proveedor de contexto

// Configura Buffer globalmente
window.Buffer = Buffer;

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <CartProvider>
        <AppRoutes />
      </CartProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);