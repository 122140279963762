// Dependencies
import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

//Containers
import LotteriesPageContainer from './../../containers/Lotteries/LotteriesPageContainer';

//Actions
import { getPageInfo } from './../../reducers/generalReducer';

class Reloads extends Component {
  render() {
    const { info } = this.props;
    return (
      <Col xs={12} className="homeContainer">
        <Row>
          <Container className="bg-primary">
            <section className="border mt-5 mb-4 p-4 back-white border-container">
              <h3 className="first-title text-center my-5">Recar<span className="color-main">gas</span></h3>
              <div className="font-montserrat-light text-center text-size-medium mb-5" dangerouslySetInnerHTML={{__html: info.description}} />
              <Row className="m-0 align-items-top justify-content-center">
                <Col xs={12} lg={4}>
                  <Link className="button-rounded button-rounded-white mb-3" to="/recargas/celular">
                    <div className="d-flex justify-content-center align-items-center">
                      <span>Recargas a Celular</span>
                    </div>
                  </Link>
                </Col>
                <Col xs={12} lg={{ size: 4, offset: 1 }}>
                  <Link className="button-rounded button-rounded-blue" to="/recargas/paquetes">
                    <div className="d-flex justify-content-center align-items-center">
                      <span>Compra de Paquetes</span>
                    </div>
                  </Link>
                </Col>
              </Row>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error } = state.general.pages;
  return {
    loading,
    error,
    info: getPageInfo(state, 'recargas')
  };
};

export default connect(mapStateToProps)(Reloads);
