import React, { Component } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

//Components
import AccountContainer from './../../containers/Account/AccountContainer';
import LoginContainer from './../../containers/Login/LoginContainer';
import RequestChangePasswordContainer from './../../containers/Login/RequestChangePasswordContainer';
import { Tab, TabList, Tabs } from 'react-tabs';

class ModalLoginContainer extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.handleChangePasswordClick = this.handleChangePasswordClick.bind(this);

    this.state = {
      activeTab: this.props.tab,
      changePassword: false,
      startDate: moment()
    };
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  handleChangePasswordClick() {
    this.setState({
      changePassword: true
    });
  }

  viewLogin() {
    if (this.state.changePassword) {
      return (
        <div className="p-5 text-center container-modal-general">
          <RequestChangePasswordContainer />
        </div>
      );
    } else {
      return (
        <div className="general-tabs">
          <Tabs tabs className="single-session-run" defaultIndex={this.state.activeTab-1}>
            <TabList className="react-tabs__tab-list tab-container">
              <Tab
                className="button-rounded button-rounded-puntos px-20 py5"
                onClick={() => this.toggle('1')}
              >
                Iniciar sesión
              </Tab>
              <Tab
                className="button-rounded button-rounded-puntos px-20 py5"
                onClick={() => this.toggle('2')}
              >
                Crear cuenta
              </Tab>
            </TabList>
          </Tabs>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1" className="p-5 text-center single-session">
              <LoginContainer onChangePasswordClick={this.handleChangePasswordClick} />
            </TabPane>
            <TabPane tabId="2" className="p-5 text-center single-session">
              <AccountContainer />
            </TabPane>
          </TabContent>
        </div>
      );
    }
  }

  render() {
    const show = this.viewLogin();
    return (
      <div>
        {show}
      </div>
    );
  }
}

export default ModalLoginContainer;
