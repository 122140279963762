// Dependencies
import React from 'react';
//import BootstrapTable from 'react-bootstrap-table-next';
import { Alert } from 'reactstrap';
import { priceFormatter } from './../../helpers/formatMoney';

import iconLotterie from './../../assets/images/icono-loteria.jpg';

const reloads = [
  {
    id: 1,
    tipo: 'Paquete',
    numero: '3167635895',
    operador: 'Claro',
    valor: 30000
  },
  {
    id: 2,
    tipo: 'Paquete',
    numero: '3167635895',
    operador: 'Claro',
    valor: 30000
  },
  {
    id: 3,
    tipo: 'Recarga Celular',
    numero: '3167635895',
    operador: 'Claro',
    valor: 30000
  }
];

const TableReloads = ({products, removeFromCart}) => {

  function phoneFormatter(cell, row) {
    return (
      <div className="d-inline-block">
        <span>{cell.substring(0, 3)}</span> - <span>{cell.substring(3, 6)}</span> - <span>{cell.substring(6, 10)}</span>
      </div>
    );
  }

  const actionsFormatter = (cell, row) => {
    return (
      <div className="d-inline-block">
        <button
          type="button"
          className="icon-button"
          title="Eliminar del Carrito de Compras"
          onClick={() => removeFromCart({id: row.id}, 'recharges')}
        >
          {row.deleting ? <i className="fa fa-spinner fa-pulse" /> : <i className="flaticon-can"/>}
        </button>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'tipo',
      text: 'Tipo',
      align: 'center',
      headerAlign: 'center',
      headerClasses: 'custom-table-data-header',
      classes: 'custom-table-data-row custom-row-reloads'
    },
    {
      dataField: 'celular',
      text: 'Número de Celular',
      formatter: phoneFormatter,
      align: 'center',
      headerAlign: 'center',
      headerClasses: 'custom-table-data-header',
      classes: 'custom-table-data-row custom-row-reloads'
    },
    {
      dataField: 'allOperator.desProducto',
      text: 'Operador',
      align: 'center',
      headerAlign: 'center',
      headerClasses: 'custom-table-data-header',
      classes: 'custom-table-data-row custom-row-reloads'
    },
    {
      dataField: 'valor',
      text: 'Valor',
      formatter: priceFormatter,
      align: 'center',
      headerAlign: 'center',
      headerClasses: 'custom-table-data-header',
      classes: 'custom-table-data-row custom-row-reloads'
    },
    {
      dataField: '',
      text: '',
      formatter: actionsFormatter,
      align: 'center',
      headerAlign: 'center',
      headerClasses: 'custom-table-data-header',
      classes: 'custom-table-data-row'
    }
  ];

  return (
    <section className="table-custom-data table-cart table-cart-reloads">
      {!products.length && <Alert className="my-2 content-center" color="warning">No ha realizado ninguna recarga</Alert>}
      {products && products.length > 0 &&
      <>
      </>
        // <BootstrapTable
        //   keyField='id'
        //   data={products}
        //   columns={columns}
        //   bordered={false}
        // />
      }
    </section>
  );
};

export default TableReloads;
