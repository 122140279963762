import React, { Component, useEffect, useState } from 'react';

import DetallesLoteria from '../../assets/images/alerta.svg';
import PersonDetallesLoteria from '../../assets/images/modal-detalles.png';
import DetallesValor from '../../assets/images/DetallesValorFraccion.svg';
import DetallesFracciones from '../../assets/images/DetallesFracciones.svg';
import DetallesTotal from '../../assets/images/DetallesTotal.svg';
import DetallesJuega from '../../assets/images/DetallesJuega.svg';

import { loadPrizePlan } from '../../actions/PrizePlanActions';
import { connect } from 'react-redux';
import { Col, Row, Button } from 'reactstrap';
import CircularProgress from '../Global/CircularProgress/CircularProgress';
import { formatMoney } from '../../helpers/formatMoney';

class ModalCuponesError extends Component {
  constructor(props) {
    super(props);

    this.handleSearchPrizePlan = this.handleSearchPrizePlan.bind(this);

    this.state = {
      loadPrizePlan: false
    };
  }

  handleSearchPrizePlan = values => {
    this.props.loadPrizePlan({
      ideLoteria: values.loteria,
      fecha: values.fecha || null
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.loadPrizePlan && nextProps.show) {
      this.setState({ loadPrizePlan: true });
      this.props.loadPrizePlan({
        ideLoteria: this.props.idLotterieURL,
        fecha: null
      });
    }
  }

  render() {
    const { loading, show, onClose, onHandleCheckoutCart, COP } = this.props;


    return (
      <div className="modal" style={{ display: show ? 'flex' : 'none' }}>
        <div className="single-modal-content" style={{ paddingLeft: 80, margin: 0,paddingRight: 80 }}>
          <div className='single-close'>
            <p onClick={onClose} className='single-close no-mrn'>X</p>
          </div>
          <div className='modalTitleCuponError'>
            <img src={DetallesLoteria} alt='Detalles de Lotería' />
            <h2 className='single-h2' style={{ padding: 0, margin: 0 }}>No es posible  redimir el  </h2>
            <h2 className='single-h2' style={{ paddingTop:0, margin: 0 }}>cupón en esta compra:  </h2>


          </div>
          <div >
            <p className='single-span-Detalle-valor no-mrn' style={{ paddingTop: 15, margin: 0, fontSize: 20, color: '#000000' }}>Revisa las condiciones: sorteo, número de </p>
            <p className='single-span-Detalle-valor-cupon  no-mrn' style={{ MozPaddingEnd: 15, margin: 15, fontSize: 20 }}> fracciones y vigencia</p>

          </div>
          <Row>

            <Col md={12} className='mb20'>




              <div className='modalTitleCuponError' style={{ paddingTop: 0, marginTop: 20 }}>
                <Button className="button-rounded button-rounded-gradient-resultados-cupon px-30 py5 mb-3 mr20" onClick={onClose} >
                  <h6 style={{ paddingTop: 0, margin: 0 }}>Revisa tu</h6>
                  <h6 style={{ paddingTop: 0, margin: 0 }}> compra</h6></Button>

              </div>



              <div className='modalTitleCuponError'>
                <a
                  onClick={event => {
                    event.preventDefault();
                    onHandleCheckoutCart();
                    onClose();

                  }}
                  className="single-span-Detalle-valor-cupon  no-mrn"
                  style={{color: '#000000'}}>
                  Comprar sin cupón
                </a>
              </div>


            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, prizePlan } = state.prizes;
  return {
    loading,
    error,
    prizePlan
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadPrizePlan(values) {
      dispatch(loadPrizePlan(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCuponesError);
