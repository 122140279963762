import { newsConstants } from './../constants/NewsConstants';
import NewsService from './../services/NewsService';
//====================================================================
// Loading Home News
//====================================================================

export const loadNewsHome = (values) => (dispatch) => {

  const newsHomeRequest = () => ({
    type: newsConstants.HOME_NEWS_REQUEST
  });

  const newsHomeSuccess = payload => ({
    type: newsConstants.HOME_NEWS_SUCCESS,
    payload
  });

  const newsHomeError = error => ({
    type: newsConstants.HOME_NEWS_FAILURE,
    error
  });

  dispatch(newsHomeRequest());
  NewsService.loadNewsHome()
    .then(
      news => {
        dispatch(newsHomeSuccess(news));
      },
      error => {
        dispatch(newsHomeError(error.message ? error.message : 'Ocurrió un error al momento de traer las noticias'));
      }
    );

};


export const loadSingleNews = (id) => (dispatch) => {

  const singleNewsRequest = () => ({
    type: newsConstants.SINGLE_NEWS_REQUEST
  });

  const singleNewsSuccess = payload => ({
    type: newsConstants.SINGLE_NEWS_SUCCESS,
    payload
  });

  const singleNewsError = error => ({
    type: newsConstants.SINGLE_NEWS_FAILURE,
    error
  });

  dispatch(singleNewsRequest());
  NewsService.loadSingleNews(id)
    .then(
      news => {
        dispatch(singleNewsSuccess(news));
      },
      error => {
        dispatch(singleNewsError(error.message ? error.message : 'Ocurrió un error al momento de traer la noticia'));
      }
    );

};
