// Dependencies
import React, { Component } from 'react';

class Page404 extends Component {
  render() {
    return (
      <div className="Cart">
        <h1>Page404 Page</h1>
      </div>
    );
  }
}

export default Page404;
