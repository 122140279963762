import React from 'react';
import { FormGroup, FormText } from 'reactstrap';

const renderCheckBox = ({input, label, id, formGroupClass, meta: {touched, error, warning}, labelContent, ...rest}) => {
  return (
    <FormGroup className={formGroupClass}>
      <div className="position-relative">
        <input
          {...input}
          id={id}
          className="custom-checkbox"
          type="checkbox"
          checked={input.value}
        />
        <label htmlFor={id} dangerouslySetInnerHTML={{__html: label}} />
        {labelContent}
      </div>
      {touched && 
      ((error && <FormText className="text-left">{error}</FormText>) ||
      (warning && <FormText className="text-left">{warning}</FormText>))}
    </FormGroup>
  );
}; 

export default renderCheckBox;
