import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { toggleModalAge } from './../../actions/ModalActions';

class ModalAge extends Component {

  render () {
    const { children, className, open, toggleModalAge, actionAge} = this.props;
    return (
      <Modal isOpen={open} toggle={toggleModalAge} className={`age-modal ${className}`}>
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="btn-boostrap" onClick={actionAge}>Aceptar</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    open : state.modal.age.open
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModalAge() {
      dispatch(toggleModalAge());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAge);
