import React, {Component} from 'react';
import { policesText } from './policesText';
import { Col, Row, Container } from 'reactstrap';
import TermsServices from './../../../services/TerminosCondiciones';

export default class PrivacyPolicies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      politicas: ''
    };
    TermsServices.TermsList().then(res => {
      if (res.length > 0) {
        this.setState({
          politicas: res[0].description
        });
      }
    });
  }
  render() {
    return (
      <Col xs={12} className="homeContainer return-seccion">
        <Row>
          <Container>
            <section className=" mt-5 mb-4 p-4 border-container">
              <h3 className="first-title text-center my-5 interna-resultados">Políticas de Privacidad</h3>
              <div className="text-polices text-justified p-4" dangerouslySetInnerHTML={{ __html: this.state.politicas }}/>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
};
