
export const pushEgoi = (params) => {
  try {
    if (typeof _egoiaq != 'undefined') {
      _egoiaq.push(params);
    }
  } catch (error) {
    console.error(error);
  }
};


export const initEgoi = (email) => {
  pushEgoi(['setSubscriber', email]); // You can use the uid (unique identifier) or the email or the phone number
};

export const addBeat = (element) => {
  const strinId=`${element.id}`;
  const total=1;
  const categories = [element.sigla];
  const nombreJuego ='Lotería en Línea';
  addProductEgoi(strinId, nombreJuego, categories, element.total, total);
};
export const addProductEgoi = (id, name, categories, price, quantity) => {
  pushEgoi(['addEcommerceItem',
    id, // (required) SKU: Product unique identifier
    name, // (optional) Product name
    categories, // (optional) Product category, string or array of up to 5 categories
    price, // (recommended) Product price
    quantity// (optional, default to 1) Product quantity
  ]);
};

export const actualizarTotalCarrito = (total) => {
  pushEgoi(['trackEcommerceCartUpdate', total]);
};

export const trackEcommerceOrder = (id, total, iva, shipping , discount) => {
  const subtotal = total - iva;
  const tax = Math.round((iva / subtotal) * 100);
  pushEgoi(['trackEcommerceOrder',
    id,
    total,
    subtotal,
    tax,
    shipping,
    discount]);
};
