import 'whatwg-fetch';
import { getAuthApp } from '../services/security/authApp';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }
  return response.json();
};

class ConteoRegresivoService {

  static consultarPrimerActivo() {
    const url = `${serverURL}/api/conteoregresivo/PrimerActivo`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => {
        return response;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default ConteoRegresivoService;
