import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducers from './../reducers';

export default function configureStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const loggerMiddleware = createLogger();

  if ( module.hot ) {
    return createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware)));
  } else {
    return createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)));
  }
}
