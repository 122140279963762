export default [
  {
    title: 'Loterías',
    url: '/loterias'
  },
  {
    title: 'Resultados',
    url: '/resultados'
  },
  /*{
    title: 'Recargas',
    url: '/recargas'
  },*/
  /* {
    title: 'Nosotros',
    url: '/nosotros/1'
  }, */
  {
    title: 'Productos',
    url: '/productos'
  }
  /* {
    title: 'Puntos Gana',
    url: '/puntos-gana'
  },
  {
    title: 'Contáctenos',
    url: '/contacto/1'
  } */
];
