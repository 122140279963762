import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import ChangePasswordForm from './../../components/Account/ChangePasswordForm';
import { changePassword, ChangePasswordClear} from './../../actions/UserActions';
import { withRouter } from 'react-router-dom';

class ChangePasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.handleOnSave = this.handleOnSave.bind(this);
  }

  handleOnSave = formValues => {
    const values = JSON.parse(JSON.stringify(formValues));
    delete values.claveNuevaConfirm;
    values.token = this.props.user.token;
    values.tipoDocumento = this.props.user.tipoDocumento;
    values.usuario = this.props.user.usuario;
    values.ideAplicacion = this.props.user.ideAplicacion;
    this.props.changePassword(values);
  };

  handleBackHome = () => {
    this.props.ChangePasswordClear();
    this.props.history.push('/');
  };

  render() {
    const { loading, error, success, user } = this.props;
    //const loading = false, error = false, success = true;

    const messageSucces = () => {
      return (
        <div className="nosotros-section">
          <h4 className="first-title text-center mt-4 mb-4">Contraseña modificada</h4>
          <p className="mb-4 text-size-medium">
            Tu contraseña ha sido cambiada con éxito
          </p>
          <button
            type="button"
            className="btn btn-primary mb-3"
            onClick={() => this.handleBackHome()}
          >
            Regresar al Inicio
          </button>
        </div>
      );
    };

    return (
      <section>
        {success ? messageSucces() :
          <section className="nosotros-section">
            <h3 className="first-title text-center mt-4 mb-4 interna-resultados">¿Quieres cambiar tu contraseña?</h3>
            <p className="mb-5 text-size-small">Recuerda que esta acción modificará tu contraseña para nuestros portales y aplicaciones.</p>
            <p className="mb-4 text-size-medium">Completa los siguientes campos</p>
            <Row className="justify-content-center">
              <Col xs={12} sm={9} md={8} lg={7} xl={6}>
                <ChangePasswordForm
                  onSubmit={this.handleOnSave}
                  loadingForm={loading}
                />
                {error && <Alert className="mt-3 mb-0" color="danger"> {error} </Alert>}
              </Col>
            </Row>
          </section>
        }
      </section>

    );
  }
}

function mapStateToProps(state) {
  const { loading, error, success } = state.changePassword.change;
  const { user } = state.authentication;
  return {
    loading,
    error,
    success,
    user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    changePassword(values) {
      dispatch(changePassword(values));
    },
    ChangePasswordClear() {
      dispatch(ChangePasswordClear());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePasswordContainer));
