import { alertConstants } from './../constants/AlertConstants';

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.OPEN_MODAL_ALERT:
      return {
        ...action.payload, open: true
      };
    case alertConstants.CLOSE_MODAL_ALERT:
      return {};
    default:
      return state;
  }
}
