import { ganaPointsConstants } from './../constants/GanaPointsConstants';

const initialState = {
  resultGanaPoints:{
    loading: false,
    results: null,
    userPoints: null,
    error: null
  }
};

export function ganaPoints(state = initialState, action) {
  switch (action.type) {
    case ganaPointsConstants.GANA_POINTS_REQUEST:
      return {
        ...state,
        resultGanaPoints: {
          loading: true
        }
      };
    case ganaPointsConstants.GANA_POINTS_SUCCESS:
      return {
        ...state,
        resultGanaPoints: {
          results: action.payload
        }
      };
    case ganaPointsConstants.GANA_POINTS_FAILURE:
      return {
        ...state,
        resultGanaPoints: {
          error: action.error
        }
      };
    case ganaPointsConstants.USER_POINTS_REQUEST:
      return {
        ...state,
        resultGanaPoints: {
          loading: true
        }
      };
    case ganaPointsConstants.USER_POINTS_SUCCESS:
      return {
        ...state,
        resultGanaPoints: {
          userPoints: action.payload
        }
      };
    case ganaPointsConstants.USER_POINTS_FAILURE:
      return {
        ...state,
        resultGanaPoints: {
          error: action.error
        }
      };
    default:
      return state;
  }
}
