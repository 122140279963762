import { sliderConstants } from './../constants/SliderConstants';

const initialState = {
  sliderHome:{
    loading: false,
    sliders: [],
    error: null
  }
};

export function slider(state = initialState, action) {
  switch (action.type) {
    case sliderConstants.HOME_SLIDER_REQUEST:
      return {
        ...state,
        sliderHome: {
          loading: true
        }
      };
    case sliderConstants.HOME_SLIDER_SUCCESS:
      return {
        ...state,
        sliderHome: {
          sliders: action.payload
        }
      };
    case sliderConstants.HOME_SLIDER_FAILURE:
      return {
        ...state,
        sliderHome: {
          error: action.error
        }
      };
    default:
      return state;
  }
}
