import 'whatwg-fetch';
import { getAuthApp } from '../services/security/authApp';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  response = (response);
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

class SliderService {

  static loadSlidersHome() {

    const url = `${serverURL}/api/sliders`;

    return fetch(url, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default SliderService;
