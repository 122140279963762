// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row } from 'reactstrap';

//Actions
import { getCart } from './../../../actions/CartActions';
import { loadListPages } from './../../../actions/GeneralActions';
import { getUserInfo } from './../../../actions/UserActions';

class Content extends Component {
  static propTypes = {
    body: PropTypes.object.isRequired
  };

  componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
    this.props.getUserInfo();
  }

  componentWillMount() {
    this.props.getUserInfo();
  }

  componentDidMount () {
    this.props.getCart();
    this.props.loadListPages();
  }

  render() {
    const { body } = this.props;

    return (
      <Container fluid className="container-main">
        <Row className="row-container">
          {body}
        </Row>
      </Container>

    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCart() {
      dispatch(getCart());
    },
    loadListPages() {
      dispatch(loadListPages());
    },
    getUserInfo() {
      dispatch(getUserInfo());
    }
  };
};

export default connect(null, mapDispatchToProps)(Content);
