import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import AccountForm from './../../components/Account/AccountForm';
import { registerUser, toggleModalLogin } from './../../actions/UserActions';
import { withRouter } from 'react-router-dom';
import { mayoriaEdad } from '../../constants/AccountConstants';
import { tratamientos } from '../../constants/AccountConstants';
import { politicas } from '../../constants/AccountConstants';
class AccountContainer extends Component {
  constructor(props) {
    super(props);

    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleBackHome = this.handleBackHome.bind(this);
    this.getObligacion = this.getObligacion.bind(this);
  }

  handleOnSave = formValues => {
    const values = JSON.parse(JSON.stringify(formValues));
    const primerApellido = values.primerApellido.trim().split(' ');
    const apellido1 = primerApellido[0];
    const apellido2 = primerApellido.slice(1).join(' ');
    delete values.emailConfirm;
    delete values.contrasenaConfirm;
    values.terminosYCondiciones = values.politicas ? politicas.SI : politicas.PENDIENTE;
    values.tratamientoDeDatos = values.tratamientos ? tratamientos.SI : tratamientos.PENDIENTE;
    values.primerApellido = apellido1;
    values.segundoApellido = apellido2;
    values.celular = values.celular.replace(/[^\d]/g, '');
    values.obligacionFiscal = this.state.valorObligacion === null ? 'R-99-PN' : this.state.valorObligacion.toString();
    values.mayoriaEdad = values.mayoriaEdad ? mayoriaEdad.SI : mayoriaEdad.PENDIENTE;
    values.fechaExpedicionDocumento = values.dayDc + '-' + values.monthDc + '-' + values.yearDc;
    values.fechaNacimiento = values.dayFn + '-' + values.monthFn + '-' + values.yearFn;
    delete values.politicas;
    delete values.tratamientos;
    this.props.registerUser(values);
  };

  handleBackHome = () => {
    this.props.toggleModalLogin('2');
    this.props.history.push('/');
  };

  getObligacion = (x) => {
    this.setState({valorObligacion: x});
  };


  render() {
    const { registering, error, registered } = this.props;
    //const registering = false, error = false, registered = true;
    const fields = {
      new_experience_loti: true
    };

    const messageSuccesAcount = () => {
      return (
        <div>
          <h4 className="mb-4 font-montserrat-bold">Cuenta Creada</h4>
          <p className="mb-4">
            Tu cuenta ha sido creada con éxito, te hemos enviado un correo de confirmación con un link para que verifiques
            tu cuenta, y que de esta forma comiences a utilizar nuestros servicios
          </p>
          <button
            type="button"
            className="btn btn-primary mb-3"
            onClick={() => this.handleBackHome()}
          >
            Regresar al Inicio
          </button>
          <p>
            Recuerda que puedes usar esta cuenta en nuestros sitios
            web <a href="https://cuenta.oki.store/login" target="_blank">OKI</a>/<a href="https://www.gana.com.co/login" target="_blank">Gana</a>/<a href="/" >LotiColombia</a>
          </p>
        </div>
      );
    };

    return (

      <section>
        {registered ?
          messageSuccesAcount() :
          <section>
            <h4 className="mb-4 font-montserrat-bold">Completa los campos necesarios</h4>
            <Row className="justify-content-center">
              <Col xs="12">
                <AccountForm
                  onSubmit={this.handleOnSave}
                  loadingForm={registering}
                  initialValues={fields}
                  getObligacion = {this.getObligacion}
                />
                {error && <Alert className="mt-3 mb-0" color="danger"> {error} </Alert>}
              </Col>
            </Row>
          </section>
        }
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { registering, error, registered } = state.registration;
  return {
    registering,
    error,
    registered
  };
}

const mapDispatchToProps = dispatch => {
  return {
    registerUser(values) {
      dispatch(registerUser(values));
    },
    toggleModalLogin(tab) {
      dispatch(toggleModalLogin(tab));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountContainer));
