import 'whatwg-fetch';
import { getAuthApp } from '../services/security/authApp';
import { decrypt } from './security/encryptorHandlerSecurity';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

class LotteriesService {

  static lotteriesHome(values) {
    //Pide Token solo token
    const url = `${serverURL}/api/Users/etn/consultar-loterias-disponibles`;
    const data = JSON.stringify({ lottery : { ...values } }, null, 2);

    return fetch(url, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(handleResponse)
      .then(lotteries => {
        return lotteries;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static lotteriesFakeHome(values) {

    const url = 'http://localhost:3004/loterias';

    return fetch(url, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(lotteries => lotteries)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static lotteriesList(values) {
    //fecha y evento en null no pide token
    const url = `${serverURL}/api/Users/etn/consultar-horarios-loterias`;
    const data = JSON.stringify({ lottery : { ...values } }, null, 2);

    return fetch(url, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static isWinnerLotterie(values) {
    const url = `${serverURL}/api/Users/etn/consultar-numero-ganador`;
    const data = JSON.stringify({ number : { ...values } }, null, 2);

    return fetch(url, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default LotteriesService;
