import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';

//Components
import { addProductCart } from './../../actions/CartActions';
import ModalComponent from './../../components/Modal/ModalComponent';
import ProductAdded from './../../components/Cart/ProductAdded';
import ReloadsPhoneForm from './../../components/Reloads/ReloadsPhone/ReloadsPhoneForm';

class ReloadsPhoneContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueReload: 0
    };

    this.handleReloadPhone = this.handleReloadPhone.bind(this);
  }

  handleReloadPhone = formValues => {
    const values = JSON.parse(JSON.stringify(formValues));
    values.celular = values.celular.replace(/[^\d]/g, '');
    values.valor = parseInt(values.valor.replace(/[^\d]/g, ''));
    values.tipo = 'Recarga Celular';

    const operator = this.props.listOperators.filter((operator) => operator.ideProducto === parseInt(values.operador));
    values.allOperator = operator[0];

    this.setState({ valueReload: values.valor });

    values.ideProducto = values.allOperator.ideProducto;
    values.sigla = values.allOperator.sigla;
    values.numero = values.celular;
    values.vlrRecarga = values.valor;
    values.ideOperador = values.allOperator.ideOperadorGana;
    values.ideFormaPago = 5;

    this.props.addProductCart(values, 'recharges');
  }

  render() {
    const { loading, error, succes } = this.props;
    return (
      <Row>
        {!loading && succes &&
          <ModalComponent className="modal-dialog-centered modal-lg">
            <ProductAdded typeReload='Recarga Celular' valueReload={this.state.valueReload}/>
          </ModalComponent>
        }
        <Col xs={12} className="mb-4 p-0">
          <ReloadsPhoneForm
            onSubmit={this.handleReloadPhone}
            loadingForm={loading}
          />
          {!loading && error && <Alert className="mt-3 mb-0" color="danger"> {error} </Alert>}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) =>{
  const { listOperators } = state.reloads.operators;
  const { loading, error, succes } = state.cart;
  return {
    loading,
    error,
    succes,
    listOperators
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addProductCart(values, typeProduct) {
      dispatch(addProductCart(values, typeProduct, 'ReloadsPhoneForm'));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReloadsPhoneContainer);
