// Dependencies
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './shared/routes';
import { PrivateRoute } from './components/Global/PrivateRoute';
import { useGaTracker } from './useGATracker';


// Components
import App from './App';

const routesMap = routes.map((route, i) => {
  return (
    route.private ? <PrivateRoute key={i} {...route} /> : <Route key={i} {...route} />
  );
});

const AppRoutes = () =>{
  useGaTracker();
  
  return (
    <App>
      <Switch>
        {routesMap}
      </Switch>
    </App> );
};

export default AppRoutes;
