import { userConstants } from './../constants/UserConstants';

const initialState = {
  application:{
    loading: false,
    success: false,
    error: null
  },
  change:{
    loading: false,
    success: false,
    error: null
  },
  userToken:{
    loadingUserToken: false,
    successUserToken: false,
    errorUserToken: null
  },
  recovery:{
    loading: false,
    success: false,
    error: null
  }
};

export function changePassword(state = initialState, action) {
  switch (action.type) {
    case userConstants.REQUEST_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        application: {
          loading: true
        }
      };
    case userConstants.REQUEST_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        application: {
          success: true
        }
      };
    case userConstants.REQUEST_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        application: {
          error: action.payload
        }
      };
    case userConstants.REQUEST_CHANGE_PASSWORD_CLEAR:
      return {
        ...state,
        application: {
          loading: false,
          success: false,
          error: null
        }
      };
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        change: {
          loading: true
        }
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        change: {
          success: true
        }
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        change: {
          error: action.payload
        }
      };
    case userConstants.CHANGE_PASSWORD_CLEAR:
      return {
        ...state,
        change: {
          loading: false,
          success: false,
          error: null
        }
      };
    case userConstants.GET_USER_WITH_TOKEN_REQUEST:
      return {
        ...state,
        userToken: {
          loadingUserToken: true
        }
      };
    case userConstants.GET_USER_WITH_TOKEN_SUCCESS:
      return {
        ...state,
        userToken: {
          successUserToken: action.payload
        }
      };
    case userConstants.GET_USER_WITH_TOKEN_FAILURE:
      return {
        ...state,
        userToken: {
          errorUserToken: action.payload
        }
      };
    case userConstants.RECOVERY_PASSWORD_REQUEST:
      return {
        ...state,
        recovery: {
          loading: true
        }
      };
    case userConstants.RECOVERY_PASSWORD_SUCCESS:
      return {
        ...state,
        recovery: {
          success: action.payload
        }
      };
    case userConstants.RECOVERY_PASSWORD_FAILURE:
      return {
        ...state,
        recovery: {
          error: action.payload
        }
      };
    default:
      return state;
  }
}
