//Dependencis
import React from 'react';
import { connect } from 'react-redux';

import { toggleModalGeneral } from './../../../actions/ModalActions';
import { Link } from 'react-router-dom';
import campana from '../../../assets/images/campana.svg';

const IsWinnerFail = ({ toggleModalGeneral }) => {
  return (
    <section className="p-5 bg-white border-rad-lg">
      <div className='text-center'>
        <img src={campana} />
      </div>
      <h3 className="text-center first-title-consulta mb-4 color-black">Lo sentimos</h3>
      <p className="text-center mb-4 text-size-medium text1 color-black">No has ganado esta vez. Pero recuerda <br /> que puedes seguir intentándolo.</p>
      <p className="text-center mb-4 text-size-medium text2 color-black">Estamos seguros que tendrás mejor <br /> suerte para la próxima</p>
      <div className="d-flex text-center justify-center content-center align-items-center">
        <Link onClick={toggleModalGeneral} className="button-rounded button-rounded-gradient-resultados px-30 py5" to="/loterias">
          <div className="center-right-button">
            Volver a jugar
          </div>
        </Link>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModalGeneral() {
      dispatch(toggleModalGeneral());
    }
  };
};

export default connect(false, mapDispatchToProps)(IsWinnerFail);
