import { userConstants } from './../constants/UserConstants';

const user = JSON.parse(localStorage.getItem('userLoti'));
const initialState = {
  loggedIn: (user ? true : false),
  loggingIn: false,
  user: (user ? user : null),
  error: null
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_USER_REQUEST:
      return {
        loggingIn: true
      };
    case userConstants.LOGIN_USER_SUCCESS:
      return {
        loggedIn: true,
        user: action.payload
      };
    case userConstants.LOGIN_USER_FAILURE:
      return {
        error: action.payload
      };
    case userConstants.LOGIN_USER_CLEAR:
      return {
        ...state,
        error: null,
        loggingIn: false
      };
    case userConstants.LOGOUT_USER:
      return {
        loggedIn: false,
        loggingIn: false,
        user: null,
        error: null
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
}
