import React from 'react';
import { Col, Row } from 'reactstrap';

const SubpageBlock = ({ subpage }) => {
  return (
    <div>
      <Row className="m-0 align-items-center mb50">
        <Col lg={7} className="p-0">
          <h4 className="mb-4 text-left text-bold">{subpage.title}</h4>
          <article dangerouslySetInnerHTML={{__html: subpage.description}} />
        </Col>
        <Col lg={5} className="pr-0">
          <figure class="ratio-2-1">
            <img src={subpage.image} className="d-block w-100"/>
          </figure>
        </Col>
      </Row>
    </div>
  );
};

export default SubpageBlock;

