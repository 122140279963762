import { sliderConstants } from './../constants/SliderConstants';
import SliderService from './../services/SliderService';

//====================================================================
// Loading Home News
//====================================================================

export const loadSliderHome = (values) => (dispatch) => {

  const sliderHomeRequest = () => ({
    type: sliderConstants.HOME_SLIDER_REQUEST
  });

  const sliderHomeSuccess = payload => ({
    type: sliderConstants.HOME_SLIDER_SUCCESS,
    payload
  });

  const sliderHomeError = error => ({
    type: sliderConstants.HOME_SLIDER_FAILURE,
    error
  });

  dispatch(sliderHomeRequest());
  SliderService.loadSlidersHome()
    .then(
      sliders => {
        dispatch(sliderHomeSuccess(sliders));
      },
      error => {
        dispatch(sliderHomeError(error.message ? error.message : 'Ocurrió un error al momento de cargar los sliders'));
      }
    );

};
