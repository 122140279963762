import { resultsConstants } from './../constants/ResultsConstants';
import ResultsService from './../services/ResultsService';

//====================================================================
// Loading Home Results
//====================================================================

export const loadResultsHome = (values, completos) => (dispatch) => {

  const resultsHomeRequest = () => ({
    type: resultsConstants.HOME_RESULTS_REQUEST
  });

  const resultsHomeSuccess = payload => ({
    type: resultsConstants.HOME_RESULTS_SUCCESS,
    payload
  });

  const resultsHomeError = error => ({
    type: resultsConstants.HOME_RESULTS_FAILURE,
    error
  });

  dispatch(resultsHomeRequest());
  ResultsService.loadResultsHome(values, completos)
    .then(
      results => {
        results = !completos ? (Array.isArray(results.data) ? results.data : []) : (results.data.resultadosLoteria ? results.data.resultadosLoteria : []);
        results = Array.isArray(results) ? results : [results];
        dispatch(resultsHomeSuccess(results));
      },
      error => {
        dispatch(resultsHomeError(error.message ? error.message : 'Ocurrió un error al momento de traer los resultados'));
      }
    );

};
