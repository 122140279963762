import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormText } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';

const key = process.env.REACT_APP_GOOGLE_RECAPTCHA;
const renderCaptcha = ({input, label, children, icon, meta: {touched, error, warning}, ...rest}) => {
  const handleChange = (response) => {
    input.onChange(response);
  };

  return (
    <FormGroup>
      <div className="position-relative reCaptchaContainer">
        {key &&
          <ReCAPTCHA
            sitekey={key}
            onChange={handleChange}
          />
        }
      </div>
      {touched &&
      ((error && <FormText>{error}</FormText>) ||
      (warning && <FormText>{warning}</FormText>))}
    </FormGroup>
  );
};

renderCaptcha.propTypes = {
  input: PropTypes.object.isRequired
};

export default renderCaptcha;
