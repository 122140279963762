import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { EventsGaTracker } from '../../useGATracker';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';
import { loadLotteriesHome } from './../../actions/LotteriesActions';
import { setLotteryToPlay } from './../../actions/GameActions';
import Lotterie from './../../components/Lotteries/Lotterie';

class LotteriesPageContainer extends Component {


  constructor(props) {

    super(props);
    this.handleOnClickLotterie = this.handleOnClickLotterie.bind(this);
    this.handleOnClickLotterie2 = this.handleOnClickLotterie2.bind(this);

    this.state = {
      dataLoteria: null,
      initialValue: false
    };
  }

  componentDidMount() {
    this.props.loadLotteriesHome({
      fecha: null,
      clase: 1
    });

    const userDataJSON = localStorage.getItem('userData');
    setTimeout(() => {
      if (userDataJSON) {
        const userData = JSON.parse(userDataJSON);
        const dataLoteria = userData.dataLoteria ? userData.dataLoteria : null;
        const initialValue = userData.initialValue ? userData.initialValue : false;
        this.setState({ dataLoteria, initialValue });
      }
    }, 1000);

  }

  componentWillUnmount () {
    const userDataJSON = localStorage.getItem('userData');
    setTimeout(() => {
      if (userDataJSON) {
        const userData = JSON.parse(userDataJSON);
        userData.dataLoteria = null;
        const userDataJSONSave = JSON.stringify(userData);
        localStorage.setItem('userData', userDataJSONSave);
      }
    }, 1000);
  }

  handleOnClickLotterie(idLotterie, sorteo) {

    EventsGaTracker('loterias', 'Carga de loterias');
    const selectedLoti = this.props.lotteries.filter(lotterie => lotterie.ideLoteria === idLotterie && lotterie.sorteo === sorteo)[0];
    this.props.setLotteryToPlay(selectedLoti);
    this.props.history.push(`/juego/${idLotterie}/${sorteo}`);
  }

  handleOnClickLotterie2(idLotterie, sorteo) {
    EventsGaTracker('loterias', 'Carga de loterias');
    const selectedLoti = this.props.lotteries.filter(lotterie => lotterie.ideLoteria === idLotterie && lotterie.sorteo === sorteo)[0];
    this.props.setLotteryToPlay(selectedLoti);
    this.props.history.push(`/juego/${idLotterie}/${sorteo}`);

    const { dataLoteria, initialValue } = this.state;
    const caracteres = initialValue.split('');
    const cadenaConGuion = caracteres.join('-');
    localStorage.setItem('numero', cadenaConGuion);
  }

  render() {
    const { loading, error, lotteries } = this.props;
    const baseURL = process.env.REACT_APP_BASEURL;
    const { dataLoteria, initialValue } = this.state;  
    const shownElements = {};

  
    return (
      <div>
        {loading && <Row><CircularProgress /></Row>}
        {!error && (lotteries && lotteries.length ?
          <Row>
            {/*---- Main Loteries ----*/}
            {!dataLoteria && lotteries.slice(0, 1).map((item, i) => {
              return (
                <Col key={i} xs={12} className="mb-4">
                  <Lotterie size="lg"
                    value={item.premioMayor ? item.premioMayor.vlrBrutoBillete : 0}
                    icon={`${baseURL}/assets/images/loterias/${item.abreviatura}.png`}
                    date={`${item.fecCierre} ${item.horaCierre}`}
                    name={item.desLoteria}
                    sorteo={item.sorteo}
                    id={item.ideLoteria}
                    onLotterieClick={() => this.handleOnClickLotterie(item.ideLoteria, item.sorteo)}
                  />
                </Col>
              );
            })}
            {/*---- End Main Loteries ----*/}

            {/*---- Second Loteries ----*/}
            {!dataLoteria && lotteries.length > 1 && lotteries.slice(1, 3).map((item, i) => {
              return (
                <Col key={i} xs={12} sm={6} className="mb-4">
                  <Lotterie size="md"
                    value={item.premioMayor ? item.premioMayor.vlrBrutoBillete : 0}
                    icon={`${baseURL}/assets/images/loterias/${item.abreviatura}.png`}
                    date={`${item.fecCierre} ${item.horaCierre}`}
                    name={item.desLoteria}
                    sorteo={item.sorteo}
                    id={item.ideLoteria}
                    onLotterieClick={() => this.handleOnClickLotterie(item.ideLoteria, item.sorteo)}
                  />
                </Col>
              );
            })}
            {/*---- End Second Loteries ----*/}

            {/*---- Third Loteries ----*/}
            {!dataLoteria && lotteries.length > 3 && lotteries.slice(3, lotteries.length).map((item, i) => {
              return (
                <Col key={i} xs={12} sm={6} md={6} lg={6} className="mb-4">
                  <Lotterie size="sm"
                    value={item.premioMayor ? item.premioMayor.vlrBrutoBillete : 0}
                    icon={`${baseURL}/assets/images/loterias/${item.abreviatura}.png`}
                    date={`${item.fecCierre} ${item.horaCierre}`}
                    name={item.desLoteria}
                    sorteo={item.sorteo}
                    id={item.ideLoteria}
                    numeroFavorito={item.numeroFavorito}
                    onLotterieClick={() => this.handleOnClickLotterie(item.ideLoteria, item.sorteo)}
                  />
                </Col>
              );
            })}
            {/*---- End Third Loteries ----*/}


            {/*---- Ford Loteries ----*/}
            {dataLoteria && lotteries.slice(0, lotteries.length).map((item, i) => {
              return dataLoteria.map((data, index) => {
                if (item.ideLoteria === data.idLoteria) {
                  if (!shownElements[item.ideLoteria]) {
                    shownElements[item.ideLoteria] = true;

                    return (
                      <Col key={i} xs={12} sm={6} md={6} lg={6} className="mb-4">
                        <Lotterie size="sm"
                          value={item.premioMayor ? item.premioMayor.vlrBrutoBillete : 0}
                          icon={`${baseURL}/assets/images/loterias/${item.abreviatura}.png`}
                          date={`${item.fecCierre} ${item.horaCierre}`}
                          name={item.desLoteria}
                          sorteo={item.sorteo}
                          id={item.ideLoteria}
                          numeroFavorito={initialValue}
                          onLotterieClick={() => this.handleOnClickLotterie2(item.ideLoteria, item.sorteo)}
                        />
                      </Col>
                    );
                  } 
                }
                return null; 
              });
            })}
            {/*---- End Ford Loteries ----*/}
          </Row> : !loading &&
          <Alert className="my-2 content-center" color="light">
            Lo sentimos, en este momento no tenemos loterías disponibles para jugar
          </Alert>
        )}
        {error && <Alert className="my-2 content-center" color="danger"> {error} </Alert>}
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
  const { loading, error, lotteries } = state.lotteries.lotteriesHome;
  return {
    loading,
    error,
    lotteries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadLotteriesHome(values) {
      dispatch(loadLotteriesHome(values));
    },
    setLotteryToPlay(lottery) {
      dispatch(setLotteryToPlay(lottery));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LotteriesPageContainer));
