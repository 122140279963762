import { alertConstants } from './../constants/AlertConstants';

//====================================================================
// Toggle Modal Alert
//====================================================================

export const openModalAlert = (payload) => {
  return {
    type: alertConstants.OPEN_MODAL_ALERT,
    payload
  };
};

export const closeModalAlert = () => {
  return {
    type: alertConstants.CLOSE_MODAL_ALERT
  };
};
