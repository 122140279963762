import { payprizeConstants } from '../constants/PayPrizeConstants';

const initialState = {
  payprizeHome:{
    loading: false,
    payprize: null,
    error: null
  },
  single:{
    loading: false,
    payprize: null,
    error: null
  }
};

export function payprize(state = initialState, action) {
  switch (action.type) {
    case payprizeConstants.HOME_PAYPRIZE_REQUEST:
      return {
        ...state,
        payprizeHome: {
          loading: true
        }
      };
    case payprizeConstants.HOME_PAYPRIZE_SUCCESS:
      return {
        ...state,
        payprizeHome: {
          payprize: action.payload
        }
      };
    case payprizeConstants.HOME_PAYPRIZE_FAILURE:
      return {
        ...state,
        payprizeHome: {
          error: action.error
        }
      };
    case payprizeConstants.SINGLE_PAYPRIZE_REQUEST:
      return {
        ...state,
        single: {
          loading: true
        }
      };
    case payprizeConstants.SINGLE_PAYPRIZE_SUCCESS:
      return {
        ...state,
        single: {
          payprize: action.payload
        }
      };
    case payprizeConstants.SINGLE_PAYPRIZE_FAILURE:
      return {
        ...state,
        single: {
          error: action.error
        }
      };
    default:
      return state;
  }
}
