import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Row, Col, Card, CardTitle, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';

//Components
import CountDown from './CountDown';
import { formatMoney } from './../../helpers/formatMoney';
import Modal from '../Modal/ModalPlanPremios';
import ModalDetalle from '../Modal/ModalDetalleLoteria';

//Assets
import Reloj from '../../assets/images/Reloj.svg';
import NumberFav from '../../assets/images/I-Number-fav.svg';

const Lotterie = ({ size, value, icon, date, name, numeroFavorito, horaCierre, sorteo, id, onLotterieClick }) => {
  
  const _date = date.replace(' ', 'T');
  const day = moment(_date).format('DD MMMM');
  const hour = moment(_date).format('h:mm a');

  const textTime = () => {
    const countDownDate = new Date(_date).getTime();
    // Get todays date and time
    const now = new Date().getTime();

    const diff = countDownDate - now;
    const days = (diff / (1000 * 60 * 60 * 24));
    if (days > 1) {
      return (
        `${day} / ${hour}`
      );
    } else {
      return (<CountDown date={_date} />);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalDetalle, setShowModalDetalle] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModalDetalle = () => {
    setShowModalDetalle(true);
  };

  const closeModalDetalle = () => {
    setShowModalDetalle(false);
  };

  if (size === 'lg') {
    return (
      <Card body className="text-center single-lotterie single-loteri">
        <Row className="m-0 align-items-center single-lotterie-lg large_lotterie">
          <Col md="3" lg="4" className="p-0">
            <div className="mb-3 image-lotterie-jugar mx-auto">
              <img className="d-block" src={icon} alt={`Icono lotería ${name}`} />
            </div>
          </Col>
          <Col md="9" lg="8" className="pr-0">
            <Row className="align-items-center">
              <Col xs={12} sm={12} md={5} lg={6} xl={7} className="p-0 large_lotterie_text">
                <CardTitle tag="h5"><strong>Premio mayor:</strong> <br /> <span>{`$ ${formatMoney(value, 0, 3, '.', ',')}`}</span> </CardTitle>

                <CardText>{textTime()}</CardText>
              </Col>
              <Col xs={12} sm={12} md={7} lg={6} xl={5}>
                <Link className="button-rounded button-rounded-white" to={`/plan-premios/${id}`}>
                  <div className="button-rounded button-rounded-gradient-resultados px-30 py5">
                    <span>Plan de premios</span>
                  </div>
                </Link>
                <br />
                <button
                  type="button"
                  className="button-rounded button-rounded-gradient-jugar-resultado px-30 py5"
                  onClick={onLotterieClick}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Jugar ahora</span>
                  </div>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  }

  if (size === 'md') {
    return (
      <Card body className="text-center single-lotterie single-lotterie-flex single-loteri">
        <Row className="m-0 align-items-center single-lotterie-md large_lotterie">
          <Col className="p-0">
            <Row className="align-items-center">
              <Col md={4} className="pt-2">
                <div className="image-lotterie mx-auto">
                  <img className="d-block" src={icon} alt={`Icono lotería ${name}`} />
                </div>
              </Col>
              <Col md={8} className="large_lotterie_text">
                <CardTitle className="text-center" tag="h5"><strong class="single">Premio mayor:</strong> <br /> <span>{`$ ${formatMoney(value, 0, 3, '.', ',')}`}</span> </CardTitle>
                <CardText className="text-center">{textTime()}</CardText>
                <button
                  type="button"
                  className="button-rounded button-rounded-gradient-jugar-resultado px-30 py5"
                  onClick={onLotterieClick}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Jugar ahora</span>
                  </div>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  }

  const valor = value / 1000000;
  const numberFavortio = numeroFavorito ? numeroFavorito.toString().split('') : [0, 0, 0, 0];

  return (
    <Card body className="text-center single-lotterie single-lotterie-flex single-loteri">
      <Row className="m-0 align-items-center large_lotterie">
        <Col xs="4" sm="12" md="5" className="single-numer-one">
          <div>
            <Modal
              title="Plan de premios"
              show={showModal}
              onClose={closeModal}
              idLotterieURL={id}
            />
          </div>
          <div>
            <ModalDetalle
              title="Detalle lotería"
              show={showModalDetalle}
              onClose={closeModalDetalle}
              idLotterieURL={id}
              date={textTime()}
            />
          </div>
          <div className="mb-3 image-lotterie twoColumnsLotterie mx-auto">
            <img className="d-block" src={icon} alt={`Icono lotería ${name}`} />
          </div>
          <h4 className='single-name'>{name}</h4>

          <br />

          <div className='single-ciere mx-auto' style={{ display: 'flex', alignItems: 'center', width: '90px' }}>
            <img className='single-reloj' src={Reloj} style={{ margin: 5, width: '50px' }} /><h6 className='single-h6' style={{ margin: 0, width: '150px' }}>Las ventas cierran a las:</h6>
          </div>

          <br />

          <CardText className='single-conteo'>{textTime()}</CardText>
        </Col>
        <Col xs="8" sm="12" md="7" className="p-0 large_lotterie_text position-pre">
          <strong className='single-pre'>PREMIO MAYOR</strong> <br />
          <span className='single-money-s' texto='$'>$</span>
          <span className='single-money' texto={`${formatMoney(valor, 0, 3, '.', ',')}`}>{`${formatMoney(valor, 0, 3, '.', ',')}`}</span>
          <h6 className='single-millones'>⭑⭑ MILLONES ⭑⭑</h6>

          <a
            className="btn single-button button-rounded button-rounded-gradient-resultados"
            onClick={onLotterieClick}
          >
            <span className='single-span'>Jugar</span>
          </a>
          <hr className='single-hr' />

          <div className='single-number-fav'>
            <h6 className='single-number-fav-h6'><img src={NumberFav} />&nbsp; Número favorito</h6>
            {numberFavortio.map((number, i) => (
              <span key={i} className="rounded-number-new">{number}</span>
            ))}
          </div>

          <hr className='single-hr' />

          <div className='single-actions-modal' >
            <a className='single-actions' onClick={openModal}>Plan de premios &nbsp;</a>
            <span className='single-line8'>/</span> <a className='single-actions' onClick={openModalDetalle}>&nbsp; Detalle lotería</a>
          </div>

        </Col>
      </Row>
    </Card>
  );
};

export default Lotterie;
