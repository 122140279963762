import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Row } from 'reactstrap';
import Slider from 'react-slick';

// Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';
import { loadNewsHome } from './../../actions/NewsActions';
import SingleNews from './../../components/News/SingleNews';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomPrevArrow = ({ onClick }) => (
  <button className="custom-carousel-arrow custom-carousel-prev-arrow" onClick={onClick}/>
);

const CustomNextArrow = ({ onClick }) => (
  <button className="custom-carousel-arrow custom-carousel-next-arrow" onClick={onClick}/>
);

const NewsHomeContainer = ({ loading, error, news, loadNewsHome }) => {
  useEffect(() => {
    loadNewsHome();
  }, [loadNewsHome]);

  const sortedNews = news && [...news].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  let slidesToShows = 3;

  if (news && news.length < 3) {
    slidesToShows = 1;
  } else if (news && news.length < 4) {
    slidesToShows = 2;
  }

  const sliderRef = useRef(null);
  const [currentCarousel, setCurrentCarousel] = useState(0);

  const prevCarousel = () => {
    sliderRef.current.slickPrev();
  };

  const nextCarousel = () => {
    sliderRef.current.slickNext();
  };

  const settings = {
    infinite: true,
    arrows: true,
    prevArrow: <CustomPrevArrow onClick={prevCarousel} />,
    nextArrow: <CustomNextArrow onClick={nextCarousel} />,
    initialSlide: currentCarousel,
    beforeChange: (_, nextSlide) => setCurrentCarousel(nextSlide),
    className: 'custom-carousel' + slidesToShows,
    slidesToShow: slidesToShows,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const renderCarousel = (newsGroup, index) => (
    <Slider ref={sliderRef} key={index} {...settings}>
      {newsGroup.map((newsItem, i) => (
        <div className="custom-carousel-item" key={i}>
          <div className="news-item-wrapper">
            <SingleNews {...newsItem} />
          </div>
        </div>
      ))}
    </Slider>
  );

  const renderCarousels = () => {
    if (!sortedNews) {
      return null;
    }

    const groups = [sortedNews];

    return groups.map((group, index) => (
      <Col className="p-4" xs={12} sm={12} key={index}>
        {renderCarousel(group, index)}
      </Col>
    ));
  };

  return (
    <div className="mt-3 last-child-nmrg-b scroll-bar-container" style={{ overflow: 'hidden' }}>
      {loading ? (
        <Col xs={12}><CircularProgress /></Col>
      ) : (
        <Row className="carousel justify-content-center">
          {renderCarousels()}
        </Row>
      )}
      {error && <Alert className="my-2 content-center" color="danger"> {error} </Alert>}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loading, error, news } = state.news.newsHome;
  return {
    loading,
    error,
    news
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadNewsHome: () => {
      dispatch(loadNewsHome());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsHomeContainer);
