//Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Col, Row, Alert } from 'reactstrap';
import { loadOperators, loadPackages } from './../../../actions/ReloadsActions';

//Components
import renderField from './../../renderFields/renderField';
import renderSelect from './../../renderFields/renderSelect';
import renderCheckBox from './../../renderFields/renderCheckBox';
import renderRadioPackages from './renderRadioPackages';
import CircularProgress from './../../Global/CircularProgress/CircularProgress';

//Utils
import Validators from './../../renderFields/validatorsFields';
import { normalizePhone, normalizeCurrency } from './../../renderFields/normalizeFields';

//Images
import iconClaro from './../../../assets/images/logo-claro.jpg';

//Actions
import { getPageInfo } from './../../../reducers/generalReducer';

const labelPoliticas = `<span>
  Acepto los <a href="/terminos-y-condiciones" target="_blank">Términos y condiciones</a> y
  las <a href="/politicas-privacidad" target="_blank">Políticas de tratamiento de datos</a>
</span>
  `;

const validate = values => {
  const errors = {};

  if (!values.paquete) {
    errors.paquete = 'Debes seleccionar por lo menos un paquete para poder añadirlo al carrito de compras';
  }

  return errors;
};

class ReloadsPackagesForm extends Component {

  componentDidMount() {
    this.props.loadOperators('S');
  }

  render () {
    const {
      handleSubmit,
      onDelete,
      pristine,
      classForm,
      reset,
      submitting,
      loadingForm,
      errorForm,
      readOnly,
      loadingOperators,
      listOperators,
      loadingPackages,
      listPackages,
      errorPackages,
      info,
      politicasValue,
      operadorValue
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className={`${classForm} form-reloads-phone`}>
        <section className="border mt-5 mb-4 p-4 back-white border-container">
          <h3 className="first-title text-center my-5">Recargas de <span className="color-main">Paquetes</span></h3>
          <div className="font-montserrat-light text-center text-size-medium mb-5" dangerouslySetInnerHTML={{__html: info.description}} />
          <Row>
            <Col xs={12} className="mb-4 p-0">
              <Row className="mx-0 mb-3 align-items-top justify-content-center">
                <Col xs={12} sm={6} md={4}>
                  <Field
                    name="celular"
                    id="reload-celular"
                    type="text"
                    icon="flaticon-phone-call"
                    label="Número de Celular"
                    component={renderField}
                    normalize={normalizePhone}
                    validate={[Validators.required, Validators.phoneNumber]}
                    readOnly={readOnly}
                  />
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Field
                    name="operador"
                    id="reload-operador"
                    type="select"
                    label="Operador"
                    icon="flaticon-arrows"
                    component={renderSelect}
                    loadingOptions={loadingOperators}
                    validate={[Validators.required, Validators.notNull]}
                    disabled={!listOperators || !listOperators.length}
                    onChange={event => {
                      if (event.target.value !== 'null') {
                        const operator = listOperators.filter(item => item.ideOperadorGana === parseInt(event.target.value));
                        this.props.loadPackages(event.target.value, operator[0]); // load packages in operator selected
                      }
                    }}
                    readOnly={readOnly}
                  >
                    <option value="null">Seleccione el Operador</option>
                    {listOperators && listOperators.length && listOperators.map(option => <option value={option.ideOperadorGana} key={option.ideProducto}>{option.desProducto}</option>)}
                  </Field>
                </Col>
              </Row>


              <Row className="m-0 justify-content-center align-items-center">

                <Col xs={12} sm={12} md={6} lg={5} xl={8} className="text-center">
                  <Field
                    name="politicas"
                    id="reload-politicas"
                    label={labelPoliticas}
                    formGroupClass="mb-0"
                    component={renderCheckBox}
                    validate={[Validators.required]}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={5} xl={12} className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loadingForm || !politicasValue}
                  >
                    Añadir al Carrito
                    {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
                  </button>
                </Col>
              </Row>

              <Row className="mx-0 mb-2">
                {errorForm && <Alert className="mt-3 mb-0 w-100" color="danger"> {errorForm} </Alert>}
              </Row>
            </Col>
          </Row>
        </section>
        <section className="w-100">
          {loadingPackages && <CircularProgress className="mb-3" />}
        </section>
        {(operadorValue && listPackages && listPackages.length > 0) ?
          <Field
            name="paquete"
            id="reload-paquete"
            packagesList={listPackages}
            loading={loadingPackages}
            error={errorPackages}
            component={renderRadioPackages}
          /> : !loadingPackages &&
          <Alert className="mt-3 mb-4 w-100" color="light"> Primero debes seleccionar un operador para poder escoger tu paquete de recarga </Alert>
        }

      </form>
    );
  }
}

const selector = formValueSelector('ReloadsPackagesForm');

const mapStateToProps = (state) =>{
  const { loadingOperators, listOperators } = state.reloads.operators;
  const { loadingPackages, listPackages, errorPackages } = state.reloads.packages;
  return {
    loadingOperators,
    listOperators,
    loadingPackages,
    listPackages,
    errorPackages,
    politicasValue: selector(state, 'politicas'),
    operadorValue: selector(state, 'operador'),
    info: getPageInfo(state, 'recargas-paquetes')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadOperators(typeReload) {
      dispatch(loadOperators(typeReload));
    },
    loadPackages(idOperator, operator) {
      dispatch(loadPackages(idOperator, operator));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'ReloadsPackagesForm', validate})(ReloadsPackagesForm));
