import { pagoPremioConstants } from '../constants/PagoPremioConstants';

const initialState = {
  loading: false,
  pagoPremio: null,
  error: null
};

export function premio(state = initialState, action) {
  switch (action.type) {
    case pagoPremioConstants.LIST_PREMIO_REQUEST:
      return {
        loading: true
      };
    case pagoPremioConstants.LIST_PREMIO_SUCCESS:
      return {
        pagoPremio: action.payload
      };
    case pagoPremioConstants.LIST_PREMIO_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
