import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { toggleModalLogin, activeAccount } from './../../actions/UserActions';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';

class ConfirmAccountContainer extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount () {
    const {
      match: {
        params: {
          token = null
        }
      }
    } = this.props;
    if (token) {
      this.props.activeAccount({token:token});
    } else {
      this.props.history.replace('/');
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.error) {
      this.props.history.replace('/');
    }
  }

  render() {
    const {toggleModal, loading, error, success, user } = this.props;

    return (
      <Col sm="12" md={{ size: 6, offset: 3 }} className="return-seccion">
        <section className="mt-5 mb-5 p-5 border-container">
          <section className="nosotros-section text-center">
            {success && success.mensaje== 'Cuenta activada' && !error &&
            <div id="cuenta-success">
              <h1 className="second-title mt-4 mb-4 textC"><b>¡Cuenta verificada!</b></h1>
              <p className="text-size-medium textC">Tu cuenta ha sido confirmada con éxito, ya puedes disfrutar de todos nuestros servicios y contenidos que ofrecemos en la web</p>
              <p className="text-size-medium textC"><b>¿Qué estás esperando?</b></p>
              <div className="mt30 mb30">
                <button
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={() => toggleModal('1')}
                >
                  Ingresa ahora
                </button>
              </div>
              <small className="t-black textC">Recuerda que puedes usar esta cuenta en nuestros sitios
              web <a className='textC' href="https://cuenta.oki.store/login" target="_self">OKI</a>
              /<a className='textC' href="https://www.gana.com.co/login" target="_blank">Gana</a>/<a className='textC' href="/" >LotiColombia</a></small>
            </div>
            }
            {success && success.mensaje != 'Cuenta activada' && !error &&
              <div id="cuenta-unsuccess">
                <h1 className="second-title mt-4 mb-4 textC"><b>El token ha expirado</b></h1>
                <p className="text-size-medium textC">Se ha enviado un nuevo correo para activar tu cuenta</p>
                <div className="mt30 mb30">
                  <button
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={() => this.props.history.replace('/')}
                  >
                    Regresar al Inicio
                  </button>
                </div>
              </div>
            }
            {loading && !error && !success && <CircularProgress />}
          </section>
        </section>
      </Col>

    );
  }
}

function mapStateToProps(state) {
  const { loading, success, error } = state.confirmAccount;
  return {
    loading,
    success,
    error,
    user: state.authentication.user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    toggleModal(tab) {
      dispatch(toggleModalLogin(tab));
    },
    activeAccount(token) {
      dispatch(activeAccount(token));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmAccountContainer));
