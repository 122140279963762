import React from 'react';
import { Row, Col, Card, CardTitle, CardText, FormText, Alert } from 'reactstrap';
import { formatMoney } from './../../../helpers/formatMoney';

import CircularProgress from './../../../components/Global/CircularProgress/CircularProgress';

const renderRadioPackages = ({input, label, id, packagesList, loading, errorPackages, meta: {touched, error, warning}, ...rest}) => {

  const baseURL = process.env.REACT_APP_BASEURL;
  return (
    <Row className="m-0 list-packages">
      <Col xs={12} className="mb-3">
        {touched &&
        ((error && <FormText className="text-left">{error}</FormText>) ||
        (warning && <FormText className="text-left">{warning}</FormText>))}
      </Col>
      {loading && <Col xs={12}><CircularProgress /></Col>}
      {packagesList.map((pack, index) => {
        return (
          <Col xs={12} sm={6} md={6} lg={4} key={index} className="mb-4">
            <Card body className="text-center single-lotterie single-package">
              <label htmlFor={`package${pack.idePaqueteGANA}`} >
                <div className="single-package-radio">
                  <div className="custom-radio-button">
                    <input
                      type="radio"
                      id={`package${pack.idePaqueteGANA}`}
                      {...input}
                      {...rest}
                      value={pack.value}
                      checked={input.value === pack.value}
                    />
                    <div className="check">
                      <div className="inside"/>
                    </div>
                  </div>
                </div>
                <Row className="m-0 align-items-center">
                  <Col xs="4" sm="12" md="5" lg="12" className="p-0">
                    <img className="mx-auto d-block mb-3" src={`${baseURL}/assets/images/operadores/${pack.operator.sigla}.svg`} alt={'Icono lotería'} />
                  </Col>
                  <Col xs="8" sm="12" md="7" lg="12" className="p-0">
                    <CardTitle className="mb-4 mr-4 mr-md-0"><strong>{pack.desPaquete}</strong></CardTitle>
                    <CardTitle className="mt-3 mr-4 mr-md-0"><span>{`$ ${formatMoney(pack.vlrComercial, 0, 3, '.', ',')}`}</span> </CardTitle>
                  </Col>
                </Row>
              </label>
            </Card>
          </Col>
        );
      })}
      {errorPackages && <Alert className="my-2 content-center" color="danger"> {errorPackages} </Alert>}
    </Row>
  );
};


export default renderRadioPackages;
