import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Col, Container, Row, Div } from 'reactstrap';
import { toggleModalLogin, logoutUser } from './../../actions/UserActions';

const HomePaymentAward = ({ coverHome, descriptionsHome, urlHome, user, toggleModal }) => {
  const renderizarTexto = () => {
    return { __html: descriptionsHome };
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleConoceMasClick = () => {
    if (!user) {
      toggleModal('1');
    } else {
      setIsLoggedIn(true);
    }
  };

  if (isLoggedIn) {
    return <Redirect to={urlHome} />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 text-center align-end posiciones">
          <img class="img-responsive-pago-premio" src={coverHome} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 texto-responcive align-middle text-center">
          <h3 className="texto-responcive my-0 d-inline-block first-title-consulta text-white" dangerouslySetInnerHTML={renderizarTexto()}></h3>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 text-center align-middle boton-acomodar">
          <a to={urlHome} className="button-rounded button-rounded-gradient-resultados px-70 py-7 button-shadow" onClick={handleConoceMasClick}>
            Conócelo aquí
            {/* {user ? 'Conoce más' : 'Iniciar sesión'} */}
          </a>
          {/* {isLoggedIn && <Redirect to="/mi-cuenta/5" />} */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loading, error, user } = state.homepayprize.homepayPrize;
  return {
    loading,
    error,
    user: state.authentication.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal(tab) {
      dispatch(toggleModalLogin(tab));
    },
    logoutUser() {
      dispatch(logoutUser());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePaymentAward);
