import React from 'react';
import { FormGroup, FormText } from 'reactstrap';

const renderRadio = ({input, label, id, radioList, indexRadio, formGroupClass, meta: {touched, error, warning}, ...rest}) => {

  return (
    <FormGroup className={formGroupClass}>
      {radioList.map((radio, index) => {
        const idRadio = indexRadio ? `${indexRadio}radio${radio.value}` : `radio${radio.value}`;
        return (
          <div className="custom-radio-button custom-radio-button-inline d-inline-block mr-3 mb-3" key={index}>
            <input
              type="radio"
              id={idRadio}
              {...input}
              {...rest}
              value={radio.value}
              checked={input.value === radio.value}
            />
            <label htmlFor={idRadio}>{radio.label}</label>
            <div className="check">
              <div className="inside"/>
            </div>
          </div>
        );
      })}
      {touched &&
      ((error && <FormText className="text-left">{error}</FormText>) ||
      (warning && <FormText className="text-left">{warning}</FormText>))}
    </FormGroup>
  );
};


export default renderRadio;
