export const gameConstants = {
  GAME_SERIES_REQUEST: 'GAME_SERIES_REQUEST',
  GAME_SERIES_SUCCESS: 'GAME_SERIES_SUCCESS',
  GAME_SERIES_FAILURE: 'GAME_SERIES_FAILURE',

  GET_SERIE_REQUEST: 'GET_SERIE_REQUEST',
  GET_SERIE_SUCCESS: 'GET_SERIE_SUCCESS',
  GET_SERIE_FAILURE: 'GET_SERIE_FAILURE',

  SET_SERIE_EDITED: 'SET_SERIE_EDITED',

  SET_LOTTERY: 'SET_LOTTERY',

  SET_GAME_FRACTIONS: 'SET_GAME_FRACTIONS',
  DISABLED_GAME_FRACTION: 'DISABLED_GAME_FRACTION',
  ENABLED_GAME_FRACTION: 'ENABLED_GAME_FRACTION',

  RESET_GAME: 'RESET_GAME'
};
