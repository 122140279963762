import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Alert } from 'reactstrap';

//Components
import { addProductCart } from './../../actions/CartActions';
import ModalComponent from './../../components/Modal/ModalComponent';
import ProductAdded from './../../components/Cart/ProductAdded';
import ReloadsPackagesForm from './../../components/Reloads/ReloadsPackages/ReloadsPackagesForm';

class ReloadsPackagesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueReload: 0
    };

    this.handleReloadPackage = this.handleReloadPackage.bind(this);
  }

  handleReloadPackage = values => {
    values.celular = values.celular.replace(/[^\d]/g, '');
    values.tipo = 'Recarga Paquete';

    // const operator = this.props.listOperators.filter((operator) => operator.ideOperadorGana === parseInt(values.operador));
    // values.allOperator = operator[0];

    const _package = this.props.listPackages.filter((pack) => pack.idePaqueteOperador === parseInt(values.paquete));
    values.allPackage = _package[0];
    values.valor = values.allPackage.vlrComercial;

    values.allOperator = values.allPackage.operator;

    this.setState({ valueReload: values.valor });

    values.ideProducto = values.allOperator.ideProducto;
    values.sigla = values.allOperator.sigla;
    values.numero = values.celular;
    values.vlrRecarga = values.valor;
    values.ideOperador = values.allOperator.ideOperadorGana;
    values.idePaqueteMovil = values.allPackage.idePaqueteGANA;
    values.ideFormaPago = 5;
    values.esPaqueteRecarga = values.allOperator.esPaquete;

    this.props.addProductCart(values, 'recharges');
  }

  render() {
    const { loading, error, succes } = this.props;
    return (
      <Col xs={12} className="homeContainer">
        <Row>
          {!loading && succes &&
            <ModalComponent className="modal-dialog-centered modal-lg">
              <ProductAdded typeReload='Recarga Paquete' valueReload={this.state.valueReload}/>
            </ModalComponent>
          }
          <Container className="bg-primary">
            <ReloadsPackagesForm
              onSubmit={this.handleReloadPackage}
              loadingForm={loading}
              errorForm={error}
            />
          </Container>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) =>{
  const { listOperators } = state.reloads.operators;
  const { listPackages } = state.reloads.packages;
  const { loading, error, succes } = state.cart;
  return {
    loading,
    error,
    succes,
    listPackages,
    listOperators
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addProductCart(values, typeProduct) {
      dispatch(addProductCart(values, typeProduct, 'ReloadsPackagesForm'));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReloadsPackagesContainer);
