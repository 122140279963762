import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';

// Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';
import { loadPrizePlan } from './../../actions/PrizePlanActions';
import LotterieForm from './../../components/Lotteries/LotterieForm';
import { priceFormatter } from './../../helpers/formatMoney';

class PrizePlanListContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSearchPrizePlan = this.handleSearchPrizePlan.bind(this);
  }

  handleSearchPrizePlan = (values) => {
    this.props.loadPrizePlan({
      ideLoteria: values.loteria,
      fecha: values.fecha || null,
    });
  };

  componentDidMount() {
    this.props.loadPrizePlan({
      ideLoteria: this.props.idLotterieURL,
      fecha: null,
    });
  }

  render() {
    const { loading, error, prizePlan, idLotterieURL } = this.props;

    // Definir columnas para DataGrid usando renderCell para formatear celdas
    const columns = [
      {
        field: 'cantidad',
        headerName: 'Cantidad',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <div style={{ textAlign: 'center', width: '100%' }}>{params.value}</div>
        ),
      },
      {
        field: 'desPlanPremio',
        headerName: 'Descripción',
        width: 300,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <div style={{ textAlign: 'center', width: '100%' }}>{params.value}</div>
        ),
      },
      {
        field: 'vlrBrutoBillete',
        headerName: 'Bruto Billete',
        width: 200,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <div style={{ textAlign: 'center', width: '100%' }}>{priceFormatter(params.value)}</div>
        ),
      },
      {
        field: 'vlrNetoBillete',
        headerName: 'Neto Billete',
        width: 200,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <div style={{ textAlign: 'center', width: '100%' }}>{priceFormatter(params.value)}</div>
        ),
      },
      {
        field: 'vlrNetoFraccion',
        headerName: 'Neto Fracción',
        width: 200,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <div style={{ textAlign: 'center', width: '100%' }}>{priceFormatter(params.value)}</div>
        ),
      },
    ];

    const initialValues = {
      loteria: idLotterieURL,
    };

    return (
      <Row>
        <Col xs={12} className="mb-4 p-0">
          <LotterieForm
            classForm="form-lotterie-reverse"
            onSubmit={this.handleSearchPrizePlan}
            loadingForm={loading}
            initialValues={initialValues}
          />
        </Col>
        <Col xs={12}>
          {loading && <CircularProgress />}
          {prizePlan && (
            <Row className="justify-content-center">
              <Col md={12} className="p-0">
                <section className="table-custom-data table-prize-plan">
                  <DataGrid
                    rows={prizePlan.planPremios} // Usar los datos de la respuesta
                    columns={columns}
                    getRowId={(row) => row.id} // Asegurarse de que cada fila tiene un ID único
                    //pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    //autoHeight
                    disableSelectionOnClick
                    disableColumnMenu
                    rowHeight={100}
                    //noRowsOverlay={<Alert color="warning">No se encontraron resultados para la fecha.</Alert>}
                  />
                </section>
              </Col>
            </Row>
          )}
          {error && <Alert className="my-2 content-center" color="danger"> {error} </Alert>}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, prizePlan } = state.prizes;
  return {
    loading,
    error,
    prizePlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPrizePlan(values) {
      dispatch(loadPrizePlan(values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrizePlanListContainer);
