import { pagoPremioConstants } from '../constants/PagoPremioConstants';
import PagoPremioService from '../services/pagoPremioService';

//====================================================================
// Loading Pago Premio
//====================================================================

export const loadPagoPremio = () => (dispatch, getState) => {

  const user = getState().authentication.user;
  if (user) {
    const body = {
      'ideUsuario': user.userId
    };
    const pagoPremioRequest = () => ({
      type: pagoPremioConstants.LIST_PREMIO_REQUEST
    });

    const pagoPremioSuccess = payload => ({
      type: pagoPremioConstants.LIST_PREMIO_SUCCESS,
      payload
    });

    const pagoPremioError = error => ({
      type: pagoPremioConstants.LIST_PREMIO_FAILURE,
      error
    });

    dispatch(pagoPremioRequest());
    PagoPremioService.loadListPagoPremio(body)
      .then(
        listPagoPremio => {
          console.log('Éxito en la carga de datos desde listPagoPremio:', listPagoPremio);
          if (listPagoPremio.data && listPagoPremio.data.length) {
            listPagoPremio.data.forEach(premio => {
              premio.ideUsuario = user.ideUsuario;
            });
          }
          dispatch(pagoPremioSuccess(listPagoPremio.data));
        },
        error => {
          dispatch(pagoPremioError(error.message ? error.message : 'No se encontraron resultados de premios.'));
        }
      );
  }
};
