import { reloadsConstants } from './../constants/ReloadsConstants';

const initialState = {
  operators:{
    loadingOperators: false,
    listOperators: []
  },
  packages:{
    loadingPackages: false,
    listPackages: [],
    errorPackages: null
  }
};

export function reloads(state = initialState, action) {
  switch (action.type) {
    case reloadsConstants.RELOADS_OPERATORS_REQUEST:
      return {
        ...state,
        operators: {
          loadingOperators: true
        }
      };
    case reloadsConstants.RELOADS_OPERATORS_SUCCESS:
      return {
        ...state,
        operators: {
          listOperators: action.payload
        }
      };
    case reloadsConstants.RELOADS_PACKAGES_REQUEST:
      return {
        ...state,
        packages: {
          loadingPackages: true
        }
      };
    case reloadsConstants.RELOADS_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: {
          listPackages: action.payload
        }
      };
    case reloadsConstants.RELOADS_PACKAGES_FAILURE:
      return {
        ...state,
        packages: {
          errorPackages: action.error
        }
      };
    default:
      return state;
  }
}
