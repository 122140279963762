import 'whatwg-fetch';
import { getAuthApp } from './security/authApp';
import { decrypt } from './security/encryptorHandlerSecurity';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

class PayPrizeService {

  static loadPayPrizeHome() {

    const url = `${serverURL}/api/payprize`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error );
      });
  }

  static loadSinglePayPrize(id) {

    const url = `${serverURL}/api/payprize/${id}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => (response))
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default PayPrizeService;
