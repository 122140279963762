import 'whatwg-fetch';
import {decryptUserData,encryptUserData} from './security/informationCodingSecurity';
import { getAuthApp } from './security/authApp';
import { decrypt } from './security/encryptorHandlerSecurity';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

class WebConfisysService {

  static loadListWebConfisys(nombreConfisys) {

    const url = `${serverURL}/api/Users/etn/web-confisys`;
    const data = JSON.stringify({ nombre: nombreConfisys }, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

}

export default WebConfisysService;
