/**
 * Validar si un objeto existe y no esta vacio
 * @param {*} obj 
 * @returns Boolean
 */
export const isObjectNotEmpty = (obj) => {
  return obj !== null && 
          obj !== undefined &&
          typeof(obj) === 'object' && 
          Object.keys(obj).length > 0;
};