import { recargaOkiConstants } from '../constants/RecargaOkiConstants';

const initialState = {
  loading: false,
  recargaOki: null,
  error: null
};

export function recarga(state = initialState, action) {
  switch (action.type) {
    case recargaOkiConstants.LIST_OKI_REQUEST:
      return {
        loading: true
      };
    case recargaOkiConstants.LIST_OKI_SUCCESS:
      return {
        recargaOki: action.payload
      };
    case recargaOkiConstants.LIST_OKI_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
