import 'whatwg-fetch';
import {decryptUserData,encryptUserData} from '../services/security/informationCodingSecurity';
import { getAuthApp } from '../services/security/authApp';
import { decrypt } from './security/encryptorHandlerSecurity';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.json());
  }
  return response.json();
};

class UserService {
  static login(values) {
    const url = `${serverURL}/api/Users/etn/login`;
    let data;
    if (values.comercial) {
      data = JSON.stringify({ user: {...values, ideAplicacion: 6, esComercializador:'true'} }, null, 2);
    } else {
      data = JSON.stringify({ user: {...values, ideAplicacion: 6} }, null, 2);
    }
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.json());
        }

        return response.json();
      })
      .then(user => {
        // login successful if there's a jwt token in the response
        /* if (user.data && user.data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('userLoti', JSON.stringify(user.data));
        } */
        return user.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  
  static getUserArray(values) {
    const userArrayUrl = `${serverURL}/api/Users/etn/userArray`;

    let data;
    if (values.comercial) {
      data = JSON.stringify({ user: {...values, ideAplicacion: 6, esComercializador:'true'} }, null, 2);
    } else {
      data = JSON.stringify({ user: {...values, ideAplicacion: 6} }, null, 2);
    }

    // Realizar la segunda solicitud
    return fetch(userArrayUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: data
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.json());
        }

        return response.json();
      })
      .then(user => {
        // Devolver el resultado de la segunda solicitud
        return user.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('userLoti');
    localStorage.removeItem('cartLoti');
  }

  static getById(id) {
    const url = `${serverURL}/users/${id}`;

    return fetch(url, {
      method: 'GET',
      headers: new Headers({'Authorization': getAuthApp()})
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static activeAccount(values) {

    const url = `${serverURL}/api/Users/etn/activate-account`;
    const data = JSON.stringify({user: {...values}}, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'cache': false,
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static getUserWithToken(values) {

    const url = `${serverURL}/api/Users/etn/consultar-token-usuario`;
    const data = JSON.stringify({user: {...values}}, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'cache': false,
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static deleteUser(values) {

    const url = `${serverURL}/api/Users/etn/cancel-account`;
    const data = JSON.stringify({user: {...values}}, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'cache': false,
        'Authorization': getAuthApp()
      })
    })
      .then(response => {
        var res = handleResponse(response);
        res.toEliminar = true;
        return res;
      })
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static register(values) {
    const obEncryptUserData = encryptUserData(values);
    const url = `${serverURL}/api/Users/etn/singup`;
    const data = JSON.stringify({user: {...obEncryptUserData}}, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'cache': false,
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static update(user) {
    const url = `${serverURL}/api/Users/etn/actualizar-usuario`;
    const data = JSON.stringify({user: {...user}}, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static changePasswordWeb(user) {
    user = encryptUserData(user);
    const url = `${serverURL}/api/Users/etn/cambiar-contrasena-web`;
    const data = JSON.stringify({user: {...user}}, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static getInfo(values) {

    const url = `${serverURL}/api/Users/etn/consultar-usuarios`;
    const data = JSON.stringify({user: {...values}}, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static getPuntos(values) {

    const url = `${serverURL}/api/Users/etn/consultar-puntos`;
    const data = JSON.stringify({user: {...values}}, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static changePasswordRequest(values) {
    const url = `${serverURL}/api/Users/etn/recuperar-contrasena`;
    const data = JSON.stringify({ user: {...values} }, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static changePassword(values) {
    let obDecryptUserData = {
      tipoDocumento: values.tipoDocumento,
      usuario: values.usuario
    };
    obDecryptUserData = decryptUserData(obDecryptUserData);
    values.tipoDocumento = obDecryptUserData.tipoDocumento;
    values.usuario = obDecryptUserData.usuario;
    const obEncryptValues = encryptUserData(values);
    const url = `${serverURL}/api/Users/etn/actualizar-contrasena`;
    const data = JSON.stringify({ user: {...obEncryptValues} }, null, 2);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

}

export default UserService;
