import { orderConstants } from './../constants/OrderConstants';

const initialState = {
  check: {
    loading: false,
    success: null,
    error: null
  }
};

export function order(state = initialState, action) {
  switch (action.type) {
    case orderConstants.GET_ORDER_STATE_REQUEST:
      return {
        ...state,
        check: {
          loading: true
        }
      };
    case orderConstants.GET_ORDER_STATE_SUCCESS:
      return {
        ...state,
        check: {
          success: action.payload
        }
      };
    case orderConstants.GET_ORDER_STATE_FAILURE:
      return {
        ...state,
        check: {
          error: action.error
        }
      };
    default:
      return state;
  }
}
