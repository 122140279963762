import { prizePlanConstants } from './../constants/PrizePlanConstants';
import PrizePlanService from './../services/PrizePlanService';

//====================================================================
// Loading Home Results
//====================================================================

export const loadPrizePlan = (values) => (dispatch) => {

  const prizePlanRequest = () => ({
    type: prizePlanConstants.LIST_PRIZE_REQUEST
  });

  const prizePlanSuccess = payload => ({
    type: prizePlanConstants.LIST_PRIZE_SUCCESS,
    payload
  });

  const prizePlanError = error => ({
    type: prizePlanConstants.LIST_PRIZE_FAILURE,
    error
  });

  dispatch(prizePlanRequest());
  PrizePlanService.loadListPrize(values)
    .then(
      listPrize => {
        if (listPrize.data.planPremios && listPrize.data.planPremios.length) {
          listPrize.data.planPremios.forEach((prize, i) => {
            prize.id = i;
          });
        }
        dispatch(prizePlanSuccess(listPrize.data));
      },
      error => {
        dispatch(prizePlanError(error.message ? error.message : 'Ocurrió un error al momento de traer el listado de premios'));
      }
    );

};
