import 'whatwg-fetch';
import { getAuthApp } from './security/authApp';
import { decrypt } from './security/encryptorHandlerSecurity';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return response.json();
};

class PayPrizeHomeService {

  static loadHomePayPrize() {
    const url = `${serverURL}/api/homepayprize`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => {
        return response;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }


  static loadSinglePayPrizeHome(id) {

    const url = `${serverURL}/api/homepayprize/${id}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      }
    })
      .then(handleResponse)
      .then(response => (response))
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default PayPrizeHomeService;
