//Dependencis
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container } from 'reactstrap';


import { toggleModalGeneral } from './../../../actions/ModalActions';
import trofeo from './../../../assets/images/trofeo.svg';
import ganador from './../../../assets/images/ganador.png';
import { Link } from 'react-router-dom';



const IsWinnerSucces = ({ toggleModalGeneral }) => {

  return (
    <Container>

      <Col>
        <section className="p-5 bg-white border-rad-lg">
          <div className='text-center'>
            <img src={trofeo} />
          </div>
          <div>
            <h3 className="text-center first-title-consulta mb-4 color-black">¡Felicitaciones, <strong> Ganaste</strong>!</h3>
            <Row>
              <Col xs={12} sm={12} md={7} lg={7} className="text-center align-end posiciones">
                <img class="img-responsives" src={ganador} />
              </Col>
              <Col xs={12} sm={12} md={5} lg={5} className="text-center posiciones mt60">
                <div>
                  <p >
                    <div className='text1'>
                      <strong>Para continuar revisa tu correo electronico o visita el punto de venta más cercano</strong>
                    </div>
                  </p>
                  <p><strong className='text2 py-10'>Sigue jugando y ganando con Loti</strong></p>
                  <div className='mt40'>
                    <Link onClick={toggleModalGeneral} id='jugar' className="button-rounded button-rounded-gradient-resultados px-30 py-10" to="/loterias">
                      Volver a jugar
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </Col>

    </Container>

  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModalGeneral() {
      dispatch(toggleModalGeneral());
    }
  };
};

export default connect(false, mapDispatchToProps)(IsWinnerSucces);
