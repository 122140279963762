import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { closeModalAlert } from './../../actions/AlertActions';


const Alert = ({ color, message, title, closeModalAlert, open }) => {
  return (
    <Modal isOpen={open} toggle={closeModalAlert}>
      <ModalHeader toggle={closeModalAlert}>{title}</ModalHeader>
      <ModalBody>
        <Alert color={color}>
          <div dangerouslySetInnerHTML={{__html: message}} />
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={closeModalAlert}>Do Something</Button>{' '}
        <Button color="secondary" onClick={closeModalAlert}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = state => {
  const { open, color, message, title } = state.alert;
  return {
    open,
    color,
    message,
    title
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModalAlert() {
      dispatch(closeModalAlert());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
