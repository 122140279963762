import React, { Component } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

//Components
import renderField from './../../renderFields/renderField';
import renderSelect from './../../renderFields/renderSelect';
import renderTextArea from './../../renderFields/renderTextArea';
import renderRadio from './../../renderFields/renderRadio';
import renderCheckBox from './../../renderFields/renderCheckBox';

//Utils
import Validators from './../../renderFields/validatorsFields';

const UpperCase = value => value.toUpperCase();

const labelTratamientos =`<span>
Acepto las <a href="/politicas-privacidad" target="_blank">Políticas de tratamiento de datos</a>
</span>
`;

class PqrsForm extends Component {

  constructor (props) {
    super(props);

    this.state = {
      title: '',
      message: ''
    };
  }

  componentDidMount() {
    const radio = this.props.radioList.find((radio) => {
      return radio.value === this.props.initialValues.issue;
    });
    if (radio) {
      this.setState({title: radio.label, message: radio.content});
    }
  }

  render() {
    const {
      handleSubmit,
      onDelete,
      pristine,
      initialValues,
      reset,
      submitting,
      loadingForm,
      readOnly,
      radioList,
      tratmentsValue
    } = this.props;

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <Row className="mb-4">
          <Col xs={12}>
            <Field
              name="issue"
              id="pq-tema"
              component={renderRadio}
              radioList={radioList}
              validate={[Validators.required]}
              onChange={event => {
                const radio = radioList.find((radio) => {
                  return radio.value === event.target.value;
                });
                this.setState({title: radio.label, message: radio.content});
              }}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <section>
              <h5 className="mb-3 interna-resultados">{ this.state.title }</h5>
              <p className="font-montserrat-light">{ this.state.message }</p>
            </section>
          </Col>
          <Col xs="12" sm="12" md="6">
            <Field
              name="document_type"
              id="pg-tipoDocumento"
              type="select"
              label="Tipo de Documento"
              icon="flaticon-arrows"
              component={renderSelect}
              validate={[Validators.required, Validators.notNull]}
              readOnly={readOnly}
            >
              <option value="default">Tipo de Documento</option>
              <option value="CC">Cédula de  ciudadanía</option>
              <option value="NIT">Num. Ident. Empre</option>
              <option value="PA">Pasaporte</option>
              <option value="CE">Cédula de extranjería</option>
              <option value="NU">Nro único id. Persona</option>
              {/* <option value="OT">Otro</option> */}
              <option value="PD">Pase diplomático</option>
              <option value="CD">Carnet diplomático</option>
              <option value="TE">Tarjeta de extranjería</option>
            </Field>
          </Col>
          <Col xs="12" sm="12" md="6">
            <Field
              name="document"
              id="pq-document"
              type="text"
              icon="flaticon-profile"
              label="Número de Identificación"
              component={renderField}
              normalize={UpperCase}
              validate={[Validators.required, Validators.documentTypePQ]}
              readOnly={readOnly}
            />
          </Col>

          <Col xs="12" sm="12" md="6">
            <Field
              name="name"
              id="pq-name"
              type="text"
              icon="flaticon-profile"
              label="Nombre"
              component={renderField}
              validate={[Validators.required, Validators.minLength3, Validators.maxLength150, Validators.named]}
              readOnly={readOnly}
            />
          </Col>
          <Col xs="12" sm="12" md="6">
            <Field
              name="last_name"
              id="pq-apellido"
              type="text"
              icon="flaticon-profile"
              label="Apellido"
              component={renderField}
              validate={[Validators.required, Validators.minLength3, Validators.maxLength50, Validators.lastname]}
              readOnly={readOnly}
            />
          </Col>

          <Col xs="12" sm="12" md="6">
            <Field
              name="email"
              id="pq-email"
              type="email"
              icon="flaticon-email"
              label="Correo Electrónico"
              component={renderField}
              validate={[Validators.required, Validators.email, Validators.minLength5, Validators.maxLength80]}
              readOnly={readOnly}
            />
          </Col>
          <Col xs="12" sm="12" md="6">
            <Field
              name="telephone"
              id="pq-telephone"
              type="number"
              icon="flaticon-phone-call"
              label="Teléfono o Móvil"
              component={renderField}
              validate={[Validators.required, Validators.minLength4, Validators.maxLength30]}
              readOnly={readOnly}
            />
          </Col>

          <Col xs={12}>
            <Field
              name="comment"
              id="pq-comentario"
              icon="fa fa-comment-o"
              rows="3"
              label="¿En qué te podemos ayudar?"
              component={renderTextArea}
              validate={[Validators.required, Validators.minLength3, Validators.maxLength150]}
              readOnly={readOnly}
            />
          </Col>
          <Col xs={12} sm={12} md={8} className="text-left">
            <Field
              name="tratments2"
              id="tratments2"
              formGroupClass="mb-0"
              label={labelTratamientos}
              component={renderCheckBox}
              validate={[Validators.required]}
            />
          </Col>
        </Row>

        <button
          type="submit"
          className="btn btn-primary"
          disabled={loadingForm || (!tratmentsValue)}
        >
          Enviar
          {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
        </button>
      </form>
    );
  }
}
const selector = formValueSelector('PqrsForm');

const mapStateToProps = (state) =>{
  return {
    tratmentsValue: selector(state, 'tratments2')
  };
};

export default connect(mapStateToProps)(reduxForm({ form: 'PqrsForm' })(PqrsForm));
