export const userConstants = {
  REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',
  REGISTER_USER_CLEAR: 'REGISTER_USER_CLEAR',

  LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILURE: 'LOGIN_USER_FAILURE',
  LOGIN_USER_CLEAR: 'LOGIN_USER_CLEAR',
  LOGOUT_USER: 'LOGOUT_USER',

  REQUEST_ACTIVE_ACCOUNT:'REQUEST_ACTIVE_ACCOUNT',
  REQUEST_ACTIVE_ACCOUNT_SUCCESS:'REQUEST_ACTIVE_ACCOUNT_SUCCESS',
  REQUEST_ACTIVE_ACCOUNT_FAILURE:'REQUEST_ACTIVE_ACCOUNT_FAILURE',

  RECOVERY_PASSWORD_REQUEST:'RECOVERY_PASSWORD_REQUEST',
  RECOVERY_PASSWORD_SUCCESS:'RECOVERY_PASSWORD_SUCCESS',
  RECOVERY_PASSWORD_FAILURE:'RECOVERY_PASSWORD_FAILURE',

  REQUEST_CHANGE_PASSWORD_REQUEST: 'REQUEST_CHANGE_PASSWORD_REQUEST',
  REQUEST_CHANGE_PASSWORD_SUCCESS: 'REQUEST_CHANGE_PASSWORD_SUCCESS',
  REQUEST_CHANGE_PASSWORD_FAILURE: 'REQUEST_CHANGE_PASSWORD_FAILURE',
  REQUEST_CHANGE_PASSWORD_CLEAR: 'REQUEST_CHANGE_PASSWORD_CLEAR',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  UPDATE_USER_CLEAR: 'UPDATE_USER_CLEAR',

  DELETE_USER_REQUEST:'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS:'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE:'DELETE_USER_FAILURE',
  DELETE_USER_CLEAR:'DELETE_USER_CLEAR',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  CHANGE_PASSWORD_CLEAR: 'CHANGE_PASSWORD_CLEAR',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  GET_USER_WITH_TOKEN_REQUEST:'GET_USER_WITH_TOKEN_REQUEST',
  GET_USER_WITH_TOKEN_SUCCESS:'GET_USER_WITH_TOKEN_SUCCESS',
  GET_USER_WITH_TOKEN_FAILURE:'GET_USER_WITH_TOKEN_FAILURE',

  TOGGLE_MODAL_LOGIN: 'TOGGLE_MODAL_LOGIN',
  CLOSE_MODAL_LOGIN: 'CLOSE_MODAL_LOGIN'
};
