import { locationsConstants } from './../constants/LocationsConstants';

const initialState = {
  countries: {
    list: [],
    loading: false
  },
  documents: {
    states: {
      list: [],
      loading: false
    },
    cities: {
      list: [],
      loading: false
    }
  },
  user: {
    states: {
      list: [],
      loading: false
    },
    cities: {
      list: [],
      loading: false
    }
  }
};

export function locations(state = initialState, action) {
  switch (action.type) {
    case locationsConstants.GET_COUNTRIES:
      return {
        ...state,
        countries: {
          loading: true
        }
      };
    case locationsConstants.SET_COUNTRIES:
      return {
        ...state,
        countries: {
          list: action.payload
        }
      };
    case locationsConstants.GET_STATES_DOCUMENT:
      return {
        ...state,
        documents:{
          ...state.documents,
          states: {
            loading: true
          }
        }
      };
    case locationsConstants.SET_STATES_DOCUMENT:
      return {
        ...state,
        documents:{
          ...state.documents,
          states: {
            list: action.payload
          }
        }
      };
    case locationsConstants.GET_CITIES_DOCUMENT:
      return {
        ...state,
        documents:{
          ...state.documents,
          cities: {
            loading: true
          }
        }
      };
    case locationsConstants.SET_CITIES_DOCUMENT:
      return {
        ...state,
        documents:{
          ...state.documents,
          cities: {
            list: action.payload
          }
        }
      };
    case locationsConstants.GET_STATES_USER:
      return {
        ...state,
        user:{
          ...state.user,
          states: {
            loading: true
          }
        }
      };
    case locationsConstants.SET_STATES_USER:
      return {
        ...state,
        user:{
          ...state.user,
          states: {
            list: action.payload
          }
        }
      };
    case locationsConstants.GET_CITIES_USER:
      return {
        ...state,
        user:{
          ...state.user,
          cities: {
            loading: true
          }
        }
      };
    case locationsConstants.SET_CITIES_USER:
      return {
        ...state,
        user:{
          ...state.user,
          cities: {
            list: action.payload
          }
        }
      };
    default:
      return state;
  }
}
